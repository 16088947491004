import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';

@Injectable({ providedIn: 'root' })
export class ReportsResolver {
    hideSpinner: Boolean;
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private reportResource: ReportResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        this.hideSpinner = activatedRoute.params.hideSpinner === 'true';

        const reportsPromise = this.reportResource.reportListV2().then((data) => {
            return data;
        });
        if (this.hideSpinner) {
            // don't show spinner if we are prefiltering to prevent double spinner
            return reportsPromise;
        } else {
            return this.loadingSpinnerService.spinnerifyPromise(reportsPromise);
        }
    }
}
