import { Component } from '@angular/core';
import { KCBreadcrumbsService } from '@services/core/kc-breadcrumbs.service';

@Component({
    selector: 'containers-landing-page',
    templateUrl: './containers-landing-page.html',
    styleUrls: ['./containers-landing-page.scss'],
})
export class ContainersLandingPage {
    constructor(private kcBreadcrumbsService: KCBreadcrumbsService) {}

    ngOnInit() {
        this.kcBreadcrumbsService.changeBreadcrumbsVisibility(false);
    }
}
