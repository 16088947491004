import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RecallResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const recallId = parseInt(activatedRoute.params.recallId);
        const recallPromise = this.recallResource.getRecall(recallId);
        return this.loadingSpinnerService.spinnerifyPromise(recallPromise);
    }
}
