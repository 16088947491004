import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';

import { DemoScan } from '@models/core/scan';

@Injectable()
export class ScanRedirectionService {
    isHome: Boolean;
    constructor(
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translationService: TranslationService
    ) {
        activatedRoute.url.subscribe((url) => (this.isHome = url[0].path === ''));
    }

    demoRedirect(demoScan: DemoScan) {
        this.router.navigate([demoScan.state], { queryParams: demoScan.params });
    }

    redirectScan(data) {
        if (data.view === 'batch_verification_scan') {
            this.router.navigate([`/scan/batch-verify/${data.id}`]);
        } else if (data.view === 'implicit_return_scan') {
            this.router.navigate([`/kit/${data.kit_ids[0]}/${data.id}`]);
        } else if (data.view === 'kit_scan_summary') {
            this.router.navigate([`/kit/${data.kit_ids[0]}/${data.id}`]);
        } else if (data.view === 'item_association') {
            this.router.navigate(['/tagging'], {
                queryParams: { type: 'batch', barcode: data.id },
            });
        } else if (data.view === 'single_item_scan') {
            this.router.navigate([`/inventory/manage-items/${data.single_item_scan_epc}`]);
        } else if (this.hospitalInfoService.allowShelvedInventory() && data.view === 'bin_scan_summary') {
            this.router.navigate([`/bin/${data.bin_ids[0]}/${data.id}`]);
        } else if (data.view === 'scan_details') {
            this.router.navigate([`/scan/details/${data.id}`]);
        } else if (this.isHome) {
            this.unrecognizedError(data);
        } else {
            this.router.navigate(['/']).then(() => {
                this.unrecognizedError(data);
            });
        }
    }

    unrecognizedError(data) {
        let message =
            this.translationService.instant('scan.errors.unrecognized_banner') +
            `${data.payload_composition_type?.split('_').join(' ')}`;
        this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
    }
}
