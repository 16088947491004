import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApplicationService } from '@services/system/application.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoginStateService } from '@services/login/login-state.service';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorResponseInterceptor implements HttpInterceptor {
    queue = new Map<string, Observable<HttpEvent<any>>>();

    constructor(
        private applicationService: ApplicationService,
        private loginStateService: LoginStateService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const obs = next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // an error status of 0 is also unknown, but it looks like in
                // ajs we just ignore those, so ???
                if ((error.status >= 200 && error.status < 300) || error.status === 0) {
                    return of(new HttpResponse(req));
                }

                // Don't log them out on a badge error, just show the error
                if (error.status === 401 && !!error.error.extra_authentication_required) {
                    throw error.error;
                }

                let errorMsg = '';
                if (error.error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                    errorMsg = `An error occurred: ${error.error.message}`;
                } else if (error.status && error.error) {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    errorMsg = `${error.error.message ? error.error.message : error.error}`;
                } else {
                    errorMsg = 'Unknown Server Error!';
                }

                // used to suppress the system error in certain cases. PLEASE USE SPARINGLY as this will hide ANY error message returned from the server.
                if (this.applicationService.suppressError) {
                    // clear our supression flag (gets set right before the call);
                    this.applicationService.suppressError = false;
                } else if (error.status !== 401) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, errorMsg);
                }

                // 401 Unauthorized - if it's the activity endpoint ignore it,
                // The frontend will handle calling logout
                if (error.status === 401 && !req.url.match(/api\/v2\/activity/)) {
                    this.loginStateService.notifyLogout();
                    this.router.navigate(['/login']);
                    localStorage.removeItem('dcui.oat');
                }

                error.error.__status = error.status;
                throw error.error;
            })
        );

        return obs;
    }
}
