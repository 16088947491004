import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '@components/common/table-component';
import { ScanningDialog } from '@components/dialogs/scanner/scanning-dialog/scanning-dialog';
import { Container } from '@models/core/container';
import { ContainerScanSummary } from '@models/core/container-scan';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

@Component({
    selector: 'containers-detail',
    templateUrl: './containers-detail.html',
    styleUrls: ['./containers-detail.scss'],
})
export class ContainersDetail extends TableComponent {
    @ViewChild(MatSort) sort: MatSort;
    protected tab = 'summary';
    container: Container;
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;
    scanningDialog: MatDialogRef<any>;
    containerScan: ContainerScanSummary;
    containerId: number;
    scanId?: number;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.containerId = parseInt(paramMap.get('id'));
            this.scanId = parseInt(paramMap.get('scanId')) || undefined;
        });

        this.fetchContainerData();
    }

    fetchContainerData() {
        const promises = [];
        if (!this.containerId) {
            return;
        }

        promises.push(
            this.containerResource.getContainer(this.containerId).then(({ container }) => {
                this.container = container;
            })
        );

        if (this.scanId) {
            promises.push(
                this.containerResource.getContainerScanSummary(this.containerId, this.scanId).then((data) => {
                    this.containerScan = data;
                })
            );
        }

        this.loadingSpinnerService.spinnerifyPromise(Promise.all(promises));
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    handleOnSegmentDelete() {
        this.fetchContainerData();
    }

    handleOnSegmentSave() {
        this.fetchContainerData();
    }

    startScan() {
        this.scanningDialog = this.dialog.open(ScanningDialog, {
            width: 'max-content',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                scanner: this.container.hardware,
                stayAfterScan: true,
            },
        });

        this.scanningDialog.afterClosed().subscribe((scanResult) => {
            if (scanResult.id) {
                this.router.navigate([`/inventory/containers/${this.containerId}/${scanResult.id}`]);
            }
        });
    }
}
