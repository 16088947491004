import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { HardwareService } from '@services/hardware/hardware.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { isEmpty } from '@utils/objects';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'admin-hardware-update',
    templateUrl: './admin-hardware-update.html',
})
export class AdminHardwareUpdate {
    //bindings
    hardware: any;

    originalHardware: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareService: HardwareService
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.hardware = data.hardware;
        });

        this.originalHardware = _.cloneDeep(this.hardware);
    }

    save(): void {
        //send only diff
        const updatedHardware: any = {};
        if (this.hardware.name !== this.originalHardware.name) {
            updatedHardware.name = this.hardware.name;
        }

        if (!isEmpty(updatedHardware)) {
            updatedHardware.id = this.hardware.id;
            const updatePromise = this.hardwareService.updateHardware(updatedHardware).then(() => {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.router.navigate(['/admin/hardware']),
                    LoadingSpinnerTypes.PANEL
                );
            });
            this.loadingSpinnerService.spinnerifyPromise(updatePromise, LoadingSpinnerTypes.PANEL);
        }
    }
}
