import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigurationProvider {
    constructor() {}

    getConfiguration() {
        return {
            api_endpoint: 'https://kc-api.kitcheck.integration.bluesight.com',
            api_regex: 'https:\/\/kc-api\.kitcheck\.integration\.bluesight\.com.*',
            phi_endpoint: 'https://api.phi.integration.bluesight.com',
            phi_regex: 'https?:\/\/api\.phi\.integration\.bluesight\.com.*',
            environment: 'integration',
            demo_scan_links: JSON.parse('[{"label":"Incomplete Scan","state":"kit-scan","params":{"kitId":131,"scanId":314}},{"label":"Complete Scan","state":"kit-scan","params":{"kitId":132,"scanId":328}},{"label":"Add Items (1)","state":"tagging","params":{"type":"item","barcode":"800100D700000000002B7441"}},{"label":"Add Items (5)","state":"tagging","params":{"type":"batch","barcode":326}}]'),
            year: '2025',
            version: '8ab2d9f20f6b070193b6a2a3b84abbec6c7236a2',
            gainsight_px_key: 'AP-RVIOKG03FB47-2-4',
            churn_zero_app_key: '1!qTG1Sf9iyFFNE3JWmLUjAsYlydUdsitWIbpuUyxBmR8tDFA',
            datadog_app_id: 'c754e6c0-8f4b-4623-af8e-c15f538ca451',
            datadog_client_token: 'pubf536a9cf65f99d43c0d634093b010beb',
            make_backwards: 'false',
            bluesight_domain: 'https://ui.kitcheck.integration.bluesight.com/',
            fresh_chat_token: 'df44ccb0-1362-42c0-a89c-80a0db926163',
            fresh_chat_uuid: 'db6ab332-cf5c-4e91-aec4-ec850463fb15',
            fresh_chat_host: 'https://bluesight-support.freshchat.com',
            launch_darkly_client_side_id: '64ef9da81b0151128c364f66',
            mobile_app_url: 'https://integration.mobile.kitcheck.bluesight.com',
            local_storage_encryption_code: '9a337fabae445c3ccee6c63b9166f4c2',
        };
    }

    getCognitoConfig() {
        return {
            cognito_authorization_endpoint: 'https://kitcheck-integration.auth.us-east-1.amazoncognito.com/oauth2/authorize',
            cognito_logout_endpoint: 'https://kitcheck-integration.auth.us-east-1.amazoncognito.com/logout',
            cognito_client_id: '3npbl3ah1el2tsssvkbegmu2su',
            cognito_redirect_uri: 'https://ui.kitcheck.integration.bluesight.com/login',
        };
    }

    kcEndpointV1() {
        return this.getConfiguration().api_endpoint + '/api/v1/';
    }

    kcEndpointV2() {
        return this.getConfiguration().api_endpoint + '/api/v2/';
    }

    kcEndpointV3() {
        return this.getConfiguration().api_endpoint + '/api/v3/';
    }

    phiEndpointV1() {
        return this.getConfiguration().phi_endpoint + '/api/v1/';
    }

    isDemo() {
        let environment = this.getConfiguration().environment;
        return environment === 'carefusion' || environment === 'demo' || environment === 'trade_show';
    }

    bluesightDomain() {
        return this.getConfiguration().bluesight_domain;
    }

    allowResetButton() {
        return this.isDemo();
    }
}
