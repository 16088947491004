import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';

import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class NgxLoginService {
    $config: any;
    $snackBar: any;
    listeners: Function[] = [];
    showPanel: boolean = true;
    appName: string;
    switching: boolean = false;
    demoToast: string;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private router: Router
    ) {
        setTimeout(() => {
            this.$config = window['config'];
            this.$snackBar = window['snackBar'];
        });
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            /* eslint-disable */
            'X-App-Name': this.appName,
            /* eslint-enable */
        });
    }

    public cognitoLogout(): void {
        const samlLogoutEnabled: boolean = this.getLocalStorageItem('hospitalSettings').saml_logout_enabled;
        if (!this.getLocalStorageItem('idpLogin') || !samlLogoutEnabled) return;

        const cognitoConfig = this.$config.getCognitoConfig();
        const logout_uri_param = `logout_uri=${cognitoConfig.cognito_redirect_uri}`;
        const redirect_uri_param = `redirect_uri=${cognitoConfig.cognito_redirect_uri}`;
        const client_id_param = `client_id=${cognitoConfig.cognito_client_id}`;
        const query_params_string = [client_id_param, logout_uri_param, redirect_uri_param].join('&');

        const fullCognitoLogoutUrl = cognitoConfig.cognito_logout_endpoint + '?' + query_params_string;

        window.open(fullCognitoLogoutUrl, '_self');
    }

    // See RFC 6749 Sec. 3.1 @ https://tools.ietf.org/html/rfc6749#section-3.1
    public cognitoHandshake(idp_identifier: string): void {
        const cognitoConfig = this.$config.getCognitoConfig();
        const response_type_param = 'response_type=code';
        const client_id_param = `client_id=${cognitoConfig.cognito_client_id}`;
        const redirect_uri_param = `redirect_uri=${cognitoConfig.cognito_redirect_uri}`;
        const idp_identifier_param = `idp_identifier=${idp_identifier}`;
        const query_params_string = [
            response_type_param,
            client_id_param,
            redirect_uri_param,
            idp_identifier_param,
        ].join('&');

        window.open(cognitoConfig.cognito_authorization_endpoint + '?' + query_params_string, '_self');

        this.setLocalStorageItem('idpLogin', true);
    }

    public updateUser(user): Observable<any> {
        return this.http
            .put<any>(this.$config.kcEndpointV3() + `users/${user.id}`, user, { headers: this.getHeaders() })
            .pipe(
                catchError((err) => {
                    this.showToast(err.message);

                    return EMPTY;
                })
            );
    }

    public forgotPassword(email: string): Observable<any> {
        return this.http.post<any>(
            this.$config.kcEndpointV3() + 'forgot_password',
            {
                email,
            },
            { headers: this.getHeaders() }
        );
    }

    public resetPassword(user): Observable<any> {
        return this.http.put<any>(
            this.$config.kcEndpointV3() + 'reset_password',
            {
                reset_password_token: user.token,
                password: user.password,
            },
            { headers: this.getHeaders() }
        );
    }

    public getHospitalPasswordRules(token: any): Observable<any> {
        return this.http.post<any>(
            this.$config.kcEndpointV3() + 'reset_password_settings',
            {
                reset_password_token: token,
            },
            { headers: this.getHeaders() }
        );
    }

    public clearDataForSwitch(): void {
        const idp_login = this.getLocalStorageItem('idpLogin');
        localStorage.clear();
        if (idp_login === 'true') {
            this.setLocalStorageItem('idpLogin', true);
        }

        return;
    }

    public getHospitalAccess(): any[] {
        return this.getLocalStorageItem('hospitalAccess') || [];
    }

    public getUserName(): string {
        return this.getLocalStorageItem('userName');
    }

    public isLoggedIn(): boolean {
        return this.getLocalStorageItem('loggedIn') === 'true';
    }

    public isAdminUser(): boolean {
        return this.getLocalStorageItem('adminUser') === 'true';
    }

    public checkLoginMethod(unencodedEmail: string): Observable<any> {
        const email = encodeURIComponent(unencodedEmail);
        return this.http.get<any>(this.$config.kcEndpointV2() + 'login_method', {
            params: { email },
        });
    }

    public cognitoLoginRequest(cognitoCode: string, serialNumber?, client?): Observable<any> {
        const data = {
            code: cognitoCode,
            device_serial_number: serialNumber,
            client: client,
            application: this.appName,
        };

        return this.http
            .post<any>(this.$config.kcEndpointV2() + 'login_idp', data, { headers: this.getHeaders() })
            .pipe(
                catchError((err) => {
                    this.showToast(err.message);

                    return EMPTY;
                }),
                map((result) => {
                    result.user.hospital_access = this.restrictHospitalAccess(result.user.hospital_access);
                    return result;
                }),
                concatMap((result) => {
                    if (result.user.hospital_access.length === 1) {
                        this.setLocalStorageItem('tokenType', result.user.token_type);
                        this.setLocalStorageItem(
                            'oat',
                            result.user.token_type + ' ' + result.user.authentication_token
                        );

                        return this.sessionHospital(result.user.hospital_access[0].id);
                    }
                    return of(result);
                })
            );
    }

    public loginRequest(username: string, password: string, serialNumber?, client?): Observable<any> {
        const data = {
            username: username,
            password: password,
            device_serial_number: serialNumber,
            client: client,
            application: this.appName,
        };

        return this.http.post<any>(this.$config.kcEndpointV2() + 'login', data, { headers: this.getHeaders() }).pipe(
            catchError((err) => {
                this.showToast(err.message);

                return EMPTY;
            }),
            map((result) => {
                result.user.hospital_access = this.restrictHospitalAccess(result.user.hospital_access);
                return result;
            }),
            concatMap((result) => {
                if (result.user.hospital_access.length === 1) {
                    this.setLocalStorageItem('tokenType', result.user.token_type);
                    this.setLocalStorageItem('oat', result.user.token_type + ' ' + result.user.authentication_token);

                    return this.sessionHospital(result.user.hospital_access[0].id);
                }
                return of(result);
            })
        );
    }

    public storeDataForSwitch(data): void {
        this.setLocalStorageItem('tokenType', data.token_type);
        this.setLocalStorageItem('oat', data.token_type + ' ' + data.authentication_token);

        this.removeAuthData(data);

        this.setLocalStorageItem('firstName', data.first_name || '');
        this.setLocalStorageItem('lastName', data.last_name || '');
        this.setLocalStorageItem('userName', (data.first_name || '') + ' ' + (data.last_name || ''));
        this.setLocalStorageItem('adminUser', data.admin_user);
        data.hospital_access = this.restrictHospitalAccess(data.hospital_access);
        this.setLocalStorageItem('hospitalAccess', data.hospital_access || []);
    }

    public initiateLogin(userData): void {
        /* Seems to be X-Check only logic, ui-login-2 doesn't need to go live with crosscheck immediately?
        if (this.adminRequired && !data.admin_user) {
            this.logout(true)
            this.$timeout(() => {
                this.$rootScope.ui.systemCaution = 'Your account is not authorized to log in to CrossCheck. Please contact KitCheck support if you think this is an error.'
            })
            return
        }
        */

        this.storeLoginData(userData);

        if (userData.password_reset_required) {
            this.router.navigate(['/password-reset'], {
                queryParams: {
                    reset_token: userData.password_reset_token,
                },
            });
        } else {
            this.switching = false;
            this.router.navigate(['/']);
        }
    }

    public sessionHospital(hospitalId: number) {
        return this.http
            .put<any>(
                this.$config.kcEndpointV2() + 'sessions/me',
                {
                    current_hospital_id: hospitalId,
                },
                { headers: this.getHeaders() }
            )
            .pipe(
                map((result) => {
                    result.user.hospital_access = this.restrictHospitalAccess(result.user.hospital_access);
                    return result;
                })
            );
    }

    public setLocalStorageItem(key: string, value: any): void {
        if (typeof value === 'object' || typeof value === 'string') {
            value = JSON.stringify(value);
        }

        this.localStorageService.set(key, value);
    }

    public getLocalStorageItem(key: string): any {
        return this.localStorageService.get(key);
    }

    public showToast(message: string, duration?: number) {
        this.$snackBar.open(message, 'Dismiss', {
            duration: duration || 60000,
        });
    }

    public storeLoginData(data): void {
        this.setLocalStorageItem('tokenType', data.token_type);
        this.setLocalStorageItem('oat', data.token_type + ' ' + data.authentication_token);
        this.setLocalStorageItem('pat', data.token_type + ' ' + data.phi_access_token);
        this.setLocalStorageItem('sat', data.token_type + ' ' + data.sargas_api_token);
        this.setLocalStorageItem('zat', data.token_type + ' ' + data.zebra_api_token);

        this.removeAuthData(data);

        this.setLocalStorageItem('loggedIn', true);
        this.setLocalStorageItem('adminUser', data.admin_user);
        this.setLocalStorageItem('groupManager', data.group_manager);
        this.setLocalStorageItem('firstName', data.first_name || '');
        this.setLocalStorageItem('lastName', data.last_name || '');
        this.setLocalStorageItem('userName', (data.first_name || '') + ' ' + (data.last_name || ''));
        this.setLocalStorageItem('userId', data.id || 0);
        this.setLocalStorageItem('email', data.email || '');
        this.setLocalStorageItem('appModules', (data.valid_applications && data.valid_applications.kitcheck) || []);
        this.setLocalStorageItem('roles', data.roles || ['guest']);
        this.setLocalStorageItem('currentHospital', data.current_hospital || {});
        this.setLocalStorageItem('currentGroup', data.current_group || {});
        this.setLocalStorageItem('hospitalId', data.hospital_id || 0);
        this.setLocalStorageItem('actions', data.actions);
        this.setLocalStorageItem('defaultPage', data.start_application);
        this.setLocalStorageItem('userSignedLicense', data.signed_license);
        this.setLocalStorageItem('userNeedsSignature', data.needs_signature);
        this.setLocalStorageItem('hospitalSettings', data.hospital_settings || {});
        this.setLocalStorageItem('timeZone', data.time_zone || '');
        this.setLocalStorageItem('accountOwner', data.account_owner || '');
        this.setLocalStorageItem('onBehalfOfHospitalSettings', data.on_behalf_of_hospital_settings || {});
        this.setLocalStorageItem('firstLogin', data.first_login || false);
        this.setLocalStorageItem('passwordResetRequired', data.password_reset_required || false);
        this.setLocalStorageItem('passwordSettings', data.password_settings || {});
        this.setLocalStorageItem('hospitalAccess', data.hospital_access || []);
        this.setLocalStorageItem('hasBasicTags', data.hospital_has_basic_tags || false);
        this.setLocalStorageItem('rootIds', data.root_group_ids.root_ids || []);
        this.setLocalStorageItem('created_at', data.created_at);
    }

    private deleteLocalStorageItem(key: string): void {
        this.localStorageService.remove(key);
    }

    private removeAuthData(data): void {
        delete data.authentication_token;
        delete data.token_type;
        delete data.refresh_token;
        delete data.phi_access_token;
        delete data.sargas_api_token;
        delete data.zebra_api_token;
    }

    private isDispenseCheck(): boolean {
        return this.appName === 'dispensecheck';
    }

    private restrictHospitalAccess(hospitalArray: any[]): any[] {
        if (this.isDispenseCheck()) {
            hospitalArray = hospitalArray.filter((hospital) => hospital.bcs_enabled);
        }

        return hospitalArray;
    }
}
