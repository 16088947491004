export interface HospitalSettings {
    acheck_billing_enabled?: boolean;
    acheck_enabled?: boolean;
    aeroscout_enabled_internal: boolean;
    aeroscout_enabled: boolean;
    aggregate_billing_enabled: boolean;
    allow_manual_administrations: any;
    allow_remapping_custom_formulary_items: boolean;
    analytics_enabled: boolean;
    assign_locks_to_carts: boolean;
    assign_locks_to_kits: boolean;
    bcs_enabled: boolean;
    benchmarking_enabled: boolean;
    billsheet_enabled: boolean;
    cart_to_location_enabled: boolean;
    charge_model_type: ChargeModelType;
    chargesheet_billing: boolean;
    chargesheet_enabled: boolean;
    chargesheet_template: string;
    clear_empty_cart_location_enabled: boolean;
    dispensecheck_inactivity_timeout: number;
    email_on_receiving_package_transfer: boolean;
    emr_workstation_enabled: boolean;
    expiring_soon_days: number;
    features: Feature[];
    first_subscription_item_scan_date: any;
    go_live_dashboard: boolean;
    hardware_update_prompt: boolean;
    has_badges: boolean;
    hospital_id: number;
    hydrofoil_enabled: boolean;
    idp_identifier: any;
    kit_cart_location_override: boolean;
    kit_to_cart_enabled: boolean;
    kit_to_location_enabled: boolean;
    kitsummary_delta_on_right: boolean;
    locale_id: number;
    locale: string;
    login_method: string;
    logo_filename: string;
    low_credit_threshold: number;
    manual_locks_enabled: boolean;
    maximum_segment_templates: number;
    mayo_rtls_enabled: boolean;
    midmark_rtls_enabled: boolean;
    omnicell_tenant_id: any;
    positive_identification_enabled: boolean;
    pre_tagged_charge_model: string;
    prefill_batch_verification_enabled: boolean;
    pretagged_item_start_fridge_expiry_on_bin_scan: boolean;
    print_item_allow_fridge_date_on_tag: boolean;
    print_item_fridge_date_custom_msg: any;
    print_item_tag_quantity_max: number;
    qr_code_on_tag: boolean;
    reconciliation_by_package: boolean;
    remove_lock_from_cart_removes_lock_on_kit: boolean;
    remove_lock_from_kit_removes_lock_on_cart: boolean;
    saml_logout_enabled: boolean;
    scan_duration: number;
    scheduled_reports_enabled: boolean;
    session_inactivity_timeout: number;
    shared_kits_enabled: boolean;
    shelved_inventory_enabled: boolean;
    show_epcs_in_batch_summary_report: boolean;
    spinnaker_enabled: boolean;
    subscription_items_lockout_date: string;
    tag_file_import_class: any;
    tile_view_enabled: boolean;
    trusted_items_enabled: boolean;
    use_generic_item_name: boolean;
}

export interface ChargeModelType {
    id: number;
    name: ChargeModelTypeName;
    created_at: string;
    updated_at: string;
}

export enum ChargeModelTypeName {
    original = 'original',
    pay_as_you_go = 'pay_as_you_go',
    pending_all_you_need_contract = 'pending_all_you_need_contract',
    all_you_need = 'all_you_need',
    opt_out = 'opt_out',
}

interface Feature {
    name: string;
}
