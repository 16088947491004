import { Injectable } from '@angular/core';

@Injectable()
export class ReportDataService {
    private preFilter: any = null;

    getPreFilter() {
        return this.preFilter;
    }

    setPreFilter(preFilter: any) {
        this.preFilter = preFilter;
    }
}
