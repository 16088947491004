import { Component } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportingResource } from '@services/resources/reporting-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { ChartingService } from '@services/report/charting.service';

import { Report, ReportCategory } from '@models/core/report';
import {
    ExpirationAnalyticsItemType,
    ExpirationAnalyticsItem,
    ExpirationAnalyticsList,
    ExpirationTotals,
    ExpirationAnalyticsData,
    ModalFilters,
} from './expiration-analytics.model';

import { underscored } from '@utils/strings';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'expiration-analytics',
    templateUrl: './report-expiration-analytics.html',
    styleUrls: ['./report-expiration-analytics.scss'],
})
export class ReportExpirationAnalytics {
    // bindings
    expirationAnalytics: ExpirationAnalyticsData;
    reports: {
        report_categories: ReportCategory[];
    };

    // constants
    private SORT_ASC: string = 'ascending';
    private SORT_DESC: string = 'descending';
    private SORT_AMOUNT: string = 'amount';
    private SORT_ALPHA: string = 'alpha';

    private CHART_DRUGS: string = 'Drugs';
    private CHART_KIT_MASTERS: string = 'Kit Masters';

    private COST_WAC: string = 'wac';
    private COST_AWP: string = 'awp';

    private COST_TYPES: string[] = [this.COST_WAC, this.COST_AWP];

    private REMOVED_ALL: string = 'All';

    // attributes
    maximums = {};
    topLists = {};
    sorts = {
        [this.CHART_DRUGS]: {
            type: this.SORT_AMOUNT,
            direction: this.SORT_DESC,
        },
        [this.CHART_KIT_MASTERS]: {
            type: this.SORT_AMOUNT,
            direction: this.SORT_DESC,
        },
    };

    report: Report;
    downloadFilters: {
        price_type?: string;
        removed_reason?: string;
        start_date?: string;
        end_date?: string;
    };
    isDownloadable: boolean;
    expirationTypes: {
        name: string;
    }[];
    removedReasonTypes: {
        name: string;
    }[];
    totals: ExpirationTotals;
    filters: {
        costType?: string;
        removedState?: any;
        start_date?: Date | string;
        end_date?: Date | string;
    } = {};

    scheduledReportsEnabled: boolean;
    hasData: boolean;

    chartTranslations: {
        [key: string]: string;
    };
    barHeight: number = 20;
    textWidth: number = 232;
    chartWidth: number = 465;
    paddingH: number = 10;
    barWidth: number = this.chartWidth - this.textWidth - this.paddingH * 3;

    constructor(
        protected translationService: TranslationService,
        protected chartingService: ChartingService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportingResource: ReportingResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute
    ) {}

    private applySerializedData(item: ExpirationAnalyticsItem): void {
        const itemTypes: ExpirationAnalyticsItemType[] = item.types;
        itemTypes.forEach((itemType: ExpirationAnalyticsItemType) => {
            itemType.removed_reason = this.removedReasonTypes[itemType.removed_reason];
            itemType.expiration_type = this.expirationTypes[itemType.expiration_type];
        });
    }

    private processData(expirationAnalyticsData: ExpirationAnalyticsData): void {
        this.expirationAnalytics = expirationAnalyticsData;
        this.hasData = this.expirationAnalytics.expiration_types.length > 0;
        this.expirationTypes = expirationAnalyticsData.expiration_types;
        this.removedReasonTypes = expirationAnalyticsData.removed_reasons;
        this.topLists[this.CHART_DRUGS] = expirationAnalyticsData.top_drugs;
        this.topLists[this.CHART_KIT_MASTERS] = expirationAnalyticsData.top_kit_masters;
        this.totals = expirationAnalyticsData.totals;

        Object.keys(this.topLists).forEach((topListKey) => {
            const topList = this.topLists[topListKey];
            topList.items.forEach((listItem: any, index: number) => {
                const listKeys: string[] = Object.keys(listItem);
                if (listKeys.length === 1) {
                    listItem = topList[index] = listItem[listKeys[0]];
                }

                this.applySerializedData(listItem);
            });
        });
    }

    updateDownloadFilters(): void {
        const removed_reason: string = this.filters.removedState
            .map((removedState: any) => {
                return underscored(removedState.name);
            })
            .join('|');

        this.downloadFilters = {
            price_type: this.filters.costType,
            removed_reason,
            start_date: moment(this.filters.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.filters.end_date).format('YYYY-MM-DD'),
        };
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
            this.expirationAnalytics = data.expirationAnalytics;
        });

        const reportCategory = this.reports.report_categories.find((category) => category.name === 'analytics');
        this.report = reportCategory.reports.find((report) => report.name === 'expiration_analytics');
        this.isDownloadable = this.report.is_downloadable;
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;

        this.processData(this.expirationAnalytics);

        this.translationService
            .get([
                'reports.expiration_analytics.removed_reason',
                'reports.expiration_analytics.drug_name',
                'reports.expiration_analytics.expiration_type',
                'reports.expiration_analytics.num_items',
                'reports.expiration_analytics.cost',
            ])
            .then((translations) => {
                this.chartTranslations = translations;
            });
    }

    refreshReport(filterData: ModalFilters): void {
        this.filters = {
            start_date: filterData.date.start_date,
            end_date: filterData.date.end_date,
            costType: filterData.priceType,
            removedState: filterData.removedReasons,
        };

        const data = {
            start_date: this.filters.start_date,
            end_date: this.filters.end_date,
        };

        const promise = this.reportingResource.expirationAnalytics(data).then((expAnalytics) => {
            this.processData(expAnalytics);
            this.updateDownloadFilters();
            this.renderCharts();
        });

        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal(filterData: ModalFilters): void {
        const subscribeFilters = {
            start_date: filterData.date.start_date,
            priceType: filterData.priceType,
            removedReasons: filterData.removedReasons,
        };
        const subscription = undefined;

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    subscription: subscription,
                },
            });
        });
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    // Sorting

    showSortAsc(table: string, type: string): boolean {
        if (type === this.SORT_AMOUNT) {
            return this.currentSort(table, type) && this.sorts[table].direction === this.SORT_ASC;
        } else {
            return !this.currentSort(table, type) || this.sorts[table].direction === this.SORT_ASC;
        }
    }

    showSortDesc(table: string, type: string): boolean {
        if (type === this.SORT_AMOUNT) {
            return !this.currentSort(table, type) || this.sorts[table].direction === this.SORT_DESC;
        } else {
            return this.currentSort(table, type) && this.sorts[table].direction === this.SORT_DESC;
        }
    }

    currentSort(table: string, type: string): boolean {
        return this.sorts[table].type === type;
    }

    sortTable(table: string, type: string): void {
        const sorter = this.sorts[table];

        if (sorter.type === type) {
            if (sorter.direction === this.SORT_ASC) {
                sorter.direction = this.SORT_DESC;
            } else {
                sorter.direction = this.SORT_ASC;
            }
        } else {
            sorter.type = type;
            if (type === this.SORT_AMOUNT) {
                sorter.direction = this.SORT_DESC;
            } else {
                sorter.direction = this.SORT_ASC;
            }
        }

        this.renderChart(table);
    }

    // Chart Rendering

    barTipTemplate(item: ExpirationAnalyticsItem, type: ExpirationAnalyticsItemType, translations: any): string {
        return `
            <div class="expiration-analytics-tip-table">
                <div class="expiration-analytics-tip-line">
                    <span class="col-left">${translations['reports.expiration_analytics.removed_reason']}</span>
                    <span class="col-right">${type.removed_reason.name}</span>
                </div>
                <div class="expiration-analytics-tip-line">
                    <span class="col-left">${translations['reports.expiration_analytics.drug_name']}</span>
                    <span class="col-right">${item.name}</span>
                </div>
                <div class="expiration-analytics-tip-line">
                    <span class="col-left">${translations['reports.expiration_analytics.expiration_type']}</span>
                    <span class="col-right">${type.expiration_type.name}</span>
                </div>
                <div class="expiration-analytics-tip-line">
                    <span class="col-left">${translations['reports.expiration_analytics.num_items']}</span>
                    <span class="col-right">${type.count}</span>
                </div>
                <div class="expiration-analytics-tip-line">
                    <span class="col-left">${translations['reports.expiration_analytics.cost']}</span>
                    <span class="col-right">${formatCurrency(type.cost[this.filters.costType], 'en', '$')}</span>
                </div>
            </div>
        `;
    }

    renderItem(chartItem: ExpirationAnalyticsItem, chart: any, chartType: string): void {
        const costType: string = this.filters.costType;

        const line: any = chart.append('div').classed('chart-line', true);

        line.append('label').html(chartItem.name);

        const barScale = d3.scaleLinear().domain([0, this.maximums[chartType][costType]]).range([0, this.barWidth]);

        const svg: any = line
            .append('svg')
            .classed('bar-chart', true)
            .attr('width', this.barWidth)
            .attr('height', this.barHeight);

        let barX: number = 0;

        _.each(chartItem.types_filtered, (type: ExpirationAnalyticsItemType) => {
            const barWidth: number = barScale(type.cost[costType]);
            const expirationSlug: string = _.kebabCase(type.expiration_type.name);
            const removedSlug: string = _.kebabCase(type.removed_reason.name);

            const tipTemplate: string = this.barTipTemplate(chartItem, type, this.chartTranslations);

            const tip = d3tip().attr('class', 'd3-tip').html(tipTemplate).offset([-8, 0]);

            svg.call(tip);

            const typeBar: KC.ISVGRect = {
                x: barX,
                y: 0,
                width: barWidth,
                height: this.barHeight,
                class: `chart-bar ${removedSlug}-bar ${expirationSlug}-bar`,
            };

            this.chartingService.addRect(svg, typeBar, tip);

            const barCost: string = formatCurrency(type.cost[this.filters.costType], 'en', '$');

            const barCostText: KC.ISVGText = {
                x: barX + barWidth / 2,
                y: 13,
                text: barWidth > 40 ? barCost : '',
                class: `chart-bar-text ${removedSlug}-bar-text`,
            };

            this.chartingService.addText(svg, barCostText);

            barX += barWidth;
        });
    }

    private filterTypes(types: ExpirationAnalyticsItemType[]): ExpirationAnalyticsItemType[] {
        return types.filter((type) => {
            return (
                !!this.filters.removedState.find((state) => state.name === type.removed_reason.name) &&
                !_.isNull(type.cost[this.filters.costType])
            );
        });
    }

    recalculateMaximums(filteredItems: ExpirationAnalyticsItem[], type: string): void {
        filteredItems.forEach((item: ExpirationAnalyticsItem) => {
            const typeSum: number = d3.sum(item.types_filtered, (itemType: ExpirationAnalyticsItemType) => {
                return itemType.cost[this.filters.costType];
            });

            _.set(item, `type_sums[${this.filters.costType}]`, typeSum);
        });

        const sums: number[] = filteredItems.map((item: ExpirationAnalyticsItem) => {
            return item.type_sums[this.filters.costType];
        });

        _.set(this.maximums, `[${type}][${this.filters.costType}]`, d3.max(sums));
    }

    renderChart(type: string): void {
        const chartSlug: string = _.kebabCase(type);

        const chartList: ExpirationAnalyticsList = this.topLists[type];
        const chartId: string = `${chartSlug}-chart`;

        const chart = d3.select(`#${chartId}`);

        chart.html('');

        const filteredItems: ExpirationAnalyticsItem[] = chartList.items.filter(
            (chartItem: ExpirationAnalyticsItem) => {
                chartItem.types_filtered = this.filterTypes(chartItem.types);
                return !!chartItem.types_filtered.length;
            }
        );

        this.recalculateMaximums(filteredItems, type);

        const sortType: string = this.sorts[type].type;
        const sortDir: string = this.sorts[type].direction;

        filteredItems.sort((a, b) => {
            const aVal = sortType === this.SORT_AMOUNT ? a.type_sums[this.filters.costType] : a.name.toLowerCase();
            const bVal = sortType === this.SORT_AMOUNT ? b.type_sums[this.filters.costType] : b.name.toLowerCase();

            return d3[sortDir](aVal, bVal);
        });

        filteredItems.forEach((chartItem: ExpirationAnalyticsItem) => {
            this.renderItem(chartItem, chart, type);
        });
    }

    renderCharts(): void {
        this.renderChart(this.CHART_DRUGS);
        this.renderChart(this.CHART_KIT_MASTERS);
    }
}
