import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class ScanActionService {
    // Sample QR Code value: KCScanAction::changeLabelType(2014-K03)
    allowedActions: any = {
        changeLabelType: function (tagTypeId) {
            this.router.navigate([`change-tag-type/${tagTypeId}`]);
        },
    };

    constructor(private router: Router) {}

    sendAction(inputString) {
        const actionRegex = /KCScanAction::([^\(]*)\(([^\)]*)\)/;
        if (actionRegex.test(inputString)) {
            const matches = inputString.match(actionRegex);
            const action = matches[1];
            let args = (matches[2] && matches[2].split(',')) || [];

            args = this.normalizeArgs(args);

            this.allowedActions[action].apply(null, args);

            return true;
        } else {
            return false;
        }
    }

    normalizeArgs(args) {
        _.each(args, (arg, idx) => {
            if (arg.toLowerCase() === 'true') {
                args[idx] = true;
            } else if (arg.toLowerCase() === 'false') {
                arg[idx] = false;
            } else if (!isNaN(parseFloat(arg))) {
                arg[idx] = parseFloat(arg);
            }
        });

        return args;
    }
}
