import { Component, Input, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';

import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { AssignKitService } from '@services/core/assign-kit.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { KitSummaryService } from '@services/core/kit-summary.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { SegmentsService } from '@services/core/segments.service';
import { TranslationService } from '@services/utils/translation.service';

import { BarcodeResource } from '@resources/barcode-resource.service';
import { BillingLabelResource } from '@resources/billing-label-resource.service';
import { CartResource } from '@resources/cart-resource.service';
import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { KitResource } from '@resources/kit-resource.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { ScanResource } from '@resources/scan-resource.service';

import { MatDialog } from '@angular/material/dialog';
import { AssignLockDialog } from '@dialogs/assign-lock/assign-lock-dialog';
import { AssignKitsToLocationDialog } from '@dialogs/assign-kits-to-location/assign-kits-to-location-dialog';
import { DispatchKitDialog } from '@dialogs/dispatch-kit/dispatch-kit-dialog';

import { KitSummary } from '@models/core/kit-summary';

import { ChargeSheetMappings } from '@components/charge-sheet-templates';
import { TemplateData } from '@components/charge-sheet-templates/templates/template-data';

import { isEmpty } from '@utils/objects';
import { titleize } from '@utils/strings';
import { Segment } from '@models/core/segment';
import { Package } from '@models/core/package';
import { isPostLockoutStateWithItems } from '@utils/charge-model-util';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { SafeUrl } from '@angular/platform-browser';
import * as print from '@utils/print';

interface PostData {
    is_kit_dispatch: boolean;
    cart_id?: string;
    name?: string;
    location_id?: string;
    completed_dispatch_validations?: string[];
}

interface KitDetailKitSummary extends KitSummary {
    location_id: number;
    locks: any;
    lockGroups: any;
    cart_id: number;
    manual_locks: any;
}

interface LocationAssignDialogResults {
    cart_id?: number;
    cart_location_id?: number;
    kits_ids?: number[];
    location_id?: number;
    removeLocations?: boolean;
    removeCarts?: boolean;
}

const KETTERING_NARC_BOX = 434;
const KIT_SUMMARY_LISTENER = 'kitsummary';

@Component({
    selector: 'kit-detail',
    templateUrl: './kit-detail.html',
    styleUrls: ['./kit-detail.scss'],
})
export class KitDetail {
    locations;
    carts: KC.ICart[];
    kitSummary: KitDetailKitSummary;
    usageSummary;

    activeTab: string;
    addCreditsFromBanner: boolean;
    aggregateBillingEnabled: boolean;
    archivedItemsPresent: any;
    archivedWarningMessage: string;
    assignKitLocation: boolean;
    balanceBelowThreshold: any;
    billingData: any;
    billingLabelAdded: any;
    billingLabelsAllowed: boolean;
    billingSheet: any;
    billingSheetLoaded: boolean = false;
    billingSheetUnavailable: boolean = false;
    chargeData: any;
    chargeModel: ChargeModelTypeName;
    chargeSheetLoaded: boolean = false;
    chargeTemplate: any;
    chargeWasteText: string;
    currentBalance: any;
    decommissioned: any;
    deleteFormularyItem: boolean;
    deltaOnRight: boolean = false;
    readonly displayedColumns: string[] = ['epc'];
    disallowIncompleteKit: boolean;
    dispatchLoading: boolean = false;
    isSegmentsLoading: boolean = false;
    hasCartLocation: boolean;
    hasPurchasedCredits: boolean;
    hasScannedNotPrepaid: any;
    hasKitCartLocationOverride: boolean;
    hospitalLogo: SafeUrl;
    kitId: any;
    kitData: any;
    kitScanIsComplete: boolean;
    latestScan: boolean = true;
    manualLocks: boolean;
    multidoseButton: string = 'unopened';
    pickListLoaded: boolean = false;
    scanId: any;
    scannedByUser: any;
    segments: Array<Segment>;
    replacementChargeSheet: boolean = false;
    requiredSubscriptionSegmentItems: Array<Package> = [];
    showMultidose: boolean = false;
    showDispatchButton: boolean;
    showKitCart: boolean;
    showPrintBillingSheet: boolean;
    showPrintChargeSheet: boolean;
    showSpinner: boolean;
    soonest = null;
    tab: string;
    tabs: Array<[string, string]> = [
        ['kit_page.summary.title', 'summary'],
        ['kit_page.scan_details', 'details'],
    ];
    templateComponent;
    templateInjector: Injector;
    unassociatedTags: any;
    updateKitSegment: boolean;
    lockoutDate: string;
    barcodeScanFromHome: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private actionService: ActionService,
        protected applicationService: ApplicationService,
        protected assignKitService: AssignKitService,
        protected barcodeScanService: BarcodeScanService,
        protected hospitalInfoService: HospitalInfoService,
        protected hospitalResource: HospitalResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        protected kitSummaryService: KitSummaryService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        protected segmentsService: SegmentsService,
        protected translationService: TranslationService,
        protected barcodeResource: BarcodeResource,
        protected billingLabelResource: BillingLabelResource,
        protected cartResource: CartResource,
        protected clinicalEquivalenceResource: ClinicalEquivalenceResource,
        protected kitResource: KitResource,
        protected kitMasterResource: KitMasterResource,
        protected scanResource: ScanResource,
        private dialog: MatDialog,
        private injector: Injector,
        private sanitizer: DomSanitizer
    ) {
        this.applicationService.subheaderTitle = undefined;
        this.hasKitCartLocationOverride = hospitalInfoService.hospitalSetting('kit_cart_location_override') || false;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.locations = data.locations;
            this.carts = data.carts;
            this.kitSummary = data.kitSummary;
            this.usageSummary = data.usageSummary;

            this.productModuleService.setModule(ModuleTypes.INVENTORY);

            // Setup view params
            this.setupViewParams();
            // view setup
            this.setupViewConfigurations();

            // charge model
            this.setupChargeModelConfigurations();

            // permissions
            this.setupPermissions();

            this.initializeListeners();

            this.loadKitScanDetails();

            this.setupKitSummaryData(this.kitSummary);

            this.sendModalSelection({ selectedItems: [this.kitSummary], selectionType: 'kit', targetType: 'cart' });
        });

        this.productModuleService.setModule(ModuleTypes.INVENTORY);
    }

    ngOnDestroy() {
        this.removeListeners();
    }

    removeListeners() {
        this.barcodeScanService.removeListener(KIT_SUMMARY_LISTENER);
    }

    initializeListeners() {
        this.barcodeScanService.registerListener((scanData) => {
            //Cart scanning is now handled in BarcodeScanService
            let newLocation;
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object) {
                    if (data.object['class'] === 'Kit') {
                        this.router.navigate([`/kit/${data.object.id}/latest`]);
                    } else if (data.object['class'] === 'Location') {
                        const locationData = { location_id: String(data.object.id) };
                        newLocation = this.locations.find((location) => location.id === data.object.id);

                        this.kitResource.kitDispatch(this.kitId, locationData).then(() => {
                            setTimeout(() => {
                                this.kitSummary.location_name = newLocation.name;
                                this.kitSummary.location_id = newLocation.id;
                                this.kitSummary.location = newLocation;
                                let successMessage = this.translationService.instant(
                                    'inventory.assigned_success.single.location',
                                    { locationName: newLocation.name }
                                );
                                this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, successMessage);
                                this.sendModalSelection({
                                    selectedItems: [this.kitSummary],
                                    selectionType: 'kit',
                                    targetType: 'cart',
                                });
                            });
                        });
                    }
                }
            });
        }, KIT_SUMMARY_LISTENER);
    }

    setupViewParams() {
        $('.kit-detail-scan-history-container').hide();

        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.kitId = parseInt(paramMap.get('kitId'));
            this.scanId = parseInt(paramMap.get('scanId'));

            if (this.kitSummary.untrusted_items) {
                if (!this.scanId) {
                    this.scanId = 'latest';
                }
                this.router.navigate([`/kit/block/${this.kitId}/${this.scanId}`]);
                return;
            }
        });

        this.activatedRoute.queryParamMap.subscribe(
            (queryParamMap) => (this.barcodeScanFromHome = !!queryParamMap.get('barcodeScanFromHome'))
        );
    }

    setupViewConfigurations() {
        this.activeTab = 'summary';
        this.aggregateBillingEnabled = this.hospitalInfoService.hospitalSetting('aggregate_billing_enabled');
        this.hasCartLocation = this.hospitalInfoService.hospitalSetting('cart_to_location_enabled');
        this.replacementChargeSheet = this.kitSummary.kit_master.charge_sheet_customization === 'replacement';
        this.archivedWarningMessage = '';
        this.chargeWasteText = 'kit_page.print_charge_sheet';
        this.archivedItemsPresent = this.kitSummary.items.wrong.some((item) => !!item.formulary_item_deleted_at);
        this.manualLocks = this.hospitalInfoService.manualLocks();
        this.showSpinner = this.loadingSpinnerService.showSpinner;
        this.lockoutDate = this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;

        if (this.barcodeScanFromHome && this.kitSummary.kit_master.mobile_scan_opens_dispatch) {
            const completeScanRequired =
                this.kitSummary.kit_master.dispatch_validations.filter(
                    (validation) => validation.validation_type === 'kit_complete_scan' && validation.required
                ).length > 0;

            if (completeScanRequired && !this.kitSummary.complete) {
                const errorMessage = this.translationService.instant(
                    'kit_page.alerts.attempt_to_dispatch_incomplete_scan'
                );
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, errorMessage);
                return;
            }

            this.dispatch(this.kitSummary);
        }
    }

    setupChargeModelConfigurations() {
        this.chargeModel = this.hospitalInfoService.getHospitalSettings().charge_model_type?.name;
        this.chargeData = {};

        this.hasPurchasedCredits =
            this.usageSummary &&
            this.usageSummary.credits_details &&
            this.usageSummary.credits_details.has_purchased_credits;
        this.currentBalance =
            this.usageSummary && this.usageSummary.current_month && this.usageSummary.current_month.current_balance;
        this.balanceBelowThreshold =
            this.usageSummary &&
            this.usageSummary.credits_details &&
            this.usageSummary.credits_details.balance_below_minimum_threshold;
        this.hasScannedNotPrepaid =
            this.usageSummary &&
            this.usageSummary.credits_details &&
            this.usageSummary.credits_details.has_scanned_not_prepaid;
    }

    setupPermissions() {
        this.showDispatchButton = this.actionService.isAllowAction(
            'kits_inventory',
            'dispatch_kit',
            'Show Dispatch Kit button'
        );
        this.showPrintChargeSheet = this.actionService.isAllowAction(
            'kits_inventory',
            'print_charge_sheet',
            'Show Print Charge Sheet in Dispatch dropdown'
        );
        this.showKitCart = this.actionService.isAllowAction(
            'kits_inventory',
            'assign_kit_cart',
            'Show Print Charge Sheet in Dispatch dropdown'
        );
        this.assignKitLocation = this.actionService.isAllowAction(
            'kits_inventory',
            'assign_kit_location',
            'Show Assign to Cart in Dispatch dropdown'
        );
        this.addCreditsFromBanner = this.actionService.isAllowAction(
            'charge_model',
            'add_credits_from_banner',
            'Show Add Credits button in alert'
        );
        this.showPrintBillingSheet = this.actionService.isAllowAction(
            'kits_inventory',
            'print_billing_sheet',
            'Show Print Billing Sheet button'
        );
        this.updateKitSegment = this.actionService.isAllowAction(
            'kits_inventory',
            'update_kit_segment',
            'Show Add Formulary Item to Segment button'
        );
        this.deleteFormularyItem = this.actionService.isAllowAction(
            'hospital_settings',
            'delete_formulary_item',
            'Unarchive an item'
        );
        this.billingLabelsAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'update_billing_label',
            'Update Billing Labels'
        );

        if (this.deleteFormularyItem) {
            this.archivedWarningMessage = this.translationService.instant(
                'kit_page.alerts.please_see_archived_items_manager'
            );
        } else {
            this.archivedWarningMessage = this.translationService.instant(
                'kit_page.alerts.please_see_archived_items_non_manager'
            );
        }
    }

    isLockedOut() {
        return isPostLockoutStateWithItems(
            this.lockoutDate,
            this.requiredSubscriptionSegmentItems?.length,
            this.chargeModel
        );
    }

    canUpdateKitSegment = () => this.updateKitSegment && this.kitSummary.items.wrong.some(this.showAddSegmentLink);

    showAddSegmentLink = (item) =>
        this.kitSummary.most_recent_scan &&
        !this.kitSummary.kit_master_modified_since_last_scan &&
        item.formulary_item_id &&
        !item.from_another_hospital;

    notOwnedByHospital = function () {
        const hospitalId = this.hospitalInfoService.getHospitalId();
        return this.kitSummary?.owner_hospital?.id !== hospitalId;
    };

    loadKitSummaryCallback(event) {
        // should also update scan details in order to refresh the Details tab
        this.loadKitScanDetails();

        this.loadKitSummary(event?.spinnerType);
    }

    loadKitScanDetails() {
        this.isSegmentsLoading = true;
        this.kitResource
            .kitScanDetails(this.kitId, this.kitSummary.scan.id)
            .then((data) => {
                const segments: Array<Segment> = data.segments;
                _.each(segments, (segment) => {
                    segment.firstItem = segment.items[0] || segment.extra[0];
                    segment.extraFirst = !segment.items.length && segment.extra.length;

                    //Creates an array of formatted strings to display the alternate fill options
                    this.segmentsService.setAlternateFillFormatted(segment);
                });

                // TODO: we should change the target compiler to es2019 so we have more methods available like flatMap
                this.requiredSubscriptionSegmentItems = segments
                    // @ts-ignore
                    .flatMap(({ items, extra }) => items.concat(extra))
                    .concat(data.wrong as Array<Package>)
                    .filter((medications) => medications.subscription_required);

                this.segments = segments;
            })
            .finally(() => {
                this.isSegmentsLoading = false;
            });
    }

    loadKitSummary(spinnerType?) {
        return this.kitSummaryService.loadKitSummary(this.kitId, this.scanId, spinnerType).then((data) => {
            this.setupKitSummaryData(data, spinnerType);
        });
    }

    locksToGroups(locks) {
        let i = 0;
        let group = [];
        const lockGroups = [];
        const numLockGroups = Math.min(3, Math.ceil(locks.length / 2));

        _.each(_.range(numLockGroups), () => {
            group = [];
            lockGroups.push(group);
        });

        _.each(locks, (lock) => {
            if (i === numLockGroups) {
                i = 0;
            }

            lockGroups[i].push(lock);
            i++;
        });

        return lockGroups;
    }

    toggleScanHistory(kitId) {
        $(`#kit-detail-scan-history-${kitId}`).toggle();
    }

    setupKitSummaryData(data, spinnerType?) {
        if (!this.scanId) {
            this.scanId = data.scan.id;
        }

        this.kitSummary = data;

        if (data.items.delta_on_right) {
            this.deltaOnRight = true;
        }

        if (this.hasDecommissionedTags()) {
            this.decommissioned = [];
            this.kitSummary.exceptions?.suspect_tags?.kits.forEach((kit) => {
                this.decommissioned.push({
                    type: 'Kit',
                    name: kit.kit_full_name_formatted,
                    epc: kit.tag_id_formatted,
                    full_epc: kit.epc,
                });
            });
            this.kitSummary.exceptions?.suspect_tags?.items.forEach((item) => {
                this.decommissioned.push({
                    type: 'Item',
                    name: item.item_name_formatted,
                    epc: item.tag_id_formatted,
                    full_epc: item.epc,
                });
            });
            this.kitSummary.exceptions?.decommissioned_tags.forEach((tag) => {
                if (!this.decommissioned.find((element) => element.epc === tag.tag_id_formatted)) {
                    this.decommissioned.push({
                        type: 'Other',
                        name: tag.name_formatted,
                        epc: tag.tag_id_formatted,
                        full_epc: tag.epc,
                    });
                }
            });
        }

        if (this.kitSummary.kit_master.charge_sheet_customization === 'addendum') {
            this.setChargeSheetAddendum();
        }

        if (!!this.hospitalInfoService.getHospitalSettings().logo_filename) {
            this.setChargeSheetLogo();
        }

        this.unassociatedTags = (() => {
            if (!this.kitSummary || !this.kitSummary.exceptions) {
                return [];
            }
            const exs = this.kitSummary.exceptions;
            return _.difference(exs.unassociated_tags, _.map(exs.decommissioned_tags, 'tag_id_formatted'));
        })();

        if (data.recent_scans[0] && data.scan && data.recent_scans[0].id !== data.scan.id) {
            this.latestScan = false;
        }

        if (data.items.multidose.length) {
            this.showMultidose = true;
        }

        this.scannedByUser = this.determineScanUsername(data.scan);

        if (data.expirations && data.expirations.soonest) {
            this.soonest = data.expirations.dates.find((d) => d.expiration === data.expirations.soonest);
        }

        if (this.kitSummary.locks) {
            this.kitSummary.lockGroups = this.locksToGroups(this.kitSummary.locks);
        }

        this.segmentsService.clear(this.kitSummary.kit_master.id);

        this.kitScanIsComplete = data.complete;

        this.disallowIncompleteKit =
            !data.complete &&
            !!this.kitSummary.kit_master.dispatch_validations.find((dv) => dv.validation_type === 'kit_complete_scan');

        //find billing labels
        if (this.billingLabelsAllowed) {
            this.billingLabelResource.getBillingLabels(this.kitSummary.id, this.kitSummary.scan.id).then((data) => {
                this.billingLabelAdded = !!data.billing_labels.length;
            });
        }

        const chargeBillingPromise = this.getChargeAndBillingSheets();
        const kitSummaryPromise = spinnerType
            ? this.loadingSpinnerService.spinnerifyPromise(chargeBillingPromise, spinnerType)
            : chargeBillingPromise;
        return kitSummaryPromise.then(() => {
            return this.kitSummary;
        });
    }

    determineScanUsername(scan_data) {
        let scannedByUser = scan_data.created_by;
        if (scan_data.is_pharmacist) {
            scannedByUser += ', Pharmacist';
        }

        return scannedByUser;
    }

    hasDecommissionedTags = () =>
        !!this.kitSummary &&
        this.kitSummary.exceptions &&
        (!_.isEmpty(this.kitSummary.exceptions.suspect_tags.items) ||
            !_.isEmpty(this.kitSummary.exceptions.suspect_tags.kits) ||
            !_.isEmpty(this.kitSummary.exceptions.decommissioned_tags));

    hasUnassociatedTags = () => !isEmpty(this.unassociatedTags);

    unopenedMultidose = () => {
        const unopened = _.find(this.kitSummary.items.multidose, {
            expiration_multidose_started: false,
        });
        return !!unopened;
    };

    allowMultidose = () => this.latestScan && this.unopenedMultidose();

    dispatchDataLoading = () => {
        return (
            !this.chargeSheetLoaded ||
            (!this.billingSheetLoaded && !this.billingSheetUnavailable) ||
            !this.pickListLoaded ||
            this.dispatchLoading
        );
    };

    disableDispatchTooltip = () =>
        this.isLockedOut() ? 'kit_page.dispatch_locked_out' : 'kit_page.dispatch_not_available';
    disableDispatch = () => {
        return this.dispatchDataLoading() || this.disallowIncompleteKit || this.isLockedOut();
    };

    disableBillingTooltip = () =>
        this.isLockedOut() ? 'kit_page.billing_locked_out' : 'kit_page.billing_not_available';
    disableBilling = () => {
        return !this.billingData || this.billingSheetUnavailable || this.isLockedOut();
    };

    getChargeData() {
        if (this.actionService.isAllowAction('kits_inventory', 'print_charge_sheet', 'Load charge sheet data')) {
            return this.kitResource.kitPrintChargeSheet(this.kitId, this.scanId).then((data) => {
                if (data.untrusted_items) {
                    this.router.navigate([`/kit/block/${this.kitId}/${this.scanId}`]);
                    return;
                }

                this.chargeData = data;

                this.chargeData.title = `${data.kit_master.name}: ${data.kit.physical_label}`;
                this.chargeData.cleaned = data.completed_dispatch_validations.find(
                    (validation) => validation === 'kit_cleaned'
                );
                this.chargeData.scannedByUser = this.determineScanUsername(data.scan);

                if (data.expirations && data.expirations.soonest) {
                    this.chargeData.soonest = _.find(data.expirations.dates, {
                        expiration: data.expirations.soonest,
                    });
                }

                this.chargeData.segments.forEach((segment: any) => {
                    segment.items = _.sortBy(segment.items, (item: any) => moment(item.expiration).toDate());
                    segment.extra = _.sortBy(segment.extra, (item: any) => moment(item.expiration).toDate());
                    segment.itemCount = segment.items.length + segment.extra.length;

                    //Creates an array of formatted strings to display the alternate fill options
                    this.segmentsService.setAlternateFillFormatted(segment);

                    segment.firstItem = segment.items[0] || segment.extra[0];
                    if (segment.items.length) {
                        segment.items.splice(0, 1);
                    } else {
                        segment.extra.splice(0, 1);
                    }

                    segment.text = this.translationService.instant('kit_page.shortage', {
                        itemCount: segment.ItemCount,
                        expected: segment.exptected,
                    });
                });

                if (this.chargeData.locks) {
                    this.chargeData.lockGroups = this.locksToGroups(this.chargeData.locks);
                }

                this.templateInjector = Injector.create(
                    [{ provide: TemplateData, useValue: { chargeData: this.chargeData, kitSummary: this.kitSummary } }],
                    this.injector
                );
                this.chargeTemplate =
                    data.chargesheet_template === 'Default' ? 'Charge' : this.componentName(data.chargesheet_template);

                this.templateComponent = ChargeSheetMappings[this.chargeTemplate];
                // default to compact template if the template was not that found
                if (!this.templateComponent) {
                    this.chargeTemplate = 'Compact';
                    this.templateComponent = ChargeSheetMappings[this.chargeTemplate];
                }

                setTimeout(() => {
                    // delay enabling the Dispatch button until the charge sheet is fully loaded
                    this.chargeSheetLoaded = true;
                }, 500);
            });
        } else {
            this.chargeSheetLoaded = true;
            return Promise.resolve();
        }
    }

    getPrintBillingData() {
        return this.kitResource.kitPrintBillingSheet(this.kitId, this.scanId).then((data) => {
            this.pickListLoaded = true;

            if (data.untrusted_items) {
                this.router.navigate([`/kit/block/${this.kitId}/${this.scanId}`]);
                return;
            }

            this.billingData = data;
            this.billingData.deltaOnRight = this.deltaOnRight;
            this.billingData.title = `${data.kit_master.name}: ${data.kit.physical_label}`;
            this.billingData.itemsMissingCount = _.reduce(
                this.billingData.missing,
                (memo, segment) => memo + segment.missing,
                0
            );
            this.billingData.scannedByUser = this.determineScanUsername(data.scan);
            this.billingData.hospitalLogo = this.hospitalLogo;
        });
    }

    getBillingSheetData() {
        this.applicationService.suppressError = true;

        return this.scanResource
            .getBillingSheetForScan(this.kitSummary.scan.id)
            .then((data) => {
                this.billingSheet = data.billing_sheet;
                this.billingSheet.hospitalLogo = this.hospitalLogo;
                this.billingSheetLoaded = true;
            })
            .catch((err) => {
                if (err.__status === 422) {
                    this.billingSheetUnavailable = true;
                }
            })
            .finally(() => {
                this.applicationService.suppressError = false;
            });
    }

    getChargeAndBillingSheets() {
        return Promise.all([this.getChargeData(), this.getPrintBillingData(), this.getBillingSheetData()]);
    }

    setChargeSheetAddendum() {
        this.kitMasterResource.getChargeSheetPng(this.kitSummary.kit_master.id).then((response: any) => {
            const blobURL = URL.createObjectURL(response);
            this.kitSummary.kit_master.charge_sheet_addendum = this.sanitizer.bypassSecurityTrustUrl(blobURL);
        });
    }

    setChargeSheetLogo() {
        this.hospitalResource
            .getLogoPng(this.hospitalInfoService.getHospitalSettings().hospital_id)
            .then((response: any) => {
                const blobURL = URL.createObjectURL(response);
                this.hospitalLogo = this.sanitizer.bypassSecurityTrustUrl(blobURL);
                this.kitSummary.kit_master.hospital_logo = this.hospitalLogo;
            });
    }

    showChargeSheet = () => {
        let showChargeSheet = false;
        showChargeSheet = this.printChargeSheet && this.chargeTemplate;
        return showChargeSheet;
    };

    showExpirationDays = (item) =>
        item.days_to_expiration !== false &&
        item.days_to_expiration !== undefined &&
        (item.days_to_expiration < 0 ||
            item.days_to_expiration < this.hospitalInfoService.hospitalSetting('expiring_soon_days'));

    loadScan(kitId, scanId) {
        this.loadingSpinnerService.spinnerifyPromise(this.router.navigate([`/kit/${kitId}/${scanId}`]));
    }

    getLatestScan() {
        this.loadingSpinnerService.spinnerifyPromise(this.router.navigate([`/kit/${this.kitId}/latest`]));
    }

    dispatchSuccessMessage(kit, hasCart?, hasLocation?) {
        let extra = '';
        if (hasCart || hasLocation) {
            extra = ` to ${hasCart ? kit.cart.name : ''}${hasCart && hasLocation ? ', ' : ''}${
                hasLocation ? kit.location_name : ''
            }`;
        }
        this.dispatchLoading = false;
        this.kcMatSnackBarService.open(
            SnackBarTypes.SUCCESS,
            `${this.kitSummary.kit_master.name} ${this.kitSummary.physical_label} dispatched${extra}`
        );
    }

    dispatch = (kit) => {
        this.barcodeScanService.setProcessOnly(true);
        let new_location;
        const dispatchDialog = this.dialog.open(DispatchKitDialog, {
            width: '640px',
            height: 'max-content',
            data: {
                kit: this.kitSummary,
                carts: this.carts,
                locations: this.locations,
                locks: this.kitSummary.locks,
                chargeWasteText: this.chargeWasteText,
            },
        });

        dispatchDialog.afterClosed().subscribe((data) => {
            this.barcodeScanService.removeListener('assignToLocation');
            this.barcodeScanService.setProcessOnly(false);
            if (data) {
                const postData: PostData = {
                    is_kit_dispatch: true,
                    completed_dispatch_validations: [],
                };

                if (this.kitScanIsComplete) {
                    postData.completed_dispatch_validations.push('kit_complete_scan');
                }
                if (data.kitWasCleaned) {
                    postData.completed_dispatch_validations.push('kit_cleaned');
                }
                let new_cart: any;

                //* dispatching to cart
                if (data.cart_id) {
                    postData.cart_id = `${data.cart_id}`;
                    new_cart = this.carts.find((cart) => cart.id === data.cart_id);
                    new_location = this.locations.find((location) => location.id === data.cart_location_id);
                    new_cart.location = {};
                    new_cart.location.id = data.cart_location_id || null;
                    new_cart.location.name = new_location ? new_location['name'] : null;
                    new_cart.location_id = data.cart_location_id || null;
                    new_cart.location_name = new_location ? new_location['name'] : null;
                    kit.cart = new_cart;
                    kit.cart_id = new_cart.id;
                    kit.cart_name = new_cart.name;
                    this.chargeData.location_name = new_cart.location_name;
                } else {
                    this.kitResource.kitDispatchUnset(kit.id, 'cart').then(() => {
                        kit.cart_id = undefined;
                    });

                    kit.cart_name = null;
                    kit.cart_id = null;
                }

                //* dispatching to location
                if (data.location_id) {
                    postData.location_id = `${data.location_id}`;
                    new_location = this.locations.find((location) => location.id === data.location_id);
                    kit.location_id = new_location.id;
                    kit.location_name = new_location.name;
                    this.chargeData.location_name = new_location.name;
                }

                // setting locks on dispatch
                if (this.manualLocks) {
                    _.extend(postData, {
                        dispatch_lock: data.dispatchLock || null,
                        return_lock: data.returnLock || null,
                    });

                    kit.manual_locks = {
                        dispatch_lock: data.dispatchLock || null,
                        return_lock: data.returnLock || null,
                    };

                    this.chargeData.manual_locks = kit.manual_locks;
                }

                // do the dispatch
                this.kitResource.kitDispatch(this.kitId, postData).then(() => {
                    let saveLocation;
                    let summaryPromise;
                    let cartPromise;

                    saveLocation = this.chargeData.location_name;

                    if (data.cart_location_id) {
                        cartPromise = this.cartResource.dispatch(new_cart);
                    } else {
                        cartPromise = Promise.resolve();
                    }

                    if (this.hasCartLocation) {
                        summaryPromise = this.loadKitSummary(LoadingSpinnerTypes.BACKDROP);
                    } else {
                        summaryPromise = Promise.resolve();
                    }

                    Promise.all([cartPromise, summaryPromise]).then(() => {
                        if (data.print) {
                            //this is a horrible hack, but for some reason sometimes
                            // this.kitResource.kitPrintChargeSheet sometimes returns
                            // a null location when there is a location assigned.
                            this.chargeData.location_name = this.chargeData.location_name || saveLocation;
                            this.chargeData.cleaned = data.kitWasCleaned;
                            setTimeout(() => this.printChargeSheet(), 250);
                        }
                    });
                    this.dispatchSuccessMessage(kit, !!data.cart_id, !!data.location_id);
                });
            }
        });
    };

    sendModalSelection = (data: any): void => {
        this.kitSummaryService.emitKitModalEvent(data);
    };

    assignToCart(kit): void {
        this.assignToCartOrLocation('cart', kit);
    }

    assignToLocation(kit): void {
        this.assignToCartOrLocation('location', kit);
    }

    assignToCartOrLocation(target, kit) {
        this.barcodeScanService.setProcessOnly(true);
        let new_cart;
        let new_location;
        const locationAssignDialog = this.dialog.open(AssignKitsToLocationDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                kits: [kit],
                locations: this.locations,
                carts: this.carts,
                target: target,
                showKits: false,
            },
        });

        locationAssignDialog.afterClosed().subscribe((results: LocationAssignDialogResults) => {
            this.barcodeScanService.removeListener('assignToLocation');
            this.barcodeScanService.setProcessOnly(false);

            if (results) {
                let data = {
                    results: results,
                    carts: this.carts,
                    locations: this.locations,
                    selected: [kit],
                    override: this.hasKitCartLocationOverride,
                    target: target,
                };

                let promises = this.assignKitService.assignToCartOrLocation(data);
                Promise.all(promises).then(() => {
                    if (results.removeLocations) {
                        kit.location_id = null;
                        kit.location_name = null;
                    } else if (results.removeCarts) {
                        kit.cart_name = null;
                        kit.cart_id = null;
                    } else {
                        if (results.cart_id) {
                            new_cart = this.carts.find((cart) => parseInt(cart.id) === results.cart_id);
                            kit.cart = new_cart;
                            new_location = this.locations.find((location) => location.id === results.cart_location_id);
                            this.chargeData.location_name = new_location.name;
                        } else if (results.location_id) {
                            new_location = this.locations.find((location) => location.id === results.location_id);
                            kit.location = new_location;
                            this.chargeData.location_name = new_location.name;
                        }
                    }
                });
            }
        });
    }

    getPickLists = () => {
        const promise = this.kitResource
            .kitPrintBillingSheet(this.kitSummary.id, this.kitSummary.scan.id)
            .then((data) => {
                this.pickListLoaded = true;
                if (data.untrusted_items) {
                    this.router.navigate(['/inventory']);
                    return;
                }
                this.billingData = data;
                this.billingData.deltaOnRight = this.deltaOnRight;
                this.billingData.title = `${data.kit_master.name}: ${data.kit.physical_label}`;
                this.billingData.itemsMissingCount = _.reduce(
                    this.billingData.missing,
                    (memo: any, segment: any) => memo + segment.missing,
                    0
                );
                this.billingData.hospitalLogo = this.hospitalLogo;
                this.pickListPromise('single');
            });
        this.loadingSpinnerService.spinnerifyPromise(promise, LoadingSpinnerTypes.BACKDROP);
    };

    pickListPromise = (pickListType) => {
        const promise = this.kitResource.kitPrintPickLists([this.kitSummary.id]).then((data) => {
            window.scrollTo(0, 0);
            const kitmaster: any = _.first(data.kit_master_pick_lists);

            kitmaster.neededItemsCount = _.sum(_.map(kitmaster.formatted_segments, 'total'));
            const expiring_soon_days: any = [].concat.apply(
                [],
                _.map(kitmaster.formatted_segments, 'expiring_soon_days')
            );
            // iterate mapped values for kitmaster, and remove negative numbers from array
            expiring_soon_days.forEach((day, index) => {
                if (day < 0) {
                    expiring_soon_days.splice(index, 1);
                }
            });

            // pick lowest expiring soon date
            kitmaster.expiring_soon_days = _.min(expiring_soon_days);
            // if min value ends up being undefinded or infinity, reset value to 0
            if (kitmaster.expiring_soon_days === Infinity || kitmaster.expiring_soon_days === undefined) {
                kitmaster.expiring_soon_days = 0;
            }

            kitmaster.formatted_segments.forEach((segment, index) => {
                //default asterisk to false
                segment.asterisk = false;
                if (kitmaster.expiring_soon_days > 0 && segment.days_to_expiration) {
                    //set segment asterisk to true, if there's a value that doesn't equal daysToExpiration
                    segment.asterisk = segment.days_to_expiration.find((day) => {
                        return day !== kitmaster.expiring_soon_days;
                    });
                }
            });
            this.kitData = data.kit_master_pick_lists;

            setTimeout(() => {
                this.printPickListSheet();
            }, 100);
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    };

    printPickListSheet = () => {
        print.printPage('print-pick-list');
    };

    printBillingSheet = () => {
        if (!this.billingSheetUnavailable) {
            $('body').removeClass('print-pick-list');
            print.printPage('print-billing-sheet');
        }
    };

    addToBillingReport = () => {
        if (this.billingLabelAdded) {
            //remove billing label
            this.billingLabelResource.addBillingLabelstoReport(this.kitSummary.id, this.kitSummary.scan.id, {
                billing_label_ids: [0],
            });
            this.billingLabelAdded = false;
        } else {
            this.billingLabelResource.getAllBillingLabels().then((data) => {
                let billingLabel: any = data.billing_labels.find((label) => {
                    return label.name === 'Department';
                });
                // add Billing label
                return this.billingLabelResource.addBillingLabelstoReport(this.kitSummary.id, this.kitSummary.scan.id, {
                    billing_label_ids: [billingLabel.id],
                });
            });
            this.billingLabelAdded = true;
        }
    };

    printChargeSheet = () => {
        if (this.replacementChargeSheet) {
            print.toggleExtraneousPageElements((resolve) => {
                this.kitMasterResource
                    .getChargeSheetPdf(this.kitSummary.kit_master.id)
                    .then((response) => {
                        let iframe = document.querySelector('iframe');
                        // remove the old iframe if it's there
                        if (iframe) iframe.parentNode.removeChild(iframe);
                        iframe = document.createElement('iframe');
                        iframe.style.display = 'none';
                        iframe.src = URL.createObjectURL(response as any);
                        document.body.appendChild(iframe);
                        iframe.contentWindow?.print();
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        } else {
            print.printPage('print-charge');
        }
    };

    happyScan = () => this.kitSummary.complete;

    assignLock() {
        const assignLockDialog = this.dialog.open(AssignLockDialog, {
            width: '640px',
            height: 'max-content',
            data: {
                item: this.kitSummary,
            },
        });

        assignLockDialog.afterClosed().subscribe((result) => {
            if (result) {
                const postData = {
                    dispatch_lock: result.dispatchLock || null,
                    return_lock: result.returnLock || null,
                };
                this.kitResource.kitDispatch(this.kitSummary.id, postData).then(() => {
                    this.kitSummary.manual_locks = postData;
                    this.chargeData.manual_locks = postData;
                });
            }
        });
    }

    // converts a file_name to a ComponentName
    componentName(str) {
        return titleize(_.camelCase(str), false);
    }
}
