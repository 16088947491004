import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { TableComponent } from '@components/common/table-component';
import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@services/utils/translation.service';

import { AddFormularyItemBinDialog } from '@dialogs/add-formulary-item/add-formulary-item-bin/add-formulary-item-bin-dialog';

@Component({
    selector: 'bin-wrong-items',
    templateUrl: './bin-wrong-items.html',
})
export class BinWrongItems extends TableComponent {
    @Input() grouped: boolean; // In 'grouped' mode, items are grouped by formulary item, and 'Add to Bin' link is available.
    @Input() binScan?: any;

    constructor(
        private dialog: MatDialog,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService,
        private clinicalEquivalenceResource: ClinicalEquivalenceResource,
        private router: Router
    ) {
        super();
    }

    public sortWrongItems(field: string): void {
        this.sortBy(field, this.items);
    }

    addFormularyItem = async (item) => {
        let binTranslation = this.translationService.instant('common.bin');
        const ceqs = await this.clinicalEquivalenceResource.clinicalEquivalences();
        const addDialog = this.dialog.open(AddFormularyItemBinDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                titleType: binTranslation,
                bin: this.binScan.bin,
                formularyItem: item,
                ceqs: ceqs.clinical_equivalences,
            },
        });

        addDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.loadingSpinnerService.spinnerifyPromise(this.router.navigateByUrl(`/bin/${this.binScan.bin.id}`));
            }
        });
    };
}
