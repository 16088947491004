import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { ActionService } from '@services/utils/action.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { prepareArchivedByName } from '@utils/formulary-item-util';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

import { TableComponent } from '@components/common/table-component';
import { FormularyItem, DeletedByUser } from '@models/core/formulary/formulary-item';

import * as moment from 'moment';

const FORMULARY_INDEX_BARCODE_LISTENER = 'formulary-index';

@Component({
    selector: 'formulary-index',
    templateUrl: './formulary-index.html',
    styleUrls: ['./formulary-index.scss'],
})
export class FormularyIndex extends TableComponent {
    // attributes
    showAltExp: boolean;
    formularySearch: string = '';
    searching: boolean = false;
    showArchivedItems: boolean = false;
    filteredItems: FormularyItem[] = [];
    createNewItemButton: boolean;
    updateFormularyItem: boolean;
    ndcTranslate: { uid: string };
    currentUsername: string;
    downloadFileName: string;
    downloadSheetName: string;

    //filter variables
    dictionaryTypeList;
    filters;
    packageTypeList;
    showColumnList: boolean = false;
    showDictionaryList: boolean = false;
    showPackageList: boolean = false;
    showSearch: boolean = false;

    hasData: boolean = false;
    dataSource: MatTableDataSourceWithNaturalSort<any>;

    createSuccess: string;
    editSuccess: string;
    showArchivedItemsParam: boolean;

    displayItems: FormularyItem[];
    formularyItems: FormularyItem[];
    archivedFormularyItems: FormularyItem[];

    columnList: string[] = [
        'ndc',
        'item_name',
        'item_strength_formatted',
        'package_size_formatted',
        'type_name_formatted',
        'alt_exp',
        'created_at',
        'dictionary_entry_item_trade_name',
        'manufacturer_name',
        'expiring_soon_days',
        'expiring_fridge_days',
        'expiring_multidose_days',
        'item_class_formatted',
    ];

    columnArchiveList: string[] = [
        'ndc',
        'item_name',
        'item_strength_formatted',
        'package_size_formatted',
        'type_name_formatted',
        'alt_exp',
        'created_at',
        'dictionary_entry_item_trade_name',
        'manufacturer_name',
        'expiring_soon_days',
        'expiring_fridge_days',
        'expiring_multidose_days',
        'item_class_formatted',
        'archived_by',
        'deleted_at',
    ];

    selectedColumns: string[] = [
        'ndc',
        'item_name',
        'item_strength_formatted',
        'package_size_formatted',
        'type_name_formatted',
        'alt_exp',
        'created_at',
    ];
    displayedColumns: string[] = [
        'ndc',
        'item_name',
        'item_strength_formatted',
        'package_size_formatted',
        'type_name_formatted',
        'alt_exp',
        'created_at',
    ];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;

    @ViewChild('searchInput') searchInput: ElementRef;

    constructor(
        private actionService: ActionService,
        private barcodeScanService: BarcodeScanService,
        private changeDetector: ChangeDetectorRef,
        private kcMatSnackBarService: KCMatSnackBarService,
        private ndcScanService: NdcScanUtilsService,
        private productModuleService: ProductModuleService,
        private loadingSpinnerService: LoadingSpinnerService,
        private localStorageService: LocalStorageService,
        private translationService: TranslationService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
            this.createSuccess = queryParamMap.get('createSuccess');
            this.editSuccess = queryParamMap.get('editSuccess');
            this.showArchivedItemsParam = !!queryParamMap.get('showArchivedItems');
        });

        this.activatedRoute.data.subscribe((data) => {
            this.displayItems = data.displayItems;
            this.formularyItems = data.formularyItems;
            this.archivedFormularyItems = data.archivedFormularyItems;
        });

        this.productModuleService.setModule(ModuleTypes.INVENTORY);

        this.createNewItemButton = this.isAllow('create', 'formulary_item', 'New Formulary Item button');
        this.updateFormularyItem = this.isAllow('update', 'formulary_item', 'Edit item button');

        this.ndcTranslate = this.ndcTranslation();
        if (this.createSuccess) {
            this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, this.createSuccess);
        }

        if (this.editSuccess) {
            this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, this.editSuccess);
        }

        this.barcodeScanService.registerListener((scanData) => {
            this.formularySearch = this.ndcScanService.extractNDCFromScan(scanData);
            this.setNDCView(this.formularySearch);
        }, FORMULARY_INDEX_BARCODE_LISTENER);

        this.formularyItems.forEach((item) => {
            item.medispan = item.formulary_type_string === 'medispan';
            item.item_class_formatted = item.item_class === 'drug' ? 'Drug' : 'Supply';
        });

        this.showAltExp = this.formularyItems.some(
            (item) => !!item.expiring_fridge_days || !!item.expiring_multidose_days
        );

        this.displayItems = this.formularyItems;
        this.currentUsername = this.localStorageService.get('userName');
        this.downloadFileName = this.translationService.instant('manage_formulary.download_file_name', {
            downloadDate: moment().format('YYYY-MM-DD'),
        });
        this.downloadSheetName = this.translationService.instant('manage_formulary.download_file_name');

        this.dictionaryTypeList = _.uniq(this.formularyItems.map((i) => i.type_name_formatted)).sort();
        this.packageTypeList = _.uniq(this.formularyItems.map((i) => i.package_description_name)).sort();

        this.filters = {
            multidose: false,
            refrigerated: false,
            package_types: this.packageTypeList,
            dictionary_types: this.dictionaryTypeList,
        };

        if (this.showArchivedItemsParam) {
            this.displayArchiveView(true);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.filterItems();
        });
    }

    ngOnDestroy() {
        this.removeBarcodeListeners();
    }

    removeBarcodeListeners() {
        this.barcodeScanService.removeListener(FORMULARY_INDEX_BARCODE_LISTENER);
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    setTableData(): void {
        this.displayedColumns = this.selectedColumns;
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.filteredItems);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.hasData = this.dataSource?.data.length > 0;
    }

    setDisplayedColumns(): void {
        this.displayedColumns = this.selectedColumns;
    }

    getActiveColumns() {
        if (this.showArchivedItems) {
            return this.columnArchiveList;
        } else {
            return this.columnList;
        }
    }

    buildArchivedByValue(archivedByUser: DeletedByUser): string {
        return prepareArchivedByName(archivedByUser);
    }

    displayArchiveView(showArchive: boolean): void {
        this.showArchivedItems = showArchive;
        if (showArchive) {
            this.selectedColumns.push('archived_by');
            this.selectedColumns.push('deleted_at');
        } else {
            this.selectedColumns = this.selectedColumns.filter((col) => col !== 'archived_by' && col !== 'deleted_at');
        }

        if (this.showArchivedItems) {
            this.displayItems = this.archivedFormularyItems;
        } else {
            this.displayItems = this.formularyItems;
        }
        this.filterItems();
    }

    ndcTranslation() {
        return { uid: this.translationService.instant('item.uid.short_name') };
    }

    setNDCView(ndc: string): void {
        this.formularySearch = ndc;
        this.filterItems();
    }

    isAllow(action, type = 'hospital_settings', reason): boolean {
        return this.actionService.isAllowAction('hospital_settings', `${action}_${type}`, reason);
    }

    isEditableFormularyItem(item: FormularyItem): boolean {
        return (
            item.editable_formulary_items &&
            this.actionService.isAllowAction(
                'hospital_settings',
                'update_formulary_item',
                'Manage Formulary update section'
            )
        );
    }

    clearFilter(): void {
        this.formularySearch = '';
        this.filterItems();
    }

    showIt(result): boolean {
        return this.showSearch;
    }

    showTable(): boolean {
        return this.hasData;
    }

    toggleSearch(show): void {
        if (this.formularySearch !== '') {
            this.showSearch = true;
        } else {
            this.showSearch = show;
        }
        if (this.showSearch) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            });
        }
    }

    toggleFilters(key): void {
        this.filters[key] = !this.filters[key];
        this.filterItems();
    }

    setPackageTypes($event): void {
        this.filters.package_types = $event;
        this.filterItems();
    }

    setDictionaryTypes($event): void {
        this.filters.dictionary_types = $event;
        this.filterItems();
    }

    setSelectedColumns($event): void {
        this.selectedColumns = $event;
        const allColumns = this.getActiveColumns();
        let orderedColumns = [];
        orderedColumns = allColumns.filter((column) => {
            return this.selectedColumns.includes(column);
        });
        this.displayedColumns = orderedColumns;
    }

    resetAllFilters() {
        this.formularySearch = null;
        this.filters.package_types = this.packageTypeList;
        this.filters.dictionary_types = this.dictionaryTypeList;
        this.filters.refrigerated = false;
        this.filters.multidose = false;
        this.filterItems();
    }

    filterItems(): void {
        this.setDisplayedColumns();
        this.filteredItems = this.displayItems;
        if (!!this.formularySearch && !!this.displayItems) {
            let noDashNDCRegEx = new RegExp(this.formularySearch.replace(/-/g, ''), 'mi');
            let nameRegEx = new RegExp(this.formularySearch, 'mi');
            this.filteredItems = this.displayItems.filter((item) => {
                return (
                    (!!item.ndcSearch && item.ndcSearch.match(noDashNDCRegEx)) ||
                    (!!item.item_name && item.item_name.match(nameRegEx)) ||
                    (!!item.clinical_equivalence_name && item.clinical_equivalence_name.match(nameRegEx))
                );
            });
        }
        if (this.filters['refrigerated']) {
            this.filteredItems = this.filteredItems.filter((item) => {
                return item.expiring_fridge_days;
            });
        }

        if (this.filters['multidose']) {
            this.filteredItems = this.filteredItems.filter((item) => {
                return item.expiring_multidose_days;
            });
        }

        this.filteredItems = this.filteredItems.filter((item) => {
            return this.filters['package_types'].includes(item.package_description_name);
        });

        this.filteredItems = this.filteredItems.filter((item) => {
            return this.filters['dictionary_types'].includes(item.type_name_formatted);
        });

        this.setTableData();
    }
}
