import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { CartResource } from '@resources/cart-resource.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CartHistoryResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private cartResource: CartResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const cartId = parseInt(activatedRoute.params.cartId);
        return this.loadingSpinnerService.spinnerifyPromise(this.cartResource.cartHistory(cartId)).then((data) => {
            return data;
        });
    }
}
