import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupLoginService } from '@services/login/group-login.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { UploadCsvDialog } from '@components/dialogs/upload-csv/upload-csv-dialog';

import { AdminLocation } from '@models/admin/admin-location';

@Component({
    selector: 'admin-locations-list',
    templateUrl: './admin-locations-list.html',
})
export class AdminLocationsList {
    //bindings
    locations: AdminLocation[];

    actionAllow: Function;

    isLoading: boolean = true;
    hasData: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name', 'barcode'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource,
        private configuration: ConfigurationProvider,
        private translationService: TranslationService,
        private dialog: MatDialog,
        protected groupLoginService: GroupLoginService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.locations = data.locations;
        });

        this.actionAllow = this.actionService.allowModule('hospital_settings');
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.locations);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.hasData = !!this.locations.length;
    }

    authToken() {
        return this.groupLoginService.authToken();
    }

    goToCreate() {
        this.router.navigate(['/admin/locations-create']);
    }

    showUploadModal(): void {
        const uploadDialog = this.dialog.open(UploadCsvDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                noun: 'location',
                url: `${this.configuration.kcEndpointV1()}locations/import`,
                authToken: this.authToken(),
            },
        });

        uploadDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const promise = this.locationResource
                    .locationList()
                    .then((response) => {
                        this.locations = response.locations;
                        this.dataSource = new MatTableDataSourceWithNaturalSort(this.locations);
                        this.dataSource.sort = this.sort;
                    })
                    .catch((err) => {
                        if (!!err.message) {
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                    });
                this.loadingSpinnerService.spinnerifyPromise(promise);
            }
        });
    }
}
