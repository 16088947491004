import { Input, OnInit, Component, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { ActionService } from '@services/utils/action.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { FormularyItemDataService } from '@services/core/formulary-item-data.service';
import { TaggingDataService } from '@services/core/tagging-data.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { TranslationService } from '@services/utils/translation.service';

const SETUP_NDC_LISTENER = 'setUpNdc';

@Component({
    selector: 'item-select',
    templateUrl: './item-select.html',
    styleUrls: ['./item-select.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ItemSelect {
    @Input() tag;
    @Input() itemAliasSelect;
    @Input() hasGlobalDictionary: boolean;
    @Input() itemForm: NgForm;

    @Output() childPick = new EventEmitter();

    searchByNDC: string;
    debouncedSendSearchToAPI: any;
    items: any[];
    pickedItem: any;
    searching: boolean;
    show_fridge_expiration: boolean;
    show_multidose_expiration: boolean;
    isAllowedCreateCustomItem: boolean;
    archivedWarning = {
        show: false,
        text: undefined,
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['ndc', 'item', 'package'];
    ndc: string;
    newFormularyItem: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private actionService: ActionService,
        private barcodeScanService: BarcodeScanService,
        private formularyItemsService: FormularyItemsService,
        private formularyItemDataService: FormularyItemDataService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private taggingDataService: TaggingDataService,
        private parseFormErrorsService: ParseFormErrorsService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.searchByNDC = '';
        this.debouncedSendSearchToAPI = _.debounce(this.sendSearchToAPI, 300);
        this.pickedItem = {};
        this.setupListener();

        if (_.isUndefined(this.itemAliasSelect)) {
            this.itemAliasSelect = {};
        }
        if (!!this.tag.barcode && !!this.tag.barcode.ndc) {
            this.setNDCSearch(this.tag.barcode.ndc);
            this.sendSearchToAPI(this.tag.barcode.ndc, true);
        }

        this.isAllowedCreateCustomItem =
            this.actionService.isAllowAction('hospital_settings', 'create_formulary_item', 'Create Formulary Items') &&
            this.actionService.isAllowAction(
                'hospital_settings',
                'create_custom_formulary_item',
                'Create custom Formulary Items'
            );
        this.newFormularyItem = this.taggingDataService.getFormularyItem();
    }

    ngOnDestroy() {
        this.barcodeScanService.removeListener(SETUP_NDC_LISTENER);
    }

    ngAfterViewInit() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.items);

        if (!!this.newFormularyItem) {
            this.searchByNDC = this.newFormularyItem.ndc;
            this.pickedItem = this.newFormularyItem;
        }
    }

    setNDCSearch(ndc) {
        this.searchByNDC = ndc;
        this.archivedWarning.show = false;
    }

    clearNdcSearch() {
        this.setNDCSearch('');
        this.archivedWarning.show = false;
    }

    onNDCFocus() {
        this.archivedWarning.show = false;
    }

    setupListener() {
        this.barcodeScanService.registerListener((scanData) => {
            setTimeout(() => {
                if ((scanData.type && scanData.lot) || scanData.expiration) {
                    this.tag.gsBarcode = scanData;
                    if (!!scanData.expiration) {
                        this.tag.gsBarcode.expire_formatted = moment(scanData.expiration).format('YYYY-MM-DD');
                    }
                    this.tag.processingGSBarcode = true;
                }
                this.searchByNDC = this.ndcScanUtilsService.extractNDCFromScan(scanData);
                this.debouncedSendSearchToAPI(this.searchByNDC, true);
            });
        }, SETUP_NDC_LISTENER);
    }

    resetSearchResults() {
        this.items = [];
        this.pickedItem = {};
        this.setupListener();
    }

    sendSearchToAPI(ndc, scan?) {
        if (ndc && ndc.length > 4) {
            this.searching = true;
            this.formularyItemsService
                .getFormularyItemsByNDC(ndc, this.tag.onBehalfOfHospitalId)
                .then((data) => {
                    if (data.ndc !== ndc) {
                        return;
                    }

                    this.items = data.items;
                    this.dataSource.data = this.items;
                    // If only one NDC is found from the search, select it automatically
                    // for both formulary and non-formulary items.
                    if (scan && this.items.length === 1) {
                        this.onPick(this.items[0], scan);
                    } else {
                        this.tag.processingGSBarcode = false;
                    }
                })
                .finally(() => {
                    this.searching = false;
                });
        } else {
            this.resetSearchResults();
        }
    }

    isPicked(): boolean {
        return this.items.length === 1 && !!this.pickedItem && !!this.pickedItem.ndc;
    }

    onPick(item, scan) {
        this.pickedItem = _.cloneDeep(item);
        if (!!this.pickedItem.deleted_at) {
            /* eslint-disable */
            this.actionService.isAllowAction('hospital_settings', 'delete_formulary_item', 'Unarchive a formulary item')
                ? this.translationService.translateInto(
                      'print_item.archived.warning_manager',
                      this.archivedWarning,
                      'text'
                  )
                : this.translationService.translateInto(
                      'print_item.archived.warning_non_manager',
                      this.archivedWarning,
                      'text'
                  );
            /* eslint-enable */
            this.archivedWarning.show = true;
        } else {
            this.archivedWarning.show = false;
            if (item.in_formulary) {
                this.childPick.emit({ item: this.pickedItem, scan: scan, searchTerm: this.searchByNDC });
            } else {
                this.tag.processingGSBarcode = false;
            }
        }
    }

    addToFormulary() {
        this.formularyItemsService
            .createTagItem(this.pickedItem, this.tag.onBehalfOfHospitalId || this.tag.hospitalSettings.id)
            .then((data) => {
                this.pickedItem.formulary_item_id = data.id;
                this.pickedItem.in_formulary = true;
                this.childPick.emit({ item: this.pickedItem, scan: true, searchTerm: 'TODO CF' });
            });
    }

    ndcSearch(ndc) {
        this.debouncedSendSearchToAPI(ndc);
    }

    allowManualCreate() {
        return !this.tag.onBehalfOfHospitalId || this.tag.onBehalfOfHospitalId === this.tag.currentHospitalId;
    }

    manualCreation() {
        if (!this.isAllowedCreateCustomItem) {
            return;
        }
        this.tag.isNewItem = true;
        this.tag.ndc = this.searchByNDC;
        this.formularyItemDataService.setTagItemInfo(this.tag);
        this.router.navigate(['/inventory/create-formulary-item']);
    }

    showFieldsForCreatingFormularyItem(): boolean {
        return this.pickedItem && this.pickedItem.ndc && !this.pickedItem.in_formulary && !this.pickedItem.deleted_at;
    }

    parseErrors(errors): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    onToggleMultidose() {
        this.show_multidose_expiration = !this.show_multidose_expiration;
        if (!this.show_multidose_expiration) {
            this.pickedItem.expiring_multidose_days = null;
        }
    }

    onToggleFridge() {
        this.show_fridge_expiration = !this.show_fridge_expiration;
        if (!this.show_fridge_expiration) {
            this.pickedItem.expiring_fridge_days = null;
        }
    }

    itemSelectValidator(): boolean {
        return !!this.pickedItem.ndc && !this.pickedItem.deleted_at;
    }
}
