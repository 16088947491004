import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { SegmentsService } from '@services/core/segments.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SegmentsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentsService: SegmentsService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const kitMasterId = parseInt(activatedRoute.params.kitMasterId);
        let segmentPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kit_segment', 'Load Segments data')) {
            if (kitMasterId) {
                segmentPromise = this.segmentsService.getSegments(kitMasterId);
            } else {
                segmentPromise = this.segmentsService.getAllSegments();
            }
        } else {
            segmentPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(segmentPromise);
    }
}
