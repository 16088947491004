import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

import { GroupLoginResource } from '@resources/group-login-resource.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { SessionResource } from '@resources/session-resource.service';

@Injectable()
export class GroupLoginService {
    constructor(
        private router: Router,
        private groupLoginResource: GroupLoginResource,
        private localStorageService: LocalStorageService,
        private sessionResource: SessionResource
    ) {}

    authToken() {
        return this.localStorageService.get('oat');
    }

    isLoggedIn() {
        return this.localStorageService.get('loggedIn') === 'true';
    }

    requirePassword() {
        return this.localStorageService.get('passwordResetRequired') === 'true';
    }

    checkAuth() {
        if (!this.isLoggedIn() && !this.requirePassword()) {
            this.router.navigate(['/login']);
        }
    }

    getUser() {
        return {
            id: this.localStorageService.get('userId'),
            firstName: this.localStorageService.get('firstName'),
            lastName: this.localStorageService.get('lastName'),
            email: this.localStorageService.get('email'),
            user_created_at: this.localStorageService.get('created_at'),
            user_roles: this.localStorageService.get('roles'),
            hospitalId: this.localStorageService.get('hospitalId'),
            isAdminUser: this.localStorageService.get('adminUser') === 'true',
        };
    }

    getRefreshedHospitalSettings(): Promise<any> {
        return this.sessionResource.verifyLoginSession().then(({ user }) => {
            return user.hospital_settings;
        });
    }

    removeAuthData(data) {
        delete data.authentication_token;
        delete data.token_type;
        delete data.refresh_token;
        delete data.phi_access_token;
        delete data.sargas_api_token;
        delete data.zebra_api_token;
    }

    storeLoginData(data) {
        this.localStorageService.set('tokenType', data.token_type);
        this.localStorageService.set('oat', data.token_type + ' ' + data.authentication_token);
        this.localStorageService.set('pat', data.token_type + ' ' + data.phi_access_token);
        this.localStorageService.set('sat', data.token_type + ' ' + data.sargas_api_token);
        this.localStorageService.set('zat', data.token_type + ' ' + data.zebra_api_token);

        this.removeAuthData(data);

        this.localStorageService.set('loggedIn', true);
        this.localStorageService.set('adminUser', data.admin_user);
        this.localStorageService.set('groupManager', data.group_manager);
        this.localStorageService.set('firstName', data.first_name || '');
        this.localStorageService.set('lastName', data.last_name || '');
        this.localStorageService.set('userName', (data.first_name || '') + ' ' + (data.last_name || ''));
        this.localStorageService.set('userId', data.id || 0);
        this.localStorageService.set('email', data.email || '');
        this.localStorageService.set('appModules', (data.valid_applications && data.valid_applications.kitcheck) || []);
        this.localStorageService.set('roles', data.roles || ['guest']);
        this.localStorageService.set('currentHospital', data.current_hospital || {});
        this.localStorageService.set('currentGroup', data.current_group || {});
        this.localStorageService.set('hospitalId', data.hospital_id || 0);
        this.localStorageService.set('actions', data.actions);
        this.localStorageService.set('defaultPage', data.start_application);
        this.localStorageService.set('userSignedLicense', data.signed_license);
        this.localStorageService.set('userNeedsSignature', data.needs_signature);
        this.localStorageService.set('hospitalSettings', data.hospital_settings || {});
        this.localStorageService.set('timeZone', data.time_zone || '');
        this.localStorageService.set('accountOwner', data.account_owner || '');
        this.localStorageService.set('onBehalfOfHospitalSettings', data.on_behalf_of_hospital_settings || {});
        this.localStorageService.set('firstLogin', data.first_login || false);
        this.localStorageService.set('passwordResetRequired', data.password_reset_required || false);
        this.localStorageService.set('passwordSettings', data.password_settings || {});
        this.localStorageService.set('hospitalAccess', data.hospital_access || []);
        this.localStorageService.set('hasBasicTags', data.hospital_has_basic_tags || false);
        this.localStorageService.set('rootIds', data.root_group_ids.root_ids || []);
    }

    checkLocalStorageAttributes(): Promise<any> {
        const checkAttributes = ['hospitalAccess', 'currentGroup'];
        const haveAttributes = _.every(checkAttributes, (attr) => {
            return !_.isNull(this.localStorageService.get(attr));
        });
        const currentVersion = $('body').data('version');
        const hasCheckedVersion = this.localStorageService.get('attributeVersionCheck') === currentVersion;
        if (haveAttributes || hasCheckedVersion) {
            return Promise.resolve();
        } else {
            return this.groupLoginResource
                .sessionHospital(this.localStorageService.get('hospitalId'))
                .then(({ user }) => {
                    this.storeLoginData(user);
                    this.localStorageService.set('attributeVersionCheck', currentVersion);
                    return Promise.resolve();
                });
        }
    }
}
