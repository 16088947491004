import { Input, OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import FilterUtils from '@utils/filterUtils';

import { ResetPrinterDialog } from '@dialogs/reset-printer/reset-printer-dialog';
import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { PrintService } from '@services/hardware/print.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'tag-print',
    templateUrl: './tag-print.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagPrint {
    @Input() tag;
    @Input() type;
    @Input() isPrinting;
    @Input() printForm: NgForm;
    @Input() printFormComplete: NgForm;
    @Input() stepper;

    printComplete: boolean = false;
    systemErrorTranslated: string;
    snackBarRef: MatSnackBar;
    tryingToStartPrint: boolean = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private hardwareService: HardwareService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private printService: PrintService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.systemErrorTranslated = this.translationService.instant('tagging.system_error');
    }

    printButton() {
        let buttonText = this.tag.timeoutError ? 'print_item.reprint' : 'print_item.print';
        if (this.tag.hasRecall) {
            buttonText += '_anyway';
        }
        return buttonText;
    }

    resetPrinter(): void {
        const resetPrinterDialog = this.dialog.open(ResetPrinterDialog, {
            width: '600px',
            height: 'max-content',
        });
    }

    canPrint() {
        // disable print button if pretagged and not overridden
        let preTagStateOK = true;
        if (this.tag.isPreTagged && !this.tag.forcePreTagReprint) {
            preTagStateOK = false;
        }

        return !this.tag.tagTypeError && this.tag.hospitalSettings && this.tag.printer && preTagStateOK;
    }

    readyToPrint(): boolean {
        return this.printForm.valid && !this.isPrinting && this.canPrint();
    }

    printLabels() {
        this.hardwareService.setDefaultPrinter(this.tag.printer.id.toString());

        this.tag.timeoutError = false;
        this.tryingToStartPrint = true;

        const tag = _.cloneDeep(this.tag);

        const printSuccess = () => {
            this.tag.printComplete = true;
            this.printFormComplete.controls['tagFormCompleteValidator'].setValue(true);
            this.stepper.next();

            this.translationService
                .get('print_item.printing_started', {
                    quantity: this.tag.quantity,
                    itemName: this.tag.item.item_name,
                    itemPackage: FilterUtils.packageLongDescription(this.tag.item, true),
                })
                .then((translation) => {
                    this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
                });
        };

        const printError = (data) => {
            const reason = data.reason;
            const unknownError = this.translationService.instant('errors.unknown_print_error');

            if (reason === 'timeout') {
                setTimeout(() => {
                    this.tag.timeoutError = true;
                });
            } else if (reason === 'encoding') {
                this.router.navigate(['/print-error']);
            } else if (reason === 'zpl') {
                this.snackBarRef = this.kcMatSnackBarService.open(
                    SnackBarTypes.ERROR,
                    data?.message ? data.message : this.systemErrorTranslated
                );
            } else if (reason === 'unknown error') {
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, unknownError);
            } else {
                // noop -- treat this as a success because it's not one of our known errors
            }
        };

        this.printService
            .printItemTags(tag)
            .then(printSuccess)
            .catch(printError)
            .finally(() => {
                this.tryingToStartPrint = false;
            });
    }
}
