import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';
import { ReportDataService } from '@services/core/report-data.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface Filters {
    days_expiring_within?: number;
    include_expired_items?: number;
    kit_master_id?: string;
    page?: number;
    per_page?: number;
    report_mode?: string; // bin or kit
    clinical_equivalence_id?: number;
    ndc?: string;
    lot_num?: string;
    group_ids?: string;
}

interface PackageData {
    manufacturer: string;
    name: string;
    ndc: string;
    package_description_name: string;
}

@Component({
    selector: 'report-item-expirations',
    templateUrl: './report-item-expirations.html',
    styleUrls: ['./report-item-expirations.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportItemExpirations {
    reports: {
        report_categories: ReportCategory[];
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    expandedElement: any; // used to track the current expanded row
    filters: Filters;
    hasData: boolean = false;
    hasRun: boolean = false;
    isDownloadable: boolean = true;
    reportDate: Date;
    report: Report;
    report_mode: string = 'kit';
    isSystem: boolean = false;
    reportFilters: string[] = ['expiration', 'binKitMasters', 'genericNdcLotSearch'];
    resultsLength: number = 0;
    perPage: number = 50;
    lastEvent: any;
    currentHospitalGroupId: string;
    prefilter: any = {};
    showReport: boolean = false;
    toggleAllStatus: boolean = false;
    reportPreFilterData: any;

    kitMasterDisplayedColumns: string[] = [
        'expander',
        'kit_master_name',
        'kit_physical_label',
        'cart_name',
        'location_name',
        'expired_count',
        'expiring_soon_count',
        'full_item_name',
        'soonest',
    ];

    binDisplayedColumns: string[] = [
        'expander',
        'bin_name',
        'expired_count',
        'expiring_soon_count',
        'full_item_name',
        'soonest',
    ];

    systemKitMasterDisplayedColumns: string[] = [
        'expander',
        'hospital_group',
        'hospital_name',
        'kit_master_name',
        'kit_physical_label',
        'cart_name',
        'location_name',
        'expired_count',
        'expiring_soon_count',
        'full_item_name',
        'soonest',
    ];

    systemBinDisplayedColumns: string[] = [
        'expander',
        'hospital_group',
        'hospital_name',
        'bin_name',
        'expired_count',
        'expiring_soon_count',
        'full_item_name',
        'soonest',
    ];

    innerDisplayedColumns: string[] = [
        'manufacturer',
        'full_item_name',
        'ndc',
        'package_formatted',
        'strength_formatted',
        'lot_num',
        'expiration',
    ];

    @ViewChild('outerSort') sort: MatSort;
    @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<PackageData>>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private reportDataService: ReportDataService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.activatedRoute.data.subscribe((data) => (this.isSystem = data.isSystem));
        this.reportPreFilterData = this.reportDataService.getPreFilter();

        if (this.reportPreFilterData) {
            this.prefilter = {
                expiration: this.reportPreFilterData.expiration,
                binKitMasters: this.reportPreFilterData.binKitMasters,
            };
        } else {
            this.showReport = true;
        }
        this.reportDate = new Date();
        if (this.isSystem) {
            this.reportFilters = ['hospitalGroups', 'expiration', 'binKitMasters', 'genericNdcLotSearch'];
        }
        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'item_attribute'
        );

        this.report = reportCategory.reports.find(
            (report) => report.name === 'item_expiration_and_attributes_custom_item'
        );
        this.filters = { report_mode: 'kit' };
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    displayColumns(): string[] {
        if (this.isSystem) {
            if (this.report_mode === 'bin') {
                return this.systemBinDisplayedColumns;
            } else {
                return this.systemKitMasterDisplayedColumns;
            }
        } else {
            if (this.report_mode === 'bin') {
                return this.binDisplayedColumns;
            } else {
                return this.kitMasterDisplayedColumns;
            }
        }
    }

    reportModeColumns(): number {
        return this.displayColumns().length;
    }

    loadPage(event) {
        this.perPage = event.pageSize;
        this.refreshReport(this.lastEvent, event.pageIndex + 1);
    }

    runPrefilteredReport(event) {
        if (this.prefilter) {
            this.refreshReport(this.prefilter);
            this.showReport = true;
        }
    }

    refreshReport(event: any, page: number = 1): void {
        // used to send event data in pagination
        this.lastEvent = event;

        const kit_master_ids: string = event.binKitMasters.kitMasters
            ?.map((kitMaster) => kitMaster.id)
            .filter((n) => n)
            .join('|');

        this.filters = {
            include_expired_items: event.expiration.includeExpired ? 1 : 0,
            kit_master_id: kit_master_ids,
            page: page,
            per_page: this.perPage,
            report_mode: event.binKitMasters.binKit,
        };

        if (event.expiration.daysExpiringIn) {
            this.filters.days_expiring_within = event.expiration.daysExpiringIn as number;
        }

        if (!!event.genericNdcLotSearch.generic.clinical_equivalence_id) {
            this.filters.clinical_equivalence_id = event.genericNdcLotSearch.generic.clinical_equivalence_id;
        }

        if (!!event.genericNdcLotSearch.ndc) {
            this.filters.ndc = event.genericNdcLotSearch.ndc;
        }

        if (!!event.genericNdcLotSearch.lot) {
            this.filters.lot_num = event.genericNdcLotSearch.lot;
        }

        if (this.isSystem && event.groupIds) {
            this.filters.group_ids = event.groupIds;
        }

        const promise = this.reportResource.customItemReport(this.filters, this.isSystem).then((rawReportData) => {
            if (this.filters.report_mode === 'bin') {
                rawReportData.bins.forEach((item, i) => {
                    item.id = i;
                    item.full_item_name = item.soonest_name;
                });
                this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.bins);
            } else {
                rawReportData.kits.forEach((item, i) => {
                    item.id = i;
                    item.full_item_name = [
                        item.soonest_name,
                        item.soonest_item_strength,
                        item.soonest_item_strength_uom,
                    ]
                        .filter((x) => x)
                        .join(' ');
                });
                this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.kits);
            }
            this.report_mode = this.filters.report_mode;
            this.hasData = !!this.dataSource.data.length;
            this.resultsLength = rawReportData.total_rows;
            this.hasRun = true;

            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    toggleRow(row): void {
        // fix up the package and strength strings for display/sorting
        row.package_events.forEach((item) => {
            item.package_formatted = [item.package_size, item.package_size_uom, item.package_description_name]
                .filter((x) => x)
                .join(' ');
            item.strength_formatted = [item.item_strength_formatted, item.item_strength_uom].filter((x) => x).join(' ');
            item.full_item_name = [item.name, item.item_strength, item.item_strength_uom].filter((x) => x).join(' ');
        });

        // only create the datasource for the inner table on toggle for performance reasons
        this.dataSource.data[row.id].package_events_data = new MatTableDataSourceWithNaturalSort(row.package_events);
        row.expanded = !row.expanded;
        this.cd.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSourceWithNaturalSort<PackageData>).sort =
                    this.innerSort.toArray()[index])
        );
    }

    toggleAll(): void {
        this.toggleAllStatus = !this.toggleAllStatus;
        this.dataSource.data.forEach((row) => {
            row.package_events.forEach((item) => {
                item.package_formatted = [item.package_size, item.package_size_uom, item.package_description_name]
                    .filter((x) => x)
                    .join(' ');
                item.strength_formatted = [item.item_strength_formatted, item.item_strength_uom]
                    .filter((x) => x)
                    .join(' ');
                item.full_item_name = [item.name, item.item_strength, item.item_strength_uom]
                    .filter((x) => x)
                    .join(' ');
            });

            // only create the datasource for the inner table on toggle for performance reasons
            this.dataSource.data[row.id].package_events_data = new MatTableDataSourceWithNaturalSort(
                row.package_events
            );
            row.expanded = this.toggleAllStatus;
        });
        this.cd.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSourceWithNaturalSort<PackageData>).sort =
                    this.innerSort.toArray()[index])
        );
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            binKitMasters: event.binKitMasters,
            expiration: event.expiration,
            genericNdcLotSearch: event.genericNdcLotSearch,
            hospitalGroups: event.groupIds,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: 'max-content',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.isSystem ? `system_${this.report.name}` : this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    isSystem: this.isSystem,
                },
            });
        });
    }
}
