import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Container, ContainerForm } from '@models/core/container';
import { ContainerScan } from '@models/core/container-scan';
import { PaginatedResource } from '@models/core/paginated-resource';
import { Segment } from '@models/core/segment';
import { ConfigurationProvider } from '@services/config/configuration';
import { HardwareService } from '@services/hardware/hardware.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ContainerResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private hardwareService: HardwareService,
        private http: HttpClient
    ) {}

    addContainer(container: ContainerForm) {
        return this.http.post<Container>(`${this.API_ENDPOINT}containers`, container).toPromise();
    }

    addContainerSegment(containerId: number, segment: Segment) {
        return this.http.post<Segment>(`${this.API_ENDPOINT}containers/${containerId}/segments`, segment).toPromise();
    }

    editContainer(containerId: number, container: Partial<ContainerForm>) {
        return this.http.put<Container>(`${this.API_ENDPOINT}containers/${containerId}`, container).toPromise();
    }

    getContainers(type: Container['type']) {
        return this.http
            .get<{ containers: Array<Container> }>(`${this.API_ENDPOINT}containers?type=${type}`)
            .toPromise();
    }

    getContainer(id: number) {
        return this.http
            .get<{ container: Container }>(`${this.API_ENDPOINT}containers/${id}`)
            .pipe(
                map((data) => {
                    const modifiedHardware = this.hardwareService.fillHardwareAttributes(data.container.hardware);

                    // keep things immutable further down
                    return { container: { ...data.container, hardware: modifiedHardware } };
                })
            )
            .toPromise();
    }

    getContainerScanSummary(containerId: number, scanId: number) {
        return this.http.get<any>(`${this.API_ENDPOINT}containers/${containerId}/scans/${scanId}/summary`).toPromise();
    }

    getContainerSegmentsScanData(containerId: number, scanId: number) {
        return this.http.get<any>(`${this.API_ENDPOINT}containers/${containerId}/scans/${scanId}`).toPromise();
    }

    getAllContainerScans(containerId: number, pagination?): Promise<PaginatedResource<ContainerScan>> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}containers/${containerId}/scans`, {
                params: {
                    ...pagination,
                },
            })
            .toPromise();
    }
}
