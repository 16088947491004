import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { TableComponent } from '@components/common/table-component';
import { isEmpty, difference } from '@utils/objects';
import * as $ from 'jquery';

import { MatDialog } from '@angular/material/dialog';
import { isAppUnderPostLockoutState, isScannedAfterLockoutState } from '@utils/charge-model-util';

import { AddFormularyItemBinDialog } from '@dialogs/add-formulary-item/add-formulary-item-bin/add-formulary-item-bin-dialog';
import { HospitalInfoService } from '@services/core/hospital-info.service';

import {
    BinScanData,
    BinScanCounts,
    ByCEQItem,
    ByFormularyItem,
    DecomissionedTag,
    ExpirationDate,
    SuspectTag,
} from '../models/bin-scan-data';
import { BinScanDetail } from '../models/bin-scan-detail';
import { Package } from '@models/core/package';
import { Scan } from '@models/core/scan';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { ApplicationService } from '@services/system/application.service';

@Component({
    selector: 'bin-scan',
    templateUrl: './bin-scan.html',
    styleUrls: ['./bin-scan.scss'],
})
export class BinScan extends TableComponent {
    binScan: BinScanData;
    binScanDetail: BinScanDetail;

    itemCount: number;
    binId: number;
    binName: string;
    scan: Scan;
    scannedByUser: string;
    recentScans: Scan[];
    counts: BinScanCounts;
    soonest: ExpirationDate;
    tab: string;
    wrong: Package[];
    formulary: ByFormularyItem[];
    generic: ByCEQItem[];
    custom: ByFormularyItem[];
    mostRecentScan: boolean;
    empty: boolean;
    unassociatedTags: string[];
    itemsInLastScanCount: number;
    decommissionTagEpcs: DecomissionedTag[];
    decommissionSuspectItems: SuspectTag[];
    commonItemTx: string;
    requiredSubscriptionSegmentItems: Package[];
    subscription_items_lockout_date: string;
    postLockout: boolean = false;
    charge_model_type: ChargeModelTypeName;

    constructor(
        private clinicalEquivalenceResource: ClinicalEquivalenceResource,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected applicationService: ApplicationService
    ) {
        super();
        this.applicationService.subheaderTitle = undefined;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.binScan = data.binScan;
            this.binScanDetail = data.binScanDetail;

            this.itemCount = this.binScan.counts.total;
            this.binName = this.binScan.bin.name;
            this.binId = this.binScan.bin.id;
            this.scan = this.binScan.scan;
            this.recentScans = this.binScan.recent_scans;
            this.counts = this.binScan.counts;
            this.soonest = this.getSoonestExpiration();
            this.wrong = this.binScan.items.wrong;
            this.formulary = this.binScan.items.by_formulary_items;
            this.generic = this.binScan.items.by_clinical_equivalences;
            this.custom = this.binScan.items.by_missing_clinical_equivalences;
            this.mostRecentScan = this.binScan.most_recent_scan;
            this.empty = isEmpty(this.formulary) && isEmpty(this.wrong);
            this.itemsInLastScanCount = this.binScan.bin.items_in_last_scan_count;
            this.decommissionTagEpcs = this.binScan.exceptions.decommissioned_tags;
            this.decommissionSuspectItems = this.binScan.exceptions.suspect_tags.items;
            if (!this.binScan || !this.binScan.exceptions) {
                this.unassociatedTags = [];
            } else {
                const exs = this.binScan.exceptions;
                this.unassociatedTags = difference([exs.unassociated_tags, exs.decommissioned_tags]);
            }
            this.commonItemTx = this.translationService.inflect('common.item', this.itemsInLastScanCount);
            this.requiredSubscriptionSegmentItems = this.binScanDetail.segment.items
                .concat(this.binScanDetail.wrong)
                .filter((item) => item.subscription_required);
            this.filterSubscriptionItems();
        });

        this.tab = 'summary';

        // @ts-ignore
        // TODO: we should change the target compiler to es2019 so we have more methods available like flatMap
        this.subscription_items_lockout_date =
            this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.charge_model_type = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;
        this.postLockout = isAppUnderPostLockoutState(this.subscription_items_lockout_date, this.charge_model_type);
    }

    loading() {
        return this.loadingSpinnerService.showSpinner;
    }

    filterSubscriptionItems() {
        if (
            this.postLockout &&
            isScannedAfterLockoutState(this.subscription_items_lockout_date, this.binScan.scan.created_at)
        ) {
            this.wrong = this.wrong.filter((item) => !item.subscription_required);
            this.binScanDetail['segment']['items'] = this.binScanDetail.segment?.items?.filter(
                (item) => !item.subscription_required
            );
        }
    }

    hasUnassociatedTags(): boolean {
        return !isEmpty(this.unassociatedTags);
    }

    sort(field): void {
        this.sortBy(field, this.decommissionSuspectItems);
    }

    addFormularyItem = async (item) => {
        let binTranslation = this.translationService.instant('common.bin');
        const ceqs = await this.clinicalEquivalenceResource.clinicalEquivalences();
        const addDialog = this.dialog.open(AddFormularyItemBinDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                titleType: binTranslation,
                bin: this.binScan.bin,
                formularyItem: item.item,
                ceqs: ceqs.clinical_equivalences,
            },
        });

        addDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.router.navigateByUrl(`/bin/${this.binId}/${this.scan.id}`)
                );
            }
        });
    };

    toggleScanHistory(binId): void {
        $(`#bin-scan-history-${binId}`).toggle();
    }

    private getSoonestExpiration(): ExpirationDate {
        const { expirations } = this.binScan;
        if (expirations && expirations.soonest) {
            return expirations.dates.find((date) => date.expiration === expirations.soonest);
        }
    }
}
