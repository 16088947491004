import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitSummaryService } from '@services/core/kit-summary.service';

@Injectable({ providedIn: 'root' })
export class BlockedKitResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private kitSummaryService: KitSummaryService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const kitId = parseInt(activatedRoute.params.kitId);
        const scanId = parseInt(activatedRoute.params.scanId);

        const blockedKitPromise = this.kitSummaryService.loadBlockedKit(kitId, scanId);

        return this.loadingSpinnerService.spinnerifyPromise(blockedKitPromise);
    }
}
