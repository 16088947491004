import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PrintService } from '@services/hardware/print.service';

@Component({
    selector: 'reset-printer-dialog',
    templateUrl: './reset-printer-dialog.html',
})
export class ResetPrinterDialog {
    constructor(
        protected printService: PrintService,
        public dialogRef: MatDialogRef<ResetPrinterDialog>
    ) {}

    printAnyway(): void {
        this.printService.overrideActivePrint();
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
