import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddCreditsForm } from '@models/core/add-credits-form';
import { PreTaggedMedicationsResource } from '@resources/pre-tagged-medications-resource.service';

@Component({
    selector: 'app-add-credits-dialog',
    templateUrl: './add-credits-dialog.component.html',
    styleUrls: ['./add-credits-dialog.component.scss'],
})
export class AddCreditsDialogComponent {
    confirmPurchase: boolean = false;
    formLoading: boolean = false;

    constructor(
        private router: Router,
        protected preTaggedMedicationsResource: PreTaggedMedicationsResource,
        public dialogRef: MatDialogRef<AddCreditsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { form: AddCreditsForm }
    ) {}

    get formattedAmount() {
        return Intl.NumberFormat().format(this.data.form.amount);
    }

    submit() {
        this.formLoading = true;

        this.preTaggedMedicationsResource
            .addCredits(this.data.form)
            .then(() => {
                this.router.navigate(['/pre-tagged-medications'], {
                    queryParams: {
                        successMessage: true
                    }
                })
                this.closeModal(true);
            })
            .finally(() => {
                this.formLoading = false;
                return true;
            });
    }

    closeModal(success = false) {
        this.dialogRef.close(success);
    }
}
