import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApplicationService } from '@services/system/application.service';
import { BannerService } from '@services/system/banner.service';
import { ChurnZeroService } from '@services/vendor/churn-zero.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { DatadogService } from '@services/vendor/datadog.service';
import { FreshDeskService } from '@services/vendor/fresh-desk.service';
import { GainsightService } from '@services/vendor/gainsight.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoginStateService } from '@services/login/login-state.service';
import { LogoutService } from '@services/login/logout.service';
import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { ProductModuleService } from '@services/core/product-module.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserInfoService } from '@services/login/user-info.service';
import * as $ from 'jquery';
import { KCBreadcrumbsService } from '@services/core/kc-breadcrumbs.service';

type TitleData = {
    title: string;
    titleKey: string;
    forceHideHeader: boolean;
};

@Component({
    selector: 'app-component',
    templateUrl: './app-component.html',
})
export class AppComponent {
    canadianHospital: boolean = false;
    isUnsupportedBrowser: boolean = false;
    langClass: string;
    bowserClass: string;
    year: string;
    version: string;
    forceDesktop: string;
    showBreadcrumbs: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private deviceService: DeviceDetectorService,
        private translate: TranslateService,
        protected applicationService: ApplicationService,
        private bannerService: BannerService,
        private churnZeroService: ChurnZeroService,
        private config: ConfigurationProvider,
        private datadogService: DatadogService,
        private gainsightService: GainsightService,
        private hospitalInfoService: HospitalInfoService,
        private loginStateService: LoginStateService,
        private logoutService: LogoutService,
        private loadingSpinnerService: LoadingSpinnerService,
        private ngxLoginService: NgxLoginService,
        private productModuleService: ProductModuleService,
        private snackBar: MatSnackBar,
        private translationService: TranslationService,
        private userInfoService: UserInfoService,
        private freshDeskService: FreshDeskService,
        private kcBreadcrumbsService: KCBreadcrumbsService
    ) {
        this.translate.setDefaultLang('en');
        this.logoutService.observeLogout();
        window['config'] = this.config;
        window['snackBar'] = this.snackBar;

        const urlParams = new URLSearchParams(window.location.search);
        this.forceDesktop = urlParams.get('desktop');
    }

    get isLoggedIn() {
        const path = window.location.pathname;

        return (
            (this.loginStateService.loggedIn &&
                ((!['/switch-hospital', '/login', '/reset-password-settings', '/forgot-password'].includes(path) &&
                    !path.includes('password-reset')) ||
                    this.loadingSpinnerService.showSpinner)) ||
            false
        );
    }

    ngOnInit() {
        this.kcBreadcrumbsService.showBreadcrumbs.subscribe((value) => {
            this.showBreadcrumbs = value;
        });

        this.year = this.applicationService.year;
        this.version = this.applicationService.version;

        this.langClass = `lang-${this.translationService.language()}`;
        this.bowserClass =
            /*@cc_on!@*/ false || !!document['documentMode'] || !!(window as any).StyleMedia ? 'internet-explorer' : '';

        this.ngxLoginService.appName = 'kitcheck';

        if (this.loginStateService.loggedIn) {
            this.userInfoService.setUserInfo();
            this.translationService.setToHospitalLocale();
        } else {
            this.translationService.setToBrowserLocale();
        }

        this.canadianHospital = this.hospitalInfoService.canadianHospital();

        // Set warning if this is IE11
        if (navigator.userAgent.indexOf('Trident/') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            this.isUnsupportedBrowser = true;
        }

        // Set warning if this is Firefox
        if (navigator.userAgent.indexOf('Firefox/') !== -1 || navigator.appVersion.indexOf('Firefox/') > -1) {
            this.isUnsupportedBrowser = true;
        }

        this.productModuleService.processModules();

        this.applicationService.isMobile =
            (this.deviceService.isTablet() || this.deviceService.isMobile()) && this.forceDesktop !== 'true';

        this.gainsightService.load();
        this.churnZeroService.load();
        this.datadogService.load();

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child = this.route.firstChild;
                    while (child) {
                        if (child.firstChild) {
                            child = child.firstChild;
                        } else if (child.snapshot.data && (child.snapshot.data.title || child.snapshot.data.titleKey)) {
                            return {
                                ...child.snapshot.data,
                            };
                        } else {
                            return null;
                        }
                    }
                    return null;
                })
            )
            .subscribe((data: TitleData) => {
                $('a').blur();
                this.setPageTitleTranslation(data);
            });
    }

    ngAfterContentChecked(): void {
        if (this.isLoggedIn) {
            document.body.className = '';
        } else {
            document.body.className = 'not-logged-in';
        }
    }

    appStyles(): any {
        /* eslint-disable */
        const styles = Object.assign(
            {
                [this.langClass]: true,
                [this.bowserClass]: true,
                'with-system-caution': this.bannerService.systemCaution || this.bannerService.systemCautionNephron,
                'with-system-refresh': this.applicationService.needsRefresh,
                'not-logged-in': !this.isLoggedIn,
                'with-unsupported-message': this.isUnsupportedBrowser,
            },
            this.loadingSpinnerService.spinnerStyles()
        );
        /* eslint-enable */
        return styles;
    }

    setPageTitleTranslation(titleData: TitleData) {
        if (titleData?.forceHideHeader) {
            this.applicationService.subheaderTitle = undefined;
            return;
        }

        if (titleData?.titleKey) {
            this.translationService.translateInto(
                titleData.titleKey,
                this.applicationService,
                'subheaderTitle',
                undefined,
                false
            );
        } else if (titleData?.title) {
            this.applicationService.subheaderTitle = titleData.title;
        } else {
            this.applicationService.subheaderTitle = null;
        }
    }

    openFreshChat() {
        this.freshDeskService.initChatWidget();
    }

    showFreshChatButton() {
        return !this.freshDeskService.isWidgetOpen();
    }
}
