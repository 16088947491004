import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';

@Injectable({ providedIn: 'root' })
export class LocationResolver {
    locationId: number;
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const locationId = parseInt(activatedRoute.params['locationId']);
        return this.loadingSpinnerService
            .spinnerifyPromise(this.locationResource.locationData(locationId))
            .then(({ location }) => {
                return location;
            });
    }
}
