import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'kc-breadcrumbs',
    templateUrl: './kc-breadcrumbs.component.html',
    styleUrls: ['./kc-breadcrumbs.component.scss'],
})
export class KCBreadcrumbsComponent implements OnInit {
    protected breadcrumbState: Array<{ name: string; url: string }> = [];
    currentRoute: ActivatedRoute;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translationService: TranslationService
    ) {
        this.buildBreadcrumbs();
    }

    ngOnInit() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => this.onNavigationStart());

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.buildBreadcrumbs());
    }

    private onNavigationStart() {
        this.breadcrumbState = [];
    }

    private buildBreadcrumbs() {
        this.currentRoute = this.activatedRoute.root;

        let currentValidRoute = this.activatedRoute.root;
        let url = '';

        while (currentValidRoute) {
            const routeConfig = currentValidRoute.routeConfig;

            if (routeConfig && routeConfig.data && routeConfig.path && routeConfig.path !== '') {
                const titleKey = routeConfig.data['titleKey'];
                const translatedTitle = titleKey ? this.translationService.instant(titleKey) : '';

                url += (routeConfig.path || '') + '/';

                this.breadcrumbState.push({
                    name: translatedTitle,
                    url,
                });
            }

            currentValidRoute = this.currentRoute.firstChild!;

            if (currentValidRoute) {
                this.currentRoute = this.currentRoute.firstChild!;
            }
        }

        this.breadcrumbState = [
            { name: this.translationService.instant('common.home'), url: '' },
            ...this.breadcrumbState,
        ];
    }
}
