import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';

@Component({
    selector: 'lib-ngx-forgot-password',
    templateUrl: './ngx-forgot-password.component.html',
    styleUrls: ['./ngx-forgot-password.component.scss'],
})
export class NgxForgotPasswordComponent {
    attemptingSendEmail: boolean = false;

    @ViewChild('email', { static: true }) emailInput: ElementRef;

    constructor(
        private router: Router,
        private loginService: NgxLoginService
    ) {
        setTimeout(() => this.emailInput.nativeElement.focus());
    }

    sendResetPwEmail(email: string): void {
        this.attemptingSendEmail = true;

        this.loginService.forgotPassword(email).subscribe(() =>
            this.router.navigate(['/login'], {
                queryParams: {
                    passwordResetEmail: email,
                },
            })
        );
    }

    backToLoginClicked(): void {
        this.router.navigate(['/login']);
    }
}
