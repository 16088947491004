import { Component, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgForm } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { KitMasterService } from '@services/core/kit-master.service';

import { KitMaster } from '@models/core/kit-master';

interface ParamData {
    kitMaster: KitMaster;
}

@Component({
    selector: 'clone-kit-master',
    templateUrl: './clone-kit-master-dialog.html',
})
export class CloneKitMasterDialog {
    kitMaster: KitMaster;
    form: {
        id: number;
        name: string;
    };

    @ViewChild('formItem') formItem: NgForm;

    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<CloneKitMasterDialog>,
        private kitMasterResource: KitMasterResource,
        private kitMasterService: KitMasterService,
        @Inject(MAT_DIALOG_DATA) public data: ParamData
    ) {}

    ngOnInit() {
        this.kitMaster = this.data.kitMaster;
        this.form = {
            id: this.kitMaster.id,
            name: this.kitMaster.name,
        };
    }

    cancel(): void {
        this.dialogRef.close();
    }

    cloneKitMaster(): void {
        this.kitMasterResource
            .cloneKitMaster(this.form)
            .then((data) => {
                this.dialogRef.close('success');
                this.router.navigate([`/inventory/manage-kit-masters/${data.id}`]);
            })
            .catch(() => {
                this.dialogRef.close();
            });
    }
}
