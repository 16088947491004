import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@services/utils/action.service';
import { ArchivedMedispanWarning } from '@models/core/formulary/archived-medispan-warning';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { FormularyItem } from '@models/core/formulary/formulary-item';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { FormularyItemDataService } from '@services/core/formulary-item-data.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { prepareItemName } from '@utils/formulary-item-util';
import { PackageDescription } from '@models/core/formulary/package-description';
import { TranslationService } from '@services/utils/translation.service';
import { NgModel } from '@angular/forms';
import { ItemAlias } from '@components/common/item-alias/item-alias';
import { ActivateFormularyItemDialog } from '@dialogs/activate-formulary-item/activate-formulary-item-dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { deleteEmptyKeys, makeNumber, nullIfBlank } from '@utils/objects';
import { ConfirmChangesFormularyItemDialog } from '@components/dialogs/confirm-changes-formulary-item/confirm-changes-formulary-item-dialog';
import { RemoveOuterPackageDialog } from '@components/dialogs/remove-outer-package/remove-outer-package-dialog';
import { TaggingDataService } from '@services/core/tagging-data.service';

const CREATE_FORMULARY_LISTENER = 'create-edit-formulary-item';

@Component({
    selector: 'create-edit-formulary-item',
    templateUrl: './create-edit-formulary-item.html',
    styleUrls: ['./create-edit-formulary-item.scss'],
})
export class CreateEditFormularyItem implements OnInit {
    @ViewChild(ItemAlias) itemAlias: ItemAlias;
    @ViewChild('drugForm') drugForm: NgModel;
    @ViewChild('supplyForm') supplyForm: NgModel;
    // constants
    TYPE_EDIT = 'edit';
    TYPE_CREATE = 'create';
    TAB_MEDISPAN = 'medispan';
    TAB_CUSTOM = 'custom';

    ALIAS_CUSTOM = 'Custom';

    // attributes
    showAnesthesia;
    showAltExp;
    types = [];
    type: any = {};
    view: string = this.TAB_CUSTOM;
    filter = {
        query: '',
        result: null,
    };
    items = [];
    item: any = {
        show_fridge_expiration: false,
        show_multidose_expiration: false,
    };
    ndc: string;
    valid: boolean;
    identifierTypes: Array<any> = [
        { name: 'Identifier for a custom compounded item', value: 'custom' },
        { name: 'Other', value: 'other' },
    ];
    switchToNdc: boolean;
    supply: any = {};
    itemType: string;
    enableCustomPriceEdit: boolean;
    outerPackageToggle: boolean = true;
    outerPackage: any = {};
    hasOuterPackages: boolean;
    outerPackages: any;
    itemAliasSelect: any = {};
    itemAlreadyInFormulary: boolean = false;
    debouncedSendSearchToAPI;
    searchByNDC;
    strippedNDClength;
    formItem: any = {};
    searching: boolean = false;
    ndcMaxLength: any;
    attempted: boolean = false;
    medispanEdit: boolean = false;
    hasGlobalDictionary: boolean = false;
    itemIsArchived: boolean = false;
    searched: boolean = false;
    showCustomDrugForm: boolean;
    showSuggestedUoU: boolean = false;
    placeholder: string;
    canadianHospital: boolean;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['ndc', 'item_name', 'dictionary_entry_item_strength', 'package', 'arrow'];
    archivedMedispanWarning: ArchivedMedispanWarning = {
        general: null,
        message: null,
        formularyItemId: null,
        link: {
            text: null,
        },
        show: false,
    };
    tagItemInfo: any;
    medispan: boolean;
    dictionaryEntry: any;
    formularyItem: FormularyItem;
    packageDescriptions: PackageDescription[];
    hospitalCatalogs: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private actionService: ActionService,
        private barcodeScanService: BarcodeScanService,
        private dialog: MatDialog,
        private formularyItemResource: FormularyItemResource,
        private formularyItemsService: FormularyItemsService,
        private formularyItemDataService: FormularyItemDataService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private productModuleService: ProductModuleService,
        private translationService: TranslationService,
        private translate: TranslateService,
        private taggingDataService: TaggingDataService
    ) {
        this.placeholder = this.translationService.instant('manage_formulary.search_ndc_placeholder');
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe(
            (queryParamMap) => (this.medispan = queryParamMap.get('medispan') === 'true')
        );

        this.activatedRoute.data.subscribe((data) => {
            this.dictionaryEntry = data.dictionaryEntry;
            this.formularyItem = data.formularyItem;
            this.packageDescriptions = data.packageDescriptions;
            this.hospitalCatalogs = data.hospitalCatalogs;
        });

        this.tagItemInfo = this.taggingDataService.getTagItemInfo();
        this.canadianHospital = this.hospitalInfoService.canadianHospital();
        if (!!this.tagItemInfo) {
            this.searchByNDC = this.tagItemInfo?.ndc || '';
            this.supply.ndc = this.tagItemInfo?.ndc || '';
        }
        if (!!this.medispan) {
            this.view = this.TAB_MEDISPAN;
        }

        this.ndcMaxLength = this.canadianHospital ? 8 : 25;

        this.productModuleService.setModule(ModuleTypes.INVENTORY);
        this.showAnesthesia = this.hospitalInfoService.getHospitalSettings().acheck_enabled;
        this.hasOuterPackages = !!this.formularyItem?.outer_packages;
        this.outerPackages = this.formularyItem?.outer_packages;

        this.debouncedSendSearchToAPI = _.debounce(this.sendSearchToAPI, 300);
        this.barcodeScanService.registerListener((scanData) => {
            setTimeout(() => {
                this.setNDCSearch(this.ndcScanUtilsService.extractNDCFromScan(scanData));
                this.debouncedSendSearchToAPI(this.searchByNDC);
            });
        }, CREATE_FORMULARY_LISTENER);
        this.reset();
        if (this.dictionaryEntry) {
            this.medispanEdit = true;
            this.type = this.TYPE_EDIT;
            this.setData(this.dictionaryEntry, true);
        }

        if (this.formularyItem) {
            this.setData(this.formularyItem, false);
        }

        if (this.hospitalCatalogs.length > 0) {
            this.hasGlobalDictionary = true;
            if (this.type !== this.TYPE_EDIT) {
                this.item.global_catalog = this.hospitalCatalogs[0].id;
            }
        }

        if (this.item.item_class === 'supply') {
            this.supply = Object.assign({}, this.item);
        }

        if (this.item.price_custom) {
            this.item.price_custom = this.displayPrice(this.item.price_custom);
        }

        //populate radio button for Edit page
        if (this.item.item_class === 'supply') {
            this.itemType = 'supply';
        } else if (this.item.item_class === 'drug') {
            this.itemType = 'ndc';
        } else if (this.item.item_class === 'custom_drug') {
            this.itemType = 'non_ndc';
        }

        if (this.type === this.TYPE_EDIT && !this.hasOuterPackages) {
            this.outerPackageToggle = false;
        }

        if (this.hasOuterPackages) {
            this.item.uos_ndc = this.outerPackages[0].ndc;
            this.item.uos_package_description_id = this.outerPackages[0].package_description_id;
            this.item.uos_package_quantity = this.outerPackages[0].package_quantity;
        }

        this.enableCustomPriceEdit = !this.item.price_custom;
    }

    ngOnDestroy() {
        //we're navigating away, so clear out any errors
        this.kcMatSnackBarService.clearAll();
        this.removeBarcodeListeners();
    }

    removeBarcodeListeners() {
        this.barcodeScanService.removeListener(CREATE_FORMULARY_LISTENER);
    }

    activateFormularyItem(): void {
        const activateFormularyItemDialog = this.dialog.open(ActivateFormularyItemDialog, {
            width: '800px',
            height: 'max-content',
            data: { formularyItem: this.formularyItem },
        });

        activateFormularyItemDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const unarchivePromise = this.formularyItemResource.unarchiveFormularyItem(
                    this.formularyItem.formulary_item_id
                );

                return this.loadingSpinnerService
                    .spinnerifyPromise(unarchivePromise)
                    .then(() => {
                        return this.router.navigate(['/inventory/manage-formulary'], {
                            queryParams: {
                                showArchivedItems: false,
                            },
                        });
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.kcMatSnackBarService.open(
                                SnackBarTypes.SUCCESS,
                                `Formulary Item "${prepareItemName(this.formularyItem)}" has been activated.`
                            );
                        }, 250);
                    });
            }
        });
    }

    reset() {
        this.formItem = {};

        this.medispanEdit = false;

        this.item = {
            show_fridge_expiration: false,
            show_multidose_expiration: false,
        };

        this.type = !!this.formularyItem ? this.TYPE_EDIT : this.TYPE_CREATE;
    }

    showMedispanCreate() {
        return (
            this.type === this.TYPE_CREATE && this.view === this.TAB_MEDISPAN && this.item && this.item.newFromSearch
        );
    }

    showCreateTabs() {
        return (
            (this.type === this.TYPE_CREATE && !this.showMedispanCreate() && this.view === this.TAB_CUSTOM) ||
            this.item.item_class === 'supply' ||
            this.item.item_class === 'non_ndc' ||
            this.item.formulary_type_string === 'custom'
        );
    }

    isAllowedCreateCustomItem() {
        return this.actionService.isAllowAction(
            'hospital_settings',
            'create_custom_formulary_item',
            'Create custom Formulary Items'
        );
    }

    isAllowedGlobalDictionaryManagement() {
        return this.actionService.isAllowAction(
            'hospital_settings',
            'global_dictionary_management',
            'Display the Global Dictionary Tab'
        );
    }

    invalidateAlternateExpirations() {
        if (!this.item.show_multidose_expiration) {
            this.item.expiring_multidose_days = null;
        }
        if (!this.item.show_fridge_expiration) {
            this.item.expiring_fridge_days = null;
        }
    }

    isAllowArchive() {
        return this.actionService.isAllowAction(
            'hospital_settings',
            'update_formulary_item',
            'Manage Formulary update section'
        );
    }

    editCustomPrice() {
        return !this.item.price_custom || this.enableCustomPriceEdit;
    }

    setPreferredData(data) {
        if (this.itemAliasSelect.name === this.ALIAS_CUSTOM) {
            const itemPreferredContent: any = {
                preferred_item_name: this.item.preferred_item_name,
                preferred_item_strength: this.item.preferred_item_strength,
                preferred_item_strength_formatted: this.item.preferred_item_strength_formatted,
                preferred_item_strength_uom: this.item.preferred_item_strength_uom,
            };
            return _.assign(data, itemPreferredContent);
        } else {
            const itemDictionaryContent: any = {
                item_name: this.item.dictionary_entry_item_trade_name,
                item_strength: this.item.dictionary_entry_item_strength,
                item_strength_formatted: this.item.dictionary_entry_item_strength_formatted,
                item_strength_uom: this.item.dictionary_entry_item_strength_uom,
                preferred_item_name: null,
                preferred_item_strength: null,
                preferred_item_strength_formatted: null,
                preferred_item_strength_uom: null,
            };
            return _.assign(data, itemDictionaryContent);
        }
    }

    archiveItem() {
        this.router.navigate([`/inventory/archive-formulary-item/${this.item.formulary_item_id}`]);
    }

    updateItem() {
        this.invalidateAlternateExpirations();
        //  base obj
        let data: any = {
            id: this.item.formulary_item_id ? this.item.formulary_item_id : this.supply.formulary_item_id,
            tag_requirement: nullIfBlank(this.item.tag_requirement),
            expiring_soon_days: makeNumber(this.item.expiring_soon_days),
            expiring_fridge_days: makeNumber(nullIfBlank(this.item.expiring_fridge_days)),
            refrigeration_start_on_tagging: this.item.refrigeration_start_on_tagging,
            expiring_multidose_days: makeNumber(nullIfBlank(this.item.expiring_multidose_days)),
        };

        //change item type
        if (this.itemType === 'ndc') {
            data.item_class = 'drug';
        } else if (this.itemType === 'non_ndc') {
            data.identifier_type = nullIfBlank(this.item.identifier_type);
            data.identifier_description = nullIfBlank(this.item.identifier_description);
            data.item_class = 'custom_drug';
        } else if (this.itemType === 'supply') {
            data.item_class = 'supply';
        }

        if (this.item.medispan) {
            data.medispan_id = this.item.medispan_id;
        }

        if (this.item.charge_code !== this.item.originalChargeCode) {
            data.charge_code = this.item.charge_code;
        }

        // add these for custom obj
        if (this.isItemCustom(this.item)) {
            const dataCustom: any = {
                clinical_equivalence_id: nullIfBlank(this.item.clinical_equivalence_id),
                ndc: this.item.ndc.toString(),
                manufacturer_name: nullIfBlank(this.item.manufacturer_name),
                item_name: this.item.item_name,
                item_strength: nullIfBlank(this.item.item_strength),
                item_strength_uom: nullIfBlank(this.item.item_strength_uom),
                package_size: nullIfBlank(this.item.package_size),
                package_size_uom: nullIfBlank(this.item.package_size_uom),
                package_description_id: makeNumber(this.item.package_description_id),
            };
            data = _.assign(data, dataCustom);
        } else {
            // for medispan or global
            this.setPreferredData(data);
        }
        if (this.item.clinical_equivalence_id) {
            data = _.omit(data, 'item_strength', 'item_strength_uom');
        }

        if (!!this.item.uos_ndc && !!this.item.uos_package_description_id) {
            data.unit_of_sale = {
                package_description_id: this.item.uos_package_description_id,
                package_quantity: this.item.uos_package_quantity,
                ndc: this.item.uos_ndc,
            };
        }

        if (!this.outerPackageToggle && this.hasOuterPackages) {
            data.delete_unit_of_sale = true;
        }

        data.price = this.formatPrice(this.item.price_custom);

        const updateFormularyPromise = this.formularyItemResource.updateFormularyItem(data);

        return this.loadingSpinnerService.spinnerifyPromise(updateFormularyPromise);
    }

    showDrugPage() {
        if (this.type === this.TYPE_EDIT) {
            return !(this.item.item_class === 'supply');
        } else {
            return (this.itemType === 'ndc' || this.itemType === 'non_ndc') && this.showCustomDrugForm;
        }
    }

    showValidIdentifierLink() {
        if (this.itemType === 'ndc') {
            return (
                !this.itemAlreadyInFormulary &&
                !this.items?.length &&
                this.searched &&
                this.valid &&
                (this.ndc?.length === 10 || this.ndc?.length === 11)
            );
        } else {
            return !this.itemAlreadyInFormulary && !this.items?.length && this.searched;
        }
    }

    submitCreateSupply() {
        let data = deleteEmptyKeys({
            item_class: 'supply',
            ndc: this.supply.ndc.toString(),
            manufacturer_name: nullIfBlank(this.supply.manufacturer_name),
            item_name: this.supply.item_name,
            package_description_id: makeNumber(this.supply.package_description_id),
            tag_requirement: nullIfBlank(this.supply.tag_requirement),
            expiring_soon_days: makeNumber(this.supply.expiring_soon_days),
            dictionary_entry_id: !!this.dictionaryEntry ? this.dictionaryEntry.dictionary_entry_id : null,
        });

        const addFormularyPromise = this.formularyItemResource.addFormularyItem(data);

        return this.loadingSpinnerService.spinnerifyPromise(addFormularyPromise);
    }

    submitUpdateSupply() {
        let data = deleteEmptyKeys({
            id: this.supply.formulary_item_id ? this.supply.formulary_item_id : this.item.formulary_item_id,
            item_class: 'supply',
            ndc: this.supply.ndc.toString(),
            manufacturer_name: nullIfBlank(this.supply.manufacturer_name),
            item_name: this.supply.item_name,
            package_description_id: makeNumber(this.supply.package_description_id),
            tag_requirement: nullIfBlank(this.supply.tag_requirement),
            expiring_soon_days: makeNumber(this.supply.expiring_soon_days),
        });

        //change item type
        if (this.itemType === 'supply') {
            data.item_class = 'supply';
        } else if (this.itemType === 'ndc') {
            data.item_class = 'drug';
        } else if (this.itemType === 'non_ndc') {
            data.item_class = 'custom_drug';
        }

        const updateFormularyPromise = this.formularyItemResource.updateFormularyItem(data);

        return this.loadingSpinnerService.spinnerifyPromise(updateFormularyPromise);
    }

    createItem() {
        this.invalidateAlternateExpirations();

        let data = deleteEmptyKeys({
            ndc: this.item.ndc.toString(),
            manufacturer_name: nullIfBlank(this.item.manufacturer_name),
            item_name: this.item.item_name,
            clinical_equivalence_id: nullIfBlank(this.item.clinical_equivalence_id),
            item_strength: nullIfBlank(this.item.item_strength),
            item_strength_uom: nullIfBlank(this.item.item_strength_uom),
            package_size: nullIfBlank(this.item.package_size),
            package_size_uom: nullIfBlank(this.item.package_size_uom),
            package_description_id: makeNumber(this.item.package_description_id),
            tag_requirement: nullIfBlank(this.item.tag_requirement),
            expiring_soon_days: makeNumber(this.item.expiring_soon_days),
            expiring_fridge_days: makeNumber(nullIfBlank(this.item.expiring_fridge_days)),
            expiring_multidose_days: makeNumber(nullIfBlank(this.item.expiring_multidose_days)),
            dictionary_entry_id: !!this.dictionaryEntry ? this.dictionaryEntry.dictionary_entry_id : null,
            charge_code: this.item.charge_code,
            catalog_id: nullIfBlank(this.item.global_catalog),
        });

        if (!!this.tagItemInfo?.onBehalfOfHospitalId) {
            data.on_behalf_of_hospital_id = this.tagItemInfo.onBehalfOfHospitalId;
        }

        if (!this.isItemCustom(this.item)) {
            this.setPreferredData(data);
        }

        if (this.itemType === 'non_ndc') {
            data.identifier_type = nullIfBlank(this.item.identifier_type);
            data.identifier_description = nullIfBlank(this.item.identifier_description);
            data.item_class = 'custom_drug';
        }

        if (this.item.price_custom) {
            data.price = this.formatPrice(this.item.price_custom);
        }

        if (!!this.item.uos_ndc && !!this.item.uos_package_description_id) {
            data.unit_of_sale = {
                package_description_id: this.item.uos_package_description_id,
                package_quantity: this.item.uos_package_quantity,
                ndc: this.item.uos_ndc,
            };
        }

        const addFormularyPromise = this.formularyItemResource.addFormularyItem(data).then((data) => {
            this.item.id = data.id;
        });

        return this.loadingSpinnerService.spinnerifyPromise(addFormularyPromise);
    }

    editMedispan(item) {
        if (!item.deleted_at) {
            if (item.in_formulary) {
                this.router.navigate([`/inventory/edit-formulary-item/${item.formulary_item_id}`]);
            } else {
                this.router.navigate([`/inventory/create-formulary-item/${item.ndc}`]);
            }
        } else {
            this.translationService.translateInto(
                'manage_formulary.archive.create_warning.general',
                this.archivedMedispanWarning,
                'general'
            );
            if (
                this.actionService.isAllowAction(
                    'hospital_settings',
                    'delete_formulary_item',
                    'Unarchive a formulary item'
                )
            ) {
                this.translationService.translateInto(
                    'manage_formulary.archive.create_warning.manager',
                    this.archivedMedispanWarning,
                    'message'
                );
                this.translationService.translateInto(
                    'manage_formulary.archive.create_warning.link',
                    this.archivedMedispanWarning.link,
                    'text'
                );
                this.archivedMedispanWarning.formularyItemId = item.formulary_item_id;
            } else {
                this.translationService.translateInto(
                    'manage_formulary.archive.create_warning.non_manager',
                    this.archivedMedispanWarning,
                    'message'
                );
            }
            this.archivedMedispanWarning.show = true;
        }
    }

    setData(item: any, fromDictionary: boolean): void {
        item.originalChargeCode = item.charge_code;
        item.ndc = item.ndc;
        item.expiration_days = parseInt(item.expiration_days);

        if (item.expiring_fridge_days !== null) {
            item.show_fridge_expiration = true;
            item.expiring_fridge_days = parseInt(item.expiring_fridge_days);
            item.original_fridge_days = parseInt(item.expiring_fridge_days);
        } else if (fromDictionary) {
            item.expiring_fridge_days = item.manufacturer_expiring_fridge_days;
        }

        if (!_.isNull(item.clinical_equivalence_name) && !_.isUndefined(item.clinical_equivalence_name)) {
            item.generic_item_name = item.clinical_equivalence_name;
            item.generic_selected = true;
        }

        item.original_fridge = !!item.show_fridge_expiration;

        if (item.expiring_multidose_days !== null) {
            item.show_multidose_expiration = true;
            item.expiring_multidose_days = parseInt(item.expiring_multidose_days);
            item.original_multidose_days = parseInt(item.expiring_multidose_days);
        } else if (fromDictionary) {
            item.expiring_multidose_days = item.manufacturer_expiring_multidose_days;
        }

        item.original_multidose = !!item.show_multidose_expiration;

        item.tag_requirement = item.tag_requirement || '';

        if (this.isItemCustom(item) && item.clinical_equivalence_id) {
            item.item_strength = null;
        } else {
            item.item_strength = parseFloat(item.item_strength);
        }

        if (item.deleted_at == null) {
            this.itemIsArchived = false;
        } else {
            this.itemIsArchived = true;
        }

        item.package_size = parseFloat(item.package_size);

        item.registry = item.formulary_type_string === 'registry';
        item.medispan = item.formulary_type_string === 'medispan';

        item.newFromSearch = fromDictionary;
        this.items = [];
        this.setNDCSearch('');

        this.item = _.clone(item);

        if (!this.item.tag_requirement) {
            this.item.tag_requirement = null;
            this.supply.tag_requirement = null;
        }
    }

    createSupply() {
        this.submitCreateSupply().then(() => {
            let name = this.supply.item_name;
            this.router.navigate(['inventory/manage-formulary'], {
                queryParams: {
                    createSuccess: `Formulary Item "${name}" created`,
                },
            });
        });
    }

    save() {
        const confirmChangesDialog = this.dialog.open(ConfirmChangesFormularyItemDialog, {
            width: '640px',
            height: 'max-content',
            data: {
                name: this.item.item_name,
            },
        });

        confirmChangesDialog.afterClosed().subscribe((result) => {
            if (result.save) {
                if (this.item.item_class === 'supply') {
                    this.editSupply();
                } else if (this.item.item_class === 'drug' || this.item.item_class === 'custom_drug') {
                    this.edit();
                }
            }
        });
    }

    editSupply() {
        this.submitUpdateSupply().then(() => {
            let name = this.supply.item_name;
            this.router.navigate(['/inventory/manage-formulary'], {
                queryParams: { editSuccess: `Formulary Item "${name}" updated` },
            });
        });
    }

    create() {
        this.createItem().then(() => {
            let itemName = this.item.item_name;
            if (this.item.preferred_item_name) {
                itemName = this.item.preferred_item_name;
            }

            if (!!this.tagItemInfo) {
                this.formularyItemsService
                    .getFormularyItemsByNDC(this.item.ndc.toString(), this.tagItemInfo.onBehalfOfHospitalId)
                    .then((data) => {
                        const item = _.find(data.items, {
                            formulary_item_id: this.item.id,
                        });
                        this.formularyItemDataService.setCreatingNewFormulary(true);
                        this.formularyItemDataService.setNewFormularyItem(item);
                        this.router.navigate(['/tagging']);
                    });
            } else {
                this.router.navigate(['/inventory/manage-formulary'], {
                    queryParams: { createSuccess: `Formulary Item "${itemName}" created` },
                });
            }
        });
    }

    edit() {
        this.updateItem().then(() => {
            let itemName = this.item.item_name;
            if (this.item.preferred_item_name) {
                itemName = this.item.preferred_item_name;
            }
            this.router.navigate(['/inventory/manage-formulary'], {
                queryParams: { editSuccess: `Formulary Item "${itemName}" updated` },
            });
        });
    }

    done() {
        //clear out any prior errors since we are resubmitting.
        this.kcMatSnackBarService.clearAll();
        if (this.type === this.TYPE_CREATE || this.medispanEdit) {
            this.create();
        } else if (this.type === this.TYPE_EDIT) {
            this.save();
        }
    }

    changeItemType(itemType) {
        if (this.type === this.TYPE_EDIT) {
            if (itemType === 'ndc') {
                this.item.item_class = 'drug';
                this.switchToNdc = true;
                //put appropriate other conditionals...
            } else if (itemType === 'non_ndc') {
                this.item.item_class = 'custom_drug';
            } else if (itemType === 'supply') {
                this.item.item_class = 'supply';
            }
        }
    }

    expirationChanging(type) {
        const currentCheck = !!this.item[`show_${type}_expiration`],
            originalCheck = this.item[`original_${type}`],
            currentDays = this.item[`expiring_${type}_days`],
            originalDays = this.item[`original_${type}_days`];

        return (
            this.item.has_been_tagged_before &&
            (originalCheck !== currentCheck || (originalCheck && originalDays !== currentDays))
        );
    }

    onExpirationTypeChange(type) {
        if (type !== 'refrigerated') {
            this.item.expiring_fridge_days = null;
        }
    }

    setCustomFormularyType() {
        if (!this.isAllowedCreateCustomItem()) return;
        this.showCustomDrugForm = true;
        this.view = this.TAB_CUSTOM;
        this.item.formulary_type_string = this.TAB_CUSTOM;
        this.item.ndc = this.searchByNDC;
        this.translationService.translateInto(
            'manage_formulary.formulary_types.custom',
            this.item,
            'type_name_formatted'
        );
        if (this.type === this.TYPE_CREATE) {
            this.item.package_description_id = null;
            this.item.tag_requirement = null;
            this.supply.package_description_id = this.packageDescriptions.find(
                (description) => description.abbreviation === 'SU'
            ).id;
            this.supply.tag_requirement = null;
        }
    }

    isItemGlobal(item) {
        if (item.formulary_type_string === 'global') {
            return true;
        } else {
            return false;
        }
    }

    isItemCustom(item) {
        if (item.formulary_type_string === this.TAB_CUSTOM) {
            return true;
        } else {
            return false;
        }
    }

    isItemEditable(item) {
        // if it is not global and not medispan and not registry it is editable
        if (!this.isItemGlobal(item) && !item.medispan && !item.registry) {
            return true;
        } else {
            return false;
        }
    }

    isCustomNdc() {
        //isHospitalDictionaryEntry() ?
        return this.isItemEditable(this.item) && (this.itemType === 'ndc' || this.item.item_class === 'drug');
    }

    isEditableFormularyItem(item) {
        return item.editable_formulary_items;
    }

    supplyFormInvalid() {
        return !!this.supplyForm && this.supplyForm.invalid;
    }

    itemFormInvalid() {
        return (
            (!!this.drugForm && this.drugForm.invalid) ||
            (this.itemAlias && this.itemAlias.preferredItemStrength && this.itemAlias.preferredItemStrength.errors) ||
            (this.itemAlias && this.itemAlias.preferredItemName && this.itemAlias.preferredItemName.errors) ||
            (this.itemAlias &&
                this.itemAlias.preferredItemStrengthUom &&
                this.itemAlias.preferredItemStrengthUom.errors)
        );
    }

    isNdcValid(ndc) {
        return !ndc || (!!ndc?.match(/^[0-9\s\-]+$/) && this.isNdcCorrectLength(ndc));
    }

    isNdcCorrectLength(ndc) {
        if (!!ndc) {
            ndc = ndc.replace(' ', ''); // clean up any trailing spaces

            const cleanedNDC = this.stripNDC(ndc);
            return cleanedNDC.length === 10 || cleanedNDC.length === 11;
        }
        return true;
    }

    async sendSearchToAPI(ndc) {
        this.showSuggestedUoU = false;
        this.valid = this.isNdcValid(ndc);
        ndc = this.stripNDC(ndc);
        this.ndc = ndc;
        this.searching = true;
        await this.setItems(ndc);
        if (this.items[0]?.ndc_type === 'unit_of_sale' && !!this.items[0]?.uou_ndc) {
            // get the uou entry instead
            this.showSuggestedUoU = true;
            await this.setItems(this.items[0].uou_ndc);
        }
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.items);
        this.searched = true;
        this.searching = false;
    }

    async setItems(ndc) {
        let data = await this.formularyItemsService.getFormularyItemsByNDC(ndc, undefined, true);
        let hasDupes = await this.ndcAlreadyInFormulary(ndc);
        this.itemAlreadyInFormulary = hasDupes;

        if (data.items.length > 1) {
            // filter any uos ndcs
            data.items = data.items.filter((item) => item.ndc_type !== 'unit_of_sale');
        }
        this.items = data.items;
    }

    async ndcAlreadyInFormulary(ndc) {
        const response = await this.formularyItemResource.formularyItemList();
        let normalizedNdc = ndc.replaceAll('-', '');
        let dupes = response.formulary_items.filter((item) => {
            if (item.ndc) {
                return item.ndc.replaceAll('-', '') === normalizedNdc;
            } else {
                return false;
            }
        });
        return dupes.length > 0;
    }

    ndcSearch(ndc) {
        this.searchByNDC = ndc;
        this.archivedMedispanWarning.show = false;
        this.debouncedSendSearchToAPI(ndc);
    }

    hideChargeCode() {
        const not_allowed = !this.actionService.isAllowAction(
            'acheck',
            'edit_charge_code',
            'Manage Formulary charge code section'
        );
        return not_allowed || (this.item && !this.item.valid_for_acheck);
    }

    setNDCSearch(ndc) {
        this.searchByNDC = ndc;
        $('#ndc-search').val(ndc);
    }

    stripNDC(ndc) {
        ndc = ndc.replace(/[^0-9a-zA-Z]/gi, '');
        this.strippedNDClength = ndc.length;
        return ndc;
    }

    cancel() {
        if (this.medispanEdit) {
            this.router.navigate(['/inventory/create-formulary-item']);
        } else {
            this.router.navigate(['/inventory/manage-formulary']);
        }
    }

    toggleOuterPackage(event) {
        this.outerPackageToggle = event;
        if (!event) {
            this.removeOuterPackage();
        }
    }

    setDate(data: Date): void {
        this.item.price_valid_until = data;
    }

    removeOuterPackage() {
        const removeOuterPackageDialog = this.dialog.open(RemoveOuterPackageDialog, {
            width: '640px',
            height: 'max-content',
        });

        removeOuterPackageDialog.afterClosed().subscribe((result) => {
            //If user cancels modal, we want to leave outerPackageToggle on
            if (!result.remove) {
                this.outerPackageToggle = true;
            }
        });
    }

    formatPrice(price) {
        //change back to number
        return Number(price);
    }

    displayPrice(price) {
        //change to string
        return Number.parseFloat(price).toFixed(2);
    }
}
