import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

@Injectable({ providedIn: 'root' })
export class DictionaryEntryResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const ndc = activatedRoute.params.ndc;
        const formularyPromise = this.formularyItemsService.getFormularyItemsByNDC(ndc).then(({ items }) => items[0]);

        return this.loadingSpinnerService.spinnerifyPromise(formularyPromise);
    }
}
