import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { UserResource } from '@resources/user-resource.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private userResource: UserResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const userId = parseInt(activatedRoute.params.userId);
        return this.loadingSpinnerService.spinnerifyPromise(this.userResource.getUser(userId)).then(({ user }) => {
            return user;
        });
    }
}
