import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { KitScanResource } from '@resources/kit-scan-resource.service';

@Injectable({ providedIn: 'root' })
export class KitScansResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private kitScanResource: KitScanResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const kitId = activatedRoute.params.kitId;
        const paging_params = {
            page_size: 25,
            page_index: 1,
        };

        const kitScanPromise = this.kitScanResource.kitScanListPaged(kitId, paging_params).then((data) => {
            return data;
        });

        return this.loadingSpinnerService.spinnerifyPromise(kitScanPromise);
    }
}
