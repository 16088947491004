import { OnInit, Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { TaggingDataService } from '@services/core/tagging-data.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { NaturalSortService } from '@services/utils/natural-sort.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TagAssociationService } from '@services/core/tag-association.service';
import { TranslationService } from '@services/utils/translation.service';

type QueryParams = {
    barcode: string;
    type: string;
};

@Component({
    selector: 'tagging',
    templateUrl: './tagging.html',
    styleUrls: ['./tagging.scss'],
})
export class Tagging implements OnInit {
    links: Array<{ name: string; type: string }> = [];
    activeLink: { name: string; type: string };

    listOfHospitalsForItem = [];
    listOfHospitalsForKit = [];
    listOfHospitalsForBin = [];
    hospitalList = [];
    type: any = {};
    scanId: string;
    hasGlobalDictionary: boolean;
    taggingOnBehalf: boolean;
    onBehalfOfHospitalSettings;
    headerTitle: string;
    quantityCheck: number;
    confirmingQuantity: boolean;
    badQuantity: boolean;
    batchError: boolean;
    printBatchValue: string = undefined;
    tag: any = {};
    permissions: any = {};
    triggerTaggingItemReload: Subject<boolean> = new Subject();
    transitionBack = _.noop;
    queryParams: QueryParams = { barcode: null, type: null };
    newFormularyItem: any;
    kitMasters: any;
    hospitalCatalogs: any;
    printers: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected actionService: ActionService,
        protected applicationService: ApplicationService,
        protected barcodeScanService: BarcodeScanService,
        protected changeDetector: ChangeDetectorRef,
        protected taggingDataService: TaggingDataService,
        protected hardwareService: HardwareService,
        protected hospitalInfoService: HospitalInfoService,
        protected kitMasterService: KitMasterService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected ndcScanUtilsService: NdcScanUtilsService,
        protected tagAssociationService: TagAssociationService,
        private productModuleService: ProductModuleService
    ) {
        productModuleService.setModule(ModuleTypes.TAGGING);
    }

    ngOnInit(): void {
        // Forces a refresh after clicking on a link that has a queryParam
        this.newFormularyItem = this.taggingDataService.getFormularyItem();

        this.activatedRoute.data.subscribe((data) => {
            this.kitMasters = data.kitMasters;
            this.hospitalCatalogs = data.hospitalCatalogs;
            this.printers = data.printers;

            if (this.hospitalCatalogs.length > 0) {
                this.hasGlobalDictionary = true;
            }
        });

        this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
            this.queryParams = {
                barcode: queryParamMap.get('barcode'),
                type: queryParamMap.get('type'),
            };
            this.scanId = this.queryParams.barcode;
            this.clearInputs();
            this.checkPermissions();
            this.initializeType();
            this.initializeHospitals();
            this.initializeTaggingOnBehalf();
        });

        this.headerTitle = this.getTitle();
    }

    clearInputs() {
        this.quantityCheck = null;
    }

    ngOnDestroy() {
        this.unblockScanListener();
    }

    checkPermissions() {
        this.listOfHospitalsForItem = this.actionService.isAllowAction(
            'kits_tagging',
            'create_item_tag',
            'Tag item on behalf of hospitals',
            'on_behalf_of_hospital'
        );
        this.listOfHospitalsForKit = this.actionService.isAllowAction(
            'kits_inventory',
            'create_kit',
            'Tag kit on behalf of hospitals',
            'on_behalf_of_hospital'
        );
        this.listOfHospitalsForBin = this.actionService.isAllowAction(
            'kits_inventory',
            'create_bin',
            'Tag bin on behalf of hospitals',
            'on_behalf_of_hospital'
        );

        const itemHospitals = this.listOfHospitalsForItem.length > 0;
        const kitHospitals = this.listOfHospitalsForKit.length > 0;
        const binHospitals = this.listOfHospitalsForBin.length > 0;

        this.taggingOnBehalf = itemHospitals || kitHospitals || binHospitals;

        if (
            this.actionService.isAllowAction(
                'kits_tagging',
                'create_item_tag',
                'Check if user can go to item tagging section'
            )
        ) {
            if (!this.taggingOnBehalf || (this.taggingOnBehalf && itemHospitals)) {
                this.permissions.itemTag = true;
                this.addMenuItem({ name: 'Items', type: 'item' });
            }
        }
        if (
            this.actionService.isAllowAction(
                'kits_inventory',
                'create_kit',
                'Check if user can go to kit tagging section'
            )
        ) {
            if (!this.taggingOnBehalf || (this.taggingOnBehalf && kitHospitals)) {
                this.permissions.kitTag = true;
                this.addMenuItem({ name: 'Kits', type: 'kit' });
            }
        }
        if (
            this.actionService.isAllowAction(
                'kits_inventory',
                'create_bin',
                'Check if user can go to bin tagging section'
            )
        ) {
            if (!this.taggingOnBehalf || (this.taggingOnBehalf && binHospitals)) {
                this.permissions.binTag = true;
                this.addMenuItem({ name: 'Bins', type: 'bin' });
            }
        }
    }

    addMenuItem(item: { name: string; type: string }) {
        if (this.links.some((l) => l.type === item.type)) {
            return;
        }

        this.links = this.links.concat(item);
    }

    getBatchInfo() {
        this.confirmingQuantity = true;
        const associationPromise = this.tagAssociationService
            .getAssociationScan(parseInt(this.scanId))
            .then((data) => {
                this.processScanData(data);
            })
            .catch(() => {
                this.batchError = true;
            });

        this.loadingSpinnerService.spinnerifyPromise(associationPromise);
    }

    processScanData(data) {
        this.tag.quantity = data.associable_epcs.length;
        this.tag.epcs = data.associable_epcs;
        this.tag.labels = data.associable_epcs;
        if (data.associable_epcs.length === 1) {
            this.tag.epc = data.associable_epcs[0];
        }
    }

    verifyQuantity(event) {
        if (parseInt(event) === this.tag.quantity) {
            this.type.batch = this.tag.quantity === 1 ? 'single' : 'multi';
            this.confirmingQuantity = false;
            this.badQuantity = false;
            this.switchType(this.links[0]); // start on the items page
        } else {
            this.badQuantity = true;
        }
    }

    initializeType() {
        const batchTagging = this.queryParams.type === 'batch' && this.permissions.itemTag;

        if (batchTagging) {
            if (!!this.queryParams.barcode) {
                this.type = {
                    name: 'item',
                    title: 'Item',
                    print: false,
                    batch: true,
                    enabled: true,
                };
            }
            return;
        }

        let enteredFromBasicTagScan: boolean = false;
        let enteredFromNDC: boolean = false;
        let enteredFromUnknown: boolean = false;

        if (this.queryParams.barcode) {
            if (!this.isNDC(this.queryParams.barcode)) {
                enteredFromBasicTagScan = true;
            } else {
                enteredFromNDC = true;
            }
        } else {
            if (!this.queryParams.type) {
                enteredFromUnknown = true;
            }
        }

        if (
            (this.queryParams.type === 'item' || enteredFromBasicTagScan || enteredFromNDC || enteredFromUnknown) &&
            this.permissions.itemTag
        ) {
            this.type = {
                name: 'item',
                title: 'Item',
                print: true,
                enabled: true,
            };
        } else if (
            (this.queryParams.type === 'kit' || enteredFromBasicTagScan || enteredFromUnknown) &&
            this.permissions.kitTag
        ) {
            this.type = {
                name: 'kit',
                title: 'Kit',
                print: true,
                enabled: true,
            };
        } else if (
            (this.queryParams.type === 'bin' || enteredFromBasicTagScan || enteredFromUnknown) &&
            this.permissions.binTag
        ) {
            this.type = {
                name: 'bin',
                title: 'Bin',
                print: true,
                enabled: true,
            };
        } else {
            this.router.navigate(['/']);
        }

        if (enteredFromBasicTagScan || this.printers.length === 0) {
            this.type.print = false;
        }
    }

    initializeHospitals(): void {
        this.tag.hospitalSettings = this.hospitalInfoService.getHospitalSettings();
        this.tag.currentHospitalId = this.tag.hospitalSettings.hospital_id;
    }

    initializeTaggingOnBehalf(): void {
        if (this.taggingOnBehalf) {
            this.hospitalList = this.hospitalsForList();
            this.registerHospitalListener();
            this.tag.batch = undefined;
            this.onBehalfOfHospitalSettings = this.hospitalInfoService.getOnBehalfOfHospitalSettings();

            this.transitionBack = () => {
                this.tag.onBehalfOfHospital = undefined;
                this.barcodeScanService.removeListener('tagging');
                this.registerHospitalListener();
            };
        }

        if (!!this.type.batch) {
            this.getBatchInfo();
        } else {
            this.activeLink = this.links.find((link) => link.type === this.type.name);
        }
    }

    isNDC(barcode: string): boolean {
        return this.ndcScanUtilsService.extractNDCFromScan(barcode) !== barcode;
    }

    switchType(link: { name: string; type: string }): void {
        this.activeLink = link;
        this.type.name = link.type;
        if (this.taggingOnBehalf) {
            this.hospitalList = this.hospitalsForList();
        }
    }

    refreshKitMasters(hospitalId: number) {
        if (this.listOfHospitalsForKit.length > 0) {
            this.kitMasterService.getKitMasters(null, hospitalId).then((kits) => {
                this.kitMasters = kits;
            });
        }
    }

    resetScanId(): void {
        this.router.navigate(['/']);
    }

    setBatch(event: string) {
        this.tag.batch = event;
    }

    filterHospital(event) {
        const firstChange = !this.tag.onBehalfOfHospital;
        this.tag.onBehalfOfHospital = event;
        this.tag.onBehalfOfHospitalId = event.id;
        Object.assign(this.tag.hospitalSettings, this.onBehalfOfHospitalSettings[event.id]);
        this.refreshKitMasters(event.id);
        if (!firstChange) {
            this.hardResetChildComponent();
        }
    }

    hardResetChildComponent() {
        this.type.enabled = false;
        this.changeDetector.detectChanges();
        this.router.navigate([], { relativeTo: this.activatedRoute });
        this.type.enabled = true;
    }

    hospitalsForList() {
        let list;
        switch (this.type.name) {
            case 'item':
                list = this.listOfHospitalsForItem;
                break;
            case 'kit':
                list = this.listOfHospitalsForKit;
                break;
            case 'bin':
                list = this.listOfHospitalsForBin;
                break;
        }

        return NaturalSortService.sort(list, 'name');
    }

    printer(id: string) {
        return _.find(this.printers, {
            id: parseInt(id),
        });
    }

    setDefaultPrinter(p) {
        this.hardwareService.setDefaultPrinter(p.id.toString());
    }

    getDefaultPrinter() {
        return this.hardwareService.getDefaultPrinter();
    }

    showTaggingContent(): boolean {
        return (
            ((this.type.name !== 'item' || this.listOfHospitalsForItem.length === 0) &&
                (this.type.name !== 'kit' || this.listOfHospitalsForKit.length === 0) &&
                (this.type.name !== 'bin' || this.listOfHospitalsForBin.length === 0)) ||
            !!this.tag.onBehalfOfHospital
        );
    }

    registerHospitalListener() {
        this.barcodeScanService.registerListener((inputString) => {
            const elementId = _.get(document, 'activeElement.id');
            switch (elementId) {
                case 'batch-field':
                    setTimeout(() => {
                        $('#ndc-input').focus();
                    });
                    break;
                case 'ndc-input':
                    break;
                default:
                    if (this.taggingOnBehalf) {
                        inputString = inputString.toUpperCase();
                        setTimeout(() => {
                            const hospitalList: any[] = this.hospitalsForList();
                            const scannedHospital = _.find(hospitalList, (h) => {
                                return h.barcode.toUpperCase() === inputString;
                            });

                            if (scannedHospital) {
                                this.tag.onBehalfOfHospital = scannedHospital;
                                this.tag.onBehalfOfHospitalId = scannedHospital.id;
                                this.refreshKitMasters(scannedHospital.id);
                                Object.assign(
                                    this.tag.hospitalSettings,
                                    this.onBehalfOfHospitalSettings[scannedHospital.id]
                                );
                            } else {
                                window.alert('Unrecognized hospital scanned');
                            }
                        });
                    } else {
                        if ($('#ndc-input').length) {
                            $('#ndc-input').focus().val(this.ndcScanUtilsService.extractNDCFromScan(inputString));
                        }
                    }
                    break;
            }
        }, 'tagging');
    }

    blockScanListener(): void {
        this.barcodeScanService.blockListeners();
    }

    unblockScanListener(): void {
        this.barcodeScanService.unblockListeners();
    }

    getTitle(): string {
        if (!!this.type.print) {
            if (this.type.name === 'item') {
                return 'tagging.titles.print_item_tags';
            } else if (this.type.name === 'kit') {
                return 'tagging.titles.print_kit_tags';
            } else if (this.type.name === 'bin') {
                return 'tagging.titles.print_bin_tag';
            }
        } else {
            if (this.type.name === 'item') {
                return 'tagging.titles.add_items';
            } else if (this.type.name === 'kit') {
                return 'tagging.titles.add_kit';
            } else if (this.type.name === 'bin') {
                return 'tagging.titles.add_bin';
            }
        }
    }
}
