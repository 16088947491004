import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { PrintResource } from '@resources/print-resource.service';
import { ScannerService } from '@services/hardware/scanner.service';
import { ScannerUpdatingService } from '@services/system/scanner-updating.service';
import { TranslationService } from '@services/utils/translation.service';

import { BannerService } from '@services/system/banner.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'admin-hardware-list',
    templateUrl: './admin-hardware-list.html',
    styleUrls: ['./admin-hardware-list.scss'],
})
export class AdminHardwareList {
    // These are radically different depending on the hardware type, so leaving as any
    hardware: any[];

    actionAllow: any;
    printerStatusSettingsToShow: string[] = [
        'DARKNESS',
        'MEDIA TYPE',
        'SENSOR TYPE',
        'LABEL TOP',
        'LEFT POSITION',
        'WEB S.',
        'MEDIA S.',
        'RIBBON S.',
        'TAKE LABEL',
        'MARK S.',
        'MARK MED S.',
        'TRANS GAIN',
        'TRANS BASE',
        'TRANS BRIGHT',
        'RIBBON GAIN',
        'MARK GAIN',
        'FIRMWARE',
        'RFID HW VERSION',
        'RFID FW VERSION',
        'PROG. POSITION',
        'RFID ANTENNA',
    ];

    isLoading: boolean = true;
    hasData: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['hardware_type_category', 'online', 'name', 'actions'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private bannerService: BannerService,
        private dialog: MatDialog,
        private hardwareService: HardwareService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private printResource: PrintResource,
        protected scannerUpdatingService: ScannerUpdatingService,
        private scannerService: ScannerService,
        private translationService: TranslationService,
        private activateRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activateRoute.data.subscribe((data) => {
            this.hardware = data.hardware;
        });

        this.getHardwareStatus();
        this.actionAllow = this.actionService.allowModule('hospital_settings');
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.hardware);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.hasData = !!this.hardware.length;
    }

    getHardwareStatus(): void {
        this.hardware.forEach((device) => {
            device.showDebug = false;
            device.online = '';
            if (device.hardware_type_category === 'rfid printer' && device.is_tunnel) {
                device.loading = true;
                // This ZPL requests setting info from the printer
                this.printResource
                    .sendZpl(device, '^XA^HH^XZ')
                    .then((data) => {
                        this.processTunnelPrinterStatus(data, device);
                    })
                    .catch(() => (device.online = 'NO'))
                    .finally(() => (device.loading = false));
            } else if (device.hardware_type_category === 'scanner') {
                this.scannerService.checkStatus(device);
                device.isThingMagic = this.scannerService.isThingMagic(device);
                device.isZebraFx = this.scannerService.isZebraFx(device);
                device.isURRI = this.scannerService.isURRI(device);
            } else {
                device.online = 'NA';
            }
        });
    }

    processTunnelPrinterStatus(data, device): void {
        const boundShowPrinterInfo = this.showPrinterInfoRow.bind(this);

        if (data.includes('"data":')) {
            data = JSON.parse(data).data;
        }

        device.printerInfo = _(data.split('\n'))
            .filter(boundShowPrinterInfo)
            .map((row: any) => {
                row = row.trim();
                row = row.replace('TM: ', 'TM:');
                row = row.replace(/\s{2,}/g, ' ');
                row = row.replace('<- FIRMWARE', 'FIRMWARE');
                row = row.split(' ');
                return {
                    value: row.shift().replace('TM:', 'TM: '),
                    key: row.join(' '),
                };
            })
            .value();
        if (device.printerInfo.length) {
            device.online = 'YES';
        } else {
            device.online = 'NO';
        }
    }

    showPrinterInfoRow(text): boolean {
        return this.printerStatusSettingsToShow.some((key) => text.includes(key));
    }

    resetPrinter(p, e): void {
        e.preventDefault();
        p.reset = 'in_progress';

        this.printResource
            .resetPrinter(p)
            .then(() => {
                setTimeout(() => {
                    p.reset = null;
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'admin.hardware.reset_command_sent',
                    });
                }, 2000);
            })
            .catch(() => (p.reset = 'error'));
    }

    restorePrinter(p, e): void {
        e.preventDefault();
        p.restore = 'in_progress';

        this.printResource
            .restorePrinter(p)
            .then(() => {
                setTimeout(() => {
                    p.restore = null;
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: 'admin.hardware.restore_command_sent',
                    });
                }, 2000);
            })
            .catch(() => (p.restore = 'error'));
    }

    showDebugTextKey(showingDebug: boolean): string {
        return showingDebug ? 'admin.hardware.hide_debug' : 'admin.hardware.show_debug';
    }

    printTestTag(hardware): void {
        this.printResource.printSampleTag(hardware, 1);
    }

    updateScannerFirmware(hardware) {
        if (!this.scannerUpdatingService.isUpdating) {
            const confirmDialog = this.dialog.open(ConfirmDialog, {
                width: '600px',
                height: 'max-content',
                data: {
                    title: this.translationService.instant('admin.hardware.update_scanner'),
                    description: this.translationService.instant('admin.hardware.update_body_message'),
                    okButton: this.translationService.instant('buttons.update'),
                },
            });

            confirmDialog.afterClosed().subscribe((confirmed) => {
                if (hardware.online === 'UPGRADE_SSL') {
                    this.scannerService.updateSSL(hardware);
                } else {
                    this.scannerService.updateFirmware(hardware);
                }
            });
        } else {
            const message = this.translationService.instant('admin.hardware.update_in_progress');
            this.kcMatSnackBarService.open(SnackBarTypes.WARNING, message);
        }
    }

    resetCertLink(hardware): string {
        if (hardware && hardware.hostname) {
            return `http://${hardware.hostname}/${hardware.hardware_settings.scan_endpoint}?command=com.network.security.load_https_crt_key(https_crt_file=/apps/bin/STAR_device_kitcheck_com_ALL.crt,https_key_file=/apps/bin/device.kitcheck.com.key)`;
        }
    }

    useTooltip(device): boolean {
        return (
            device.updating ||
            ((device.isThingMagic || device.isZebraFx || device.isURRI) && device.online && device.online !== 'NO')
        );
    }

    revisionTooltip(device): string {
        let templateStr = '';

        _.each(device.firmwareRevisions, (rev) => {
            const updateVersion: any = _.find(device.needsUpdate, {
                firmware_revision_type: rev.firmware_revision_type,
            });
            const needsUpdate = !!updateVersion;
            const revStr = `
                <div>
                    <span class="fas ${needsUpdate ? 'fa-arrow-circle-up' : 'fa-check-circle'}"></span>
                    <span>${rev.firmware_revision_type}: ${rev.version}</span>
                    ${
                        needsUpdate
                            ? `<i> <span class="fas fa-long-arrow-right"></span> ${updateVersion.version}</i>`
                            : ''
                    }
                </div>
            `;
            templateStr += revStr;
        });

        _.each(device.needsUpdate, (rev) => {
            const installedVersion: any = _.find(device.firmwareRevisions, {
                firmware_revision_type: rev.firmware_revision_type,
            });
            const hasInstalled = !!installedVersion;
            if (hasInstalled) {
                return;
            }

            const revStr = `
                <div>
                    <span class="fas fa-arrow-circle-up"></span>
                    <span>${rev.firmware_revision_type}: ???</span>
                    <i> <span class="fas fa-long-arrow-right"></span> ${rev.version}</i>
                </div>
            `;
            templateStr += revStr;
        });

        return templateStr;
    }

    updatingTooltip(device): string {
        return this.scannerUpdatingService.updateMessage;
    }

    tooltipTemplate(device): string {
        if (device.updating) {
            return this.updatingTooltip(device);
        } else {
            return this.revisionTooltip(device);
        }
    }
}
