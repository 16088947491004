import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

@Injectable({ providedIn: 'root' })
export class ContainersResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containersResource: ContainerResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const containerId = parseInt(activatedRoute.params.id);

        const containersPromise = this.containersResource.getContainer(containerId);
        return this.loadingSpinnerService.spinnerifyPromise(containersPromise);
    }
}
