import { Input, Output, EventEmitter, Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';

import { MatStep, MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { ActionService } from '@services/utils/action.service';
import { TaggingDataService } from '@services/core/tagging-data.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { RecallResource } from '@resources/recall-resource.service';
import { TranslationService } from '@services/utils/translation.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { Subscription } from 'rxjs';
import { FormularyItemDataService } from '@services/core/formulary-item-data.service';
import { ScanType } from '../tagging';

@Component({
    selector: 'tagging-item',
    templateUrl: './tagging-item.html',
})
export class TaggingItem {
    @Input() type: ScanType;
    @Input() tag;
    @Input() printers;

    @Output() refreshMe = new EventEmitter();
    @Output() resetAssociationState = new EventEmitter();

    validators: {
        [key: string]: Function;
    };

    hasGlobalDictionary: boolean;
    itemAliasSelect: any;
    itemPicked: boolean = false;
    searchTerm: string;
    sharedRecallsArray: any;
    pickedItem: any;
    usePrePrintedTags: boolean;
    triggerAutopick: boolean = false;
    triggerAssociate: Subject<boolean> = new Subject();
    triggerLotExpSuccess: Subject<boolean> = new Subject();
    haveCheckedBins = false;
    newFormularyItem: any;
    barcode: string;
    barcodeObject: any;
    creatingFormularyItem: any;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('itemStep') itemStep: MatStep;
    @ViewChild('lotExpStep') lotExpStep: MatStep;
    @ViewChild('tagStep') tagStep: MatStep;
    @ViewChild('doneStep') doneStep: MatStep;
    @ViewChild('itemForm') itemForm: NgForm;
    @ViewChild('lotExpForm') lotExpForm: NgForm;
    @ViewChild('tagForm') tagForm: NgForm;
    @ViewChild('itemFormComplete') itemFormComplete: NgForm;
    lotExpFormSubscription: Subscription;
    tagFormSubscription: Subscription;
    itemFormCompleteSubscription: Subscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private actionService: ActionService,
        private taggingDataService: TaggingDataService,
        private formularyItemDataService: FormularyItemDataService,
        private formularyItemResource: FormularyItemResource,
        private hardwareService: HardwareService,
        private hospitalInfoService: HospitalInfoService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private recallResource: RecallResource,
        private translationService: TranslationService,
        private changeRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService
    ) {}

    ngOnInit() {
        this.creatingFormularyItem = this.formularyItemDataService.getCreatingNewFormulary();
        this.route.queryParamMap.subscribe((queryParamMap) => {
            this.barcode = queryParamMap.get('barcode');
        });
        this.newFormularyItem = this.formularyItemDataService.getNewFormularyItem();
        this.barcodeObject = this.taggingDataService.getBarcodeObject();
        this.hasGlobalDictionary = false;
        this.searchTerm = '';
        this.sharedRecallsArray = [];

        if (_.isUndefined(this.itemAliasSelect)) {
            this.itemAliasSelect = {};
        }

        this.startNewPrint();
        this.initValidators();
        if (!this.hospitalInfoService.hospitalSetting('shelved_inventory_enabled')) {
            this.haveCheckedBins = true;
        }
    }

    ngOnChanges() {
        this.startNewPrint();
        this.initValidators();
    }

    ngAfterViewInit() {
        this.changeRef.detectChanges();

        this.lotExpFormSubscription = this.lotExpForm.statusChanges.subscribe((result) => {
            setTimeout(() => this.lotExpFormChanged(result));
        });

        this.tagFormSubscription = this.tagForm.statusChanges.subscribe((result) => {
            this.tagFormChanged(result);
        });

        this.itemFormCompleteSubscription = this.itemFormComplete.statusChanges.subscribe((result) => {
            this.itemFormCompleteChanged(result);
        });

        if (!!this.newFormularyItem && this.creatingFormularyItem) {
            this.setupNewFormularyItemFlow();
        }
    }

    ngOnDestroy() {
        this.unsubscribeListeners();
    }

    unsubscribeListeners() {
        this.lotExpFormSubscription.unsubscribe();
        this.tagFormSubscription.unsubscribe();
        this.itemFormCompleteSubscription.unsubscribe();
    }

    setupNewFormularyItemFlow() {
        this.pick(this.newFormularyItem, true, 'TODO CF');
        this.newFormularyItem = null;

        const tagStateInfo = this.taggingDataService.getTagItemInfo();

        if (tagStateInfo) {
            this.tag = {
                ...this.tag,
                epcs: tagStateInfo.epcs,
                epc: tagStateInfo.epc,
                labels: tagStateInfo.labels,
                print: !!tagStateInfo.print,
            };

            this.taggingDataService.setTagItemInfo(null);
        }

        this.formularyItemDataService.setCreatingNewFormulary(false);
    }

    isNDC(barcode: string): boolean {
        return this.ndcScanUtilsService.extractNDCFromScan(barcode) !== barcode;
    }

    startNewPrint() {
        Object.assign(this.tag, {
            isNewItem: false,
            item: {
                saveComplete: false,
            },
            printer: null,
            labelName: '',
            expire: '',
            lot: '',
            ndc: '',
            expire_formatted: '',
            lotRequired: false,
            expireRequired: false,
            lotExpSaved: false,
            compoundRequired: false,
            lotAllowed: true,
            expireAllowed: true,
            compoundAllowed: false,
            calculate_fridge_date: false,
            hasRecall: false,
            gsBarcode: undefined,
            processingGSBarcode: false,
            addToBin: undefined,
            forcePreTagReprint: false,
        });

        this.resetTag();

        this.hardwareService.getDefaultPrinter().then((printer) => {
            this.tag.printer = printer;
        });

        // If this is coming from a batch scan, then the quantity and EPCs are already set
        // and the state param is the scanId, so don't do anything with it
        if (!this.type.batch) {
            Object.assign(this.tag, {
                quantity: 1,
                epc: '',
                labels: [],
            });

            // When scanning an item barcode from a page other than the Print Item/Add Item
            // page, save the barcode data received in the $stateParams.

            if (!!this.barcodeObject && (!this.barcode || this.barcode.length === 0) && this.type.name === 'item') {
                if (this.isNDC(this.barcodeObject)) {
                    this.tag.barcode = this.barcodeObject;
                    this.tag.gsBarcode = this.barcodeObject;
                } else {
                    this.tag.epc = this.barcodeObject;
                }
            } else if (this.barcode && this.type.name === 'item') {
                if (this.isNDC(this.barcode)) {
                    this.tag.barcode = this.barcode;
                } else {
                    this.tag.epc = this.barcode;
                }
            }
        }
    }

    itemFormCompleteChanged(result): void {
        if (result !== 'VALID' || this.itemFormComplete.pristine || !this.isSelected(this.itemStep)) {
            return;
        }

        // Always Automatically Advance
        this.stepper.next();
    }

    lotExpFormChanged(result): void {
        if (
            result === 'DISABLED' &&
            this.lotExpForm.dirty &&
            this.isSelected(this.lotExpStep) &&
            !this.tag.showFridgeCheckbox
        ) {
            this.stepper.next();
            return;
        }

        if (result !== 'VALID' || this.lotExpForm.pristine || !this.isSelected(this.lotExpStep)) {
            return;
        }

        // Automatically Advance if Fridge Checkbox not present
        if (!this.tag.showFridgeCheckbox) {
            this.stepper.next();
        }
    }

    tagFormChanged(result): void {
        if (result === 'DISABLED' || result === 'INVALID') {
            return;
        }

        if (result !== 'VALID' || this.tagForm.pristine || !this.isSelected(this.tagStep)) {
            return;
        }

        // Automatically Advance if we are in batch mode (will require choosing a bin if we have bins)
        if (!!this.type.batch && this.haveCheckedBins) {
            this.stepper.next();
        }
    }

    resetStepper(): void {
        // this one was way too complicated to reset effectively, so use a sledgehammer in the parent
        this.refreshMe.emit();
    }

    initValidators(): void {
        this.validators = {
            tag: () => {
                if (!!this.type.batch && this.haveCheckedBins && !this.useBins() && this.tagForm) {
                    this.tagForm.control.markAsDirty();
                }

                return this.isSelected(this.tagStep);
            },
        };
    }

    itemFormCompleteValidator(): boolean {
        return !!this.itemForm?.valid && this.itemPicked;
    }

    tagFormCompleteValidator(): boolean {
        return !this.type.print || (!!this.tagForm?.valid && this.tag.printComplete);
    }

    selectionChange(event: StepperSelectionEvent): void {
        if (event.selectedStep === this.itemStep) {
            this.resetStepper();
        }

        if (this.fromTo(event, this.itemStep, this.lotExpStep)) {
            this.transitionToLotExp();
        } else {
            if (event.selectedStep === this.lotExpStep) {
                this.resetStepper();
            }
        }

        if (this.fromTo(event, this.lotExpStep, this.tagStep)) {
            this.prepareToPrint();
            this.triggerLotExpSuccess.next(true);
            this.lotExpStep.completed = true;
        }

        if (this.fromTo(event, this.tagStep, this.doneStep)) {
            if (!this.type.print) {
                setTimeout(() => {
                    this.triggerAssociate.next(true);
                }, 0);
            }

            this.resetAssociationState.emit();
        }

        if (event.selectedIndex < event.previouslySelectedIndex) {
            this.stepper.steps.forEach((step, index) => {
                if (index > event.selectedIndex) {
                    step.interacted = false;
                }
            });
        }
    }

    setInputFocus(): void {
        let focusField;
        if (this.isSelected(this.itemStep)) {
            const batchField = document.getElementById('batch-field');
            if (!!batchField) {
                // don't change focus if there is a batch field
                return;
            }

            focusField = document.getElementById('ndc-input');
            focusField?.focus();

            return;
        }

        if (this.isSelected(this.lotExpStep)) {
            focusField = document.getElementById('lot');
            focusField?.focus();

            return;
        }

        if (this.isSelected(this.tagStep)) {
            focusField = document.getElementById('print-quantity');
            focusField?.focus();

            return;
        }
    }

    isSelected(step: MatStep): boolean {
        return !!this.stepper && this.stepper.selected === step;
    }

    fromTo(transition: StepperSelectionEvent, from: MatStep, to: MatStep): boolean {
        return transition.previouslySelectedStep === from && transition.selectedStep === to;
    }

    resetTag() {
        Object.assign(this.tag, {
            barcode: null,
            expire: '',
            expire_confirmation: '',
            lot: '',
            lot_confirmation: '',
            timeoutError: false,
            addToBin: undefined,
        });
    }

    setupFormattedTagRequirement() {
        if (!this.type.print) {
            this.translationService.translateInto('tagging.pre_printed', this.pickedItem, 'tag_requirement_formatted');
            this.usePrePrintedTags = true;
            this.tag.print = false;
        } else {
            if (this.tag.item.tag_type) {
                this.pickedItem.tag_requirement_formatted = this.tag.item.tag_type.display_name;
            } else {
                this.translationService.translateInto('tagging.normal', this.pickedItem, 'tag_requirement_formatted');
            }
            this.usePrePrintedTags = false;
            this.tag.print = true;
        }
    }

    childPick(data) {
        this.pick(data.item, data.scan, data.searchTerm);
    }

    pick(item: any, scan: boolean, searchTerm) {
        const gsBarcode: any = this.tag.gsBarcode; // Save and use the scanned barcode object.
        this.resetTag();
        this.itemAliasSelect.name = item.formulary_type_string;
        this.pickedItem = item;
        this.tag.item = item;
        this.tag.ndc = this.pickedItem.ndc;
        this.tag.print = this.type.print;
        this.searchTerm = searchTerm;
        this.setupFormattedTagRequirement();
        if (!this.pickedItem.in_formulary) {
            this.pickedItem.tag_requirement = 'normal';
        }

        if (!!gsBarcode) {
            // Set the 'pickedItem' and 'tag' properties from the scanned
            // barcode object, in order to populate the form fields and
            // label info.
            this.pickedItem.lot = gsBarcode.lot;
            this.pickedItem.expire = gsBarcode.expiration;
            this.tag.lot = gsBarcode.lot;
            this.tag.expire = gsBarcode.expiration;
            this.tag.expire_formatted = this.tag.gsBarcode.expire_formatted || this.tag.expire_formatted;
            this.tag.processingGSBarcode = false;
        } else {
            this.tag.resetExpLot = true;
        }

        this.itemPicked = true;
        this.itemForm.control.markAsDirty();
        this.itemFormComplete.control.markAsDirty();
    }

    transitionToLotExp() {
        this.tag.hasSharedRecall = false;
        this.tag.hasRecall = false;

        if (
            !this.pickedItem.bins &&
            this.hospitalInfoService.allowShelvedInventory() &&
            this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Scan Inventory Resolver to bin scan')
        ) {
            this.loadingSpinnerService.spinnerifyPromise(
                this.formularyItemResource.bins(this.pickedItem.ndc, this.tag.onBehalfOfHospitalId).then(({ bins }) => {
                    this.pickedItem.bins = bins;
                    this.haveCheckedBins = true;
                    this.getSharedRecalls();
                })
            );
        } else {
            this.getSharedRecalls();
        }
    }

    prepareToPrint() {
        this.checkSharedRecalls();
    }

    checkSharedRecalls() {
        if (!!this.tag.lot) {
            const normalized = this.tag.lot.replace(/[^A-Z0-9]+/gi, '').toUpperCase();
            this.tag.hasSharedRecall = this.sharedRecallsArray.find((recall) => recall.lot_number === normalized);
        } else {
            this.tag.hasSharedRecall = false;
        }
    }

    getSharedRecalls() {
        if (!this.tag.onBehalfOfHospitalId) {
            this.recallResource.sharedRecalls(this.tag.item.formulary_item_id).then(({ shared_recalls }) => {
                this.sharedRecallsArray = shared_recalls;
            });
        }
    }

    useBins() {
        return (
            this.hospitalInfoService.allowShelvedInventory() &&
            this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Scan Inventory Resolver to bin scan') &&
            !!this.pickedItem &&
            !!this.pickedItem.bins &&
            !!this.pickedItem.bins.length
        );
    }

    isPreTagged(tag) {
        this.tag.isPreTagged = false;
        if (
            !!this.tag.item.pretagged_lot_numbers &&
            this.tag.item.pretagged_lot_numbers.some((lot_number) =>
                this.normalizedLotCompare(lot_number, tag.item.lot)
            )
        ) {
            this.tag.isPreTagged = true;
            return true;
        }
        return false;
    }

    normalizedLotCompare(pretagged_lot_number: string, entered_lot_number: string): boolean {
        if (!!pretagged_lot_number && !!entered_lot_number) {
            pretagged_lot_number = pretagged_lot_number.replace(/[\W_]+/g, '').toLowerCase();
            entered_lot_number = entered_lot_number.replace(/[\W_]+/g, '').toLowerCase();
            return pretagged_lot_number === entered_lot_number;
        }
        return false;
    }
}
