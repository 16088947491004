import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocationResource } from '@resources/location-resource.service';

@Injectable({ providedIn: 'root' })
export class LocationsIncludeRtlsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private locationResource: LocationResource
    ) {}

    resolve(): Promise<any> {
        let locationPromise;
        if (this.actionService.isAllowAction('hospital_settings', 'view_location', 'Load location list data')) {
            locationPromise = this.locationResource
                .locationWithRTLSList({ includeAeroscout: true, includeMayoRtls: true, includeMidmarkRtls: true })
                .then((data) => {
                    return data.locations;
                });
        } else {
            locationPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(locationPromise);
    }
}
