import { Component } from '@angular/core';
import * as _ from 'lodash';

import { ActionService } from '@services/utils/action.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ProductModuleService } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';
import { Router } from '@angular/router';

export interface AdminType {
    name: string;
    titleKey: string;
    title?: string;
    acl: {
        segment: string;
        param: string;
    };
}

@Component({
    selector: 'admin',
    templateUrl: './admin.html',
    styleUrls: ['./admin.scss'],
})
export class Admin {
    types: AdminType[] = [];

    constructor(
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected localStorageService: LocalStorageService,
        private productModuleService: ProductModuleService,
        protected translationService: TranslationService,
        private router: Router
    ) {}

    ngOnInit() {
        this.productModuleService.setModule();

        const canViewHospitalSettings = this.actionService.isAllowAction(
            'hospital_settings',
            'view_hospital_settings',
            'Check Admin hospital settings'
        );

        if (canViewHospitalSettings) {
            this.addType('hospital', 'hospital', 'hospital_settings', 'hospital_settings', 'update');
        }

        this.addType('users', 'users', 'user_settings', 'users');

        if (canViewHospitalSettings) {
            this.addType('hardware', 'hardware', 'hospital_settings', 'hardware');
            if (this.hospitalInfoService.hospitalSetting('kit_to_location_enabled')) {
                this.addType('locations', 'locations', 'hospital_settings', 'location');
            }
            if (this.hospitalInfoService.hospitalSetting('kit_to_cart_enabled')) {
                this.addType('carts', 'carts', 'hospital_settings', 'cart');
            }
        }
        this.addType('locks', 'locks', 'hospital_settings', 'locks');

        this.addType('item-expirations', 'update_expirations', 'kits_inventory', 'item_expirations', 'update');
        this.addType('batch-lots', 'update_lots', 'kits_inventory', 'batch_lots', 'update');
        this.addType('recalls', 'item_recalls', 'administration', 'recalls');
        this.addType('pretagged-quarantine', 'pretagged_quarantine', 'kits_tagging', 'tag', 'quarantine');

        const titleKeys = this.types.map((type) => type.titleKey);
        this.translationService.get(titleKeys).then((translations) => {
            _.each(translations, (title, titleKey) => {
                _.find(this.types, { titleKey }).title = title;
            });
        });
    }

    addType(name: string, titleKey: string, aclSegment: string, aclParam: string, oAction?: string): void {
        const action = oAction ? oAction : 'view';
        if (
            this.actionService.isAllowAction(
                aclSegment,
                `${action}_${aclParam}`,
                `Enable admin section ${action} ${aclParam}`
            )
        ) {
            this.types.push({
                name,
                titleKey: `admin.${titleKey}.title`,
                acl: {
                    segment: aclSegment,
                    param: aclParam,
                },
            });
        }
    }

    onAdminPage(): boolean {
        return this.router.url.startsWith('/admin');
    }

    hideContentPanel(): boolean {
        return this.onAdminPage() && this.loadingSpinnerService.showSpinner;
    }

    isActive(state): boolean {
        return this.router.url.split('/')[1] === state;
    }
}
