import { Component, Input, Output, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActionService } from '@services/utils/action.service';
import { ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'reports-list',
    templateUrl: './reports-list.html',
    styleUrls: ['./report-list.scss'],
})
export class ReportsList {
    // bindings
    @Input() reports: {
        report_categories: ReportCategory[];
    };
    @Input() reportsCategory: ReportCategory;
    @Input() isSystem: boolean;
    @Output() closeModal;
    reportsExpiry: ReportCategory[];
    reportsAttributes: ReportCategory[];
    reportsActivity: ReportCategory[];
    reportsAnalytics: ReportCategory[];
    systemReportsAttributes: ReportCategory[];
    lowBatteryReportEnabled: boolean = false;

    constructor(
        protected actionService: ActionService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    ngOnInit() {
        // hospital reports
        this.reportsExpiry = this.findReports('item_expiration');
        this.reportsAttributes = this.findReports('item_attribute');
        this.reportsActivity = this.findReports('activity');
        this.reportsAnalytics = this.findReports('analytics');
        // system reports
        this.systemReportsAttributes = this.findReports('system_item_attribute');
        // other
        this.lowBatteryReportEnabled = this.hospitalInfoService.getHospitalSettings().midmark_rtls_enabled;
    }

    // helper functions
    findReports(category: String): ReportCategory[] {
        let reportsToReturn: any[] = [];
        const reportsForCategory = this.reports.report_categories.find((report) => report.name === category);

        if (!reportsForCategory) {
            return [];
        }

        const reportPathHash = {
            item_expiration_and_attributes_custom_item: 'custom',
            aggregate_billing: 'aggregate-billing',
            item_expiration_and_attributes_ndc_lot: 'customLot',
            kits_needing_rework: 'rework',
            item_recalls: 'item-recalls',
            inferred_items_consumed_removed: 'items-consumed-or-removed',
            kitted_items_by_ndc: 'byNDC',
            user_activity: 'recent',
            hospital_activity: 'hospital',
            items_tagged: 'itemsTagged',
            print_batch: 'print-batch',
            print_batch_detail: 'print-batch-detail',
            kit_activity: 'kit_activity',
            activity_analytics: 'activity-analytics',
            kitted_inventory_with_cost: 'kitted-inventory-with-cost',
            expiration_analytics: 'expiration-analytics',
            virginia_board_of_pharmacy: 'virginia-board-of-pharmacy',
            advanced_segment_optimization: 'advanced-segment-optimization',
            system_item_expiration_and_attributes_custom_item:
                'system-reports/system_item_expiration_and_attributes_custom_item',
            system_item_recalls: 'system-reports/system_item_recalls',
            system_inferred_items_consumed_removed: 'system-reports/system_inferred_items_consumed_removed',
            item_batch_by_hospital: 'item-batch-by-hospital',
        };

        reportsToReturn = reportsToReturn.concat(
            reportsForCategory.reports.map((report) => {
                let route;
                if (reportsForCategory.name === 'system_item_attribute') {
                    route = `/${reportPathHash[report.name]}`;
                } else {
                    route = `/report/${reportPathHash[report.name]}`;
                }
                return {
                    name: report.name,
                    display_name: report.display_name,
                    route: route,
                    isNew: report.is_new,
                };
            })
        );

        return _.chain(reportsToReturn)
            .reject((report) => report.name === 'print_batch_detail')
            .sortBy('display_name')
            .value();
    }
}
