import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LoginStateService } from '@services/login/login-state.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserInfoService } from '@services/login/user-info.service';

@Injectable({ providedIn: 'root' })
export class LoginResolver {
    hideSpinner: boolean;
    constructor(
        private router: Router,
        private barcodeScanService: BarcodeScanService,
        private groupLoginService: GroupLoginService,
        private loadingSpinnerService: LoadingSpinnerService,
        private localStorageService: LocalStorageService,
        private loginStateService: LoginStateService,
        private translationService: TranslationService,
        private userInfoService: UserInfoService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        this.hideSpinner = activatedRoute.params.hideSpinner === 'true';

        let loginPromise;

        if (!this.groupLoginService.isLoggedIn() && !this.groupLoginService.requirePassword()) {
            loginPromise = Promise.reject();
            setTimeout(() => {
                this.loginStateService.notifyLogout(true);
            });
        } else if (this.groupLoginService.requirePassword()) {
            // this code is probably not called anymore, but in case it is
            // updating for the new password reset which requires a token

            const password_reset_token = this.localStorageService.get('password_reset_token');
            this.localStorageService.set('loggedIn', false);
            this.router.navigate(['/password-reset'], { queryParams: password_reset_token });
        } else {
            setTimeout(() => {
                if (!this.groupLoginService.requirePassword() && !this.loginStateService.loggedIn) {
                    this.loginStateService.notifyLogin();
                }
            });
            this.barcodeScanService.listenForBarcode();
            this.userInfoService.setUserInfo();
            this.translationService.setToHospitalLocale();
            loginPromise = this.groupLoginService.checkLocalStorageAttributes();
        }

        if (this.hideSpinner) {
            // don't show spinner if we are prefiltering to prevent double spinner
            return loginPromise;
        } else {
            return this.loadingSpinnerService.spinnerifyPromise(loginPromise);
        }
    }
}
