import { Injectable } from '@angular/core';

@Injectable()
export class FormularyItemDataService {
    private newFormularyItem: any;
    private creatingFormularyItem: boolean;

    getNewFormularyItem() {
        return this.newFormularyItem;
    }

    setNewFormularyItem(obj) {
        this.newFormularyItem = obj;
    }

    getCreatingNewFormulary() {
        return this.creatingFormularyItem;
    }

    setCreatingNewFormulary(creating: boolean) {
        this.creatingFormularyItem = creating;
    }
}
