import { Injectable } from '@angular/core';

import { ConfigurationProvider } from '@services/config/configuration';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    //attributes
    version: string;
    isMobile: boolean;
    needsRefresh: boolean;
    environment: string;
    year: string;
    subheaderTitle: string;
    user: any;
    suppressError: boolean = false;

    constructor(private configuration: ConfigurationProvider) {
        this.year = configuration.getConfiguration().year;
        this.environment = configuration.getConfiguration().environment;
        this.version = configuration.getConfiguration().version;
    }
}
