import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class BinResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    printBinTag(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}bins/tags`, data).toPromise();
    }

    associateBinTag(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}bins/tag_associations`, data).toPromise();
    }

    binsIndex(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bins`).toPromise();
    }

    getBin(id: number): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bins/${id}`).toPromise();
    }

    updateBin(id: number, data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}bins/${id}`, data).toPromise();
    }

    removeFormularyItem(binId: number, segTempFormId: number): Promise<any> {
        return this.http
            .delete<any>(`${this.API_ENDPOINT}bins/${binId}/segment_template_formulary_items/${segTempFormId}`)
            .toPromise();
    }

    removeClinicalEquivalence(binId: number, segTempCeqId: number): Promise<any> {
        return this.http
            .delete<any>(`${this.API_ENDPOINT}bins/${binId}/segment_template_clinical_equivalences/${segTempCeqId}`)
            .toPromise();
    }

    showBinScan(binId: number | string, scanId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bins/${binId}/scans/${scanId}`).toPromise();
    }

    showBinScanDetail(binId: number | string, scanId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bins/${binId}/scans/${scanId}/detail`).toPromise();
    }

    createBinSegment(binId: number, data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}bins/${binId}/segments`, data).toPromise();
    }

    deleteBin(binId: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}bins/${binId}`).toPromise();
    }

    replaceBin(binId: number, printerId: number): Promise<any> {
        return this.http
            .post<any>(`${this.API_ENDPOINT}bins/${binId}/tag`, { printer_hardware_id: printerId })
            .toPromise();
    }

    replaceBinBasic(binId: number, epc: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}bins/${binId}/tag_association`, { epc }).toPromise();
    }

    downloadBinReport(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}bins/report.xlsx`).toPromise();
    }
}
