/***
 * Barrel file to unburden app.module.ts and routes.ts imports
 * ***/

// NGX Page Components
import { Admin } from './admin/admin';
import { AddCredits } from './charge-model/add-credits/add-credits';
import { AdminBatchLots } from './admin/admin-batch-lots/admin-batch-lots';
import { AdminCartsCreate } from './admin/admin-carts-create/admin-carts-create';
import { AdminCartsList } from './admin/admin-carts-list/admin-carts-list';
import { AdminHardwareList } from './admin/admin-hardware-list/admin-hardware-list';
import { AdminHardwareUpdate } from './admin/admin-hardware-update/admin-hardware-update';
import { AdminHome } from './admin/admin-home/admin-home';
import { AdminHospital } from './admin/admin-hospital/admin-hospital';
import { AdminItemExpirations } from './admin/admin-item-expirations/admin-item-expirations';
import { AdminLocationsCreate } from './admin/admin-locations-create/admin-locations-create';
import { AdminLocationsList } from './admin/admin-locations-list/admin-locations-list';
import { AdminLocks } from './admin/admin-locks/admin-locks';
import { AdminPretaggedQuarantine } from './admin/admin-pretagged-quarantine/admin-pretagged-quarantine';
import { AdminRecallsCreate } from './admin/admin-recalls-create/admin-recalls-create';
import { AdminRecallsList } from './admin/admin-recalls-list/admin-recalls-list';
import { AdminUsersCreate } from './admin/admin-users-create/admin-users-create';
import { AdminUsersList } from './admin/admin-users-list/admin-users-list';
import { AdminUsersProfile } from './admin/admin-users-profile/admin-users-profile';
import { BinEdit } from './inventory/bins/bin-edit/bin-edit';
import { BinsIndex } from './inventory/bins/bins-index/bins-index';
import { BinScan } from './inventory/bins/bin-scan/bin-scan';
import { CartsIndex } from './inventory/carts/carts-index/carts-index';
import { CartManage } from './inventory/carts/cart-manage/cart-manage';
import { ChangeTagType } from './tags/change-tag-type/change-tag-type';
import { CreateEditFormularyItem } from './inventory/formulary/create-edit-formulary-item/create-edit-formulary-item';
import { CreditHistory } from './charge-model/credit-history/credit-history';
import { ContainersDetail } from './inventory/containers/containers-detail/containers-detail';
import { ContainersIndex } from './inventory/containers/containers-index/containers-index';
import { ContainersLandingPage } from './inventory/containers/containers-landing-page/containers-landing-page';
import { FormularyArchiveItem } from './inventory/formulary/formulary-archive-item/formulary-archive-item';
import { FormularyIndex } from './inventory/formulary/formulary-index/formulary-index';
import { Home } from './home/home';
import { KitBlock } from './inventory/kits/kit-detail/kit-block/kit-block';
import { KitDetail } from './inventory/kits/kit-detail/kit-detail';
import { KitMasterEdit } from './inventory/kit-masters/kit-master-edit/kit-master-edit';
import { KitMastersList } from './inventory/kit-masters/kit-masters-list/kit-masters-list';
import { KitScans } from './inventory/kits/kit-scan-history/kit-scan-history';
import { KitsIndex } from './inventory/kits/kits-index/kits-index';
import { ManageItems } from './inventory/items/manage-items/manage-items';
import { ManageItemsPrintError } from './inventory/items/manage-items/manage-items-print-error/manage-items-print-error';
import { ManageItemsPrintUnconfirmed } from './inventory/items/manage-items/manage-items-print-unconfirmed/manage-items-print-unconfirmed';
import { ManageSubscriptions } from './report/manage-subscriptions/manage-subscriptions';
import { PretaggedMedications } from './charge-model/pre-tagged-medications/pre-tagged-medications';
import { PreTaggedUsageHistory } from './tags/pre-tagged-medications/pre-tagged-usage-history/pre-tagged-usage-history';
import { PrintError } from './tags/print-error/print-error';
import { ReportActivityAnalytics } from './report/report-activity-analytics/report-activity-analytics';
import { ReportAdvancedSegmentOptimization } from './report/report-advanced-segment-optimization/report-advanced-segment-optimization';
import { ReportBilling } from './report/report-billing/report-billing';
import { ReportExpirationAnalytics } from './report/report-expiration-analytics/report-expiration-analytics';
import { ReportHospitalActivity } from './report/report-hospital-activity/report-hospital-activity';
import { ReportInferredItemsConsumedRemoved } from './report/report-inferred-items-consumed-removed/report-inferred-items-consumed-removed';
import { ReportItemBatchByHospital } from './report/report-item-batch-by-hospital/report-item-batch-by-hospital';
import { ReportItemBatchSummary } from './report/report-item-batch-summary/report-item-batch-summary';
import { ReportItemExpirations } from './report/report-item-expirations/report-item-expirations';
import { ReportItemsByNDC } from './report/report-items-by-ndc/report-items-by-ndc';
import { ReportItemsTagged } from './report/report-items-tagged/report-items-tagged';
import { ReportKitActivity } from './report/report-kit-activity/report-kit-activity';
import { ReportKitsNeedingRework } from './report/report-kits-needing-rework/report-kits-needing-rework';
import { ReportKittedItemInventory } from './report/report-kitted-item-inventory/report-kitted-item-inventory';
import { ReportKittedInventoryWithCost } from './report/report-kitted-inventory-with-cost/report-kitted-inventory-with-cost';
import { ReportRecalls } from './report/report-recalls/report-recalls';
import { ReportsIndex } from './report/reports-index/reports-index';
import { ReportsList } from './report/reports-list/reports-list';
import { ReportTaggedItemBatchSummary } from './report/report-tagged-item-batch-summary/report-tagged-item-batch-summary';
import { ReportUserActivity } from './report/report-user-activity/report-user-activity';
import { ReportVirginiaBoardOfPharmacy } from './report/report-virginia-board-of-pharmacy/report-virginia-board-of-pharmacy';
import { ScanBatchVerify } from './scan/scan-batch-verify/scan-batch-verify';
import { ScanDetails } from './scan/scan-details/scan-details';
import { SegmentEdit } from './inventory/kit-masters/segments/segment-edit/segment-edit';
import { ShortageManagementPage } from './inventory/shortages/shortage-management-page/shortage-management-page';
import { ShortageReportPage } from './inventory/shortages/shortage-report-page/shortage-report-page';
import { SingleKitMaster } from './inventory/kit-masters/single-kit-master/single-kit-master';
import { Tagging } from './tags/tagging';
import { UserSettings } from './user-settings/user-settings';

// NGX UI Components
import { ActivitiesFilter } from './report/report-filter/activities-filter/activities-filter';
import { ActivityFilter } from './report/report-filter/activity-filter/activity-filter';
import { ArchiveKitMasterDialog } from './inventory/kit-masters/single-kit-master/archive-kit-master/archive-kit-master-dialog';
import { AssignableForm } from './admin/assignable-form/assignable-form';
import { AutoComplete } from './common/auto-complete/auto-complete';
import { BatchSelect } from './admin/admin-batch-lots/batch-select/batch-select';
import { BarcodeImgComponent } from './common/barcode-img/barcode-img';
import { BarcodeScanField } from './common/barcode-scan-field/barcode-scan-field';
import { Benchmarking } from './report/benchmarking/benchmarking';
import { BillingSheet } from './inventory/kits/billing-sheet/billing-sheet';
import { BinCustomItems } from './inventory/bins/bin-common/bin-custom-items';
import { BinDetails } from './inventory/bins/bin-details/bin-details';
import { BinFormularyItems } from './inventory/bins/bin-common/bin-formulary-items';
import { BinKitFilter } from './report/report-filter/bin-kit-filter/bin-kit-filter';
import { BinKitMastersFilter } from './report/report-filter/bin-kit-masters-filter/bin-kit-masters-filter';
import { BinScanDetails } from './inventory/bins/bin-scan/bin-scan-details/bin-scan-details';
import { BinScanItemsTable } from './inventory/bins/bin-scan/bin-scan-summary/bin-scan-items-table/bin-scan-items-table';
import { BinScanSummary } from './inventory/bins/bin-scan/bin-scan-summary/bin-scan-summary';
import { BinSummary } from './inventory/bins/bin-summary/bin-summary';
import { BinWrongItems } from './inventory/bins/bin-common/bin-wrong-items';
import { CartExpirations } from './inventory/carts/cart-expirations/cart-expirations';
import { CartExpiringSoon } from './common/cart-expiring-soon-table/cart-expiring-soon';
import { CartHistory } from './inventory/carts/cart-history/cart-history';
import { ContainerSummaryTab } from './inventory/containers/containers-detail/components/container-summary-tab/container-summary-tab';
import { ContainerDetailsTab } from './inventory/containers/containers-detail/components/container-details-tab/container-details-tab';
import { ContainerOtherItemsTable } from './inventory/containers/containers-detail/components/container-other-items-table/container-other-items-table';
import { ExpiringChip } from './inventory/containers/containers-detail/components/expiring-chip/expiring-chip';
import { Allowlist } from './common/allowlist/allowlist';
import { CartKitMastersFilter } from './report/report-filter/cart-kit-masters-filter/cart-kit-masters-filter';
import { CartLocationsFilter } from './report/report-filter/cart-locations-filter/cart-locations-filter';
import { CategoryInfo } from './dashboard/pharmacy-management/category-info/category-info';
import { CeqSearchTypeahead } from './common/ceq-search-typeahead/ceq-search-typeahead';
import { CheckboxComponent } from './common/checkbox-group/checkbox';
import { CheckboxGroupComponent } from './common/checkbox-group/checkbox-group';
import { CloneKitMasterDialog } from './inventory/kit-masters/single-kit-master/clone-kit-master/clone-kit-master-dialog';
import { Dashboard } from './dashboard/dashboard';
import { DateFilter } from './report/report-filter/date-filter/date-filter';
import { DateFilterV2 } from './report/report-filters/date-filter/date-filter';
import { DateMonthFilter } from './report/report-filter/date-month-filter/date-month-filter';
import { DateMonthFilterV2 } from './report/report-filters/date-month-filter/date-month-filter';
import { DeleteKitsDialog } from './inventory/kits/kits-index/delete-kits/delete-kits-dialog';
import { DownloadButton } from './common/download-button/download-button';
import { EpcLink } from './common/epc-link/epc-link.component';
import { ErrorMessage } from './common/error-message/error-message';
import { FormularySearch } from './common/formulary-search/formulary-search';
import { FkAlertBannerComponent } from './common/fk-alert-banner/fk-alert-banner.component';
import { FreshDesk } from './common/fresh-desk/fresh-desk.component';
import { ExpirationFilter } from './report/report-filter/expiration-filter/expiration-filter';
import { GenericNdcItemTypeFilter } from './report/report-filter/generic-ndc-item-type-filter/generic-ndc-item-type-filter';
import { GenericNdcLotSearchFilter } from './report/report-filter/generic-ndc-lot-search-filter/generic-ndc-lot-search-filter';
import { Header } from './common/header/header';
import { HospitalGroupFilter } from './report/report-filter/hospital-group-filter/hospital-group-filter';
import { HospitalOwnershipFilter } from './report/report-filter/hospital-ownership-filter/hospital-ownership-filter';
import { IdnGroupDropdown } from './admin/admin-users-list/idn-group-dropdown/idn-group-dropdown';
import { ItemAlias } from './common/item-alias/item-alias';
import { ItemAttributes } from './tags/tagging-item/item-attributes/item-attributes';
import { ItemLotExp } from './tags/tagging-item/item-lot-exp/item-lot-exp';
import { ItemSelect } from './tags/tagging-item/item-select/item-select';
import { ItemSelectAlias } from './tags/tagging-item/item-select-alias/item-select-alias';
import { ItemsList } from './scan/items-list/items-list';
import { ItemTypeGroupFilter } from './report/report-filter/item-type-group-filter/item-type-group-filter';
import { KCBreadcrumbsComponent } from './common/kc-breadcrumbs/kc-breadcrumbs.component';
import { KCDateInput } from './common/kc-date-input/kc-date-input';
import { KCDatePicker } from './common/kc-datepicker/kc-datepicker';
import { KCDateValidatorDirective } from './common/kc-datepicker/kc-date-validator';
import { KCDownloadButton } from './common/kc-download-button/kc-download-button';
import { KCHospitalGroupSelect } from './common/kc-hospital-group-select/kc-hospital-group-select';
import { KCLogo } from './common/kc-logo/kc-logo';
import { KCMatSnackBar } from './common/kc-mat-snack-bar/kc-mat-snack-bar';
import { KCMatStepperNext } from './common/kc-mat-stepper-next/kc-mat-stepper-next';
import { KCMatStepperPrevious } from './common/kc-mat-stepper-previous/kc-mat-stepper-previous';
import { KCMultiselectMenu } from './common/kc-multiselect-menu/kc-multiselect-menu';
import { KCSpinner } from './common/kc-spinner/kc-spinner';
import { KitAllItemsBySegment } from './inventory/kits/kit-detail/kit-all-items-by-segment/kit-all-items-by-segment';
import { KitDecommissioned } from './inventory/kits/kit-detail/kit-decommissioned/kit-decommissioned';
import { KitDrugGroupFilter } from './report/report-filter/kit-drug-group-filter/kit-drug-group-filter';
import { KitItemsTable } from './inventory/kits/kit-detail/kit-items-table/kit-items-table';
import { KitLabelFilter } from './report/report-filter/kit-label-filter/kit-label-filter';
import { KitMasterFilter } from './report/report-filter/kit-master-filter/kit-master-filter';
import { KitMastersFilter } from './report/report-filter/kit-masters-filter/kit-masters-filter';
import { KitMissingItems } from './inventory/kits/kit-detail/kit-missing-items/kit-missing-items';
import { KitMultidose } from './inventory/kits/kit-detail/kit-multidose/kit-multidose';
import { KitScanDetails } from './inventory/kits/kit-detail/kit-scan-details/kit-scan-details';
import { KitScanSummary } from './inventory/kits/kit-detail/kit-scan-summary/kit-scan-summary';
import { KitShortage } from './inventory/kits/kit-detail/kit-shortage/kit-shortage';
import { KitsNeedingReworkFilter } from './report/report-filter/kits-needing-rework-filter/kits-needing-rework-filter';
import { LockoutDateBannerComponent } from './common/lockout-date-banner/lockout-date-banner.component';
import { MarkOpenedDialog } from './inventory/kits/kit-detail/kit-multidose/mark-opened-dialog/mark-opened-dialog';
import { MultiKitPickList } from './inventory/kits/pick-lists/multi-kit-pick-list/multi-kit-pick-list';
import { NavMenu } from './common/nav-menu/nav-menu';
import { NdcLotSearchFilter } from './report/report-filter/ndc-lot-search-filter/ndc-lot-search-filter';
import { NumberStepperComponent } from './common/kc-number-stepper/kc-number-stepper.component';
import { PickList } from './inventory/kits/pick-lists/pick-list/pick-list';
import { PriceTypeFilter } from './report/report-filter/price-type-filter/price-type-filter';
import { PrintLogo } from './inventory/kits/pick-lists/print-logo/print-logo';
import { ReasonsFilter } from './report/report-filter/reasons-filter/reasons-filter';
import { RecallLotNumbers } from './admin/admin-recalls-list/recall-lot-numbers/recall-lot-numbers';
import { RecallsFilter } from './report/report-filter/recalls-filter/recalls-filter';
import { RemovedReasonsFilter } from './report/report-filter/removed-reasons-filter/removed-reasons-filter';
import { ReplaceBinTagDialog } from './inventory/bins/replace-bin-tag/replace-bin-tag-dialog';
import { RegisterBadge } from './admin/admin-users-create/register-badge/register-badge';
import { ReportNoData } from './report/report-no-data/report-no-data';
import { ReportSubscriptionFilters } from './dialogs/report-subscription/report-subscription-filters/report-subscription-filters';
import { RoleRow } from './admin/admin-users-create/role-section/role-row/role-row';
import { RoleSection } from './admin/admin-users-create/role-section/role-section';
import { ScanButton } from './common/scan-button/scan-button';
import { SegmentItems } from './inventory/bins/segment-items/segment-items';
import { SegmentTemplateEdit } from './inventory/kit-masters/segments/segment-template-edit/segment-template-edit';
import { Sorter } from './common/sorter/sorter';
import { SystemRefresh } from './common/system-refresh/system-refresh';
import { TableComponentFixedHeader } from './common/table-component-header';
import { TagAddTags } from './tags/tagging-item/tag-add-tags/tag-add-tags';
import { TagAddToBin } from './tags/tagging-item/tag-add-to-bin/tag-add-to-bin';
import { TagAssociation } from './tags/tagging-item/tag-association/tag-association';
import { TaggingBin } from './tags/tagging-bin/tagging-bin';
import { TaggingItem } from './tags/tagging-item/tagging-item';
import { TaggingKit } from './tags/tagging-kit/tagging-kit';
import { TagHistory } from './inventory/items/manage-items/tag-history/tag-history';
import { TagPrint } from './tags/tagging-item/tag-print/tag-print';
import { TagQuantityPrinter } from './tags/tagging-item/tag-quantity-printer/tag-quantity-printer';
import { TimeframeFilter } from './report/report-filter/timeframe-filter/timeframe-filter';
import { UserFilter } from './report/report-filter/user-filter/user-filter';
import { WholesalerPriceTypeFilter } from './report/report-filter/wholesaler-price-type-filter/wholesaler-price-type-filter';

// Login pages
import { NgxForgotPasswordComponent } from './login/ngx-forgot-password/ngx-forgot-password.component';
import { NgxLoginComponent } from './login/ngx-login/ngx-login.component';
import { NgxPasswordResetComponent } from './login/ngx-password-reset/ngx-password-reset.component';
import { NgxPasswordResetExpiredComponent } from './login/ngx-password-reset-expired/ngx-password-reset-expired.component';
import { NgxSwitchHospitalComponent } from './login/ngx-switch-hospital/ngx-switch-hospital.component';

// Mobile Cart Pages
import { MobileCartComponents } from './mobile-cart/index';
import { MobileCartHomeComponent } from './mobile-cart/home/home';
import { MobileCartKitBlockComponent } from './mobile-cart/kit-block/kit-block';
import { MobileCartLandingPageComponent } from './mobile-cart/home/landing-page/landing-page';
import { MobileCartScanSummaryComponent } from './mobile-cart/scan-summary/scan-summary';

//common dialogs
import { ActivateFormularyItemDialog } from './dialogs/activate-formulary-item/activate-formulary-item-dialog';
import { AddCreditsDialogComponent } from './dialogs/add-credits-dialog/add-credits-dialog.component';
import { ManageContainerDialog } from './dialogs/manage-container-dialog/manage-container-dialog';
import { AddFormularyItemBinDialog } from './dialogs/add-formulary-item/add-formulary-item-bin/add-formulary-item-bin-dialog';
import { AddFormularyItemToSegmentDialog } from './dialogs/add-formulary-item/add-formulary-item-to-segment/add-formulary-item-to-segment-dialog';
import { AeroscoutDialog } from './admin/admin-carts-list/aeroscout-dialog/aeroscout-dialog';
import { AeroscoutLoginDialog } from './inventory/carts/aeroscout-asset-select/aeroscout-login/aeroscout-login-dialog';
import { AssignCartToLocationDialog } from './dialogs/assign-cart-to-location/assign-cart-to-location-dialog';
import { AssignKitsToLocationDialog } from './dialogs/assign-kits-to-location/assign-kits-to-location-dialog';
import { AssignLockDialog } from './dialogs/assign-lock/assign-lock-dialog';
import { BadgeAuthorizationDialog } from './dialogs/badge-authorization/badge-authorization-dialog';
import { ChooseBinDialog } from './dialogs/choose-bin/choose-bin-dialog';
import { ChooseBinVerifyDialog } from './dialogs/choose-bin-verify/choose-bin-verify-dialog';
import { ConfirmDialog } from './dialogs/confirm/confirm-dialog';
import { ContainerScanInfoDialog } from './inventory/containers/containers-detail/components/container-scan-info/container-scan-info-dialog';
import { ContainerAddToSegment } from './inventory/containers/containers-detail/components/container-add-to-segment/container-add-to-segment';
import { DecommissionPackagesDialog } from './dialogs/decommission-packages/decommission-packages-dialog';
import { DefaultScannerDialog } from './dialogs/scanner/default-scanner-dialog/default-scanner-dialog';
import { DispatchKitDialog } from './dialogs/dispatch-kit/dispatch-kit-dialog';
import { DispatchKitMobileDialog } from './dialogs/dispatch-kit-mobile/dispatch-kit-mobile';
import { EditContainerLocationDialog } from './dialogs/edit-container-location-dialog/edit-container-location-dialog';
import { EditLotExpirationDialog } from './dialogs/edit-lot-expiration/edit-lot-expiration-dialog';
import { ExpirationDialog } from './dialogs/expiration/expiration-dialog';
import { FillOptionsDialog } from './dialogs/fill-options/fill-options-dialog';
import { IgnoreRecallDialog } from './admin/admin-recalls-list/ignore-recall-dialog/ignore-recall-dialog';
import { MessageDialog } from './dialogs/message/message-dialog';
import { MissingScannerDialog } from './dialogs/scanner/missing-scanner-dialog/missing-scanner-dialog';
import { PretaggedIssueDialog } from './dialogs/pretagged-issue/pretagged-issue-dialog';
import { RemoteBatchVerificationDialog } from './dialogs/remote-batch-verification/remote-batch-verification-dialog';
import { RemoveSegmentDialog } from './dialogs/remove-segment-dialog/remove-segment-dialog';
import { ReplaceKitTagDialog } from './dialogs/replace-kit-tag/replace-kit-tag-dialog';
import { ReportSubscriptionDialog } from './dialogs/report-subscription/report-subscription-dialog';
import { ResetPrinterDialog } from './dialogs/reset-printer/reset-printer-dialog';
import { ScannerUpdateDialog } from './dialogs/scanner/scanner-update-dialog/scanner-update-dialog';
import { ScanningDialog } from './dialogs/scanner/scanning-dialog/scanning-dialog';
import { SegmentTemplateEditDialog } from './dialogs/segment-template-edit/segment-template-edit-dialog';
import { SessionTimeoutWarningDialog } from './dialogs/session-timeout-warning/session-timeout-warning-dialog';
import { UnquarantinePretaggedDialog } from './dialogs/unquarantine-pretagged/unquarantine-pretagged-dialog';
import { UploadCsvDialog } from './dialogs/upload-csv/upload-csv-dialog';
import { VerifyItemDialog } from './dialogs/verify-item/verify-item-dialog';

import { AeroscoutAssetSelect } from './inventory/carts/aeroscout-asset-select/aeroscout-asset-select';
import { AppComponent } from './app-component/app-component';
import { KCNameplate } from './common/kc-nameplate/kc-nameplate';
import { KitsTable } from './inventory/kits/kits-table/kits-table';

// starting to move the report filters over to a new UI style
// Need to keep the old ones in place for now,
// but when all the filters have been updated,
// we can remove the old ReportFilter files.
// https://kitcheck.atlassian.net/browse/KCC-6324
import { ReportFilter } from './report/report-filter/report-filter/report-filter';
import { ReportFilters } from './report/report-filters/report-filters/report-filters';

import { ReportHeader } from './report/report-header/report-header';

// ADMIN ONLY page for testing
import { ChargeSheetTest } from './inventory/kits/charge-sheet-test/charge-sheet-test';

//Dashboard
import { ExpiredAndRecalled } from './dashboard/expired-and-recalled/expired-and-recalled';
import { KitProcessing } from './dashboard/kit-processing/kit-processing';
import { MessageDisplay } from './dashboard/message-board/message-display/message-display';
import { MessageBoard } from './dashboard/message-board/message-board';
import { PharmacyManagement } from './dashboard/pharmacy-management/pharmacy-management';
import { ShelvedInventory } from './dashboard/shelved-inventory/shelved-inventory';
import { ConfirmChangesFormularyItemDialog } from './dialogs/confirm-changes-formulary-item/confirm-changes-formulary-item-dialog';
import { RemoveOuterPackageDialog } from './dialogs/remove-outer-package/remove-outer-package-dialog';
import { MidmarkLowBatteryReport } from './report/midmark-low-battery/midmark-low-battery-report';

// Barrel export of all Pages for routes.ts and app.module.ts
// Pages that are references directly in routes.ts
/* eslint-disable */
export const PAGES = {
    AddCredits: AddCredits,
    Admin: Admin,
    AdminBatchLots: AdminBatchLots,
    AdminCartsCreate: AdminCartsCreate,
    AdminCartsList: AdminCartsList,
    AdminHardwareList: AdminHardwareList,
    AdminHardwareUpdate: AdminHardwareUpdate,
    AdminHome: AdminHome,
    AdminHospital: AdminHospital,
    AdminItemExpirations: AdminItemExpirations,
    AdminLocationsCreate: AdminLocationsCreate,
    AdminLocationsList: AdminLocationsList,
    AdminLocks: AdminLocks,
    AdminPretaggedQuarantine: AdminPretaggedQuarantine,
    AdminRecallsCreate: AdminRecallsCreate,
    AdminRecallsList: AdminRecallsList,
    AdminUsersCreate: AdminUsersCreate,
    AdminUsersList: AdminUsersList,
    AdminUsersProfile: AdminUsersProfile,
    BinEdit: BinEdit,
    BinScan: BinScan,
    BinsIndex: BinsIndex,
    CartManage: CartManage,
    CartsIndex: CartsIndex,
    ChangeTagType: ChangeTagType,
    ChargeSheetTest: ChargeSheetTest,
    ContainersIndex: ContainersIndex,
    ContainersDetail: ContainersDetail,
    CreateEditFormularyItem: CreateEditFormularyItem,
    CreditHistory: CreditHistory,
    FormularyArchiveItem: FormularyArchiveItem,
    FormularyIndex: FormularyIndex,
    Home: Home,
    KitBlock: KitBlock,
    KitDetail: KitDetail,
    KitMasterEdit: KitMasterEdit,
    KitMastersList: KitMastersList,
    KitScans: KitScans,
    KitsIndex: KitsIndex,
    ManageItems: ManageItems,
    ManageItemsPrintError: ManageItemsPrintError,
    ManageItemsPrintUnconfirmed: ManageItemsPrintUnconfirmed,
    ManageSubscriptions: ManageSubscriptions,
    MobileCartHomeComponent: MobileCartHomeComponent,
    MobileCartKitBlockComponent: MobileCartKitBlockComponent,
    MobileCartLandingPageComponent: MobileCartLandingPageComponent,
    MobileCartScanSummaryComponent: MobileCartScanSummaryComponent,
    NgxForgotPasswordComponent: NgxForgotPasswordComponent,
    NgxLoginComponent: NgxLoginComponent,
    NgxPasswordResetComponent: NgxPasswordResetComponent,
    NgxPasswordResetExpiredComponent: NgxPasswordResetExpiredComponent,
    NgxSwitchHospitalComponent: NgxSwitchHospitalComponent,
    PreTaggedUsageHistory: PreTaggedUsageHistory,
    PretaggedMedications: PretaggedMedications,
    PrintError: PrintError,
    ReportActivityAnalytics: ReportActivityAnalytics,
    ReportAdvancedSegmentOptimization: ReportAdvancedSegmentOptimization,
    ReportBilling: ReportBilling,
    ReportExpirationAnalytics: ReportExpirationAnalytics,
    ReportHospitalActivity: ReportHospitalActivity,
    ReportInferredItemsConsumedRemoved: ReportInferredItemsConsumedRemoved,
    ReportItemBatchByHospital: ReportItemBatchByHospital,
    ReportItemBatchSummary: ReportItemBatchSummary,
    ReportItemExpirations: ReportItemExpirations,
    ReportItemsByNDC: ReportItemsByNDC,
    ReportItemsTagged: ReportItemsTagged,
    ReportKitActivity: ReportKitActivity,
    ReportKitsNeedingRework: ReportKitsNeedingRework,
    ReportKittedInventoryWithCost: ReportKittedInventoryWithCost,
    ReportKittedItemInventory: ReportKittedItemInventory,
    ReportRecalls: ReportRecalls,
    ReportTaggedItemBatchSummary: ReportTaggedItemBatchSummary,
    ReportUserActivity: ReportUserActivity,
    ReportVirginiaBoardOfPharmacy: ReportVirginiaBoardOfPharmacy,
    ReportsIndex: ReportsIndex,
    ReportsList: ReportsList,
    ScanBatchVerify: ScanBatchVerify,
    ScanDetails: ScanDetails,
    SegmentEdit: SegmentEdit,
    ShortageManagementPage: ShortageManagementPage,
    ShortageReportPage: ShortageReportPage,
    SingleKitMaster: SingleKitMaster,
    Tagging: Tagging,
    UserSettings: UserSettings,
    MidmarkLowBatteryReport: MidmarkLowBatteryReport,
};
/* eslint-enable */

export const PageComponents = [
    AddCredits,
    Admin,
    AdminBatchLots,
    AdminCartsCreate,
    AdminCartsList,
    AdminHardwareList,
    AdminHardwareUpdate,
    AdminHome,
    AdminHospital,
    AdminItemExpirations,
    AdminLocationsCreate,
    AdminLocationsList,
    AdminLocks,
    AdminPretaggedQuarantine,
    AdminRecallsCreate,
    AdminRecallsList,
    AdminUsersCreate,
    AdminUsersList,
    AdminUsersProfile,
    BinEdit,
    BinScan,
    BinsIndex,
    CartManage,
    CartsIndex,
    ChangeTagType,
    ChargeSheetTest,
    CreateEditFormularyItem,
    CreditHistory,
    ContainersIndex,
    ContainersDetail,
    EpcLink,
    FormularyArchiveItem,
    FormularyIndex,
    Home,
    KitBlock,
    KitDetail,
    KitMasterEdit,
    KitMastersList,
    KitScans,
    KitsIndex,
    ManageItems,
    ManageItemsPrintError,
    ManageItemsPrintUnconfirmed,
    ManageSubscriptions,
    MobileCartHomeComponent,
    MobileCartKitBlockComponent,
    MobileCartLandingPageComponent,
    MobileCartScanSummaryComponent,
    NgxForgotPasswordComponent,
    NgxLoginComponent,
    NgxPasswordResetComponent,
    NgxPasswordResetExpiredComponent,
    NgxSwitchHospitalComponent,
    PreTaggedUsageHistory,
    PretaggedMedications,
    PrintError,
    ReportActivityAnalytics,
    ReportAdvancedSegmentOptimization,
    ReportBilling,
    ReportExpirationAnalytics,
    ReportHospitalActivity,
    ReportInferredItemsConsumedRemoved,
    ReportItemBatchByHospital,
    ReportItemBatchSummary,
    ReportItemExpirations,
    ReportItemsByNDC,
    ReportItemsTagged,
    ReportKitActivity,
    ReportKitsNeedingRework,
    ReportKittedInventoryWithCost,
    ReportKittedItemInventory,
    ReportRecalls,
    ReportTaggedItemBatchSummary,
    ReportUserActivity,
    ReportVirginiaBoardOfPharmacy,
    ReportsIndex,
    ReportsList,
    ScanBatchVerify,
    ScanDetails,
    SegmentEdit,
    ShortageManagementPage,
    ShortageReportPage,
    SingleKitMaster,
    Tagging,
    UserSettings,
    MidmarkLowBatteryReport,
];

// Dialogs - generally not referenced by their selectors
export const DialogComponents = [
    ActivateFormularyItemDialog,
    AddCreditsDialogComponent,
    AddFormularyItemBinDialog,
    AddFormularyItemToSegmentDialog,
    AeroscoutDialog,
    AeroscoutLoginDialog,
    ArchiveKitMasterDialog,
    AssignCartToLocationDialog,
    AssignKitsToLocationDialog,
    AssignLockDialog,
    BadgeAuthorizationDialog,
    ChooseBinDialog,
    ChooseBinVerifyDialog,
    CloneKitMasterDialog,
    ConfirmChangesFormularyItemDialog,
    ConfirmDialog,
    ContainerScanInfoDialog,
    ContainerAddToSegment,
    ContainersLandingPage,
    DecommissionPackagesDialog,
    DefaultScannerDialog,
    DeleteKitsDialog,
    DispatchKitDialog,
    DispatchKitMobileDialog,
    EditContainerLocationDialog,
    EditLotExpirationDialog,
    ExpirationDialog,
    FillOptionsDialog,
    IgnoreRecallDialog,
    ManageContainerDialog,
    MarkOpenedDialog,
    MessageDialog,
    MissingScannerDialog,
    PretaggedIssueDialog,
    RemoteBatchVerificationDialog,
    RemoveOuterPackageDialog,
    RemoveSegmentDialog,
    ReplaceBinTagDialog,
    ReplaceKitTagDialog,
    ReportSubscriptionDialog,
    ResetPrinterDialog,
    ScannerUpdateDialog,
    ScanningDialog,
    SegmentTemplateEditDialog,
    SessionTimeoutWarningDialog,
    UnquarantinePretaggedDialog,
    UploadCsvDialog,
    VerifyItemDialog,
];

// Components explicitly referenced in HTML via their selectors
export const UiComponents = [
    ActivitiesFilter,
    ActivityFilter,
    AeroscoutAssetSelect,
    AutoComplete,
    BarcodeImgComponent,
    BarcodeScanField,
    BatchSelect,
    Benchmarking,
    BillingSheet,
    BinCustomItems,
    BinDetails,
    BinFormularyItems,
    BinKitFilter,
    BinKitMastersFilter,
    BinScanDetails,
    BinScanItemsTable,
    BinScanSummary,
    BinSummary,
    BinWrongItems,
    CartExpirations,
    CartExpiringSoon,
    CartHistory,
    CartKitMastersFilter,
    CartLocationsFilter,
    CategoryInfo,
    CeqSearchTypeahead,
    CheckboxComponent,
    CheckboxGroupComponent,
    ContainerDetailsTab,
    ContainerSummaryTab,
    ContainerOtherItemsTable,
    Dashboard,
    DateFilter,
    DateFilterV2,
    DateMonthFilter,
    DateMonthFilterV2,
    DownloadButton,
    ErrorMessage,
    ExpirationFilter,
    ExpiredAndRecalled,
    ExpiringChip,
    FormularySearch,
    FkAlertBannerComponent,
    FreshDesk,
    GenericNdcItemTypeFilter,
    GenericNdcLotSearchFilter,
    Header,
    HospitalGroupFilter,
    HospitalOwnershipFilter,
    IdnGroupDropdown,
    ItemAlias,
    ItemAttributes,
    ItemLotExp,
    ItemSelect,
    ItemSelectAlias,
    ItemTypeGroupFilter,
    ItemsList,
    KCBreadcrumbsComponent,
    KCDateInput,
    KCDatePicker,
    KCDateValidatorDirective,
    KCDownloadButton,
    KCHospitalGroupSelect,
    KCLogo,
    KCMatStepperNext,
    KCMatStepperPrevious,
    KCMultiselectMenu,
    KCNameplate,
    KCSpinner,
    KitAllItemsBySegment,
    KitDecommissioned,
    KitDrugGroupFilter,
    KitItemsTable,
    KitLabelFilter,
    Allowlist,
    KitMasterFilter,
    KitMastersFilter,
    KitMissingItems,
    KitMultidose,
    KitProcessing,
    KitScanDetails,
    KitScanSummary,
    KitShortage,
    KitsNeedingReworkFilter,
    KitsTable,
    LockoutDateBannerComponent,
    MessageBoard,
    MessageDisplay,
    ...MobileCartComponents,
    MultiKitPickList,
    NavMenu,
    NdcLotSearchFilter,
    NumberStepperComponent,
    PharmacyManagement,
    PickList,
    PriceTypeFilter,
    PrintLogo,
    ReasonsFilter,
    RecallLotNumbers,
    RecallsFilter,
    RegisterBadge,
    RemovedReasonsFilter,
    ReportFilter,
    ReportFilters,
    ReportHeader,
    ReportNoData,
    ReportSubscriptionFilters,
    RoleRow,
    RoleSection,
    ScanButton,
    SegmentItems,
    SegmentTemplateEdit,
    ShelvedInventory,
    Sorter,
    SystemRefresh,
    TableComponentFixedHeader,
    TagAddTags,
    TagAddToBin,
    TagAssociation,
    TagHistory,
    TagPrint,
    TagQuantityPrinter,
    TaggingBin,
    TaggingItem,
    TaggingKit,
    TimeframeFilter,
    UserFilter,
    WholesalerPriceTypeFilter,
];

// Other components that aren't explicitly referenced in HTML with a selector
export const OtherEntryComponents = [AppComponent, KCMatSnackBar];
