import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { PrintResource } from '@resources/print-resource.service';
import { ProductModuleService } from '@services/core/product-module.service';

import { Printer } from '@models/core/printer';
import { HardwareType } from '@models/core/hardware-type';

interface TagType {
    barcode: string;
    description: string;
    display_name: string;
    formulary_item_ids: number[];
    hardware_types: HardwareType[];
    id: number;
    name: string;
}
@Component({
    selector: 'change-tag-type',
    templateUrl: './change-tag-type.html',
    styleUrls: ['./change-tag-type.scss'],
})
export class ChangeTagType {
    //bindings
    @Input() printers: Printer[];
    @Input() tagTypes: TagType[];

    originalTagTypes: TagType[];
    tagForm = {
        checks: [null, false, false, false, null, false],
        loadingReset: false,
        printer: undefined,
        loading: false,
    };

    step: number = 1;
    tagTypeId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private hardwareService: HardwareService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private printResource: PrintResource,
        private productModuleService: ProductModuleService
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap) => (this.tagTypeId = paramMap.get('tagTypeId')));

        this.productModuleService.setModule();

        this.originalTagTypes = _.cloneDeep(this.tagTypes);

        this.setDefaultPrinter().then(() => {
            this.setTagTypes();
        });
    }

    private setDefaultPrinter(): Promise<any> {
        if (!this.tagForm.printer) {
            return this.hardwareService.getDefaultPrinter().then((defaultPrinter) => {
                this.tagForm.printer = this.printers.length < 2 ? this.printers[0] : defaultPrinter;
            });
        } else {
            return Promise.resolve();
        }
    }

    setTagTypes(): void {
        if (this.tagForm.printer) {
            if (this.tagTypeId) {
                const scannedType = this.originalTagTypes.find((tagType) => tagType.barcode === this.tagTypeId);
                this.tagTypes = [scannedType];
                this.tagForm.printer.tagType = scannedType;
            } else {
                this.tagTypes = this.originalTagTypes.filter((tagType: any) => {
                    return tagType.hardware_types.find((hardwareType) => {
                        return (
                            hardwareType.id === this.tagForm.printer.hardware_type_id &&
                            (!tagType.formulary_item_ids || !tagType.formulary_item_ids.length)
                        );
                    });
                });
                this.tagTypes = this.tagTypes.sort((a, b) => (a.display_name > b.display_name ? 1 : -1));
                this.tagForm.printer.tagType = this.originalTagTypes.find(
                    (tagType) => tagType.id === this.tagForm.printer.tag_type_id
                );
            }
        } else {
            this.tagTypes = [];
        }
    }

    tagTypeClasses(tagType: TagType) {
        return {
            selected: this.tagForm.printer && tagType === this.tagForm.printer.tagType,
            disabled: !!this.tagTypeId && this.tagForm.printer && tagType !== this.tagForm.printer.tagType,
        };
    }

    clickTagType(tagType: TagType): void {
        if (!this.tagTypeId) {
            this.tagForm.printer.tagType = tagType;
        }
    }

    stepClasses(step: number) {
        return {
            opened: this.step === step,
            closed: this.step !== step,
            filled: this.isFilledStep(step),
            valid: this.isValidStep(step),
        };
    }

    isFilledStep(step: number): boolean {
        return this.isValidStep(step) && this.step !== step;
    }

    isValidStep(step: number): boolean {
        if (step === 1) {
            return !!this.tagForm.printer && !!this.tagForm.printer.tagType;
        } else if (step >= 2) {
            return this.isCheckedStep(step);
        }
    }

    isCheckedStep(step: number): boolean {
        return this.tagForm.checks[step] === true;
    }

    icoGotoStep(step: number): void {
        if (this.isFilledStep(step) && !this.tagForm.loading) {
            this.gotoStep(step);
        }
    }

    gotoStep(step: number): void {
        this.step = step;

        Array.from({ length: this.tagForm.checks.length - step }, (_, i) => i + step).forEach((idx) => {
            this.tagForm.checks[idx] = false;
        });

        if (step === 5) {
            this.setTagType();
        }
    }

    setTagType(): void {
        this.tagForm.checks[5] = false;
        this.tagForm.loading = true;

        const printer = _.clone(this.tagForm.printer);
        printer.tag_type_id = printer.tagType.id;

        this.hardwareService
            .updateHardware(printer)
            .then(() => {
                return this.printResource.resetPrinter(printer);
            })
            .then(() => {
                setTimeout(() => {
                    this.printResource.printSampleTag(printer, 1).then(() => {
                        this.tagForm.loading = false;
                        this.tagForm.checks[5] = true;
                        this.gotoStep(6);
                    });
                }, 6000);
            });
    }

    finishSwap(): void {
        this.router
            .navigate(['/tagging'], {
                queryParams: {
                    type: 'item',
                },
            })
            .then(() => {
                this.kcMatSnackBarService.open(
                    SnackBarTypes.SUCCESS,
                    `Tag type set to: ${this.tagForm.printer.tagType.display_name}`
                );
            });
    }
}
