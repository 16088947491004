import { Input, OnInit, Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { Tagging } from '@components/tags/tagging';

import { ActionService } from '@services/utils/action.service';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { BarcodeService } from '@services/core/barcode.service';
import { BinResource } from '@resources/bin-resource.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { PrintService } from '@services/hardware/print.service';
import { TagAssociationResource } from '@resources/tag-association-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { LastPipe } from '@pipes/last.pipe';

import { deleteEmptyKeys } from '@utils/objects';

interface Tag {
    epc: string;
    printer: {
        id: string;
    };
    timeoutError: boolean;
}

@Component({
    selector: 'tagging-bin',
    templateUrl: './tagging-bin.html',
    styleUrls: ['./tagging-bin.scss'],
})
export class TaggingBin {
    @Input() type;
    @Input() printers;
    @Input() tag: Tag;

    title: string;

    bin: {
        name: string;
        minimumParLevel: number;
        maximumParLevel: number;
        expiringSoon: number;
        scannedEpc: string;
    };
    tagError: string;
    defaultErrorMessage: string;
    printComplete: boolean = false;
    tryingToStartPrint: boolean;
    boundClearEpc: Function;

    validators: {
        [key: string]: Function;
    };
    snackBarRef: MatSnackBar;
    allowRemove: boolean = true;
    barcode: string;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('binStep') binStep: MatStep;
    @ViewChild('printStep') printStep: MatStep;
    @ViewChild('doneStep') doneStep: MatStep;
    @ViewChild('binForm') binForm: NgForm;
    @ViewChild('printForm') printForm: NgForm;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private actionService: ActionService,
        private barcodeResource: BarcodeResource,
        private barcodeScanService: BarcodeScanService,
        private barcodeService: BarcodeService,
        private binResource: BinResource,
        private hardwareService: HardwareService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private kitMasterService: KitMasterService,
        private ndcScanUtilsService: NdcScanUtilsService,
        private parseFormErrorsService: ParseFormErrorsService,
        private printService: PrintService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe((queryParamMap) => (this.barcode = queryParamMap.get('barcode')));
        const translationKey = this.type.print ? 'print_bin.print_bin_title' : 'print_bin.add_bin_title';

        this.title = this.translationService.instant(translationKey);
        this.defaultErrorMessage = this.translationService.instant('tagging.system_error');

        this.startNewPrint();
        this.initValidators();

        this.boundClearEpc = this.clearScannedEpc.bind(this);
        this.barcodeScanService.blockListeners();
    }

    isNDC(barcode: string): boolean {
        return this.ndcScanUtilsService.extractNDCFromScan(barcode) !== barcode;
    }

    addBin(event) {
        this.bin.scannedEpc = event;
        const scanValues = this.bin.scannedEpc && this.barcodeScanService.processBarcode(this.bin.scannedEpc);

        if (scanValues) {
            this.tagError = '';
            this.barcodeService
                .barcodeObject(scanValues.epc)
                .then((data) => {
                    this.tagError = '';
                    if (data.object['class'] === 'Tag' && data.object['decommissioned'] === false) {
                        this.tag.epc = scanValues.epc;
                    } else {
                        let errorKey;
                        if (data.object['decommissioned'] === true) {
                            errorKey = 'tagging.tag_decommissioned';
                        } else if (data.object['class'] === 'Package') {
                            errorKey = 'tagging.tag_already_associated_with_package';
                        } else if (data.object['class'] === 'Kit') {
                            errorKey = 'tagging.tag_already_associated_with_kit';
                        } else if (data.object['class'] === 'Bin') {
                            errorKey = 'tagging.tag_already_associated_with_bin';
                        } else {
                            errorKey = 'tagging.tag_already_associated';
                        }

                        this.translationService.get(errorKey, { epc: this.bin.scannedEpc }).then((translation) => {
                            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, translation);
                        });
                    }
                })
                .catch((error) => {
                    this.tagError = error.message;
                });
        }

        this.bin.scannedEpc = '';
    }

    startNewPrint() {
        Object.assign(this.tag, {
            timeoutError: false,
        });

        this.bin = {
            name: undefined,
            minimumParLevel: undefined,
            maximumParLevel: undefined,
            expiringSoon: undefined,
            scannedEpc: undefined,
        };

        if (!this.type.batch) {
            this.tag.epc = null;

            if (this.barcode) {
                if (!this.isNDC(this.barcode)) {
                    this.tag.epc = this.barcode;
                }
            }
        }

        if (!this.tag.printer) {
            this.hardwareService.getDefaultPrinter().then((printer) => {
                this.tag.printer = printer;
            });
        }
    }

    resetStepper(): void {
        this.stepper.reset();
        this.binForm?.resetForm();
        this.printForm?.resetForm();
        this.ngOnInit();
    }

    initValidators(): void {
        this.validators = {
            print: () => {
                return this.printComplete;
            },
            associate: () => {
                return !!this.tag.epc;
            },
        };
    }

    readyToPrint(): boolean {
        return !this.isPrinting() && !!this.tag.printer;
    }

    isPrinting(): boolean {
        return this.printService.printing();
    }

    selectionChange(event: StepperSelectionEvent): void {
        if (event.selectedIndex < event.previouslySelectedIndex) {
            this.stepper.steps.forEach((step, index) => {
                if (index > event.selectedIndex) {
                    step.interacted = false;
                }
            });
        }

        if (this.fromTo(event, this.printStep, this.doneStep)) {
            if (!this.type.print) {
                this.associate();
            }
        }

        if (this.fromTo(event, this.binStep, this.doneStep)) {
            this.associate();
        }
    }

    setInputFocus(): void {
        let focusField;
        if (this.isSelected(this.binStep)) {
            focusField = document.getElementById('bin-name');
            focusField.focus();
            return;
        }

        if (this.isSelected(this.printStep)) {
            focusField = document.getElementById('select-bin-printer');
            focusField.focus();
            return;
        }
    }

    isSelected(step: MatStep): boolean {
        return !!this.stepper && this.stepper.selected === step;
    }

    fromTo(transition: StepperSelectionEvent, from: MatStep, to: MatStep): boolean {
        return transition.previouslySelectedStep === from && transition.selectedStep === to;
    }

    print() {
        this.tryingToStartPrint = true;

        const printSuccess = () => {
            this.printComplete = true;
            this.printForm.controls['printValidator'].setValue(true);
            this.stepper.next();

            this.translationService.get('print_bin.printing_started', values).then((translation) => {
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
            });
        };

        const printError = (data) => {
            const reason = data.reason;
            const unknownError = this.translationService.instant('errors.unknown_print_error');

            if (reason === 'timeout') {
                setTimeout(() => {
                    this.tag.timeoutError = true;
                });
            } else if (reason === 'encoding') {
                this.router.navigate(['/print-error']);
            } else if (reason === 'zpl') {
                const message = data?.message || this.defaultErrorMessage;
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
            } else if (reason === 'unknown error') {
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, unknownError);
            } else {
                // noop -- treat this as a success because it's not one of our known errors
            }
        };

        const binData = deleteEmptyKeys({
            printer: this.tag.printer,
            printer_hardware_id: this.tag.printer.id.toString(),
            name: this.bin.name,
            min_par_level: this.bin.minimumParLevel,
            max_par_level: this.bin.maximumParLevel,
            expiring_soon_days: this.bin.expiringSoon,
        });

        const values = {
            binName: this.bin.name,
        };

        this.hardwareService.setDefaultPrinter(this.tag.printer.id.toString());
        this.printService
            .printBinTag(binData)
            .then(printSuccess)
            .catch(printError)
            .finally(() => {
                this.tryingToStartPrint = false;
            });
    }

    associate() {
        const associationError = (data) => {
            const message = data?.message || this.defaultErrorMessage;
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
        };

        const finishAssociation = () => {
            const message = this.translationService.instant('tagging_batch.bin_created', { binName: this.bin.name });
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, message);
        };

        const binData = {
            name: this.bin.name,
            min_par_level: this.bin.minimumParLevel,
            max_par_level: this.bin.maximumParLevel,
            expiring_soon_days: this.bin.expiringSoon,
            epc: this.tag.epc,
        };

        this.binResource.associateBinTag(binData).then(finishAssociation).catch(associationError);
    }

    printKey(): string {
        return this.tag.timeoutError ? 'print_item.reprint' : 'common.print';
    }

    removeEpc() {
        this.tag.epc = undefined;
    }

    clearScannedEpc() {
        this.bin.scannedEpc = '';
    }

    parseErrors(errors): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    comparePrinters(o1: any, o2: any): boolean {
        return o1.id === o2?.id;
    }
}
