import { Injectable } from '@angular/core';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { SegmentsService } from '@services/core/segments.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SingleSegmentResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentsService: SegmentsService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const segmentId = parseInt(activatedRoute.params.segmentId);
        let segmentPromise;

        if (this.actionService.isAllowAction('kits_inventory', 'view_kit_segment', 'Load single segment data')) {
            segmentPromise = this.segmentsService.getSingleSegment(segmentId);
        } else {
            segmentPromise = Promise.resolve([]);
        }

        return this.loadingSpinnerService.spinnerifyPromise(segmentPromise);
    }
}
