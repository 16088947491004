import { Input, OnInit, Component, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ActionService } from '@services/utils/action.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { RecallResource } from '@resources/recall-resource.service';
import { TranslationService } from '@services/utils/translation.service';

export const DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-M-D', 'M-D-YYYY', 'M/D/YYYY'],
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'item-lot-exp',
    templateUrl: './item-lot-exp.html',
    styleUrls: ['./item-lot-exp.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ItemLotExp {
    @Input() tag;
    @Input() lotExpForm: NgForm;
    @Input() triggerLotExpSuccess: Subject<boolean>;

    @Output() tagChange = new EventEmitter();

    minDate: Date;
    invalidReason: string;
    snackBarRef: MatSnackBar;
    disable_calc_fridge_checkbox: boolean = false;

    @ViewChild('lot') lot: NgModel;
    @ViewChild('exp') exp: NgModel;
    @ViewChild('lotConfirm') lotConfirm: NgModel;
    @ViewChild('expConfirm') expConfirm: NgModel;

    constructor(
        private actionService: ActionService,
        private formularyItemResource: FormularyItemResource,
        private kcMatSnackBarService: KCMatSnackBarService,
        private parseFormErrorsService: ParseFormErrorsService,
        private recallResource: RecallResource,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.minDate = new Date();
        if (!this.tag.lotExpSaved) {
            this.setup();
        }
        this.triggerLotExpSuccess.subscribe((v) => {
            this.saveLotExp();
        });
    }

    setupTag() {
        Object.assign(this.tag, {
            allowed_lot_num_expirations: {},
            expire: '',
            expire_confirmation: '',
            expAllowed: true,
            expEnabled: true,
            expireDisabled: false,
            expObscured: false,
            expReadOnly: false,
            expRequired: false,
            overridePastExpiration: false,
            lot: '',
            lot_confirmation: '',
            lotAllowed: true,
            lotRequired: false,
            lotEnabled: true,
            lotDisabled: false,
            lotObscured: false,
            lotReadOnly: false,
        });

        this.tag.showFridgeCheckbox = !!(this.tag.hospitalSettings && this.tag.item.expiring_fridge_days);

        if (this.tag.showFridgeCheckbox) {
            if (this.tag.item.refrigeration_start_on_tagging === 'always') {
                this.tag.calculate_fridge_date = true;
                this.disable_calc_fridge_checkbox = true;
            }
            if (this.tag.item.refrigeration_start_on_tagging === 'never') {
                this.disable_calc_fridge_checkbox = true;
            }
        }
    }

    setup() {
        this.setupTag();

        if (!this.tag || !this.tag.ndc) {
            return;
        }

        const success = (data) => {
            const keys = _.keys(data);
            const values = _.values(data);
            this.tag.allowed_lot_num_expirations = data;

            if (keys.length > 0) {
                // we have lot numbers, so we need one
                this.tag.lotRequired = false;
                this.tag.lotAllowed = true;
                if (_.every(values, (v) => v === undefined)) {
                    this.tag.expAllowed = false;
                    this.tag.expRequired = false;
                } else {
                    this.tag.expRequired = false;
                    this.tag.expAllowed = true;
                }
            } else {
                this.tag.expAllowed = true;
                this.tag.lotAllowed = true;
            }
            Object.assign(this.tag, {
                lot: '',
                expire: '',
                lot_confirmation: '',
                expire_confirmation: '',
            });

            this.applyGSBarcode();
        };

        const error = () => {
            Object.assign(this.tag, {
                allowed_lot_num_expirations: {},
                expire: '',
                expire_confirmation: '',
                expAllowed: true,
                lot: '',
                lot_confirmation: '',
                lotAllowed: true,
            });
        };

        const data = {
            ndc: this.tag.item.ndc,
            on_behalf_of_hospital_id: this.tag.onBehalfOfHospitalId,
        };

        this.formularyItemResource.expirations(data).then(success).catch(error);
    }

    applyGSBarcode() {
        const scannedGSBarcode = this.tag.gsBarcode;
        if (scannedGSBarcode) {
            this.tag.lot = scannedGSBarcode.lot;
            this.tag.lot_confirmation = scannedGSBarcode.lot;
            this.tag.expire = scannedGSBarcode.expiration;
            this.tag.expire_confirmation = scannedGSBarcode.expiration;
            this.lotExpForm.control.markAsDirty();
        }

        this.tag.processingGSBarcode = false;
    }

    customErrors(control: NgModel): string {
        let errString = this.parseErrors(control.errors);

        if (control.errors.mustmatch) {
            if (control === this.lotConfirm) {
                errString = 'Lot Numbers Must Match';
            } else if (control === this.expConfirm) {
                errString = 'Expiration Dates Must Match';
            }
        }
        return errString;
    }

    parseErrors(errors): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    debug() {
        this.tag.lotObscured = !this.tag.lotObscured;
    }

    updateExp(event) {
        this.tag.expire = moment(event.value).format('YYYY-MM-DD'); //event.value;
        if (this.expConfirm?.touched) {
            setTimeout(() => this.lotExpForm.controls['exp-confirm'].updateValueAndValidity(), 0);
        }
    }

    updateExpConfirmation(event) {
        this.tag.expire_confirmation = event.value;
    }

    initialLotEntryDisallowable(): boolean {
        return !this.tag.lotRequired && this.tag.lotAllowed;
    }

    initialExpEntryDisallowable(): boolean {
        return !this.tag.expRequired && this.tag.expAllowed;
    }

    showConfirmationFields(): boolean {
        return !this.lotSeenBefore();
    }

    lotSeenBefore(): boolean {
        return (
            this.tag &&
            this.tag.lotEnabled &&
            this.tag.allowed_lot_num_expirations &&
            this.tag.allowed_lot_num_expirations.hasOwnProperty(this.lotUpperCased())
        );
    }

    lotUpperCased(): string {
        return this.tag.lot ? this.tag.lot.toUpperCase() : null;
    }

    blurLot() {
        this.tag.lotObscured = true;
    }

    focusLot() {
        this.tag.lotObscured = false;
    }

    updateLot(event) {
        this.tag.lot = event;
        if (this.lotConfirm?.touched) {
            setTimeout(() => this.lotExpForm.controls['lot-confirm'].updateValueAndValidity(), 0);
        }
    }

    updateLotConfirmation(event) {
        this.tag.lot_confirmed = event;
    }

    blurExp() {
        this.tag.expObscured = true;
    }

    focusExp() {
        this.tag.expObscured = false;
    }

    toggleLotEnabled(event) {
        this.tag.lotEnabled = event;
        this.tag.lotDisabled = !this.tag.lotEnabled;
        if (!this.tag.lotEnabled) {
            this.tag.lot = '';
            this.tag.lot_confirmation = '';
            this.tagChange.emit(this.tag);
            this.lot.reset();
            this.lotConfirm?.reset();
            this.lotExpForm.control.markAsDirty();
        }
    }

    toggleExpEnabled(event) {
        this.tag.expEnabled = event;
        this.tag.expireDisabled = !this.tag.expEnabled;
        if (!this.tag.expEnabled) {
            this.tag.expire = '';
            this.tag.expire_confirmation = '';
            this.tagChange.emit(this.tag);
            this.exp.reset();
            this.expConfirm?.reset();
            this.lotExpForm.control.markAsDirty();
        }
    }

    lotSeenExpValidator(): boolean {
        const expected_expiration = this.expectedExpirationForLot();
        const set_expiration = moment(this.tag.expire).format('YYYY-MM-DD');
        return !this.lotSeenBefore() || (this.lotSeenBefore() && set_expiration === expected_expiration);
    }

    expectedExpirationForLot() {
        if (this.lotSeenBefore()) {
            return this.tag.allowed_lot_num_expirations[this.lotUpperCased()];
        }
        return undefined;
    }

    canCorrect(): boolean {
        return (
            this.actionService.isAllowAction(
                'kits_inventory',
                'view_item_expirations',
                'Link to item expiration page from tagging items.'
            ) &&
            this.actionService.isAllowAction(
                'kits_inventory',
                'update_item_expirations',
                'Link to item expiration page from tagging items.'
            )
        );
    }

    needsConfirmationMessage(): boolean {
        return (
            !this.lotSeenBefore() && !!this.tag.lot && this.tag.lotEnabled && !!this.tag.expire && this.tag.expEnabled
        );
    }

    showErrorMessage(): boolean {
        if (
            this.lotExpForm &&
            this.lotExpForm.invalid &&
            this.lotSeenBefore() &&
            !!this.tag.expire &&
            !this.lotSeenExpValidator()
        ) {
            this.invalidReason = 'wrong expiration for lot';
            return true;
        }
        return false;
    }

    saveLotExp(): void {
        if (this.tag.lotEnabled || this.tag.expEnabled) {
            let msg;
            if (!this.lotSeenBefore()) {
                msg = this.translationService.instant('print_item.you_have_confirmed_lot_exp');
            } else {
                msg = this.translationService.instant('print_item.we_have_seen_lot_before');
            }
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, msg);
        }

        this.tag.lotExpSaved = true;
        this.tag.item.lot = this.tag.lot;
        this.tag.item.expire = this.tag.expire;

        if (!!this.tag.item.expire) {
            this.tag.item.expire_formatted = moment(this.tag.expire).format('YYYY-MM-DD');
            this.tag.expire_formatted = this.tag.item.expire_formatted;
        } else {
            this.tag.item.expire_formatted = undefined;
            this.tag.expire_formatted = undefined;
        }

        if (!this.tag.lotDisabled) {
            this.recallResource
                .recallSearch({
                    ndc: this.tag.ndc,
                    lot_number: this.tag.lot,
                })
                .then((data) => {
                    this.tag.hasRecall = !!data.recalls.length;
                })
                .finally(() => {
                    this.tag.processingGSBarcode = false;
                    this.tagChange.emit(this.tag);
                });
        } else {
            this.tag.processingGSBarcode = false;
            this.tag.hasRecall = false;
            this.tagChange.emit(this.tag);
        }
    }

    onToggleFridge(): void {
        this.tag.calculate_fridge_date = !this.tag.calculate_fridge_date;
    }

    onTogglePastExpiration(): void {
        this.tag.overridePastExpiration = !this.tag.overridePastExpiration;
    }

    expirationInPast(): boolean {
        let expDate = moment(this.tag.expire);
        return !!expDate && expDate < moment();
    }

    overridePastExpirationValidator(): boolean {
        return !!this.tag.overridePastExpiration;
    }
}
