import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { Package } from '@models/core/package';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { from } from 'rxjs';
import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';
import { ContainerScanInfoDialog } from '../container-scan-info/container-scan-info-dialog';
import { MatDialog } from '@angular/material/dialog';
import { Container } from '@models/core/container';
import { ContainerAddToSegment } from '../container-add-to-segment/container-add-to-segment';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import {
    SegmentResourceType,
    SegmentTemplateEditDialog,
} from '@components/dialogs/segment-template-edit/segment-template-edit-dialog';
import { ActivatedRoute } from '@angular/router';

export interface OtherItemsRow {
    ndc: string;
    need_attention: number;
    quantity: number;
    expired: number;
    recalled: number;
    expiring_soon: number;
    formulary_item_id: number;
}

@Component({
    selector: 'container-other-items-table',
    templateUrl: './container-other-items-table.html',
    styleUrls: ['./container-other-items-table.scss'],
})
export class ContainerOtherItemsTable extends TableComponent {
    @Input() otherItems: Package[];
    @Input() containerData: Container;
    @Output() onSegmentSave = new EventEmitter<void>();
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSourceWithNaturalSort<Package>;
    hasData: boolean;
    otherItemsTableColumns = [
        'name',
        'need_attention',
        'ndc',
        'quantity',
        'expired',
        'recalled',
        'expiring_soon',
        'tableActions',
    ];
    containerId: number;

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.containerId = parseInt(paramMap.get('id'));
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes && changes.hasOwnProperty('otherItems')) {
            this.compileOtherItemsData(changes?.otherItems.currentValue);
        }
    }

    compileOtherItemsData(packages: Package[]) {
        const compiledData$ = from(packages).pipe(
            // group by NDC
            groupBy((p) => p.ndc),
            mergeMap((group$) =>
                group$.pipe(
                    // merge the grouped items into its own array
                    reduce((acc: Package[], cur: Package) => {
                        return [...acc, cur];
                    }, []),
                    // compile need_attention, quantity, expired, recalled and expiring soon
                    reduce((acc, cur) => {
                        const row = {
                            ...cur[0],
                            name: cur[0].name,
                            ndc: cur[0].ndc, // all ndcs will be the same the the order doesn't matter
                            need_attention: cur.filter((na) => na.days_to_expiration < 0 || na.recalled).length,
                            quantity: cur.length,
                            expired: cur.filter((e) => e.days_to_expiration < 0).length,
                            recalled: cur.filter((r) => r.recalled === true).length,
                            expiring_soon: cur.filter((es) => es.item_expiring_soon === true).length,
                            formulary_item_id: cur[0].formulary_item_id,
                        };

                        return acc.concat(row);
                    }, [])
                )
            ),
            toArray()
        );

        compiledData$.subscribe((value: Array<OtherItemsRow[]>) => {
            const flattenData = (value as any).flat();

            this.dataSource = new MatTableDataSourceWithNaturalSort(flattenData);

            setTimeout(() => (this.dataSource.sort = this.sort));
            this.hasData = this.dataSource?.data.length > 0;
        });
    }

    getUnitMeasure(value) {
        return value > 1 || value === 0 ? 'containers.container_summary.units' : 'containers.container_summary.unit';
    }

    openExpirationUnitsDialog(selectedItem: Package, reference: 'expiring_soon' | 'expired') {
        let packages;

        if (reference === 'expiring_soon') {
            packages = this.otherItems.filter((pkg) => pkg.ndc === selectedItem.ndc && pkg.item_expiring_soon === true);
        }

        if (reference === 'expired') {
            packages = this.otherItems.filter((pkg) => pkg.ndc === selectedItem.ndc && pkg.days_to_expiration < 0);
        }

        this.dialog.open(ContainerScanInfoDialog, {
            width: '1100px',
            height: 'max-content',
            data: {
                tableData: packages,
                modalTitleKey: `containers.container_summary.modals.${reference}_items`,
            },
            autoFocus: false,
        });
    }

    openRecalledUnitsDialog(selectedItem: OtherItemsRow) {
        const recalledPackages = this.otherItems.filter((pkg) => pkg.ndc === selectedItem.ndc && pkg.recalled === true);

        this.dialog.open(ContainerScanInfoDialog, {
            width: '1100px',
            height: 'max-content',
            data: {
                tableData: recalledPackages,
                modalTitleKey: 'containers.container_summary.modals.recalled_items',
            },
            autoFocus: false,
        });
    }

    addToSegment(item: OtherItemsRow) {
        const containerId = this.containerId;
        const containerAddToSegmentDialog = this.dialog.open(ContainerAddToSegment, {
            width: '460px',
            height: 'max-content',
            data: {
                segments: this.containerData.segments,
                formularyItem: item,
                modalTitleKey: 'containers.container_summary.modals.recalled_items',
                containerId,
            },
            autoFocus: false,
        });

        containerAddToSegmentDialog.afterClosed().subscribe((confirmSave) => {
            if (confirmSave) {
                this.onSegmentSave.emit();
            }
        });
    }

    openContainerSegmentModal(formularyItem) {
        const containerId = this.containerId;
        this.loadingSpinnerService
            .spinnerifyPromise(this.formularyItemsService.getFormularyItems(), LoadingSpinnerTypes.BACKDROP)
            .then((formularyItems) => {
                const dialogConfirm = this.dialog.open(SegmentTemplateEditDialog, {
                    width: '900px',
                    height: 'max-content',
                    data: {
                        formularyItems,
                        segmentTemplate: {
                            formulary_items: [
                                formularyItems.find((fi) => fi.formulary_item_id === formularyItem.formulary_item_id),
                            ],
                            clinical_equivalences: [],
                        },
                        segments: [],
                        resource: SegmentResourceType.container,
                        containerId,
                        insertMode: true,
                    },
                    autoFocus: false,
                });

                dialogConfirm.afterClosed().subscribe((confirmSave) => {
                    if (confirmSave) {
                        this.onSegmentSave.emit();
                    }
                });
            });
    }
}
