import { Component, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatDialog } from '@angular/material/dialog';
import { AssignLockDialog } from '@dialogs/assign-lock/assign-lock-dialog';
import { DeleteKitsDialog } from './delete-kits/delete-kits-dialog';
import { ReplaceKitTagDialog } from '@dialogs/replace-kit-tag/replace-kit-tag-dialog';
import { AssignKitsToLocationDialog } from '@dialogs/assign-kits-to-location/assign-kits-to-location-dialog';

import { CartResource } from '@resources/cart-resource.service';
import { KitResource } from '@resources/kit-resource.service';

import { ActionService } from '@services/utils/action.service';
import { AssignKitService } from '@services/core/assign-kit.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { FileHandler } from '@services/utils/file-streamer.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { NaturalSortService } from '@services/utils/natural-sort.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { LocationResource } from '@resources/location-resource.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { SafeUrl } from '@angular/platform-browser';
import { printPage } from '@utils/print';

interface LocationAssignDialogResults {
    cart_id?: number;
    cart_location_id?: number;
    kits_ids?: number[];
    location_id?: number;
    removeLocations?: boolean;
    removeCarts?: boolean;
}

interface KitIndexFilter {
    masterId?: number;
    locationId?: number;
    cartId?: number;
    inventorySearch?: string;
}
@Component({
    selector: 'kits-index',
    templateUrl: './kits-index.html',
})
export class KitsIndex {
    searchUpdated: Subject<string> = new Subject<string>();

    // Member Vars
    carts: KC.ICart[] = [];
    billingData;
    deleteKitAllowed;
    canDeleteOrUpdateKit;
    deltaOnRight;
    disablePickList;
    filterOptionsKitMasters;
    filterOptionsLocations = [];
    filterOptionsCarts = [];
    filteredKits = [];
    hasCart;
    hasCartLocation;
    hasKitCartLocationOverride;
    useOverride;
    hasLocation;
    inventoryFlags;
    kits = [];
    kitData;
    kitMasters = [];
    locations = [];
    manualLocks;
    pickListLoaded;
    replaceKitAllowed;
    selectedKits = [];
    showCheckbox;
    updateLocationAllowed;
    updateCartAllowed;
    isDownloadingPdf: boolean;
    successMessage;
    currentPage: number = 1;
    pageKits: Array<number>;
    printers: any;
    kitsNeedingArchive: boolean = false;
    loadDataErrorMessage: string;
    hospitalLogo: SafeUrl;

    apiUrl: string = '/reports/kits';

    allOption = {
        name: 'common.all',
        id: -1,
    };

    filter: KitIndexFilter = {
        masterId: -1,
        locationId: -1,
        cartId: -1,
        inventorySearch: '',
    };

    sort = {
        field: 'last_scan_created_at',
        reverse: true,
    } as any;

    ALL_PAGES = 10000;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        protected actionService: ActionService,
        private assignKitService: AssignKitService,
        private translationService: TranslationService,
        private barcodeScanService: BarcodeScanService,
        private hospitalInfoService: HospitalInfoService,
        private hospitalResource: HospitalResource,
        private cartResource: CartResource,
        private kitResource: KitResource,
        protected loadingSpinnerService: LoadingSpinnerService,
        private fileHandler: FileHandler,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private productModuleService: ProductModuleService,
        private kitMasterService: KitMasterService,
        private locationResource: LocationResource,
        private hardwareService: HardwareService,
        private sanitizer: DomSanitizer,
        private zone: NgZone
    ) {
        this.productModuleService.setModule(ModuleTypes.INVENTORY);

        // Uses rxJs subject/observer/subscribe with de-bounce on search input
        this.searchUpdated
            .pipe(
                debounceTime(500), // wait x ms after the last event before emitting last event
                distinctUntilChanged() // only emit if value is different from previous value
            )
            .subscribe(() => this.filterKits());

        this.hasLocation = this.hospitalInfoService.hospitalSetting('kit_to_location_enabled') || false;
        this.hasCart = this.hospitalInfoService.hospitalSetting('kit_to_cart_enabled') || false;
        this.hasCartLocation = this.hospitalInfoService.hospitalSetting('cart_to_location_enabled') || false;
        this.hasKitCartLocationOverride =
            this.hospitalInfoService.hospitalSetting('kit_cart_location_override') || false;
        this.updateLocationAllowed = actionService.isAllowAction(
            'kits_inventory',
            'assign_kit_location',
            'Show Set Location button'
        );
        this.updateCartAllowed = actionService.isAllowAction(
            'kits_inventory',
            'assign_kit_cart',
            'Show Set Cart button'
        );
        this.deleteKitAllowed = actionService.isAllowAction(
            'kits_inventory',
            'delete_kit_instance',
            'Show Delete Kit button'
        );
        this.replaceKitAllowed = actionService.isAllowAction(
            'kits_tagging',
            'replace_kit_tag',
            'Show Replace Kit Tag button'
        );
        this.manualLocks = this.hospitalInfoService.manualLocks();
        this.showCheckbox = this.hasCart || this.hasLocation;
        this.canDeleteOrUpdateKit = this.deleteKitAllowed || this.replaceKitAllowed;

        // Multiple flag properties passed into kits table as one object
        this.inventoryFlags = {
            manualLocks: this.manualLocks,
            hasLocation: this.hasLocation,
            hasCart: this.hasCart,
            showCheckbox: this.showCheckbox,
            updateCartAllowed: this.updateCartAllowed,
            updateLocationAllowed: this.updateLocationAllowed,
            canDeleteOrUpdateKit: this.canDeleteOrUpdateKit,
            replaceKitAllowed: this.replaceKitAllowed,
            deleteKitAllowed: this.deleteKitAllowed,
            allChecked: this.allChecked,
        };

        let buttonTextKey;

        if (this.hasCart && this.hasLocation) {
            buttonTextKey = 'cart_location';
        } else if (this.hasCart) {
            buttonTextKey = 'cart';
        } else if (this.hasLocation) {
            buttonTextKey = 'location';
        } else {
            buttonTextKey = 'base';
        }

        translationService.translateInto(`inventory.edit_button_text.${buttonTextKey}`, this, 'buttonText');
        translationService.translateInto(this.allOption.name, this.allOption, 'name');
    }

    async ngOnInit() {
        this.route.queryParamMap.subscribe(
            (queryParamMap) => (this.kitsNeedingArchive = !!queryParamMap.get('kitsNeedingArchive'))
        );

        // Sync translate (using translationService.instant) needs to be called after the translation file is loaded.
        // As it is possible to be called on the first steps of the page lifecycle, the use of async translation
        // avoids to display the translation "key" instead of the message
        this.loadDataErrorMessage = await this.translationService.get('errors.generic_error_message');

        // Start loading/processing the kits async as soon as posible since it has the largest amount of data
        this.loadingSpinnerService.spinnerifyPromise(this.initialKitLoad());

        if (!!this.hospitalInfoService.getHospitalSettings().logo_filename) {
            this.setHospitalLogo();
        }

        this.setupPageData();
    }

    setupPageData() {
        return Promise.all([this.loadCarts(), this.loadKitMasters(), this.loadLocations(), this.loadPrinters()]).catch(
            () => {
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, this.loadDataErrorMessage);
            }
        );
    }

    setHospitalLogo() {
        this.hospitalResource
            .getLogoPng(this.hospitalInfoService.getHospitalSettings().hospital_id)
            .then((response: any) => {
                const blobURL = URL.createObjectURL(response);
                this.hospitalLogo = this.sanitizer.bypassSecurityTrustUrl(blobURL);
            });
    }

    initialKitLoad() {
        return this.loadWithActions(
            ['kits_inventory', 'view_kits_inventory', 'Load kits list data'],
            this.kitResource.kitListWithParams({}).then(({ kits, collection_size }) => {
                this.kits = kits;
                this.initializeKits();
            })
        );
    }

    loadCarts() {
        return this.loadWithActions(
            ['hospital_settings', 'view_cart', 'Load cart list data'],
            this.cartResource.cartList().then(({ carts }) => {
                this.carts = carts;

                this.filterOptionsCarts = _.cloneDeep(this.carts);
                this.filterOptionsCarts = NaturalSortService.sort(this.filterOptionsCarts, 'name');
                this.addFirstOption(this.filterOptionsCarts);
            })
        );
    }

    loadKitMasters() {
        return this.loadWithActions(
            ['kits_inventory', 'view_kit_master', 'Load Kit Masters data'],
            this.kitMasterService.getKitMasters(true).then((kitMasters) => {
                this.kitMasters = kitMasters;
                this.filterOptionsKitMasters = _.cloneDeep(this.kitMasters);

                this.filterOptionsKitMasters = NaturalSortService.sort(this.filterOptionsKitMasters, 'name');
                this.addFirstOption(this.filterOptionsKitMasters);
            })
        );
    }

    loadLocations() {
        return this.loadWithActions(
            ['hospital_settings', 'view_location', 'Load location list data'],
            this.locationResource.locationList().then(({ locations }) => {
                this.locations = locations;

                this.filterOptionsLocations = _.cloneDeep(this.locations);
                this.filterOptionsLocations = NaturalSortService.sort(this.filterOptionsLocations, 'name');
                this.addFirstOption(this.filterOptionsLocations);
            })
        );
    }

    loadPrinters() {
        return this.hardwareService.getPrinters().then((printers) => {
            this.printers = printers;
        });
    }

    loadWithActions(actions: Array<string>, fn: Promise<any>) {
        if (this.actionService.isAllowAction.apply(this.actionService, actions)) {
            return fn;
        }

        return Promise.resolve([]);
    }

    deleteKits() {
        const deleteKitsDialog = this.dialog.open(DeleteKitsDialog, {
            width: '550px',
            height: 'max-content',
            data: {
                kits: this.selectedKits,
                total: this.kits.length,
            },
        });

        deleteKitsDialog.afterClosed().subscribe((result) => {
            if (result === 'success') {
                const selectedKitIds = this.selectedKits.map((kit) => kit.id);
                // these are filtered to remove deleted kits from displaying, without page refresh
                this.filteredKits = this.filteredKits.filter((kit) => !selectedKitIds.includes(kit.id));
                this.kits = this.kits.filter((kit) => !selectedKitIds.includes(kit.id));
                if (this.selectedKits.length === 1) {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        this.translationService.instant('inventory.delete_kit_success')
                    );
                } else {
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        this.translationService.instant('inventory.delete_kits_success', {
                            quantity: this.selectedKits.length,
                        })
                    );
                }
            }
        });
    }

    initializeKits() {
        const hospitalId = this.hospitalInfoService.getHospitalId();

        _.each(this.kits, (kit) => {
            kit.kit_master_name = kit.kit_master.name;
            kit.last_scan_created_at = kit.last_scan ? kit.last_scan.created_at : null;
            kit.hasManualLocks = kit.manual_locks && (kit.manual_locks.return_lock || kit.manual_locks.dispatch_lock);

            kit.mineAtOtherHospital =
                _.get(kit, 'current_hospital.id') !== _.get(kit, 'owner_hospital_id') &&
                _.get(kit, 'owner_hospital_id') === hospitalId;
            if (kit.mineAtOtherHospital) {
                kit.locationHospitalName = kit.current_hospital.name;
            }
        });

        this.filterKits();
    }

    getPhysicalLabelParam() {
        if (this.filter.inventorySearch !== '') return this.filter.inventorySearch;
        return undefined;
    }

    // Bound Methods
    addFirstOption = (optionsList) => {
        optionsList.unshift(this.allOption);
    };

    showRow = (kit, type, target) => {
        if (this.filter[target] === -1) {
            return true;
        } else {
            return kit[type] !== null && kit[type].id === this.filter[target];
        }
    };

    onSearchTyped(value: string) {
        this.searchUpdated.next(value);
    }

    anyChecked = () => _.some(this.filteredKits, 'checked');

    allChecked = () => this.filteredKitsOnCurrentPage().every((kit) => !!kit.checked);

    kitCount = () => _.filter(this.filteredKits, { checked: true }).length;

    toggleCheckAll() {
        const newState = !this.allChecked();
        _.each(this.filteredKitsOnCurrentPage(), (kit) => {
            kit.checked = newState;
        });
        this.selectedKits = this.filteredKits.filter((kit) => !!kit.checked);
    }

    toggleCheck(kit) {
        kit.checked = !kit.checked;
        this.selectedKits = this.filteredKits.filter((kit) => !!kit.checked);
        const nullScans = this.selectedKits.filter((kit) => kit.last_scan == null);
        if (nullScans && nullScans.length === this.selectedKits.length) {
            this.disablePickList = true;
        } else {
            this.disablePickList = false;
        }
    }

    clearSearch() {
        this.filter.inventorySearch = '';
        this.filterKits();
    }

    replaceKit() {
        // the button will only show if there is one kit selected.
        let kit = this.selectedKits[0];
        const replaceKitTagDialog = this.dialog.open(ReplaceKitTagDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                kit: kit,
                printers: this.printers,
            },
        });

        replaceKitTagDialog.afterClosed().subscribe((result) => {
            if (result) {
                kit.epc = result;
            }
        });
    }

    retrieveScanList(kit) {
        if (kit.last_scans_requested) {
            return;
        }
        kit.last_scans_requested = true;
        this.kitResource.kitScanDetails(kit.id, 'recent').then((data) => {
            kit.recent_scans = data.recent_scans;
        });
    }

    assignToCart(kit): void {
        this.assignToCartOrLocation('cart', kit);
    }

    assignToLocation(kit): void {
        this.assignToCartOrLocation('location', kit);
    }

    assignToCartOrLocation(target, kit) {
        this.selectedKits = kit ? [kit] : _.filter(this.filteredKits, 'checked');
        this.barcodeScanService.setProcessOnly(true);
        const locationAssignDialog = this.dialog.open(AssignKitsToLocationDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                kits: this.selectedKits,
                locations: this.locations,
                carts: this.carts,
                target: target,
                showKits: true,
            },
        });

        locationAssignDialog.afterClosed().subscribe((results: LocationAssignDialogResults) => {
            this.barcodeScanService.removeListener('assignToLocation');
            this.barcodeScanService.setProcessOnly(false);

            if (results) {
                let data = {
                    results: results,
                    carts: this.carts,
                    locations: this.locations,
                    selected: this.selectedKits,
                    override: this.hasKitCartLocationOverride,
                    target: target,
                };

                let promises = this.assignKitService.assignToCartOrLocation(data);
                Promise.all(promises).then((results) => {
                    this.reloadKits();
                    if (this.updateCartAllowed) {
                        this.cartResource.cartList().then((results) => {
                            // regrab the carts list because the cart location may have changed
                            this.carts = results.carts;
                        });
                    }
                });
            }
        });
    }

    getPickLists() {
        this.selectedKits = this.filteredKits.filter((kit) => !!kit.checked);
        const nullScans = this.selectedKits.filter((kit) => kit.last_scan == null);
        let containsNullScans: boolean = false;
        if (nullScans && nullScans.length === this.selectedKits.length) {
            this.disablePickList = true;
            containsNullScans = true;
        }

        if (this.selectedKits.length === 1 && !containsNullScans) {
            this.disablePickList = false;
            this.kitResource
                .kitPrintBillingSheet(this.selectedKits[0].id, this.selectedKits[0].last_scan.id)
                .then((data) => {
                    this.pickListLoaded = true;
                    if (data.untrusted_items) {
                        this.router.navigate(['/inventory']);
                        return;
                    }
                    this.billingData = data;
                    this.deltaOnRight = this.billingData.deltaOnRight;
                    this.billingData.title = `${data.kit_master.name}: ${data.kit.physical_label}`;
                    this.billingData.itemsMissingCount = _.reduce(
                        this.billingData.missing,
                        (memo: any, segment: any) => memo + segment.missing,
                        0
                    );
                    this.billingData.hospitalLogo = this.hospitalLogo;
                    this.pickListPromise('single');
                });
        }

        if (this.selectedKits.length > 1) {
            this.disablePickList = false;
            this.pickListPromise('multi');
        }
    }

    pickListPromise(pickListType) {
        const initialData: any = _.map(this.selectedKits, 'id');
        const promise = this.kitResource.kitPrintPickLists(initialData).then((data) => {
            _.each(data.kit_master_pick_lists, (kitmaster) => {
                kitmaster.neededItemsCount = _.sum(_.map(kitmaster.formatted_segments, 'total'));
                const expiring_soon_days: any = [].concat.apply(
                    [],
                    _.map(kitmaster.formatted_segments, 'expiring_soon_days')
                );
                // iterate mapped values for kitmaster, and remove negative numbers from array
                _.each(expiring_soon_days, (day, index) => {
                    if (day < 0) {
                        expiring_soon_days.splice(index, 1);
                    }
                });
                // pick lowest expiring soon date
                kitmaster.expiring_soon_days = _.min(expiring_soon_days);
                // if min value ends up being undefinded or infinity, reset value to 0
                if (kitmaster.expiring_soon_days === Infinity || kitmaster.expiring_soon_days === undefined) {
                    kitmaster.expiring_soon_days = 0;
                }
                _.each(kitmaster.formatted_segments, (segment) => {
                    //default asterisk to false
                    segment.asterisk = false;
                    if (kitmaster.expiring_soon_days > 0 && segment.days_to_expiration) {
                        //set segment asterisk to true, if there's a value that doesn't equal daysToExpiration
                        segment.asterisk = _.find(segment.days_to_expiration, (day) => {
                            return day !== kitmaster.expiring_soon_days;
                        });
                    }
                });
            });
            this.kitData = data.kit_master_pick_lists;
            setTimeout(() => {
                this.printPickListSheet(pickListType);
            }, 100);
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    printPickListSheet(picks) {
        const printClass: string = picks === 'multi' ? 'print-multi-kit-pick-list' : 'print-pick-list';
        printPage(printClass);
    }

    assignLocks(kit) {
        const assignLockDialog = this.dialog.open(AssignLockDialog, {
            width: '640px',
            height: 'max-content',
            data: {
                item: kit,
            },
        });

        assignLockDialog.afterClosed().subscribe((result) => {
            if (result) {
                const postData = {
                    dispatch_lock: result.dispatchLock || null,
                    return_lock: result.returnLock || null,
                };
                this.kitResource.kitDispatch(kit.id, postData).then(() => {
                    const userSetManualLocks = result.dispatchLock || result.returnLock;
                    kit.hasManualLocks = userSetManualLocks;
                    kit.manual_locks = postData;
                });
            }
        });
    }

    onPageChange(pageKits) {
        this.pageKits = pageKits;
    }

    downloadPdf(current: boolean) {
        this.isDownloadingPdf = true;
        let kits = this.filteredKits.map((kit) => kit.id);

        let fileName: string;
        const filtersNotApplied = Object.keys(this.filter).reduce((acc, currentKey) => {
            let filterNotApplied = this.filter[currentKey] === -1 || this.filter[currentKey] === '';
            return acc && filterNotApplied;
        }, true);
        const filteredMessage = filtersNotApplied ? '' : 'filtered-';
        if (current) {
            kits = this.pageKits;
            fileName = `${filteredMessage}kits-current-page.pdf`;
        } else {
            fileName = `${filteredMessage}kits-all-pages.pdf`;
        }
        const downloadRequest = this.kitResource.asPdf(kits);
        downloadRequest.subscribe(
            (response) => {
                this.fileHandler.convertBufferToDownload(response, fileName, 'application/pdf');
            },
            (_) =>
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, { key: '.inventory.print_failure' }),
            () => {
                this.isDownloadingPdf = false;
            }
        );
    }

    getDownloadFilters = () => {
        return {
            kit_master_id: this.filter.masterId === -1 ? null : this.filter.masterId,
            location_id: this.filter.locationId === -1 ? null : this.filter.locationId,
            cart_id: this.filter.cartId === -1 ? null : this.filter.cartId,
            physical_label: this.filter.inventorySearch === '' ? null : this.filter.inventorySearch,
        };
    };

    sortBy(field) {
        if (this.sort.field === field) {
            this.sort.reverse = !this.sort.reverse;
        } else {
            this.sort.field = field;
            this.sort.reverse = false;
        }
        this.sortKits();
    }

    filterKits() {
        this.zone.runOutsideAngular(() => {
            this.filteredKits = this.kits
                .filter(this.kitsFilterMaster)
                .filter(this.kitsFilterLocation)
                .filter(this.kitsFilterCart)
                .filter(this.labelSearch);
            this.sortKits();
        });
        this.filterKitsNeedingArchive();
        this.selectedKits = _.filter(this.filteredKits, 'checked');
    }

    kitsFilterMaster = (kit) => this.showRow(kit, 'kit_master', 'masterId');

    kitsFilterLocation = (kit) => this.showRow(kit, 'location', 'locationId');

    kitsFilterCart = (kit) => this.showRow(kit, 'cart', 'cartId');

    searchMatch = (item, attribute) =>
        item[attribute] && item[attribute].toLowerCase().indexOf(this.filter.inventorySearch.toLowerCase()) !== -1;

    sortKits() {
        this.zone.runOutsideAngular(() => {
            let sortedKits = NaturalSortService.sort(this.filteredKits, this.sort.field);

            this.filteredKits = this.sort.reverse ? sortedKits.reverse() : sortedKits;
        });
    }

    labelSearch = (kit) => {
        if (this.searchMatch(kit, 'physical_label')) {
            return true;
        } else {
            return false;
        }
    };

    filterKitsNeedingArchive() {
        if (this.kitsNeedingArchive) {
            const oneYearAgo = moment(new Date()).subtract(12, 'months').toDate();
            this.filteredKits = this.filteredKits.filter((kit) => new Date(kit.last_scan_created_at) < oneYearAgo);
        }
    }

    reloadKits() {
        return this.loadingSpinnerService.spinnerifyPromise(this.kitResource.kitListWithParams({})).then(({ kits }) => {
            this.kits = kits;
            this.initializeKits();
        });
    }

    filteredKitsOnCurrentPage() {
        return this.filteredKits.filter((kit) => this.pageKits.includes(kit.id));
    }
}
