import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';

@Component({
    selector: 'lib-ngx-password-reset-expired',
    templateUrl: './ngx-password-reset-expired.component.html',
    styleUrls: ['./ngx-password-reset-expired.component.scss'],
})
export class NgxPasswordResetExpiredComponent implements OnInit {
    passwordSettings: any;

    constructor(
        private router: Router,
        private loginService: NgxLoginService,
        private ngxLoginEventService: NgxLoginEventService
    ) {}

    ngOnInit(): void {}

    cancelSwitch(): void {
        this.router.navigate(['/login']);
    }
}
