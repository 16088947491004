import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';

@Injectable()
export class LocationResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    locationData(locationId: number | string): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}locations/${locationId}`).toPromise();
    }

    locationWithRTLSList(params: LocationListParams): Promise<any> {
        const data = {
            include_aeroscout: String(params.includeAeroscout) || 'false',
            include_mayo_rtls: String(params.includeMayoRtls) || 'false',
            include_midmark_rtls: String(params.includeMidmarkRtls) || 'false',
        };

        return this.http.get<any>(`${this.API_ENDPOINT}locations`, { params: data }).toPromise();
    }

    locationList(): Promise<any> {
        return this.http.get<any>(`${this.API_ENDPOINT}locations`).toPromise();
    }

    addLocation(data: any): Promise<any> {
        return this.http.post<any>(`${this.API_ENDPOINT}locations`, data).toPromise();
    }

    updateLocation(data: any): Promise<any> {
        return this.http.put<any>(`${this.API_ENDPOINT}locations/${data.id}`, data).toPromise();
    }

    removeLocation(locationId: number): Promise<any> {
        return this.http.delete<any>(`${this.API_ENDPOINT}locations/${locationId}`).toPromise();
    }
}
interface LocationListParams {
    includeAeroscout?: boolean; // Specify types explicitly for better type safety
    includeMayoRtls?: boolean;
    includeMidmarkRtls?: boolean;
}
