import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { BinResource } from '@resources/bin-resource.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CurrentBinResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private binResource: BinResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const binId = parseInt(activatedRoute.params.binId);
        return this.loadingSpinnerService.spinnerifyPromise(this.binResource.showBinScan(binId, 'current'));
    }
}
