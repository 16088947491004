import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MobileCartService } from '@services/utils/mobile-cart.service';
import { TranslationService } from '@services/utils/translation.service';
import { DispatchKitMobileDialog } from '@dialogs/dispatch-kit-mobile/dispatch-kit-mobile';
import { KitSummary, ExpirationDate } from '@models/core/kit-summary';
import { disallowDispatchIncompleteKit, getSoonestExpirations } from '@utils/kit-summary-util';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'mobile-cart-scan-summary',
    templateUrl: './scan-summary.html',
    styleUrls: ['./scan-summary.scss'],
})
export class MobileCartScanSummaryComponent implements OnInit {
    disableDispatchButton: boolean;

    kitSummary: KitSummary;

    public soonestExpirations: ExpirationDate;

    constructor(
        private dialog: MatDialog,
        private mobileCartService: MobileCartService,
        private snackBar: MatSnackBar,
        private translationService: TranslationService,
        private activatedRouter: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRouter.data.subscribe((data) => {
            this.kitSummary = data.kitSummary;
            this.disableDispatchButton = disallowDispatchIncompleteKit(this.kitSummary);
            this.mobileCartService.showScanSummaryHeader(this.kitSummary);
            this.soonestExpirations = getSoonestExpirations(this.kitSummary);
        });
    }

    ngOnDestroy() {
        this.dialog.closeAll();
    }

    dispatchKit() {
        const dispatchDialog = this.dialog.open(DispatchKitMobileDialog, {
            width: 'max-content',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                kitSummary: this.kitSummary,
            },
        });

        dispatchDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.disableDispatchButton = true;
                this.snackBar.open(
                    this.translationService.instant('modals.kit.mobile.success_dispatch'),
                    this.translationService.instant('buttons.dismiss'),
                    { duration: 5000 }
                );
            }
        });
    }
}
