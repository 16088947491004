import { Injectable } from '@angular/core';

@Injectable()
export class FormularyItemDataService {
    private tagItemInfo: any;
    private creatingFormularyItem: boolean;

    getTagItemInfo() {
        return this.tagItemInfo;
    }

    setTagItemInfo(tagItemInfo: any) {
        this.tagItemInfo = tagItemInfo;
    }

    getCreatingNewFormulary() {
        return this.creatingFormularyItem;
    }

    setCreatingNewFormulary(creating: boolean) {
        this.creatingFormularyItem = creating;
    }
}
