import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ReportService } from '@services/report/report.service';
import { Report, ReportCategory } from '@models/core/report';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    DEFAULT_SHOW_FIRST_LAST_BUTTONS,
} from '@models/common/report-pagination';

import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

interface BatchDetailData {
    first_name: string;
    last_name: string;
    full_name: string;
    created_at_hospital: string;
    created_at_hospital_id: number;
    created_at: string;
    created_at_formatted: string;
    on_behalf_of_hospital_id: number;
    on_behalf_of_hospital_name: string;
    quantity_printed: number;
    formulary_item_id: number;
    lot_num: string;
    ndc: string;
    expiration: string;
    manufacturer_name: string;
    item_name: string;
    item_strength: string;
    item_strength_uom: string;
    tag_association_batch_id: number;
    package_description: string;
    package_size: string;
    package_size_uom: string;
}

@Component({
    selector: 'item-batch-by-hospital',
    templateUrl: './report-item-batch-by-hospital.html',
    styleUrls: ['./report-item-batch-by-hospital.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportItemBatchByHospital {
    protected DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
    protected DEFAULT_PAGE_SIZE_OPTIONS = DEFAULT_PAGE_SIZE_OPTIONS;
    protected DEFAULT_SHOW_FIRST_LAST_BUTTONS = DEFAULT_SHOW_FIRST_LAST_BUTTONS;

    reports: {
        report_categories: Array<ReportCategory>;
    };
    report;

    reportDate: Date;
    filters: {
        start_date: string;
        end_date: string;
    };
    hasRun: boolean = false;
    hasData: boolean = false;
    hospitalBatches;
    expandedElement: any; // used to track the current expanded row

    isDownloadable: boolean = true;
    isLoading: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    hospitalColumns: string[] = ['expander', 'on_behalf_of_hospital_name', 'total_qty'];
    batchDetailColumns: string[] = ['created_at', 'full_name', 'item_name', 'ndc', 'quantity_printed'];
    @ViewChild('outerSort') sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<BatchDetailData>>;

    constructor(
        protected reportService: ReportService,
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private productModuleService: ProductModuleService,
        private activatedRoute: ActivatedRoute
    ) {
        this.reportDate = new Date();
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
            this.report = data.report.report;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);
    }

    refreshReport(event: any): Promise<void> {
        this.filters = {
            start_date: event.date.start_date,
            end_date: event.date.end_date,
        };
        this.isLoading = true;

        return this.reportResource.itemBatchByHospitalReport(this.filters).then((data: any): void => {
            this.hospitalBatches = data.batch_summaries;

            this.hospitalBatches.forEach((hospital, i) => {
                hospital.id = i;
            });

            this.dataSource = new MatTableDataSourceWithNaturalSort(this.hospitalBatches);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.reportDate = new Date();
            this.isLoading = false;
            this.hasRun = true;
            this.hasData = this.hospitalBatches.length > 0;
        });
    }

    toggleRow(row): void {
        // fix up the package and strength strings for display/sorting
        row.batches.forEach((item) => {
            item.full_name = `${item.first_name} ${item.last_name}`.trim();
            item.expiration_formatted = moment(item.expiration).format('MMM DD, YYYY');
        });

        // only create the datasource for the inner table on toggle for performance reasons
        this.dataSource.data[row.id].batch_detail = new MatTableDataSourceWithNaturalSort(row.batches);
        this.expandedElement = this.expandedElement === row ? null : row;
        this.changeDetectorRef.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSourceWithNaturalSort<BatchDetailData>).sort =
                    this.innerSort.toArray()[index])
        );
    }
}
