import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportingResource } from '@resources/reporting-resource.service';
import { BenchmarkResource } from '@resources/benchmark-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { OrdinalPipe } from '@pipes/ordinal.pipe';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';
import * as $ from 'jquery';
import * as _ from 'lodash';

export interface BenchmarkingTile {
    data: {
        benchmark: {
            description: string;
            values: {
                maximum: number;
                mean: number;
                median: number;
                minimum: number;
            };
        };
        reference: {
            description: string;
            values: {
                total: number;
                percentile: number;
            };
        };
    };
    description: string;
    id: number;
    name: string;
    tile_group_id: number;
}

@Component({
    selector: 'benchmarking',
    templateUrl: './benchmarking.html',
    styleUrls: ['./benchmarking.scss'],
})
export class Benchmarking {
    //bindings
    loading: boolean = true;
    loadingMessage: string = undefined;

    tiles: any[];
    bedCounts: {
        lower: number;
        upper: number;
        value: number;
    };

    chartsInitialized: boolean = false;
    chartHeight: number = 265;
    chartWidth: number = 135;
    padding: number = 10;
    circleRadius: number = 13;
    medianWidth: number = this.circleRadius * 2;
    medianHeight: number = 10;
    axisHeight: number = 3;
    axisFontHeight: number = 11;
    valueFontHeight: number = 14;
    currentState: string;

    bedMin: number;
    bedMax: number;

    benchmarkTranslateParams: {
        bedCountLower: string | number;
        bedCountUpper: string | number;
    };

    constructor(
        private route: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        protected translationService: TranslationService,
        protected reportingResource: ReportingResource,
        protected benchmarkResource: BenchmarkResource
    ) {}

    ngOnInit() {
        this.route.url.subscribe((urlSegments) => (this.currentState = urlSegments[0].path));
        this.translationService.get('benchmarking.loading').then((translation) => {
            this.loadingMessage = translation;
        });

        this.loadBedCounts()
            .then((bedCountData) => {
                this.bedCounts = bedCountData;
                this.benchmarkTranslateParams = {
                    bedCountLower: this.bedCounts.lower || '',
                    bedCountUpper: this.bedCounts.upper || '',
                };

                return this.loadBenchmarkingTileData();
            })
            .then((tiles: BenchmarkingTile[]) => {
                this.loading = false;
                setTimeout(() => {
                    if (this.currentState === 'reports' && !this.showSpinner()) {
                        tiles.forEach((tile) => {
                            if (this.verifyTileData(tile)) {
                                this.createChart(tile);
                            }
                        });
                    }
                });
            })
            .catch((error) => {
                console.log('Could not get bed counts $(error)');
            });
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    addExtras(shape: any, data: KC.ISVGShape, tip?: any): any {
        if (data.class) {
            shape.classed(data.class, true);
        }

        if (data.clipPath) {
            shape.attr('clip-path', `url(#${data.clipPath})`);
        }

        if (tip) {
            shape.on('mouseout', tip.hide);
            shape.on('mouseover', tip.show);
        }

        return shape;
    }

    addRect(container: any, data: KC.ISVGRect, tip?: any): any {
        const rect: any = container
            .append('rect')
            .classed(data.class, true)
            .attr('x', data.x)
            .attr('y', data.y)
            .attr('width', data.width)
            .attr('height', data.height);

        return this.addExtras(rect, data, tip);
    }

    addCircle(container: any, data: KC.ISVGCircle, tip?: any): any {
        const circle: any = container.append('circle').attr('cx', data.x).attr('cy', data.y).attr('r', data.radius);

        return this.addExtras(circle, data, tip);
    }

    addText(container: any, data: KC.ISVGText, tip?: any): any {
        const text: any = container
            .append('text')
            .classed(data.class, true)
            .attr('x', data.x)
            .attr('y', data.y)
            .text(data.text);

        return this.addExtras(text, data, tip);
    }

    createChart(tileData: BenchmarkingTile) {
        const benchmarkingContainer: any = d3
            .select('#benchmarking-charts')
            .append('div')
            .classed('benchmarking-chart', true)
            .attr('id', `benchmarking-${tileData.id}`);

        const titleContainer: any = benchmarkingContainer.append('div').classed('benchmarking-title-outer', true);

        titleContainer.append('span').classed('benchmarking-title', true).text(tileData.name);

        const svgContainer: any = benchmarkingContainer
            .append('svg')
            .attr('width', this.chartWidth)
            .attr('height', this.chartHeight);

        const label: string = tileData.data.reference.description;

        const min: number = tileData.data.benchmark.values.minimum;
        const max: number = tileData.data.benchmark.values.maximum;
        const median: number = tileData.data.benchmark.values.median;
        const hospitalValue: number = tileData.data.reference.values.total;

        const linearScale: any = d3
            .scaleLinear()
            .domain([min, max])
            .range([this.chartHeight - this.padding, this.padding]);

        // Draw Axes
        const topAxisY: number = linearScale(max);

        const axisTextX: number = this.chartWidth - this.padding;
        const topAxisTextY: number = topAxisY + this.axisFontHeight / 2;

        const topAxisText = {
            x: axisTextX,
            y: topAxisTextY,
            text: String(max),
            class: 'axis-text',
        };

        const topAxisTextShape = this.addText(svgContainer, topAxisText);

        const bottomAxisY: number = linearScale(min) - this.axisHeight;
        const bottomAxisTextY: number = bottomAxisY + this.axisFontHeight / 2;

        const bottomAxisText = {
            x: axisTextX,
            y: bottomAxisTextY,
            text: String(min),
            class: 'axis-text',
        };

        const bottomAxisTextShape = this.addText(svgContainer, bottomAxisText);

        const topAxisTextBBox = topAxisTextShape.node().getBBox();
        const topAxisWidth = this.chartWidth - this.padding * 3 - topAxisTextBBox.width;

        const topAxis = {
            x: this.padding,
            y: topAxisY,
            width: topAxisWidth,
            height: this.axisHeight,
            class: 'axis',
        };

        this.addRect(svgContainer, topAxis);

        const bottomAxisTextBBox = bottomAxisTextShape.node().getBBox();
        const bottomAxisWidth = this.chartWidth - this.padding * 3 - bottomAxisTextBBox.width;

        const bottomAxis = {
            x: this.padding,
            y: bottomAxisY,
            width: bottomAxisWidth,
            height: this.axisHeight,
            class: 'axis',
        };

        this.addRect(svgContainer, bottomAxis);

        const scaledHospital: number = linearScale(hospitalValue);
        const scaledMedian: number = linearScale(median);

        const medianY: number = Math.min(scaledMedian - this.medianHeight / 2, bottomAxisY - this.medianHeight);
        let circleY: number = scaledHospital;

        circleY = Math.min(circleY - this.circleRadius, bottomAxisY - this.circleRadius * 2) + this.circleRadius;
        circleY =
            Math.max(circleY + this.circleRadius, topAxisY + this.axisHeight + this.circleRadius * 2) -
            this.circleRadius;

        // Draw clip path for masking circle and gap
        const defs = svgContainer.append('defs');
        const clipPath = defs.append('clipPath').attr('id', `cut-off-${tileData.id}`);

        const clipX: number = this.padding;
        const clipY: number = scaledHospital > scaledMedian ? medianY : 0;
        const clipWidth: number = this.medianWidth;
        const clipHeight: number = scaledHospital > scaledMedian ? this.chartHeight - clipY : medianY;

        const clipRect = {
            x: clipX,
            y: clipY,
            width: clipWidth,
            height: clipHeight,
        };

        this.addRect(clipPath, clipRect);

        // Draw gap bar between hospital value and median
        const gapY: number = Math.min(circleY, medianY);
        const gapHeight: number = Math.abs(circleY - medianY);

        const gapRect = {
            x: this.padding,
            y: gapY,
            width: this.circleRadius * 2,
            height: gapHeight,
            clipPath: `cut-off-${tileData.id}`,
            class: 'gap-val',
        };

        this.addRect(svgContainer, gapRect);

        // Draw circle representing hospital's value
        const circleX: number = this.circleRadius + this.padding;
        const hospitalPercentile: string = new OrdinalPipe().transform(
            Math.round(tileData.data.reference.values.percentile)
        );
        const labelSplit: string[] = label.split('For Your Hospital');
        const forYourHospital: string = !_.isUndefined(labelSplit[1])
            ? `</div><div>For Your Hospital${labelSplit[1]}`
            : '';
        const hospitalTipTemplate: string = `<div>${labelSplit[0]}${forYourHospital}: ${hospitalValue}</div><div>${hospitalPercentile} percentile</div>`;
        const hospitalTip = d3tip()
            .attr('class', 'd3-tip')
            .html(() => hospitalTipTemplate)
            .offset([-8, 0]);

        svgContainer.call(hospitalTip);

        const hospitalCircle = {
            x: circleX,
            y: circleY,
            radius: this.circleRadius,
            clipPath: `cut-off-${tileData.id}`,
            class: 'hospital-val',
        };

        this.addCircle(svgContainer, hospitalCircle, hospitalTip);

        // Draw bar for the median value
        const medianRect = {
            x: this.padding,
            y: medianY,
            width: this.medianWidth,
            height: this.medianHeight,
            class: 'median-val',
        };

        this.addRect(svgContainer, medianRect);

        // Calculate and make adjustments for the placement of the median and hospital value texts
        const valueTextX: number = this.padding * 2 + this.circleRadius * 2;
        const hospitalValueTextX: number = valueTextX;

        let medianTextY: number = medianY + this.medianHeight / 2 + this.valueFontHeight / 2;
        medianTextY = Math.min(medianTextY, bottomAxisY - 1);
        medianTextY = Math.max(medianTextY, topAxisY + this.axisHeight);

        const medianTextTop: number = medianTextY - this.valueFontHeight;
        const medianTextBottom: number = medianTextY;

        let hospitalValueTextY: number = circleY + this.valueFontHeight / 2;

        hospitalValueTextY = Math.min(hospitalValueTextY, bottomAxisY - 1);
        hospitalValueTextY = Math.max(hospitalValueTextY, topAxisY + this.axisHeight);

        const hospitalValueTop: number = hospitalValueTextY - this.valueFontHeight;
        const hospitalValueBottom: number = hospitalValueTextY;

        if (scaledHospital > this.chartHeight / 2) {
            if (scaledMedian >= scaledHospital) {
                if (
                    (hospitalValueBottom >= medianTextTop && hospitalValueBottom < medianTextBottom) ||
                    (hospitalValueTop >= medianTextTop && hospitalValueTop < medianTextBottom)
                ) {
                    hospitalValueTextY = medianTextTop - this.padding / 2;
                }
            } else {
                if (
                    (medianTextBottom >= hospitalValueTop && medianTextBottom < hospitalValueBottom) ||
                    (medianTextTop >= hospitalValueTop && medianTextTop < hospitalValueBottom)
                ) {
                    medianTextY = hospitalValueTop - this.padding / 2;
                }
            }
        } else {
            if (scaledMedian >= scaledHospital) {
                if (
                    (hospitalValueBottom >= medianTextTop && hospitalValueBottom < medianTextBottom) ||
                    (hospitalValueTop >= medianTextTop && hospitalValueTop < medianTextBottom)
                ) {
                    medianTextY = hospitalValueBottom + this.padding / 2;
                }
            } else {
                if (
                    (medianTextBottom >= hospitalValueTop && medianTextBottom < hospitalValueBottom) ||
                    (medianTextTop >= hospitalValueTop && medianTextTop < hospitalValueBottom)
                ) {
                    medianTextY = medianTextBottom + this.padding / 2;
                }
            }
        }

        // Draw the median and hospital value texts
        const hospitalText = {
            x: hospitalValueTextX,
            y: hospitalValueTextY,
            text: String(Math.round(hospitalValue * 100) / 100),
            class: 'value-text hospital-value',
        };

        this.addText(svgContainer, hospitalText);

        const medianText = {
            x: valueTextX,
            y: medianTextY,
            text: String(Math.round(median * 100) / 100),
            class: 'value-text',
        };

        this.addText(svgContainer, medianText);
    }

    verifyTileData(tileData: BenchmarkingTile): boolean {
        return (
            !_.isNull(tileData.data.benchmark.values.maximum) &&
            !_.isNull(tileData.data.benchmark.values.minimum) &&
            !_.isNull(tileData.data.benchmark.values.median) &&
            !_.isNull(tileData.data.reference.values.total)
        );
    }

    loadBedCounts() {
        return this.reportingResource
            .bedCounts()
            .then(({ bed_count }) => bed_count)
            .catch((error) => {
                console.log('Could not get bed counts $(error)');
            });
    }

    loadBenchmarkingTileData() {
        let tileGroups;
        return this.benchmarkResource
            .tileGroups()
            .then(({ tile_groups }) => {
                tileGroups = tile_groups;
                return this.benchmarkResource.getLocations();
            })
            .then((locations) => {
                let homepageLocation: any = locations.tile_locations.find(
                    (tile) => tile.location === 'benchmark_homepage'
                );
                let tileRequests;
                tileGroups.forEach((tile_group) => {
                    if (tile_group.location_id === homepageLocation.id) {
                        let tileIds = tile_group.tile_ids;
                        tileRequests = tileIds.map((tileId) => {
                            return this.benchmarkResource.tile(tileId);
                        });
                    }
                });
                return Promise.all(tileRequests);
            })
            .then((tileData: any) => {
                tileData = tileData.map((tile: any) => tile.tile);
                return tileData;
            });
    }
}
