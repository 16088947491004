import { Component, ViewChild } from '@angular/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

//require('./report-hospital-activity.scss');
@Component({
    selector: 'report-hospital-activity',
    templateUrl: './report-hospital-activity.html',
})
export class ReportHospitalActivity {
    reports: {
        report_categories: ReportCategory[];
    };

    report: Report;
    isDownloadable: boolean = true;
    filters: {
        start_date: string;
        end_date: string;
    };
    hasData: boolean;
    reportDate: Date;
    customDates: {
        start_date: Date;
        end_date: Date;
    };

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['date', 'scans', 'kits', 'items'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.reportDate = new Date();

        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'activity'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'hospital_activity');

        this.customDates = {
            start_date: moment(new Date()).subtract('months', 1).startOf('month').toDate(),
            end_date: moment(new Date()).subtract('months', 1).endOf('month').toDate(),
        };
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(event: any): void {
        this.filters = {
            start_date: event.date.start_date,
            end_date: event.date.end_date,
        };

        const promise = this.reportResource.hospitalActivityReport(this.filters).then((rawReportData) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            start_date: event.date.start_date,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: 'max-content',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
