import { Component, ViewChild } from '@angular/core';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'report-billing',
    templateUrl: './report-billing.html',
    styleUrls: ['./report-billing.scss'],
})
export class ReportBilling {
    reports: {
        report_categories: ReportCategory[];
    };

    report: Report;
    isDownloadable: boolean = true;
    filters: {
        start_date: string;
        end_date: string;
        kit_master_id?: number;
    };
    reportDate: Date;
    scheduledReportsEnabled: boolean;
    hasData: boolean;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'kit_master_name',
        'segment_name',
        'facility',
        'billing_code',
        'ndc_upc_hri_full',
        'quantity',
    ];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private hospitalInfoService: HospitalInfoService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.reportDate = new Date();

        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'item_attribute'
        );
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;

        this.report = reportCategory.reports.find((report) => report.name === 'aggregate_billing');
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(event: any): void {
        this.filters = {
            start_date: event.date.start_date,
            end_date: event.date.end_date,
        };

        if (!!event.kitMaster) {
            this.filters.kit_master_id = event.kitMaster;
        }

        const promise = this.reportResource.aggregateBilling(this.filters).then((rawReportData) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            start_date: event.date.start_date,
            kitMaster: event.kitMaster,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
