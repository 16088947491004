import { Injectable } from '@angular/core';
import { ScanType } from '@components/tags/tagging';

@Injectable()
export class TaggingDataService {
    private barcodeObject: any = null;
    private scanType: ScanType = null;
    private tagItemInfo: any;

    getTagItemInfo() {
        return this.tagItemInfo;
    }

    setTagItemInfo(tagItemInfo: any) {
        this.tagItemInfo = tagItemInfo;
    }

    getBarcodeObject() {
        return this.barcodeObject;
    }

    setBarcodeObject(barcodeObject: any) {
        this.barcodeObject = barcodeObject;
    }

    setScanType(obj: ScanType) {
        this.scanType = obj;
    }

    getScanType() {
        return this.scanType;
    }
}
