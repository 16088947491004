import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationService } from '@services/system/application.service';
import { ClinicalEquivalenceResource } from '@resources/clinical-equivalence-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

import { MatDialog } from '@angular/material/dialog';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';

import { Subscription } from '@models/core/subscription';

@Component({
    selector: 'manage-subscriptions',
    templateUrl: './manage-subscriptions.html',
    styleUrls: ['./manage-subscriptions.scss'],
})
export class ManageSubscriptions {
    subscriptions: Subscription[];

    translation: {
        message: string;
        type: string;
    };
    kitActivityList: Array<any>;
    kits: Array<any>;
    kitMasters: Array<any>;
    subheaderTitle: string;

    constructor(
        private applicationService: ApplicationService,
        private clinicalEquivalenceResource: ClinicalEquivalenceResource,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        private reportSubscriptionResource: ReportSubscriptionResource,
        private translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.subscriptions = data.subscriptions;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);
        this.subheaderTitle = this.applicationService.subheaderTitle;
        this.subscriptions = this.subscriptions.sort((a, b) => (b.id > a.id ? 1 : -1));
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    showSubscription(id: number): void {
        const sub: any = this.subscriptions.find((subscription) => subscription.id === id);
        let schedule: any = {
            name: sub.name,
            send_if_empty: sub.send_if_empty,
            frequency: sub.schedule.type,
            on_the: sub.schedule.days[0].id,
            in_the: sub.schedule.delivery_window,
        };

        if (sub.timeframe.unit) {
            schedule.timeframe = sub.timeframe;
        }

        let promises = [];
        // should probably move this inside the dialog since we get it for every subscription.
        promises.push(this.reportSubscriptionResource.frequenciesList());

        if (sub.parameters.clinical_equivalence_id) {
            let equivalencePromise = this.clinicalEquivalenceResource
                .clinicalEquivalence(sub.parameters.clinical_equivalence_id)
                .then((data) => {
                    let clinicalEquivalences = data.clinical_equivalences;
                    let clinicalEquivalence = clinicalEquivalences.find(
                        (generic) => generic.id === sub.parameters.clinical_equivalence_id
                    );
                    sub.parameters.clinical_equivalence = {
                        clinical_equivalence_id: clinicalEquivalence.id,
                        generic_item_name: clinicalEquivalence.item_name,
                        item_strength_formatted: `${clinicalEquivalence.item_strength_scalar}`,
                        item_strength_uom: clinicalEquivalence.item_strength_uom,
                        generic_selected: true,
                    };
                });

            promises.push(equivalencePromise);
        }

        Promise.all(promises).then((response) => {
            this.subscribeModal(sub, response[0].frequencies, this.kits, this.kitMasters, this.kitActivityList);
        });
    }

    subscribeModal(sub, frequencies, kits, kitMasters, kitActivityList): void {
        const subDialog = this.dialog.open(ReportSubscriptionDialog, {
            height: 'max-content',
            autoFocus: false,
            data: {
                reportName: sub.report_name,
                filterData: {},
                apiSubscriptionData: sub.parameters,
                frequencies: frequencies,
                subscription: sub,
                kits: kits,
                kitMasters: kitMasters,
                kitActivityList: kitActivityList,
            },
        });

        subDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                // needs a delay because reload would wipe it.
                setTimeout(() => {
                    this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                        key: `report_subscriptions.success.${confirmed}`,
                    });
                }, 500);
                this.router.navigate(['/manage-subscriptions']);
            }
        });
    }
}
