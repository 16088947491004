import { Injectable } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { RecallResource } from '@resources/recall-resource.service';

@Injectable({ providedIn: 'root' })
export class RecallsResolver {
    constructor(
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        private recallResource: RecallResource
    ) {}

    resolve(_, state: RouterStateSnapshot): Promise<any> {
        const getCounts = state.url.includes('/admin/recalls');

        let recallsPromise;
        if (this.actionService.isAllowAction('administration', 'view_recalls', 'Load recalls network list data')) {
            recallsPromise = this.recallResource.recallsList(getCounts).then((data) => {
                return data.recalls;
            });
        } else {
            recallsPromise = Promise.resolve([]);
        }
        return this.loadingSpinnerService.spinnerifyPromise(recallsPromise);
    }
}
