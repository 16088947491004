import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ActionService } from '@services/utils/action.service';
import { BarcodeScanService } from '@services/core/barcode-scan.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

import { BarcodeResource } from '@resources/barcode-resource.service';
import { CartResource } from '@resources/cart-resource.service';

import { AssignCartToLocationDialog } from '@dialogs/assign-cart-to-location/assign-cart-to-location-dialog';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { MatDialog } from '@angular/material/dialog';

import { NaturalSortService, SortDirection } from '@services/utils/natural-sort.service';
import { TableComponent } from '@components/common/table-component';

import { KitMaster } from '@models/core/kit-master';
import { Location } from '@models/core/location';
import { AssignLockDialog } from '@components/dialogs/assign-lock/assign-lock-dialog';
import { TranslationService } from '@services/utils/translation.service';

interface Filter {
    selectedKitMasters: Array<any>;
    selectedCarts: Array<any>;
    emptyCarts: boolean;
    locations: Array<any>;
    noLocation: boolean;
    expiration: number | null;
    report_mode?: string;
}

interface SubscribeFilter {
    cartKitMasters?: {
        includeEmpty: boolean;
        kitMasters: KitMaster[];
        report_mode?: string;
    };
    cartLocations?: {
        includeEmpty: boolean;
        locations: Location[];
    };
    expiration?: {
        daysExpiringIn: number;
        includeExpired: boolean;
    };
}

const CARTS_INDEX_BARCODE_LISTENER = 'cartsIndex';

@Component({
    selector: 'carts-index',
    templateUrl: './carts-index.html',
    styleUrls: ['./carts-index.scss'],
})
export class CartsIndex extends TableComponent {
    // Bindings from UI-Router Resolvers
    protected carts: KC.ICart[];
    protected locations: KC.ILocation[];
    protected kitMasters;

    //Member Vars
    canReplaceTag: boolean;
    scheduledReportsEnabled: boolean;
    aeroscoutEnabled: boolean;
    ui: any;
    filterOptionsKitMasters: Array<any> = [];
    filterOptionsLocations: Array<any> = [];
    filterOptionsCarts: Array<any> = [];
    hasLocation: boolean;
    multiselectTranslations: any;
    minControl;
    assignLocksToCartsEnabled: boolean;

    dropdownSettingsKitMasters = {
        singleSelection: false,
        text: 'Select Kit Masters',
        selectAllText: 'Select Shown Kit Masters',
        unSelectAllText: 'Clear All Kit Masters',
        enableSearchFilter: true,
        classes: 'kit-master-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Kit Masters Selected',
        objectTypeName: 'Kit Master',
    };

    dropdownSettingsCarts = {
        singleSelection: false,
        text: 'Select Carts',
        selectAllText: 'Select Shown Carts',
        unSelectAllText: 'Clear Carts',
        enableSearchFilter: true,
        classes: 'cart-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Carts Selected',
        objectTypeName: 'Cart',
    };

    dropdownSettingsLocations = {
        singleSelection: false,
        text: 'Select Locations',
        selectAllText: 'Select Shown Locations',
        unSelectAllText: 'Clear All Locations',
        enableSearchFilter: true,
        classes: 'kit-master-select',
        labelKey: 'name',
        maxHeight: 250,
        badgeShowLimit: 3,
        selectAllFiltered: true,
        searchPlaceholderText: 'All Locations Selected',
        objectTypeName: 'Location',
    };

    filter: Filter = {
        selectedKitMasters: [],
        selectedCarts: [],
        emptyCarts: true,
        locations: [],
        noLocation: true,
        expiration: null,
    };
    downloadFilters: any;
    report: any;

    constructor(
        protected actionService: ActionService,
        protected cartResource: CartResource,
        protected barcodeResource: BarcodeResource,
        protected barcodeScanService: BarcodeScanService,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private reportSubscriptionResource: ReportSubscriptionResource,
        private dialog: MatDialog,
        private productModuleService: ProductModuleService,
        private translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super();
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
        this.assignLocksToCartsEnabled = this.hospitalInfoService.getHospitalSettings().assign_locks_to_carts;
        const url = '/reports/carts';
        this.report = { url: url, name: 'carts' };
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.carts = data.carts;
            this.locations = data.locations;
            this.kitMasters = data.kitMasters;
        });

        // Resolver bindings are available in ngOnInit
        this.defaultSort = { field: 'name', direction: SortDirection.asc }; // Set superclass sort.
        this.minControl = new FormControl('', Validators.min(0));
        this.hasLocation = this.hospitalInfoService.hospitalSetting('kit_to_location_enabled') || false;
        this.filterOptionsKitMasters = this.kitMasters?.map((kitMaster) => kitMaster);
        this.filterOptionsKitMasters = NaturalSortService.sort(this.filterOptionsKitMasters, 'name');
        this.filterOptionsLocations = this.locations.map((location) => location);
        this.filterOptionsLocations = NaturalSortService.sort(this.filterOptionsLocations, 'name');
        this.filterOptionsCarts = this.carts.map((cart) => cart);
        this.filterOptionsCarts = NaturalSortService.sort(this.filterOptionsCarts, 'name');
        this.clearFilters(); // Clear fileter and sort carts by default field/direction.
        this.filter.selectedKitMasters = this.filterOptionsKitMasters.map((option) => option);
        this.filter.locations = this.filterOptionsLocations.map((option) => option);
        this.filter.selectedCarts = this.filterOptionsCarts.map((option) => option);
        this.aeroscoutEnabled = this.hospitalInfoService.getHospitalSettings().aeroscout_enabled;

        // This here code copies location_name up one level so the table can be sorted by it
        this.items.forEach((cart) => {
            if (cart.location) {
                cart.location_name = cart.location.name;
            }
        });

        this.barcodeScanService.registerListener((scanData) => {
            this.barcodeResource.barcodeObject(scanData).then((data) => {
                if (data && data.object) {
                    if (data.object['class'] === 'Cart') {
                        const cartId = String(data.object.id);
                        this.router.navigate([`/inventory/cart/${cartId}`]);
                    }
                }
            });
        }, CARTS_INDEX_BARCODE_LISTENER);
    }

    ngOnDestroy() {
        this.removeBarcodeListeners();
    }

    removeBarcodeListeners() {
        this.barcodeScanService.removeListener(CARTS_INDEX_BARCODE_LISTENER);
    }

    fireAnalytics = (type: string) => {
        if (type === 'kitMasters') {
            let selected: number = this.filter['selectedKitMasters'].length;
            let total: number = this.kitMasters.length;
        } else if (type === 'locations') {
            let selected: number = this.filter['locations'].length;
            let total: number = this.locations.length;
        } else if (type === 'cart') {
            let selected: number = this.filter['selectedCarts'].length;
            let total: number = this.carts.length;
        }
    };

    filterByAll = (cart: any): boolean => {
        const { selectedCarts, emptyCarts, selectedKitMasters, noLocation, locations, expiration } = this.filter;

        let showCart: boolean = false;

        // Filter by selected carts
        if (selectedCarts.length > 0) {
            if (
                (!emptyCarts && cart.kit_master_ids.length === 0) ||
                !selectedCarts.some((selectedCart) => selectedCart.id === cart.id)
            ) {
                return false;
            } else {
                showCart = true;
            }
        } else {
            // no car is selected
            return false;
        }

        // Filter by selected kit masters
        if (cart.kit_master_ids.length > 0) {
            if (selectedKitMasters.length === this.filterOptionsKitMasters.length) {
                /* all available kit masters are selected, so show the cart. this case is distinct from checking whether
                 * the filter has the kit master id selected because the cart may contain kits whose kit master belongs
                 * to another hospital. in that case, the kit master won't be in the kit master filter dropdown and we'd
                 * never display the cart to the user */
                showCart = true;
            } else if (selectedKitMasters.length > 0) {
                if (selectedKitMasters.some((km) => cart.kit_master_ids.includes(km.id))) {
                    showCart = true;
                } else {
                    return false;
                }
            }
        } else if (emptyCarts) {
            showCart = true;
        }

        // Filter by locations
        if (noLocation) {
            if (!cart.location || locations.some((location) => location.id === cart.location.id)) {
                showCart = true;
            } else {
                return false;
            }
        } else if (locations) {
            if (cart.location && locations.some((location) => location.id === cart.location.id)) {
                showCart = true;
            } else {
                return false;
            }
        }

        // Filter by expiration
        if (!this.minControl.errors) {
            if (!Number.isInteger(expiration)) {
                showCart = true;
            } else {
                if (!cart.first_expiring_date) {
                    showCart = false;
                } else {
                    const cartExpiration = moment(new Date(cart.first_expiring_date));
                    const filterDay = moment(new Date()).add('days', expiration);
                    showCart = cartExpiration < filterDay;
                }
            }
        }

        return showCart;
    };

    clearFilters(): void {
        this.filter = {
            selectedKitMasters: [],
            selectedCarts: [],
            emptyCarts: true,
            locations: [],
            noLocation: true,
            expiration: null,
        };
        this.filter.selectedKitMasters = this.filterOptionsKitMasters.map((option) => option);
        this.filter.selectedCarts = this.filterOptionsCarts.map((option) => option);
        this.filter.locations = this.filterOptionsLocations.map((option) => option);
        this.filterCarts('none');
    }

    filterCarts(type: string): void {
        this.items = this.carts.filter(this.filterByAll);
        this.downloadFilters = this.getDownloadFilters();
        this.fireAnalytics(type);
    }

    getDownloadFilters(): any {
        let downloadFilters = {
            include_empty: this.filter.emptyCarts,
            include_carts_with_no_location: this.filter.noLocation,
        };
        // if all kit masters are selected, don't bother sending the parameter (it's too long and isn't properly
        // subscribable):
        if (this.filter.selectedKitMasters.length !== this.filterOptionsKitMasters.length) {
            downloadFilters['kit_masters'] = this.filter.selectedKitMasters.map((kitMaster) => kitMaster.id).join('|');
        }
        // if all locations are selected, don't bother sending the parameter (for the same reason as kit masters)
        if (this.filter.locations.length !== this.filterOptionsLocations.length) {
            downloadFilters['locations'] = this.filter.locations.map((location) => location.id).join('|');
        }
        if (this.filter.selectedCarts.length !== this.filterOptionsCarts.length) {
            downloadFilters['carts'] = this.filter.selectedCarts.map((cart) => cart.id).join('|');
        }
        // if expiration days is emapy, omit it.
        if (!Number.isInteger(this.filter.expiration)) {
            delete this.filter.expiration;
        }
        return downloadFilters;
    }

    sortCarts(field: string): void {
        this.sortBy(field, this.items);
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    subscribeModal(): void {
        let subscribeFilter: SubscribeFilter = {};

        subscribeFilter.cartKitMasters = {
            includeEmpty: this.filter.emptyCarts,
            kitMasters: this.filter.selectedKitMasters,
        };

        if (this.filter.selectedKitMasters.length !== this.filterOptionsKitMasters.length) {
            subscribeFilter.cartKitMasters.report_mode = 'kit'; // If 'kit_masters' "All" is selected, omit report_mode param to signifiy "All" vs specific kit list.
        }

        subscribeFilter.cartLocations = {
            locations: this.filter.locations,
            includeEmpty: this.filter.noLocation,
        };

        subscribeFilter.expiration = {
            includeExpired: false,
            daysExpiringIn: null,
        };

        if (Number.isInteger(this.filter.expiration)) {
            subscribeFilter.expiration.daysExpiringIn = this.filter.expiration;
        }

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '1020px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilter,
                    frequencies: frequencies,
                },
            });
        });
    }

    setCartLock(cart) {
        const cartLockDialog = this.dialog.open(AssignLockDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                item: cart,
                editDispatchLockText: this.translationService.instant('modals.cart.edit_dispatch_cart_locks'),
                editReturnLockText: this.translationService.instant('modals.cart.edit_return_cart_locks'),
            },
        });

        cartLockDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            const manualLocks = {
                dispatch_lock: result.dispatchLock || null,
                return_lock: result.returnLock || null,
            };
            this.cartResource.updateCartLock(cart.id, manualLocks).then(() => {
                if (manualLocks.dispatch_lock === null && manualLocks.return_lock === null) {
                    cart.manual_locks = null;
                    return;
                }

                cart.manual_locks = manualLocks;
            });
        });
    }

    setCartLocation(cart): void {
        if (!cart.location) {
            cart.location = {};
        }
        const filteredLocations = this.locations.filter((location) => location.type === 'Location');
        const locationAssignDialog = this.dialog.open(AssignCartToLocationDialog, {
            width: '820px',
            height: 'max-content',
            autoFocus: false,
            data: {
                cart: cart,
                locations: filteredLocations,
            },
        });

        locationAssignDialog.afterClosed().subscribe((results) => {
            if (results) {
                if (results?.location?.id) {
                    const selected_location = _.find(filteredLocations, {
                        id: results.location.id,
                    });
                    cart.location = {};
                    cart.location.id = selected_location.id;
                    cart.location.name = selected_location.name;
                    cart.location_id = selected_location.id;
                    cart.location_name = selected_location.name;
                    this.cartResource.dispatch(cart);
                } else {
                    cart.location = null;
                    cart.location_id = null;
                    cart.location_name = null;
                    this.cartResource.removeLocation(cart);
                }
            }
            this.barcodeScanService.removeListener('assignToLocation');
        });
    }

    cartLocationButtonValue(cart, translation) {
        if (!cart.location || JSON.stringify(cart.location) === '{}') {
            return translation;
        } else {
            return cart.location.name;
        }
    }
}
