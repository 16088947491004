import { HttpErrorResponse } from '@angular/common/http';
import { ScannedItem, ScanError, ScanData } from '@models/core/scan';
import { Scanner } from '@models/hardware/scanner';

export const getScanDuration = (scanner: Scanner): number => {
    return (!!scanner.hardware_settings.scan_duration ? scanner.hardware_settings.scan_duration : 4) * 1000.0;
};

export const getAbsoluteTimeout = (scanner: Scanner): number => {
    return (!!scanner.hardware_settings.absolute_timeout ? scanner.hardware_settings.absolute_timeout : 10) * 1000.0;
};

export const getMotorolaScanUrl = (scanner: Scanner): string => {
    let queryParameters = `duration=${getScanDuration(scanner)}`;
    if (scanner.hardware_settings.scan_endpoint === 'user_apps/kitcheck_dynamic.psp') {
        queryParameters += `&absolute_timeout=${getAbsoluteTimeout(scanner)}`;
    }
    return `${scanner.value}/${scanner.hardware_settings.scan_endpoint}?${queryParameters}`;
};

export const getMotorolaScanUrlDeprecated = (scanner: Scanner): string => {
    let queryParameters = `command=tag.db.scan_tags(${getScanDuration(
        scanner
    )})&purge=Yes&fields=tag_id,repeat,antenna&callback=JSON_CALLBACK`;
    return `${scanner.value}/${scanner.hardware_settings.scan_endpoint}?${queryParameters}`;
};

export const getMotorolaScanUrlV1Deprecated = (scanner: Scanner): string => {
    let queryParameters = `command=tag.db.scan_tags(${getScanDuration(
        scanner
    )})&purge=Yes&fields=tag_id,repeat,antenna`;
    return `${scanner.value}/${scanner.hardware_settings.scan_endpoint}?${queryParameters}`;
};

export const getMotorolaDownloadFileDeprecated = (scanner: Scanner, httpError: HttpErrorResponse): string => {
    let result = scanner.hardware_settings.scan_filename;
    // on error, check a header to determine whether the psp is indicating there are more files to download
    // the x-additional-files header is ever only used for downloading accompanying .py files not for actual psp upgrade
    if (!!httpError.headers && !!httpError.headers.get('x-additional-files')) {
        result = httpError.headers.get('x-additional-files');
    }
    return result;
};

export const processScanResult = (rawScanData, hardwareId: number): ScanData => {
    let result: ScanData;
    if (Array.isArray(rawScanData)) {
        let nestedData = {
            tags: rawScanData,
        };
        rawScanData = nestedData;
    } else if (rawScanData?.data?.data) {
        rawScanData = rawScanData.data.data;
    }
    result = rawScanData;

    result.scan_results = rawScanData.tags.map((tagObj: ScannedItem) => processTagId(tagObj));
    result.hardware_id = hardwareId;

    delete result.tags;

    return result;
};

export const processTagId = (tag: ScannedItem): ScannedItem => {
    if (!tag.tag_id) {
        return tag;
    }

    if (tag.tag_id.substr(0, 2) === '0x') {
        tag.tag_id = tag.tag_id.substr(2);
    }
    tag.epc = tag.tag_id;
    delete tag.tag_id;

    return tag;
};

export const getScanError = (
    errorComposition: string,
    associatedTags: any[] = [],
    unassociatedTags: any[] = []
): ScanError => {
    let result: ScanError = new ScanError();

    switch (errorComposition) {
        case 'nothing_detected':
            result.nothingDetectedError = true;
            result.errorMessage = 'scan.errors.nothing_detected';
            break;
        case 'badges':
            result.errorMessage = 'scan.errors.badges';
            break;
        case 'multiple_kits':
            result.errorMessage = 'scan.errors.multiple_kits';
            break;
        case 'multiple_bins':
            result.errorMessage = 'scan.errors.multiple_bins';
            break;
        case 'bins_and_kits':
            result.errorMessage = 'scan.errors.bins_and_kits';
            break;
        case 'unknown':
            result.errorMessage = 'scan.errors.unknown';
            break;
        case 'some_unassociated_tags':
            result.unassociatedTagsError = true;
            result.associatedTags = associatedTags;
            result.unassociatedTags = unassociatedTags;
            result.errorMessage = 'scan.errors.some_unassociated_tags';
            break;
        case 'bin_scan_summary':
            result.shelvedInventoryError = true;
            result.errorMessage = 'scan.errors.shelved_inventory';
            break;
        case 'scan_failed':
            result.errorMessage = 'scan.errors.server_error';
            break;
        case 'scan_currently_processing':
            result.errorMessage = 'scan.errors.concurrency';
            break;
        default:
            result.errorMessage = 'scan.errors.generic';
    }

    return result;
};
