import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

@Component({
    selector: 'reports-index',
    templateUrl: './reports-index.html',
    styleUrls: ['./reports-index.scss'],
})
export class ReportsIndex implements OnInit {
    // bindings
    reports: any;

    // props
    isSystem: boolean;
    reportCategory: string;

    constructor(
        private productModuleService: ProductModuleService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);
        this.activatedRoute.data.subscribe((data) => {
            this.isSystem = data.isSystem;

            if (this.isSystem) {
                this.reportCategory = 'system';
            } else {
                this.reportCategory = 'hospital';
            }
        });
    }
}
