import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { PageEvent } from '@angular/material/paginator';
import { KitScanResource } from '@resources/kit-scan-resource.service';

@Component({
    selector: 'kit-scans',
    templateUrl: './kit-scan-history.html',
    styleUrls: ['./kit-scan-history.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('detailTables', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
        ]),
    ],
})
export class KitScans {
    kitScans;

    expandedElement: any;
    kitId: number;
    collectionSize: number;
    pageIndex: number = 0;
    pageSize: number = 25;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'created_at_formatted',
        'counts.expired',
        'counts.actual',
        'counts.missing',
        'counts.extra',
        'expander',
    ];
    innerDisplayedColumns: string[] = ['header', 'ndc', 'manufacturer', 'name', 'lot_num', 'expiration_formatted'];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private kitScanResource: KitScanResource,
        private loadingSpinnerService: LoadingSpinnerService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((paramMap) => (this.kitId = parseInt(paramMap.get('kitId'))));
        this.activatedRoute.data.subscribe((data) => {
            this.kitScans = data.kitScans;
        });
    }

    ngAfterViewInit(): void {
        this.setupTable();
    }

    setupTable() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.kitScans.scans);
        this.collectionSize = this.kitScans.total_entries;
        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) {
                return property.split('.').reduce((o, i) => o[i], item);
            }
            return item[property];
        };

        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    toggleRow(row) {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    handlePageEvent(event: PageEvent) {
        if (!event.hasOwnProperty('pageIndex') && !event.hasOwnProperty('pageSize')) {
            return;
        }
        if (event.pageSize !== this.pageSize) {
            this.pageSize = event.pageSize;
            this.pageIndex = 0;
            this.refreshScans();
        } else if (event.pageIndex !== this.pageIndex) {
            this.pageIndex = event.pageIndex;
            this.refreshScans();
        }
    }

    refreshScans() {
        const pageParams = this.pageParams();
        return this.loadingSpinnerService
            .spinnerifyPromise(this.kitScanResource.kitScanListPaged(this.kitId, pageParams))
            .then((data) => {
                this.kitScans = data;
                this.setupTable();
            });
    }

    pageParams() {
        return {
            page_index: (this.pageIndex || 0) + 1,
            page_size: this.pageSize || 25,
        };
    }
}
