import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { NavigationStart, Router } from '@angular/router';
import { Package } from '@models/core/package';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'container-scan-info-dialog',
    templateUrl: './container-scan-info-dialog.html',
    styleUrls: ['./container-scan-info-dialog.scss'],
})
export class ContainerScanInfoDialog {
    displayedColumns = ['name', 'manufacturer', 'ndc', 'epc', 'strength', 'package', 'lot_num', 'expiration_formatted'];
    dataSource: MatTableDataSourceWithNaturalSort<Package>;
    hasData: boolean;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<ContainerScanInfoDialog>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: { tableData: Package[]; segmentName: string; modalTitleKey: string }
    ) {}

    ngOnInit() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.data.tableData);
        this.dataSource.sort = this.sort;
        this.hasData = this.dataSource?.data.length > 0;

        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.dialogRef.close();
        });
    }

    onCancel() {
        this.dialogRef.close();
    }
}
