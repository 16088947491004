import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';

import { KitMaster } from '@models/core/kit-master';

import { ActionService } from '@services/utils/action.service';
import { DashboardResource } from '@resources/dashboard-resource.service';
import { KitMasterResource } from '@services/resources/kit-master-resource.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { ReportResource } from '@services/resources/report-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { ReportDataService } from '@services/core/report-data.service';

@Component({
    selector: 'kit-processing',
    templateUrl: './kit-processing.html',
    styleUrls: ['./kit-processing.scss'],
})
export class KitProcessing {
    @Input() timeframeSelect;
    @Input() kitMasterSelect: any[];
    @ViewChild('kitMasters') kitMasters: MatSelect;
    @ViewChild(MatSort) sort: MatSort;

    isKitMasterListViewable: boolean;
    viewBySelect;
    viewOptions = {
        expiringIn: 'Expiring In...',
        notScannedIn: 'Not Scanned In...',
        kitsNeedingRework: 'Kits Needing Rework',
    };
    viewByValues = [this.viewOptions.expiringIn, this.viewOptions.notScannedIn, this.viewOptions.kitsNeedingRework];
    possibleTimeframes = ['24 Hours', '48 Hours', '7 Days', '14 Days', '30 Days', '60 Days', '90 Days'];
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[];
    kitMastersList: KitMaster[];
    timeframeDays: number;
    filters = {};
    allKitMastersSelected = false;
    kitMasterIds: string;
    hasRun = false;
    anyFiltersChanged = false;
    showLink = true;
    kitMasterSelectDisplay: string;
    loading = false;
    sortColumn: string;
    sortDirection: string;
    cost: number;
    expiringSoonCount: number;

    constructor(
        private actionService: ActionService,
        private reportResource: ReportResource,
        private kitMasterResource: KitMasterResource,
        private dashboardResource: DashboardResource,
        private hospitalInfoService: HospitalInfoService,
        private router: Router,
        private reportDataService: ReportDataService
    ) {}

    ngOnInit() {
        this.isKitMasterListViewable = this.actionService.isAllowAction(
            'kits_inventory',
            'view_kit_master',
            'Load Kit Masters data'
        );
    }

    ngAfterViewInit() {
        if (this.isKitMasterListViewable) {
            this.kitMasterResource.kitMasterList(true).then((data) => {
                this.kitMastersList = data.kit_masters;
                this.setDefaultFilters();
                this.applyFilters();
            });
        }
    }

    setDefaultFilters() {
        if (!this.actionService.isAllowAction('kits_reporting', 'view_kits_reports', 'Reports dropdown')) {
            this.viewByValues = [this.viewOptions.notScannedIn];
        }
        this.viewBySelect = this.viewByValues[0];
        this.timeframeSelect = this.possibleTimeframes[2];
    }

    applyFilters() {
        this.hasRun = false;
        this.dataSource = new MatTableDataSourceWithNaturalSort<any>();
        this.parseKitMastersAndTimeframe();
        this.runSelectedReport();
        this.showLink =
            this.viewBySelect === this.viewOptions.expiringIn ||
            this.viewBySelect === this.viewOptions.kitsNeedingRework;
        this.anyFiltersChanged = false;
    }

    runSelectedReport() {
        if (this.viewBySelect === this.viewOptions.expiringIn) {
            this.sortColumn = 'soonest';
            this.sortDirection = 'asc';
            this.filters = {
                days_expiring_within: this.timeframeDays,
                kit_master_id: this.kitMasterIds,
                report_mode: 'kit',
                include_expired_items: 0,
                cost: true,
            };
            this.refreshItemExpirationsReport();
            this.displayedColumns = ['kit_master_name', 'kit_physical_label', 'expiring_soon_count', 'soonest', 'cost'];
        } else if (this.viewBySelect === this.viewOptions.notScannedIn) {
            this.sortColumn = 'last_scan_date';
            this.sortDirection = 'desc';
            this.filters = {
                days_not_scanned_in: this.timeframeDays,
                kit_master_ids: this.kitMasterIds,
            };
            this.refreshNotScannedIn();
            this.displayedColumns = [
                'kit_master_name',
                'kit_physical_label',
                'expiring_soon_count',
                'last_scan_date',
                'location_name',
                'cart_name',
                'cost',
            ];
        } else if (this.viewBySelect === this.viewOptions.kitsNeedingRework) {
            this.sortColumn = 'expiring_soon_count';
            this.sortDirection = 'desc';
            this.filters = {
                scanned_in_last_interval: this.timeframeDays * 24 * 60 * 60, // get seconds
                kit_master_id: this.kitMasterIds,
                hide_completed: 1,
            };
            this.refreshKitsReworkReport();
            this.displayedColumns = [
                'kit_master_name',
                'kit_physical_label',
                'expired_count',
                'expiring_soon_count',
                'missing_count',
                'extra_count',
            ];
        }
    }

    parseKitMastersAndTimeframe() {
        if (!this.kitMasterSelect) {
            this.kitMasterIds = ''; // Don't limit to any Kit Masters if none are specified
        } else {
            this.kitMasterIds = this.kitMasterSelect
                .map((kitMaster) => kitMaster.id)
                .filter((n) => n)
                .join('|');
        }

        this.timeframeDays = this.parseTimeframe();
    }

    parseTimeframe() {
        let numOfDays;
        switch (this.timeframeSelect) {
            case this.possibleTimeframes[0]:
                numOfDays = 1; // 24 hours === 1 day
                break;
            case this.possibleTimeframes[1]:
                numOfDays = 2; // 48 hours === 2 days
                break;
            case this.possibleTimeframes[2]:
                numOfDays = 7;
                break;
            case this.possibleTimeframes[3]:
                numOfDays = 14;
                break;
            case this.possibleTimeframes[4]:
                numOfDays = 30;
                break;
            case this.possibleTimeframes[5]:
                numOfDays = 60;
                break;
            case this.possibleTimeframes[6]:
                numOfDays = 90;
                break;
            default:
                numOfDays = 90;
        }
        return numOfDays;
    }

    refreshKitsReworkReport() {
        this.reportResource.kitsNeedingReworkReport(this.filters).then((rawReportData) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(rawReportData.reports);
            this.dataSource.sort = this.sort;
            this.hasRun = true;
        });
    }

    refreshNotScannedIn() {
        this.dashboardResource.kitsNotScannedIn(this.filters).then((notScannedInData) => {
            const kitsNotScannedIn = this.setNullCartsAndLocations(notScannedInData['dashboards']).map((kit) => {
                return Object.assign(kit, {
                    cost: kit.expiring_soon_packages_formulary_items_cost.total_cost?.toFixed(2),
                    any_missing_price: kit.expiring_soon_packages_formulary_items_cost.missing_any_price,
                });
            });
            this.dataSource = new MatTableDataSourceWithNaturalSort(kitsNotScannedIn);
            this.dataSource.sort = this.sort;
            this.cost = this.calculateCost(notScannedInData.dashboards);
            this.expiringSoonCount = this.calculateItemsExpiringSoon(notScannedInData.dashboards);
            this.hasRun = true;
        });
    }

    refreshItemExpirationsReport() {
        this.reportResource.customItemReport(this.filters, false).then((rawReportData) => {
            rawReportData.kits.forEach((item, i) => {
                item.cost = item.cost?.toFixed(2);
                item.id = i;
                item.full_item_name = [item.soonest_name, item.soonest_item_strength, item.soonest_item_strength_uom]
                    .filter((x) => x)
                    .join(' ');
            });
            const sortedKits = this.sortKits(rawReportData);
            this.dataSource = new MatTableDataSourceWithNaturalSort(sortedKits);
            this.dataSource.sort = this.sort;
            this.cost = this.calculateCost(rawReportData.kits);
            this.expiringSoonCount = this.calculateItemsExpiringSoon(rawReportData.kits);
            this.hasRun = true;
        });
    }

    setNullCartsAndLocations(kits) {
        kits.filter((kit) => !kit.cart_name).map((kit) => (kit.cart_name = 'No Cart Set'));
        kits.filter((kit) => !kit.location_name).map((kit) => (kit.location_name = 'No Location Set'));
        return kits;
    }

    sortKits(rawReportData) {
        return rawReportData['kits'].sort((val1, val2) => {
            return <any>new Date(val1.soonest) - <any>new Date(val2.soonest);
        });
    }

    goToItemExpirationsReport() {
        this.loading = true;
        this.reportDataService.setPreFilter({
            expiration: {
                daysExpiringIn: this.timeframeDays,
                includeExpired: false,
            },
            genericNdcLotSearch: {
                generic: {},
                lot: null,
                ndc: null,
                type: 'generic',
            },
            binKitMasters: {
                binKit: 'kit',
                kitMasters: this.parseKitMasterIds(),
            },
        });
        this.router.navigate(['/report/custom']);
    }

    goToKitsNeedingReworkReport() {
        this.loading = true;
        this.router.navigate(['/report/rework']);
    }

    parseKitMasterIds() {
        let kitMasterIds;
        if (this.kitMasterIds === '') {
            kitMasterIds = [];
        } else {
            kitMasterIds = this.kitMasterIds.split('|').map((id) => {
                return { id: Number(id) };
            });
        }

        return kitMasterIds;
    }

    toggleAllKitMasters() {
        this.allKitMastersSelected = !this.allKitMastersSelected;

        if (this.allKitMastersSelected) {
            this.kitMasters.options.forEach((item) => item.select());
        } else {
            this.kitMasters.options.forEach((item) => {
                item.deselect();
            });
        }
        this.kitMasters.close();
    }

    unhideApply() {
        this.anyFiltersChanged = true;
    }

    updateDisplay() {
        if (this.allKitMastersSelected) {
            this.kitMasterSelectDisplay = 'All Selected';
        } else {
            this.kitMasterSelectDisplay = `${this.kitMasterSelect.length} Selected`;
        }
    }

    populateTooltip(lineItem) {
        let expiring_soon_meds = '';
        let count = 1;
        if (lineItem.package_events !== undefined) {
            lineItem.package_events.map((pe) => {
                if (pe.item_expiring_soon) {
                    expiring_soon_meds += count.toString() + '. ' + pe.name + '\n';
                    count += 1;
                }
            });
        }
        return expiring_soon_meds;
    }

    calculateCost(kits) {
        const totalCost = kits.map((kit) => (kit.cost ? Number(kit.cost) : 0)).reduce((a, b) => a + b, 0);

        return totalCost.toFixed(2);
    }

    calculateItemsExpiringSoon(kits) {
        return kits.map((kit) => kit.expiring_soon_count).reduce((a, b) => a + b, 0);
    }
}
