import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationService } from '@services/system/application.service';
import { KitScanResource } from '@resources/kit-scan-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';

@Injectable()
export class KitSummaryService {
    private kitModalObserver: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private router: Router,
        private applicationService: ApplicationService,
        private kitScanResource: KitScanResource,
        private loadingSpinnerService: LoadingSpinnerService
    ) {}

    observeKitModal(): Observable<any> {
        return this.kitModalObserver;
    }

    emitKitModalEvent(data: any): void {
        this.kitModalObserver.next(data);
    }

    loadKitSummary(kitId, scanId, spinnerType?) {
        if (scanId) {
            const summaryPromise = this.kitScanResource.kitSummary(kitId, scanId).then((data) => {
                if (data.untrusted_items) {
                    let url = this.applicationService.isMobile ? '/mobile/kit/block' : '/kit/block';
                    this.router.navigate([`${url}/${kitId}/${scanId}`]);
                    return false;
                }
                return data;
            });
            return this.loadingSpinnerService.spinnerifyPromise(summaryPromise, spinnerType);
        } else {
            return this.loadingSpinnerService.spinnerifyPromise(
                this.kitScanResource.kitLatestSummary(kitId),
                spinnerType
            );
        }
    }

    loadBlockedKit(kitId, scanId): Promise<any> {
        const blockedKitPromise = this.kitScanResource.kitSummary(kitId, scanId).then((data) => {
            if (!data.untrusted_items) {
                this.router.navigate([`/mobile-kit/block/${kitId}/${scanId}`]);
                return false;
            }
            return data;
        });
        return this.loadingSpinnerService.spinnerifyPromise(blockedKitPromise);
    }
}
