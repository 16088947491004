import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TableComponent } from '@components/common/table-component';
import { ContainerScanSummary } from '@models/core/container-scan';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'container-details-tab',
    templateUrl: './container-details-tab.html',
    styleUrls: ['./container-details-tab.scss'],
})
export class ContainerDetailsTab extends TableComponent {
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean = false;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'name',
        'manufacturer',
        'ndc',
        'epc_last_4',
        'strength',
        'package',
        'lot_num',
        'expiration',
    ];
    containerId: number;
    scanId?: number;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private translationService: TranslationService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.containerId = parseInt(paramMap.get('id'));
            this.scanId = parseInt(paramMap.get('scanId')) || undefined;

            this.loadContainerSegmentScanData();
        });
    }

    get containerDetailTableHeading() {
        const containerSegmentsLength = this.dataSource?.data?.length || 0;
        const itemTranslation = this.translationService.instant('containers.container_details.item_title');

        return `${containerSegmentsLength} ${itemTranslation}${
            containerSegmentsLength > 1 || containerSegmentsLength === 0 ? 's' : ''
        }`;
    }

    private loadContainerSegmentScanData() {
        if (!this.scanId) {
            return;
        }

        this.containerResource
            .getContainerSegmentsScanData(this.containerId, this.scanId)
            .then((containerScanSummary: ContainerScanSummary) => {
                const segmentItemDetails = containerScanSummary.segments.reduce(
                    (acc, segment) => acc.concat([...segment.items, ...segment.extra]),
                    []
                );

                const wrongItems = containerScanSummary.wrong;
                this.dataSource = new MatTableDataSourceWithNaturalSort(segmentItemDetails.concat(wrongItems));
                this.dataSource.sort = this.sort;
                this.hasData = this.dataSource?.data.length > 0;
            });
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }
}
