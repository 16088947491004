import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatCurrency } from '@angular/common';
import { ReportingResource } from '@resources/reporting-resource.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { ReportDataService } from '@services/core/report-data.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportSubscriptionDialog } from '@components/dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';

import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'inferred-items-consumed-removed',
    templateUrl: './report-inferred-items-consumed-removed.html',
    styleUrls: ['./report-inferred-items-consumed-removed.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ReportInferredItemsConsumedRemoved {
    // Bindings from UI-Router Resolvers
    reports;
    inferredItemsConsumedRemoved;
    kitMasters;

    //Member Vars
    report: any;
    isDownloadable: boolean;
    downloadFilters: {
        kit_masters: string; // pipe delimited array
        start_date: string;
        end_date: string;
        reasons: string; // pipe delimited array
        wholesaler_price_type: string;
        include_items_outside_kits: boolean;
        group_ids?: string; // pipe delimited array
    };
    reportDate: any;
    dataSet: Array<any>;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    scheduledReportsEnabled: boolean;
    filterData: any;
    isSystem: boolean = false;
    reasons: Array<string>;
    consumedKitMasters: Array<any>;
    hasData: boolean;
    kitMasterName: boolean;
    generic: boolean;
    hasRun: boolean = false;

    reportFilters: string[] = ['date', 'itemTypeGroup', 'reasons', 'wholesalerPriceType'];
    groupMode: string; // drug or kitMaster
    reportMode: string; // generic or ndc_pkg_str
    expandedElement: any; // used to track the current expanded row
    sortColumn: string;
    displayedColumns: string[];
    resultsLength: number = 0;
    page: number = 1;
    perPage: number = 50;
    preFilter: any = {};

    innerDisplayedColumns: string[] = ['headers', 'values'];

    @ViewChild('outerSort') sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportingResource: ReportingResource,
        private reportSubscriptionResource: ReportSubscriptionResource,
        private reportDataService: ReportDataService,
        private activatedRoute: ActivatedRoute
    ) {
        this.reportDate = new Date();
        this.isDownloadable = true;
        this.scheduledReportsEnabled = this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
            this.inferredItemsConsumedRemoved = data.inferredItemsConsumedRemoved;
            this.kitMasters = data.kitMasters;
        });

        const preFilter = this.reportDataService.getPreFilter();
        if (preFilter) {
            this.preFilter = preFilter;
            this.filterData = preFilter;
        }

        this.activatedRoute.data.subscribe((data) => (this.isSystem = data.isSystem));

        if (this.isSystem) {
            this.reportFilters = ['hospitalGroups', 'date', 'itemTypeGroup', 'reasons', 'wholesalerPriceType'];
        }

        this.consumedKitMasters = this.inferredItemsConsumedRemoved.kit_masters;
        const reportCategory: any = this.reports.report_categories.find(
            (category) => category.name === 'item_attribute'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'inferred_items_consumed_removed');
    }

    getColumns() {
        let columns = ['expander'];

        if (this.isSystem) {
            columns.push('parent_group_names');
            columns.push('group_name');
        }

        if (this.groupMode === 'kitMaster') {
            columns.push('kit_master_name');
        }

        if (this.reportMode === 'generic') {
            columns.push('clinical_equivalence_name');
        } else {
            columns.push('dictionary_item_name');
            columns.push('dictionary_ndc_formatted');
        }

        columns.push('strength');
        columns.push('package');
        columns.push('sorted_total');
        columns.push('total_price');

        return columns;
    }

    getInitSort(): string {
        if (this.groupMode === 'drug') {
            if (this.reportMode === 'generic') {
                return 'clinical_equivalence_name';
            } else {
                return 'dictionary_item_name';
            }
        } else {
            if (!!this.filterData.itemTypeGroup.kitMasters) {
                return 'kit_master_name';
            } else {
                if (this.reportMode === 'generic') {
                    return 'clinical_equivalence_name';
                } else {
                    return 'dictionary_item_name';
                }
            }
        }
    }

    toggleRow(row): void {
        this.expandedElement = this.expandedElement === row ? null : row;
        this.changeDetectorRef.detectChanges();
    }

    loadPage(event) {
        this.perPage = event.pageSize;
        this.filterConsumedRemovedItems(this.filterData, event.pageIndex + 1);
    }

    filterConsumedRemovedItems(event: any, page: number = 1): void {
        this.filterData = event;

        // fetching reasons in the filter, so need to default it if the api hasn't returned yet
        if (!this.filterData.reasons) {
            this.filterData.reasons = [
                'consumed',
                'damaged',
                'expired',
                'expiring_soon',
                'no_longer_used',
                'recalled',
                'replaced',
                'shortage',
                'used_outside',
            ];
        }
        this.reasons = [...this.filterData.reasons];
        this.groupMode = this.filterData.itemTypeGroup.kitDrugGroup;
        this.reportMode = this.filterData.itemTypeGroup.genericNdcItemType;
        const data = this.filterData?.date;

        let errorMsg;

        if (this.isSystem) {
            data.group_ids = this.filterData.groupIds;
            data.page = page;
            data.per_page = this.perPage;

            if (this.reportMode === 'generic') {
                data.detail = 'generic';
            } else {
                data.detail = 'formulary';
            }
            errorMsg = 'Report load has failed. Please select fewer hospitals or a smaller range of dates.';
        } else {
            errorMsg = 'Report load has failed. Please select a smaller range of dates.';
        }

        if (this.groupMode === 'kitMaster') {
            if (!!this.filterData.itemTypeGroup.kitMasters) {
                data.kit_masters = this.filterData.itemTypeGroup.kitMasters
                    .map((kitMaster) => kitMaster.id)
                    .filter((n) => n)
                    .join('|');
            }
        } else {
            data.kit_masters = [];
        }
        data.wholesaler_price_type = this.filterData.wholesalerPriceType;
        const reportResponse = this.reportingResource.itemsConsumedRemoved(data, this.isSystem);

        const errorTimer = new Promise((_resolve, reject) => {
            let wait = setTimeout(
                () => {
                    clearTimeout(wait);
                    reject({ message: errorMsg });
                },
                5 * 60 * 1000
            );
        });

        //Returns whichever promise resolves/rejects first
        const promise = Promise.race([reportResponse, errorTimer]).then(
            (reportData) => {
                this.inferredItemsConsumedRemoved = reportData;
                this.consumedKitMasters = this.inferredItemsConsumedRemoved.kit_masters;
                if (this.groupMode === 'drug') {
                    if (this.reportMode === 'generic') {
                        this.dataSet = this.inferredItemsConsumedRemoved.generic_products;
                    } else {
                        this.dataSet = this.inferredItemsConsumedRemoved.formulary_items;
                    }
                } else {
                    // filter by selected kitmaster
                    if (this.filterData.itemTypeGroup.kitMasters.length > 0) {
                        if (this.reportMode === 'generic') {
                            this.dataSet = this.inferredItemsConsumedRemoved.kit_masters_generic_products;
                        } else {
                            this.dataSet = this.inferredItemsConsumedRemoved.kit_masters_formulary_items;
                        }
                        const kitMasterIds = this.filterData.itemTypeGroup.kitMasters.map((kitMaster) => kitMaster.id);
                        this.dataSet = this.dataSet.filter((item: any) => {
                            return kitMasterIds.includes(item.kit_master_id);
                        });
                        this.dataSet.forEach((item: any) => {
                            if (!item.kit_master_name) {
                                item.kit_master_name = '— Items decommissioned outside of kits';
                            }
                        });
                    } else {
                        if (this.reportMode === 'generic') {
                            this.dataSet = this.inferredItemsConsumedRemoved.generic_products;
                        } else {
                            this.dataSet = this.inferredItemsConsumedRemoved.formulary_items;
                        }
                    }
                }
                this.dataSet.forEach((item, index) => {
                    this.total(index);
                    this.calcPrices(index);
                });
                this.dataSource = new MatTableDataSourceWithNaturalSort(this.dataSet);
                this.dataSource.sort = this.sort;

                if (this.inferredItemsConsumedRemoved.pagination_metadata) {
                    this.resultsLength = this.inferredItemsConsumedRemoved.pagination_metadata.total_entries;
                }
                this.hasData = this.hasAnyData();
                this.updateDownloadFilters();
                this.displayedColumns = this.getColumns();
                this.sortColumn = this.getInitSort();
                this.hasRun = true;
            },
            (error) => {
                this.dataSet = null;
                this.resultsLength = 0;
                this.hasRun = false;
                if (error.message) {
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, error.message);
                } else {
                    // errors from server are caught in separate interceptor component, case for empty object returned with timeouts
                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, errorMsg);
                }
            }
        );
        this.loadingSpinnerService.spinnerifyPromise(promise).then(() => {
            this.loadingSpinnerService.kill();
        });
    }

    reasonsHas(reason: string): boolean {
        return !!this.reasons && this.reasons.includes(reason);
    }

    total(item: number): number {
        if (this.filterData && this.reasons) {
            let count = 0,
                total_price = 0;
            let data = this.dataSet[item];

            this.reasons.forEach((reason) => {
                count += data[`count_${reason}`];
                if (this.priceExist(data)) {
                    total_price += parseFloat(data[`${reason}_total_price`]);
                }
            });

            data['sorted_total'] = count;
            if (this.priceExist(data)) {
                data['total_price'] = this.formatCurrency(total_price);
            } else {
                data['total_price'] = '-';
            }
            return count;
        } else {
            return 0;
        }
    }

    calcPrices(item: number): void {
        if (this.filterData) {
            let data = this.dataSet[item];
            if (this.priceExist(data)) {
                this.reasons.forEach((reason) => {
                    data[`${reason}_total_price`] = this.formatCurrency(data[`${reason}_total_price`]);
                });
            }
        }
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    updateDownloadFilters(): void {
        const kit_masters = this.filterData.itemTypeGroup.kitMasters.map((kitMaster) => kitMaster.id).join('|');
        const reasons = this.filterData.reasons.join('|');
        const includeItemsOutsideKits =
            this.filterData.itemTypeGroup.kitMasters.some((kitMaster) => kitMaster.id === null) ||
            this.filterData.itemTypeGroup.kitDrugGroup === 'drug';
        this.downloadFilters = {
            kit_masters,
            start_date: this.filterData.date.start_date,
            end_date: this.filterData.date.end_date,
            reasons,
            wholesaler_price_type: this.filterData.wholesalerPriceType,
            include_items_outside_kits: includeItemsOutsideKits,
        };

        if (this.isSystem) {
            this.downloadFilters.group_ids = this.filterData.groupIds;
        }
    }

    subscribeModal(subscription = undefined): void {
        const subscribeFilterData: any = {
            start_date: this.filterData.date.start_date,
            kitMasters: this.filterData.itemTypeGroup.kitMasters,
            reasons: this.filterData.reasons,
            wholesaler_price_type: this.filterData.wholesalerPriceType,
            hospitalGroups: this.filterData.groupIds,
        };
        const subscriptionFrequenciesPromise = this.reportSubscriptionResource.frequenciesList();

        Promise.all([subscriptionFrequenciesPromise]).then((response) => {
            const frequencies = response[0].frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilterData,
                    frequencies: frequencies,
                    consumedKitMasters: this.consumedKitMasters,
                    isSystem: this.isSystem,
                },
            });
        });
    }

    hasAnyData(): boolean {
        return (
            !!this.inferredItemsConsumedRemoved.kit_masters ||
            !!this.inferredItemsConsumedRemoved.formulary_items ||
            !!this.inferredItemsConsumedRemoved.generic_products ||
            !!this.inferredItemsConsumedRemoved.kit_masters_formulary_items ||
            !!this.inferredItemsConsumedRemoved.kit_masters_generic_products ||
            !!this.inferredItemsConsumedRemoved.kits_formulary_items ||
            !!this.inferredItemsConsumedRemoved.kits_generic_products
        );
    }

    priceExist(item: any): boolean {
        return item.last_catalog_price != null;
    }

    formatCurrency(price: number): String {
        return formatCurrency(price, 'en', '$').replace('.00', '');
    }
}
