import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@resources/report-resource.service';

@Injectable({ providedIn: 'root' })
export class SingleDossierReportResolver {
    reportName: string;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private reportResource: ReportResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const dossierPromise = this.reportResource.dossierReport(activatedRoute.url[1].path).then((data) => {
            return data;
        });

        return this.loadingSpinnerService.spinnerifyPromise(dossierPromise);
    }
}
