import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { BinResource } from '@resources/bin-resource.service';

@Injectable({ providedIn: 'root' })
export class BinScanDetailResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private binResource: BinResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const binId = activatedRoute.params.binId;
        const scanId = activatedRoute.params.scanId;

        return this.loadingSpinnerService.spinnerifyPromise(this.binResource.showBinScanDetail(binId, scanId));
    }
}
