import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { ActionService } from '@services/utils/action.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { RecallResource } from '@resources/recall-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { TableComponent } from '@components/common/table-component';
import { unit } from '@utils/filterUtils';

import { AdminRecall } from '@models/admin/admin-recall';
import { FormularyItem } from '@models/admin/admin-formulary-item';

@Component({
    selector: 'admin-recalls-create',
    templateUrl: './admin-recalls-create.html',
    styleUrls: ['./admin-recalls-create.scss'],
})
export class AdminRecallsCreate {
    //bindings
    recall: AdminRecall;
    formularyItems: FormularyItem[];

    actionAllow: any;
    addingRecall: boolean;
    editMode: boolean = false;
    otherReason: any;
    pickedFormularyItem: FormularyItem;
    recallReasons: string[];
    recall_reason_id: number;
    step: number = 1;

    //material tag stuff
    selectable: boolean = true;
    removable: boolean = true;
    addOnBlur: boolean = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    dashboard: boolean;

    constructor(
        private actionService: ActionService,
        private dialog: MatDialog,
        private kcMatSnackBarService: KCMatSnackBarService,
        private recallResource: RecallResource,
        private translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.recall = data.recall;
            this.formularyItems = data.formularyItems.filter((formularyItem) => formularyItem.ndc !== null);
        });

        this.actionAllow = this.actionService.allowModule('administration');
        this.activatedRoute.queryParamMap.subscribe(
            (queryParamMap) => (this.dashboard = queryParamMap.get('dashboard') === 'true')
        );

        if (!!this.recall) {
            this.editMode = true;
            this.step = 2;
            this.pickedFormularyItem = this.formularyItems.find((item) => item.ndc === this.recall.ndc);
            this.recall.package_description_name = this.pickedFormularyItem.package_description_name;
            this.recall_reason_id = this.recall.recall_reason.id;
        } else {
            this.editMode = false;
            this.step = 1;
            this.pickedFormularyItem = null;
            this.recall_reason_id = null;
            this.initRecall();
        }

        this.recallResource.getRecallReasonIds().then(({ recall_reasons }) => {
            this.recallReasons = recall_reasons;
            this.otherReason = recall_reasons.find((reason) => reason.name === 'other');
        });
    }

    initRecall() {
        this.recall = {
            id: null,
            item_name: null,
            formulary_item_id: null,
            lot_numbers: [],
            manufacturer_name: null,
            ndc: '',
            package_description: null,
            reason_detail: null,
            recall_reason: {},
            updated_at: null,
        };
    }

    addLot(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            //handles pasting with commas
            let lots = event.value.split(/,+/);

            lots.forEach((lot) => {
                this.recall.lot_numbers.push(lot.trim());
            });

            this.recall.lot_numbers = this.recall.lot_numbers.filter(this.onlyUnique);
        }
        if (input) {
            input.value = '';
        }
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    removeLot(tag): void {
        const index = this.recall.lot_numbers.indexOf(tag);

        if (index >= 0) {
            this.recall.lot_numbers.splice(index, 1);
        }
    }

    pick(formularyItem): void {
        this.pickedFormularyItem = formularyItem;
    }

    isValidStep(checkStep): boolean {
        if (checkStep === 1) {
            return this.pickedFormularyItem && this.pickedFormularyItem.ndc ? true : false;
        } else if (checkStep === 2) {
            if (this.otherReason?.id === this.recall_reason_id) {
                // require reason detail if reason is other
                return !!this.recall.lot_numbers && !!this.recall.lot_numbers.length && !!this.recall.reason_detail;
            }
            return !!this.recall.lot_numbers && !!this.recall.lot_numbers.length && !!this.recall_reason_id;
        }
    }

    isFilledStep(checkStep): boolean {
        return this.step > checkStep && this.isValidStep(checkStep);
    }

    goTo(newStep): void {
        if (newStep === this.step) {
            return;
        }

        if (newStep === 1) {
            this.step = 1;
            this.pickedFormularyItem = undefined;
            setTimeout(() => {
                $('.formulary-item-search').focus();
            });
        } else if (newStep === 2) {
            this.step = 2;
            if (this.recall.ndc !== this.pickedFormularyItem.ndc) {
                this.recall = {
                    id: this.recall.id,
                    ndc: this.pickedFormularyItem.ndc,
                    item_name: this.pickedFormularyItem.item_name,
                    manufacturer_name: this.pickedFormularyItem.manufacturer_name,
                    package_description_name: this.pickedFormularyItem.package_description_name,
                    formulary_item_id: this.pickedFormularyItem.formulary_item_id,
                    item_strength: {
                        scalar: this.pickedFormularyItem.item_strength,
                        scalar_formatted: this.pickedFormularyItem.item_strength_formatted,
                        uom: this.pickedFormularyItem.item_strength_uom,
                    },
                    package_size: {
                        scalar: this.pickedFormularyItem.package_size,
                        scalar_formatted: this.pickedFormularyItem.package_size_formatted,
                        uom: this.pickedFormularyItem.package_size_uom,
                    },
                    lot_numbers: [],
                    reason_detail: '',
                    recall_reason: {},
                };
                this.recall_reason_id = null;
            }
            setTimeout(() => {
                $('#lot-input').focus();
            });
        }
    }

    setRecallData() {
        const data: any = {
            formulary_item_id: this.recall.formulary_item_id,
            lot_numbers: this.recall.lot_numbers,
            recall_reason_id: this.recall_reason_id,
        };

        if (this.recall.reason_detail && this.recall.reason_detail.length) {
            data.reason_detail = this.recall.reason_detail;
        }
        return data;
    }

    addRecall() {
        if (!this.isValidStep(2)) {
            return;
        }

        this.addingRecall = true;
        let data = this.setRecallData();

        this.recallResource
            .createRecall(data)
            .then(() => {
                if (!!this.dashboard) {
                    this.router.navigate(['/dashboard']);
                } else {
                    this.router.navigate(['/admin/recalls']);
                }
            })
            .then(() => {
                return this.translationService.instant('item.uid.name');
            })
            .then((ndcText) => {
                const strengthUnit = unit(this.recall.item_strength);
                const packageUnit = unit(this.recall.package_size);
                const translationValues = {
                    itemName: this.recall.item_name,
                    strengthText: `${strengthUnit.scalar} ${strengthUnit.uom}`,
                    packageText: `${packageUnit.scalar} ${packageUnit.uom}`,
                    packageDescriptionName: this.recall.package_description_name,
                    ndcText,
                    ndc: this.recall.ndc,
                };
                this.kcMatSnackBarService.openWithTranslate(
                    SnackBarTypes.SUCCESS,
                    { key: 'admin.item_recalls.created', params: translationValues },
                    null,
                    8000
                );
            })
            .finally(() => {
                this.addingRecall = false;
            });
    }

    updateRecall() {
        if (!this.isValidStep(2)) {
            return;
        }

        this.addingRecall = true;
        let data = this.setRecallData();

        this.recallResource
            .updateRecall(this.recall.id, data)
            .then(() => {
                return this.router.navigate(['/admin/recalls']);
            })
            .then(() => {
                return this.translationService.instant('item.uid.name');
            })
            .then((ndcText) => {
                const strengthUnit = unit(this.recall.item_strength);
                const packageUnit = unit(this.recall.package_size);
                const translationValues = {
                    itemName: this.recall.item_name,
                    strengthText: `${strengthUnit.scalar} ${strengthUnit.uom}`,
                    packageText: `${packageUnit.scalar} ${packageUnit.uom}`,
                    packageDescriptionName: this.recall.package_description_name,
                    ndcText,
                    ndc: this.recall.ndc,
                };
                this.kcMatSnackBarService.openWithTranslate(
                    SnackBarTypes.SUCCESS,
                    { key: 'admin.item_recalls.updated', params: translationValues },
                    null,
                    8000
                );
            })
            .finally(() => {
                this.addingRecall = false;
            });
    }

    cancelUpdate() {
        this.router.navigate(['/admin/recalls']);
    }

    async confirmRemove(data) {
        const objType = await this.translationService.get('admin.item_recalls.recall');
        const objName = await this.translationService.get('admin.item_recalls.this_recall');
        const title = await this.translationService.get('modals.confirm_delete.header', { objType: objType });
        const description = await this.translationService.get('modals.confirm_delete.confirm', { objName: objName });

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: title,
                description: description,
                okButton: this.translationService.instant('buttons.delete'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.recallResource
                    .deleteRecall(data.id)
                    .then(() => {
                        return this.router.navigate(['/admin/recalls']);
                    })
                    .then(() => {
                        return this.translationService.instant('item.uid.name');
                    })
                    .then((ndcText) => {
                        const strengthUnit = unit(this.recall.item_strength);
                        const packageUnit = unit(this.recall.package_size);
                        const translationValues = {
                            itemName: this.recall.item_name,
                            strengthText: `${strengthUnit.scalar} ${strengthUnit.uom}`,
                            packageText: `${packageUnit.scalar} ${packageUnit.uom}`,
                            packageDescriptionName: this.recall.package_description_name,
                            ndcText,
                            ndc: this.recall.ndc,
                        };
                        this.kcMatSnackBarService.openWithTranslate(
                            SnackBarTypes.SUCCESS,
                            { key: 'admin.item_recalls.deleted', params: translationValues },
                            null,
                            8000
                        );
                    });
            }
        });
    }
}
