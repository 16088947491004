import { Injectable } from '@angular/core';

@Injectable()
export class TaggingDataService {
    private formularyItem: any = null;
    private barcodeObject: any = null;

    getFormularyItem() {
        return this.formularyItem;
    }

    setFormularyItem(formularyItem: any) {
        this.formularyItem = formularyItem;
    }

    getBarcodeObject() {
        return this.barcodeObject;
    }

    setBarcodeObject(barcodeObject: any) {
        this.barcodeObject = barcodeObject;
    }
}
