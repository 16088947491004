import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { ActionService } from '@services/utils/action.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { PreTaggedMedicationsResource } from '@services/resources/pre-tagged-medications-resource.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { states } from '@utils/states';
import { NgModel } from '@angular/forms';
import { ApplicationService } from '@services/system/application.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCreditsDialogComponent } from '@components/dialogs/add-credits-dialog/add-credits-dialog.component';
import { AddCreditsForm } from '@models/core/add-credits-form';
import { isPhoneNumberValid } from '@utils/phone-validator-utils';

@Component({
    selector: 'add-credits',
    templateUrl: './add-credits.html',
    styleUrls: ['./add-credits.scss'],
})
export class AddCredits implements OnInit {
    // Bindings from UI-Router Resolvers
    usageSummary;

    @ViewChild('creditsForm') creditsForm: NgModel;

    // Member Vars
    canAddCredits: boolean = false;
    chargeModel: string;
    csvUploader = new FileUploader({});
    fileUploaderItem: FileItem;
    url: string;
    hasFile: boolean;
    fileSizeError: boolean = false;
    fileTypeError: boolean = false;
    uploading: boolean;
    sameInfo: boolean;
    formData: AddCreditsForm = {} as AddCreditsForm;
    messages: string[];
    states: string[];
    isContactPhoneValid: boolean = true;
    isBillingContactPhoneValid: boolean = true;
    creditPacksAmount: number = 0;
    user: any;
    private readonly AMOUNT_IN_CREDIT_PACK = 1000;
    private readonly WARNING_CREDITS = 10000;
    private readonly MAX_PO_FILE_SIZE = 5242881;

    constructor(
        protected actionService: ActionService,
        protected groupLoginService: GroupLoginService,
        protected preTaggedMedicationsResource: PreTaggedMedicationsResource,
        protected configurationProvider: ConfigurationProvider,
        protected applicationService: ApplicationService,
        protected dialog: MatDialog,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    get totalCreditPacks() {
        return (this.formData.amount || 0) / 1000;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.usageSummary = data.usageSummary;
        });

        this.states = states;
        this.user = this.groupLoginService.getUser();
        this.canAddCredits = this.actionService.isAllowAction('charge_model', 'add_credits_from_banner', 'Add credits');

        // Pre-fill user info to the form
        this.formData.contact_name = `${this.user.firstName} ${this.user.lastName}`;
        this.formData.contact_email = this.user.email;

        this.formData.billing_state = null;
        this.url = `${this.configurationProvider.kcEndpointV1()}charge_model/credits`;
        this.csvUploader = new FileUploader({
            url: this.url,
            authToken: this.groupLoginService.authToken(),
            allowedFileType: ['pdf'],
            maxFileSize: this.MAX_PO_FILE_SIZE,
        });

        this.csvUploader.onAfterAddingFile = async (item: FileItem) => {
            this.fileTypeError = item.file?.type !== 'application/pdf';
            this.fileSizeError = item.file?.size > this.MAX_PO_FILE_SIZE;
            item.withCredentials = false;
            this.hasFile = true;
            this.csvUploader.options.additionalParameter = this.formData;
            this.fileUploaderItem = item;
        };

        this.csvUploader.onWhenAddingFileFailed = async (item: FileLikeObject) => {
            this.fileTypeError = item?.type !== 'application/pdf';
            this.fileSizeError = item?.size > this.MAX_PO_FILE_SIZE;
        };

        this.csvUploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
            this.clear();
            this.router.navigate([
                '/pre-tagged-medications',
                {
                    queryParams: {
                        successMessage: true,
                    },
                },
            ]);
        };

        this.csvUploader.onErrorItem = (item: FileItem, response: any, status: any, headers: any) => {
            this.uploading = false;
        };
    }

    disableSubmit() {
        return !!this.creditsForm && !this.creditsForm.valid;
    }

    onChangeContactPhone() {
        if (this.sameInfo) {
            this.formData.billing_phone = this.formData.contact_phone;
        }

        this.isContactPhoneValid = isPhoneNumberValid(this.formData.contact_phone);

        if (!this.isContactPhoneValid) {
            this.creditsForm.control['controls']['contact_phone'].setErrors({ incorrect: true });
        }
    }

    onChangeBillingContactPhone() {
        this.isBillingContactPhoneValid = isPhoneNumberValid(this.formData.billing_phone);

        if (!this.isBillingContactPhoneValid) {
            this.creditsForm.control['controls']['billing_phone'].setErrors({ incorrect: true });
        }
    }

    onChangeContactEmail() {
        if (this.sameInfo) {
            this.formData.billing_email = this.formData.contact_email;
        }
    }

    onAmountChange(value: number) {
        this.formData.amount = value * this.AMOUNT_IN_CREDIT_PACK;

        // Amount should be positive and have no decimals
        if (this.formData.amount < 0 || this.formData.amount % 1 !== 0) {
            this.creditsForm.control['controls']['credits'].setErrors({ incorrect: true });
        }
    }

    clear() {
        this.fileUploaderItem = undefined;
        this.hasFile = false;
        this.messages = undefined;
        this.uploading = false;
    }

    copyContactInfo() {
        if (this.sameInfo) {
            this.formData.billing_phone = this.formData.contact_phone;
            this.formData.billing_email = this.formData.contact_email;
        }
    }

    submit() {
        if (this.formData.amount < this.WARNING_CREDITS) {
            // can upload without modal
            this.uploadAndAdd();
        } else {
            const confirmCredits = this.dialog.open(AddCreditsDialogComponent, {
                width: '485px',
                height: 'max-content',
                data: {
                    form: this.formData,
                },
            });

            confirmCredits.afterClosed().subscribe((confirmed) => {
                if (confirmed && this.hasFile) {
                    // credits were added in the modal code;
                    this.fileUploaderItem.upload();
                } else {
                    return;
                }
            });
        }
    }

    uploadAndAdd() {
        this.uploading = true;

        if (this.hasFile) {
            this.fileUploaderItem.upload();
        }

        this.preTaggedMedicationsResource.addCredits(this.formData).then(() => {
            this.router.navigate([
                '/pre-tagged-medications',
                {
                    queryParams: {
                        successMessage: true,
                    },
                },
            ]);
        });
    }
}
