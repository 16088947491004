import { ActivityAnalyticsResolver } from '@resolvers/activity-analytics.resolver';
import { AdminCartsResolver } from '@resolvers/admin-carts.resolver';
import { AdvancedSegmentOptimizationResolver } from '@resolvers/advanced-segment-optimization.resolver';
import { ArchivedFormularyItemsResolver } from '@resolvers/archived-formulary-items.resolver';
import { BinScanDetailResolver } from '@resolvers/bin-scan-detail.resolver';
import { BinScanResolver } from '@resolvers/bin-scan.resolver';
import { BinsResolver } from '@resolvers/bins.resolver';
import { BlockedKitResolver } from '@resolvers/blocked-kit.resolver';
import { CartHistoryResolver } from '@resolvers/cart-history.resolver';
import { CartResolver } from '@resolvers/cart.resolver';
import { CartsResolver } from '@resolvers/carts.resolver';
import { CatalogsResolver } from '@resolvers/catalogs.resolver';
import { CurrentBinResolver } from '@resolvers/current-bin.resolver';
import { ContainersResolver } from './containers.resolver';
import { DetailScanResolver } from '@resolvers/detail-scan.resolver';
import { DictionaryEntryResolver } from '@resolvers/dictionary-entry.resolver';
import { ExpirationAnalyticsResolver } from '@resolvers/expiration-analytics.resolver';
import { FormularyItemArchiveKitMasterListResolver } from '@resolvers/formulary-item-archive-kit-master-list.resolver';
import { FormularyItemKitBinCountResolver } from '@resolvers/formulary-item-kit-bin-count.resolver';
import { FormularyItemResolver } from '@resolvers/formulary-item.resolver';
import { FormularyItemsResolver } from '@resolvers/formulary-items.resolver';
import { GroupIdnMapActionViewUsersResolver } from '@resolvers/group-idn-map-action-view-users.resolver';
import { GroupRolesResolver } from '@resolvers/group-roles.resolver';
import { HardwareResolver } from '@resolvers/hardware.resolver';
import { HospitalDataResolver } from '@resolvers/hospital-data.resolver';
import { InferredItemsConsumedRemovedResolver } from '@resolvers/inferred-items-consumed-removed.resolver';
import { KitMasterTypesResolver } from '@resolvers/kit-master-types.resolver';
import { KitMastersResolver } from '@resolvers/kit-masters.resolver';
import { KitMastersWithFromKitsResolver } from '@resolvers/kit-masters-with-from-kits.resolver';
import { KitScansResolver } from '@resolvers/kit-scans.resolver';
import { KitSummaryResolver } from '@resolvers/kit-summary.resolver';
import { KitsResolver } from '@resolvers/kits.resolver';
import { KittedInventoryWithCostResolver } from '@resolvers/kitted-inventory-with-cost.resolver';
import { LocationResolver } from '@resolvers/location.resolver';
import { LocationsIncludeRtlsResolver } from '@resolvers/locations-include-rtls.resolver';
import { LocationsResolver } from '@resolvers/locations.resolver';
import { LoginResolver } from '@resolvers/login.resolver';
import { ManufacturersResolver } from '@resolvers/manufacturers.resolver';
import { PackageDescriptionsResolver } from '@resolvers/package-descriptions.resolver';
import { PanelSpinnerResolver } from '@resolvers/panel-spinner.resolver';
import { PreTaggedMedicationsResolver } from '@resolvers/pre-tagged-medications.resolver';
import { PretaggedProblemsResolver } from '@resolvers/pretagged-problems.resolver';
import { PretaggedReportsResolver } from '@resolvers/pretagged-reports.resolver';
import { PrinterResolver } from '@resolvers/printer.resolver';
import { RecallResolver } from '@resolvers/recall.resolver';
import { RecallsNetworkResolver } from '@resolvers/recalls-network.resolver';
import { RecallsResolver } from '@resolvers/recalls.resolver';
import { ReportSubscriptionsResolver } from '@resolvers/report-subscriptions.resolver';
import { ReportsResolver } from '@resolvers/reports.resolver';
import { ScanInventoryResolver } from '@resolvers/scan-inventory.resolver';
import { SegmentsResolver } from '@resolvers/segments.resolver';
import { ShelvedInventorySettingResolver } from '@resolvers/shelved-inventory-setting.resolver';
import { ShortageSegmentsResolver } from '@resolvers/shortage-segments.resolver';
import { SingleDossierReportResolver } from '@resolvers/single-dossier-report.resolver';
import { SingleHardwareResolver } from '@resolvers/single-hardware.resolver';
import { SingleKitMasterResolver } from '@resolvers/single-kit-master.resolver';
import { SingleSegmentResolver } from '@resolvers/single-segment.resolver';
import { ScannerResolver } from '@resolvers/scanner.resolver';
import { TagTypesResolver } from '@resolvers/tag-types.resolver';
import { UserListResolver } from '@resolvers/user-list.resolver';
import { UserResolver } from '@resolvers/user.resolver';

const resolvers = [
    ActivityAnalyticsResolver,
    AdminCartsResolver,
    AdvancedSegmentOptimizationResolver,
    ArchivedFormularyItemsResolver,
    BinScanDetailResolver,
    BinScanResolver,
    BinsResolver,
    BlockedKitResolver,
    CartHistoryResolver,
    CartResolver,
    CartsResolver,
    CatalogsResolver,
    CurrentBinResolver,
    ContainersResolver,
    DetailScanResolver,
    DictionaryEntryResolver,
    ExpirationAnalyticsResolver,
    FormularyItemArchiveKitMasterListResolver,
    FormularyItemKitBinCountResolver,
    FormularyItemResolver,
    FormularyItemsResolver,
    GroupIdnMapActionViewUsersResolver,
    GroupRolesResolver,
    HardwareResolver,
    HospitalDataResolver,
    InferredItemsConsumedRemovedResolver,
    KitMasterTypesResolver,
    KitMastersResolver,
    KitMastersWithFromKitsResolver,
    KitScansResolver,
    KitSummaryResolver,
    KitsResolver,
    KittedInventoryWithCostResolver,
    LocationResolver,
    LocationsIncludeRtlsResolver,
    LocationsResolver,
    LoginResolver,
    ManufacturersResolver,
    PackageDescriptionsResolver,
    PanelSpinnerResolver,
    PreTaggedMedicationsResolver,
    PretaggedProblemsResolver,
    PretaggedReportsResolver,
    PrinterResolver,
    RecallResolver,
    RecallsNetworkResolver,
    RecallsResolver,
    ReportSubscriptionsResolver,
    ReportsResolver,
    ScanInventoryResolver,
    ScannerResolver,
    SegmentsResolver,
    ShelvedInventorySettingResolver,
    ShortageSegmentsResolver,
    SingleDossierReportResolver,
    SingleHardwareResolver,
    SingleKitMasterResolver,
    SingleSegmentResolver,
    TagTypesResolver,
    UserListResolver,
    UserResolver,
];

export { resolvers };
