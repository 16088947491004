import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class FormularyItemKitBinCountResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemResource: FormularyItemResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const formularyItemId = parseInt(activatedRoute.params.formularyItemId);
        const formularyPromise = this.formularyItemResource.getFormularyItemKitBinCount(formularyItemId);

        return this.loadingSpinnerService.spinnerifyPromise(formularyPromise);
    }
}
