import { Component, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { ActionService } from '@services/utils/action.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { ReportService } from '@services/report/report.service';
import { Report, ReportCategory } from '@models/core/report';
import { TranslationService } from '@services/utils/translation.service';

import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ReportSubscriptionDialog } from '@dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';
import { ReportResource } from '@services/resources/report-resource.service';
import { RemoteBatchVerificationDialog } from '@dialogs/remote-batch-verification/remote-batch-verification-dialog';
import { EditLotExpirationDialog } from '@dialogs/edit-lot-expiration/edit-lot-expiration-dialog';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';

@Component({
    selector: 'item-batch-summary',
    templateUrl: './report-item-batch-summary.html',
    styleUrls: ['./report-item-batch-summary.scss'],
})
export class ReportItemBatchSummary {
    reports: {
        report_categories: Array<ReportCategory>;
    };
    report;
    title: string;
    reportDate: Date;
    filteredBatches;
    hasRun: boolean;
    hasData: boolean;
    downloadFilters;
    batchTab: string = 'all';
    filterData;

    reportNamePrintBatch: string = 'print-batch';
    batches;
    authToken;
    isDownloadable: boolean = true;
    remoteBatches: string;
    remoteBatch: string;
    packages: string;
    package: string;
    showCheckboxes;
    showEditIcon: boolean;
    currentHospitalId: number;

    resultsLength: number = 0;
    page?: number = 1;
    perPage: number = 50;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private actionService: ActionService,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        private router: Router,
        private translationService: TranslationService,
        protected reportService: ReportService,
        protected reportResource: ReportResource,
        protected reportSubscriptionResource: ReportSubscriptionResource,
        private zone: NgZone,
        private activatedRoute: ActivatedRoute
    ) {
        this.reportDate = new Date();
        this.translationService
            .get([
                'reports.remote_batch.batches',
                'reports.remote_batch.batch',
                'common.package_singular',
                'common.package_plural',
            ])
            .then((translations) => {
                this.remoteBatches = translations['reports.remote_batch.batches'];
                this.remoteBatch = translations['reports.remote_batch.batch'];
                this.packages = translations['common.package_plural'].toLowerCase();
                this.package = translations['common.package_singular'].toLowerCase();
            });

        this.currentHospitalId = this.hospitalInfoService.getHospitalId();
    }

    remoteBatchTranslation() {
        return this.batchCount() > 1 ? this.remoteBatches : this.remoteBatch;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
            this.report = data.report.report;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);
        this.hasRun = false;
        this.hasData = false;

        this.showCheckboxes =
            this.actionService.isAllowAction(
                'kits_tagging',
                'remote_batch_verification',
                'Show checkboxes for remote batch verification'
            ) &&
            this.actionService.isAllowAction(
                'administration',
                'verify_batch_external',
                'Show checkboxes for remote batch verification'
            );

        let allowUpdateExpiration = this.actionService.isAllowAction(
            'kits_inventory',
            'update_item_expirations',
            'Update Expirations'
        );
        let allowUpdateLot = this.actionService.isAllowAction('kits_inventory', 'update_batch_lots', 'Update Lots');
        this.showEditIcon = allowUpdateExpiration && allowUpdateLot;

        this.downloadFilters = {};

        this.setDisplayColumns();
    }

    updatePrintBatchDownloadFilters() {
        this.downloadFilters.exclude_fully_trusted = this.batchTab === 'untrusted' ? '1' : '0';
    }

    onTabChange(selection) {
        this.zone.run(() => {
            this.batchTab = selection;
            this.updatePrintBatchDownloadFilters();
            this.setDisplayColumns();
            this.filterReport(this.filterData);
        });
    }

    setDisplayColumns() {
        this.displayedColumns = [];
        if (this.batchTab === 'untrusted' && this.showCheckboxes) {
            this.displayedColumns.push('selector');
        }

        this.displayedColumns.push(
            'created_at',
            'full_name',
            'item_name',
            'ndc',
            'quantity_printed',
            'quantity_untrusted'
        );

        if (this.showEditIcon) {
            this.displayedColumns.push('edit_icon');
        }
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    //this function should be used to clean filters that are not cleaned identically as in report-subscription.
    cleanFilters(filterData) {
        let filters = Object.assign({}, filterData);

        if (!!filters.date) {
            filters.start_date = filters.date.start_date;
            filters.end_date = filters.date.end_date;
            delete filters.date;
        }

        filters = {
            ...filters,
            page: this.page,
            per_page: this.perPage,
            exclude_fully_trusted: this.batchTab === 'untrusted' ? '1' : '0',
        };

        filters = this.reportService.cleanFilters(filters);
        return filters;
    }

    loadPage(event) {
        this.perPage = event.pageSize;
        this.page = event.pageIndex + 1;
        this.filterReport(this.filterData);
    }

    filterReport(filterData?: any) {
        if (this.actionService.isAllowAction('kits_reporting', 'run_item_reports', 'Refresh reports') && this.report) {
            this.hasRun = false;
            this.hasData = false;
            if (filterData) {
                this.filterData = filterData; //needed for subscribeModal function;
            }
            const data = this.cleanFilters(filterData);
            this.downloadFilters = _.clone(data);
            this.downloadFilters.exclude_fully_trusted = this.batchTab === 'untrusted' ? '1' : '0';
            this.loadingSpinnerService.spinnerifyPromise(this.refreshReport(data));
        }
    }

    batchFilter() {
        if (this.batches) {
            return this.batches.filter((batch) => {
                return !(this.batchTab === 'untrusted' && batch.quantity_untrusted <= 0);
            });
        }
    }

    goToPrintBatchDetail(batch: any): void {
        const hospital_ownership = this.filterData.hospital_ownership;
        if (batch.tag_association_batch_id !== null) {
            this.router.navigate(['/report-print-batch-detail'], {
                queryParams: {
                    batchId: batch.tag_association_batch_id,
                    hospitalOwnership: hospital_ownership,
                },
            });
        } else {
            this.router.navigate(['/report-print-batch-detail'], {
                queryParams: {
                    formularyItemId: batch.formulary_item_id,
                    lotNum: batch.lot_num,
                    createdAt: batch.created_at_utc,
                    hospitalOwnership: hospital_ownership,
                },
            });
        }
    }

    anyChecked(): boolean {
        return this.batches.some((batch) => batch.checked);
    }

    batchCount(): number {
        return this.batches.filter((batch) => batch.checked).length;
    }

    toggleCheck(batch): void {
        batch.checked = !batch.checked;
    }

    remoteVerifyBatches() {
        const confirmDialog = this.dialog.open(RemoteBatchVerificationDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                batches: this.batches.filter((batch) => batch.checked),
                okButton: this.translationService.instant('buttons.submit'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.filterReport(this.filterData);
            }
        });
    }

    refreshReport(data: any): Promise<void> {
        return this.reportResource.printBatchReport(data).then((data: any): void => {
            this.hasRun = true;
            this.batches = data.reports.item_information;

            this.batches.forEach((item) => {
                item.full_name = `${item.first_name} ${item.last_name}`.trim();
            });

            this.hasData = this.batches.length > 0;
            this.reportDate = new Date();
            this.resultsLength = data.total_rows;
            this.dataSource = new MatTableDataSourceWithNaturalSort(this.batches);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.paginator.length = this.resultsLength;
        });
    }

    isEditableLine(item) {
        return this.showEditIcon && this.currentHospitalId === item.item_hospital_id;
    }

    editLotExp(item) {
        const editLotDialog = this.dialog.open(EditLotExpirationDialog, {
            width: '800px',
            height: 'max-content',
            data: { item: item, okButton: this.translationService.instant('buttons.submit') },
        });

        editLotDialog.afterClosed().subscribe((result) => {
            if (result) {
                let item = this.batches.find(
                    (batch) => batch.tag_association_batch_id === result.newItem.tag_association_batch_id
                );
                this.filterReport(this.filterData);
                result.newItem.expiration_formatted = moment(result.newItem.expiration).format('MMM DD, YYYY');

                if (result.type === 'lot') {
                    this.setLotSuccessMessage(result.newItem, item);
                } else if (result.type === 'expiration') {
                    this.setExpirationSuccessMessage(result.newItem);
                } else if (result.type === 'lotExp') {
                    this.setLotExpirationSuccessMessage(result.newItem, item);
                }
            }
        });
    }

    setLotSuccessMessage(newItem, item) {
        const translationValues = {
            packageCount: item.quantity_printed,
            packageText: item.quantity_printed === 1 ? this.package : this.packages,
            itemName: item.item_name,
            newLot: newItem.lot_num,
        };

        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
            key: 'admin.update_lots.success',
            params: translationValues,
        });
    }

    setExpirationSuccessMessage(newItem) {
        const count = newItem.quantity_printed;
        const date = newItem.expiration_formatted;

        const translationValues = {
            date,
            count,
            itemName: newItem.item_name,
            itemStrength: newItem.item_strength_formatted || '',
            itemStrengthUom: newItem.item_strength_uom || '',
            packageText: count === 1 ? this.package : this.packages,
        };

        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
            key: 'admin.update_expirations.updated',
            params: translationValues,
        });
    }

    setLotExpirationSuccessMessage(newItem, item) {
        const count = newItem.quantity_printed;
        const date = newItem.expiration_formatted;

        const translationValues = {
            date,
            count,
            itemName: newItem.item_name,
            itemStrength: newItem.item_strength_formatted || '',
            itemStrengthUom: newItem.item_strength_uom || '',
            packageText: count === 1 ? this.package : this.packages,
            newLot: newItem.lot_num,
        };

        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
            key: 'reports.lot_exp_success',
            params: translationValues,
        });
    }

    subscribeModal(event: any): void {
        const subscribeFilters = {
            start_date: event.start_date,
        };

        this.reportSubscriptionResource.frequenciesList().then((response) => {
            const frequencies = response.frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                },
            });
        });
    }
}
