import { Component, Input, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { ActionService } from '@services/utils/action.service';
import { AeroscoutDialog } from './aeroscout-dialog/aeroscout-dialog';
import { AeroscoutService } from '@services/hardware/aeroscout.service';
import { AeroscoutResource } from '@resources/aeroscout-resource.service';
import { BarcodeImgComponent } from '@components/common/barcode-img/barcode-img';
import { CartResource } from '@resources/cart-resource.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { HospitalResource } from '@resources/hospital-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { UploadCsvDialog } from '@components/dialogs/upload-csv/upload-csv-dialog';

import { AdminCart } from '@models/admin/admin-cart';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'admin-carts-list',
    templateUrl: './admin-carts-list.html',
    styleUrls: ['../admin.scss'],
})
export class AdminCartsList {
    //bindings
    carts: AdminCart[];

    actionAllow: Function;
    aeroscoutEnabled: boolean;
    aeroscoutCanBeEnabled: boolean;
    aeroscoutUrl: string;

    isLoading: boolean = true;
    hasData: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name', 'barcode'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private aeroscoutResource: AeroscoutResource,
        private aeroscoutService: AeroscoutService,
        private cartResource: CartResource,
        private configuration: ConfigurationProvider,
        private dialog: MatDialog,
        private hospitalResource: HospitalResource,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private loadingSpinnerService: LoadingSpinnerService,
        private localStorageService: LocalStorageService,
        protected groupLoginService: GroupLoginService,
        protected router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.carts = data.carts;
        });

        this.actionAllow = this.actionService.allowModule('hospital_settings');
        this.aeroscoutEnabled = this.hospitalInfoService.getHospitalSettings().aeroscout_enabled;

        this.aeroscoutCanBeEnabled = this.hospitalInfoService.getHospitalSettings().aeroscout_enabled_internal;
        this.aeroscoutService.getAeroscoutUrl().then((url: string) => {
            this.aeroscoutUrl = url;
        });
    }

    ngAfterViewInit(): void {
        if (this.aeroscoutEnabled) {
            this.displayedColumns = ['name', 'aeroscout_asset_name_id', 'barcode'];
        }

        this.dataSource = new MatTableDataSourceWithNaturalSort(this.carts);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.hasData = !!this.carts.length;
    }

    authToken() {
        return this.groupLoginService.authToken();
    }

    toggleAeroscout(): void {
        if (!this.aeroscoutEnabled) {
            this.enableAeroscout();
            this.displayedColumns = ['name', 'aeroscout_asset_name_id', 'barcode'];
        } else {
            this.updateAeroscoutSetting(false);
            this.displayedColumns = ['name', 'barcode'];
        }
    }

    enableAeroscout(): void {
        const aeroscoutDialog = this.dialog.open(AeroscoutDialog, {
            width: '600px',
            height: 'max-content',
            data: { url: this.aeroscoutUrl },
        });

        aeroscoutDialog.afterClosed().subscribe((url) => {
            if (url) {
                this.aeroscoutUrl = url;

                this.updateAeroscoutSetting(true)
                    .then(() => {
                        let promise = this.aeroscoutResource.postAeroscoutUrl(url);

                        this.loadingSpinnerService.spinnerifyPromise(promise, LoadingSpinnerTypes.PANEL).then(() => {
                            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                                key: 'hospital_settings.updated_message',
                            });
                        });
                    })
                    .then(() => {
                        this.aeroscoutService.clearAeroscoutUrl();
                        this.localStorageService.remove('aeroscoutCredentials');
                    })
                    .catch((e) => {
                        this.updateAeroscoutSetting(false);
                    });
            } else {
                this.updateAeroscoutSetting(false);
            }
        });
    }

    updateAeroscoutSetting(aeroscoutEnabled): Promise<any> {
        let setting = { aeroscout_enabled: aeroscoutEnabled };
        return this.hospitalResource.updateHospital(this.hospitalInfoService.getHospitalId(), setting).then(() => {
            this.aeroscoutEnabled = aeroscoutEnabled;
            this.localStorageService.set('hospitalSettings', {
                ...this.hospitalInfoService.getHospitalSettings(),
                ...setting,
            });
            return Promise.resolve();
        });
    }

    goToCreate(): void {
        this.router.navigate(['/admin/carts/create']);
    }

    showUploadModal(): void {
        const uploadDialog = this.dialog.open(UploadCsvDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                noun: 'cart',
                url: `${this.configuration.kcEndpointV1()}carts/import`,
                authToken: this.authToken(),
            },
        });

        uploadDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const promise = this.cartResource
                    .cartList()
                    .then((response) => {
                        this.carts = response.carts;
                        this.dataSource = new MatTableDataSourceWithNaturalSort(this.carts);
                        this.dataSource.sort = this.sort;
                    })
                    .catch((err) => {
                        if (!!err.message) {
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                    });
                this.loadingSpinnerService.spinnerifyPromise(promise);
            }
        });
    }
}
