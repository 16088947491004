import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '@components/common/table-component';
import { ScanningDialog } from '@components/dialogs/scanner/scanning-dialog/scanning-dialog';
import { Container } from '@models/core/container';
import { ContainerScan } from '@models/core/container-scan';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

@Component({
    selector: 'containers-scans',
    templateUrl: './containers-scans.html',
    styleUrls: ['./containers-scans.scss'],
})
export class ContainersScans extends TableComponent {
    container: Container;
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;
    scanningDialog: MatDialogRef<any>;
    containerId: number;
    recentScans: ContainerScan[];
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSourceWithNaturalSort<ContainerScan>;
    displayedColumns: string[] = [
        'created_at',
        'counts.total',
        'counts.missing',
        'counts.expired',
        'counts.recalled',
        'counts.expiring_soon',
    ];
    hasData: boolean;
    collectionSize: number;
    pageSize: number = 25;
    pageIndex: number = 0;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.containerId = parseInt(paramMap.get('id'));

            this.loadingSpinnerService.spinnerifyPromise(
                Promise.all([this.fetchContainerData(), this.fetchScansData()])
            );
        });
    }

    fetchScansData(pagination?) {
        const paginationParams = pagination || {
            page_size: 25,
            page_index: 1,
        };

        const fetchScansPromise = this.containerResource
            .getAllContainerScans(this.containerId, paginationParams)
            .then((scans) => {
                this.recentScans = scans.entries;
                this.collectionSize = scans.pagination.total_entries;
                this.dataSource = new MatTableDataSourceWithNaturalSort(this.recentScans);
                this.hasData = this.dataSource?.data.length > 0;

                setTimeout(() => {
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) {
                            return property.split('.').reduce((o, i) => o?.[i] || '', item);
                        }

                        return item?.[property];
                    };

                    this.dataSource.sort = this.sort;
                });
            });

        this.loadingSpinnerService.spinnerifyPromise(fetchScansPromise);
    }

    fetchContainerData() {
        if (!this.containerId) {
            return;
        }

        return this.containerResource.getContainer(this.containerId).then(({ container }) => {
            this.container = container;
        });
    }

    handlePageEvent(event: PageEvent) {
        if (!event.hasOwnProperty('pageIndex') && !event.hasOwnProperty('pageSize')) {
            return;
        }
        if (event.pageSize !== this.pageSize) {
            this.pageSize = event.pageSize;
            this.pageIndex = 0;
            this.fetchScansData(this.pageParams());
        } else if (event.pageIndex !== this.pageIndex) {
            this.pageIndex = event.pageIndex;
            this.fetchScansData(this.pageParams());
        }
    }

    pageParams() {
        return {
            page_index: (this.pageIndex || 0) + 1,
            page_size: this.pageSize || 25,
        };
    }

    getUnitMeasure(value) {
        return value > 1 || value === 0 ? 'containers.container_summary.units' : 'containers.container_summary.unit';
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    startScan() {
        this.scanningDialog = this.dialog.open(ScanningDialog, {
            width: 'max-content',
            height: 'max-content',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                scanner: this.container.hardware,
                stayAfterScan: true,
            },
        });

        this.scanningDialog.afterClosed().subscribe((scanResult) => {
            if (scanResult.id) {
                this.router.navigate([`/inventory/containers/${this.containerId}/${scanResult.id}`]);
            }
        });
    }
}
