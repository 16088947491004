import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationProvider } from '@services/config/configuration';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ActionService {
    //attributes
    hasActionLogging: Boolean;

    constructor(
        private configuration: ConfigurationProvider,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute
    ) {
        route.queryParamMap.subscribe(
            (queryParamMap) => (this.hasActionLogging = !!queryParamMap.get('actionLogging'))
        );
    }

    actionLogging(): boolean {
        return this.hasActionLogging && this.configuration.getConfiguration().environment !== 'production';
    }

    isAllowAction(module, action, reason, returnParams?): any {
        const actions = this.localStorageService.get('actions') || {};
        const actionObject = actions[module] ? actions[module].find((module) => module.name === action) : null;
        // Used for `on_behalf_of_hospital` nested action permissions
        if (returnParams) {
            const paramData = _(actions[module])
                .filter({
                    name: action,
                })
                .map(returnParams)
                .filter((data) => {
                    return data ? true : false;
                })
                .value();

            if (this.actionLogging()) {
                console.log(
                    'ActionService CHECK: module: ',
                    module,
                    ' - action: ',
                    action,
                    ' - reason: ',
                    reason,
                    ' - paramData:',
                    paramData
                );
            }
            return paramData;
        } else {
            const actionAllowed = actionObject ? true : false;
            if (this.actionLogging()) {
                console.log(
                    'ActionService CHECK: module: ',
                    module,
                    ' - action: ',
                    action,
                    ' - reason: ',
                    reason,
                    ' - ',
                    actionAllowed ? 'ALLOWED' : 'DENIED'
                );
            }
            return actionAllowed;
        }
    }

    allowModule(module) {
        return (action, reason, returnParams?) => {
            return this.isAllowAction(module, action, reason, returnParams);
        };
    }
}
