import { Component } from '@angular/core';
import * as moment from 'moment';

import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { ReportResource } from '@services/resources/report-resource.service';
import { Report } from '@models/core/report';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'virginia-board-of-pharmacy',
    templateUrl: './report-virginia-board-of-pharmacy.html',
    styleUrls: ['./report-virginia-board-of-pharmacy.scss'],
})
export class ReportVirginiaBoardOfPharmacy {
    // Bindings
    reports: Report[];

    filters: {
        start_date: Date;
        end_date: Date;
    };

    reportDate: Date = null;
    bopData;

    constructor(
        private productModuleService: ProductModuleService,
        private actionService: ActionService,
        private loadingSpinnerService: LoadingSpinnerService,
        protected reportResource: ReportResource,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);

        this.filters = {
            start_date: moment().subtract(90, 'days').toDate(),
            end_date: moment().toDate(),
        };

        this.refreshReport();
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(): void {
        if (this.actionService.isAllowAction('kits_reporting', 'run_item_reports', 'Refresh reports')) {
            const reportPromise = this.reportResource.virginiaBoardOfPharmacyReport(this.filters).then((data) => {
                this.bopData = data;
                this.reportDate = new Date();
            });
            this.loadingSpinnerService.spinnerifyPromise(reportPromise);
        }
    }
}
