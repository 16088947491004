import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { TranslationService } from '@services/utils/translation.service';
import { UserListService } from '@services/core/user-list.service';
import { UserResource } from '@resources/user-resource.service';

import { LongUsernamePipe } from '@pipes/long-username.pipe';

import { GroupMap } from '@models/admin/admin-group-map';
import { GroupRole } from '@models/core/group';

import { isEmpty, deleteEmptyKeys, areDeepEqual } from '@utils/objects';

@Component({
    selector: 'admin-users-create',
    templateUrl: './admin-users-create.html',
    styleUrls: ['./admin-users-create.scss'],
})
export class AdminUsersCreate {
    private _defaultUser: any = {};

    //bindings
    groupRoles: GroupMap;
    user: any;

    attempted: boolean = false;
    formError: boolean = false;
    loading: boolean = false;
    badgeSet: boolean = false;
    badgeRegisterType: string;
    attemptBadgeSave: boolean = false;
    badgeValid: boolean = false;
    badgeChanged: boolean = false;

    selectedGroup: number;
    selectedGroupRoles: GroupRole[];
    updateMode: boolean;
    actionAllow: Function;

    constructor(
        private actionService: ActionService,
        private applicationService: ApplicationService,
        private cdRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private localStorageService: LocalStorageService,
        private loadingSpinnerService: LoadingSpinnerService,
        private translationService: TranslationService,
        private userListService: UserListService,
        private userResource: UserResource,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.groupRoles = data.groupRoles;
            this.user = data.user;
        });

        this.activatedRoute.paramMap.subscribe(
            (paramMap) => (this.selectedGroup = parseInt(paramMap.get('selectedGroup')))
        );
        this.actionAllow = this.actionService.allowModule('user_settings');
        this.updateMode = !!this.user;
        this.user = { ...this._defaultUser, ...this.user };

        if (this.user.tag) {
            this.badgeSet = true;
            this.user.barcode = this.user.tag.epc;
            this.user.oldBarcode = this.user.tag.epc;
        }

        this.badgeRegisterType = this.updateMode ? 'update' : 'create';
        this.resetUserFormPin();
        if (this.updateMode) {
            this.selectedGroupRoles = this.user.roles_for_group;
            this.selectedGroupRoles.forEach((groupRole) => {
                groupRole.role_ids = groupRole.roles.map((role) => role.id);
            });
        }
    }

    onActivateBadgeSave(badgeValid): void {
        this.attemptBadgeSave = true;
        this.badgeValid = badgeValid;
        this.badgeChanged = true;
        this.cdRef.detectChanges();
    }

    // gets emitted when the register-badge cancels or removes the badge
    onDeactivateBadgeSave(badgeChanged): void {
        this.attemptBadgeSave = false;
        this.badgeValid = false;
        this.badgeChanged = badgeChanged;
        this.cdRef.detectChanges();
    }

    allowSave(userForm: NgForm): boolean {
        let validForm = userForm.valid;
        let isNotLoading = !this.loading;
        let hasGroups = !this.noGroupRoles();
        let attemptingBadgeSet = this.hasBadges() && this.attemptBadgeSave;

        if (this.updateMode && !attemptingBadgeSet) {
            return validForm && isNotLoading && hasGroups && this.formUpdated(userForm);
        } else {
            return validForm && isNotLoading && hasGroups && (this.badgeValid || !attemptingBadgeSet);
        }
    }

    validateForm(userForm: NgForm): boolean {
        this.attempted = true;
        let formVal = userForm.valid;
        this.formError = this.noGroupRoles() || !formVal;

        return !this.formError;
    }

    formUpdated(userForm: NgForm): boolean {
        if (this.noGroupRoles()) {
            return false;
        }
        return userForm.dirty || !areDeepEqual(this.user.roles_for_group, this.selectedGroupRoles) || this.badgeChanged;
    }

    resetUserFormPin() {
        this.user.epc_pin = '';
        this.user.retypePin = '';
    }

    save(userForm: NgForm): Promise<any> {
        if (!this.validateForm(userForm)) {
            return;
        }

        this.loading = true;
        this.user.username = this.user.email;
        this.user.group_roles = this.selectedGroupRoles;

        const userData = _.cloneDeep(this.user);
        userData.first_name = userData.first_name.trim();
        userData.last_name = userData.last_name.trim();

        let createPromise = this.userResource.createUser(deleteEmptyKeys(userData));
        createPromise = createPromise
            .then((user) => {
                this.userListService.clear();
                this.router.navigate(['/admin/users']);
                return user.id;
            })
            .catch((err) => {
                if (!isEmpty(err)) {
                    this.translationService.get('admin.users.errors.could_not_add_user').then((translation) => {
                        let message = err.message ? err.message : translation;
                        this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                    });
                }
                throw 'ChainBreak';
            });

        if (userData.barcode) {
            createPromise = createPromise.then((userId) =>
                this.userResource.addOrUpdateUserBadge(userId, userData.barcode).catch((err) => {
                    if (err !== 'ChainBreak') {
                        this.translationService.get('admin.users.errors.could_not_add_badge').then((translation) => {
                            let barcodeError = _.get(err, 'message', translation);
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, barcodeError);
                        });
                    }
                    throw 'ChainBreak';
                })
            );
        }

        createPromise = createPromise.then(() => {
            setTimeout(() => {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'admin.users.new_user_created',
                    params: { firstName: userData.first_name, lastName: userData.last_name },
                });
            }, 500);
        });

        createPromise = createPromise.finally(() => {
            this.loading = false;
        });

        return createPromise;
    }

    update(userForm: NgForm): Promise<any> {
        if (!this.validateForm(userForm)) {
            return;
        }

        this.attemptBadgeSave = false;
        this.loading = true;

        let updateUser: any = {
            id: this.user.id,
            first_name: this.user.first_name.trim(),
            last_name: this.user.last_name.trim(),
            email: this.user.email,
            username: this.user.username,
        };

        updateUser.group_roles = this.selectedGroupRoles;

        if (
            this.user.password &&
            (this.actionAllow('change_pwd', 'Send password in user update') ||
                this.actionAllow('change_other_pwd', 'Send password in user update'))
        ) {
            updateUser.password = this.user.password;
        }
        if (
            this.user.epc_pin &&
            (this.actionAllow('change_pin', 'Send pin in user update') ||
                this.actionAllow('change_other_pin', 'Send pin in user update'))
        ) {
            updateUser.epc_pin = this.user.epc_pin;
        }
        if (
            this.currentUser() &&
            this.user.current_password &&
            this.actionAllow('change_pwd', 'Send current password in user update')
        ) {
            updateUser.current_password = this.user.current_password;
        }
        if (
            this.currentUser() &&
            this.user.current_pin &&
            this.actionAllow('change_pin', 'Send current pin in user update')
        ) {
            updateUser.current_pin = this.user.current_pin;
        }

        updateUser = deleteEmptyKeys(updateUser);
        let updatePromise = this.userResource.updateUser(updateUser);
        let barcodeError = null;

        updatePromise = updatePromise.catch((err) => {
            this.translationService.get('admin.users.errors.could_not_update_user').then((translation) => {
                let message = err.message ? err.message : translation;
                this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
            });
            throw 'ChainBreak';
        });

        if (this.user.barcode !== this.user.oldBarcode) {
            updatePromise = updatePromise
                .then(() => {
                    if (this.user.barcode === undefined) {
                        return this.userResource.removeUserBadge(this.user.id);
                    } else {
                        return this.userResource.addOrUpdateUserBadge(this.user.id, this.user.barcode);
                    }
                })
                .catch((err) => {
                    if (err !== 'ChainBreak') {
                        this.translationService
                            .get([
                                'admin.users.errors.could_not_update_badge',
                                'admin.users.errors.could_not_remove_badge',
                            ])
                            .then(
                                ({
                                    'admin.users.errors.could_not_update_badge': couldNotUpdate,
                                    'admin.users.errors.could_not_remove_badge': couldNotRemove,
                                }) => {
                                    let message = err?.message
                                        ? err.message
                                        : this.user.barcode
                                        ? couldNotUpdate
                                        : couldNotRemove;
                                    this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                                }
                            );
                    }
                    throw 'ChainBreak';
                });
        }

        updatePromise = updatePromise
            .then(() => {
                this.userListService.clear();
                this.loadingSpinnerService
                    .spinnerifyPromise(this.router.navigate(['/admin/users']), LoadingSpinnerTypes.PANEL)
                    .then(() => {
                        this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                            key: 'admin.users.user_updated',
                            params: { firstName: this.user.first_name, lastName: this.user.last_name },
                        });
                    });
                if (parseInt(this.user.id, 10) === parseInt(this.localStorageService.get('userId'), 10)) {
                    this.localStorageService.set('firstName', this.user.first_name);
                    this.localStorageService.set('lastName', this.user.last_name);
                    this.applicationService.user.formatted_name = new LongUsernamePipe().transform(
                        this.user.first_name,
                        this.user.last_name
                    );
                }
            })
            .finally(() => {
                this.loading = false;
            });

        return updatePromise;
    }

    async remove() {
        const description = await this.translationService.get('modals.confirm_delete.confirm', {
            objName: this.user.full_name,
        });
        const objType = await this.translationService.get('admin.users.user');
        const title = await this.translationService.get('modals.confirm_delete.header', { objType: objType });

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                title: title,
                description: description,
                okButton: this.translationService.instant('buttons.delete'),
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const removePromise = this.userResource
                    .removeUser(this.user)
                    .then(() => {
                        this.userListService.clear();
                        this.loadingSpinnerService
                            .spinnerifyPromise(this.router.navigate(['/admin/users']), LoadingSpinnerTypes.PANEL)
                            .then(() => {
                                return this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                                    key: 'admin.users.deleted_user',
                                    params: { firstName: this.user.first_name, lastName: this.user.last_name },
                                });
                            });
                    })
                    .catch((err) => {
                        this.translationService.get('admin.users.errors.could_not_remove_user').then((translation) => {
                            let message = err.message ? err.message : translation;
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, message);
                        });
                    });

                this.loadingSpinnerService.spinnerifyPromise(removePromise, LoadingSpinnerTypes.PANEL);
            }
        });
    }

    currentUser(): boolean {
        if (this.user.id) {
            return parseInt(this.localStorageService.get('userId')) === this.user.id;
        } else {
            return false;
        }
    }

    hasBadges(): boolean {
        return this.hospitalInfoService.hospitalSetting('has_badges');
    }

    cancel(): void {
        this.router.navigate(['/admin/users']);
    }

    showPasswordFields(): boolean {
        if (this.updateMode) {
            return (
                this.hospitalInfoService.hospitalSetting('login_method') !== 'idp_only' &&
                ((this.currentUser() && this.actionAllow('change_pwd', 'Show password fields')) ||
                    (!this.currentUser() && this.actionAllow('change_other_pwd', 'Show password fields')))
            );
        } else {
            return this.hospitalInfoService.hospitalSetting('login_method') !== 'idp_only';
        }
    }

    showPinFields(): boolean {
        if (this.updateMode) {
            return (
                this.hasBadges() &&
                ((this.currentUser() && this.actionAllow('change_pin', 'Show pin fields')) ||
                    (!this.currentUser() && this.actionAllow('change_other_pin', 'Show pin fields')))
            );
        } else {
            return this.hasBadges();
        }
    }

    pinRequired(): boolean {
        let hasPin = !this.user.has_epc_pin;
        let hasBarcode = !!this.user.barcode;
        return hasPin && hasBarcode;
    }

    noGroupRoles(): boolean {
        if (isEmpty(this.selectedGroupRoles)) {
            return true;
        } else {
            return isEmpty(_.flatten(_.map(this.selectedGroupRoles, 'roles')));
        }
    }
}
