import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { FormularyItemResource } from '@resources/formulary-item-resource.service';

@Injectable({ providedIn: 'root' })
export class FormularyItemResolver {
    formularyItemId: number;
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemResource: FormularyItemResource
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        this.formularyItemId = route.params.formularyItemId;
        const formularyPromise = this.formularyItemResource
            .formularyItem(this.formularyItemId)
            .then(({ formulary_item }) => formulary_item);

        return this.loadingSpinnerService.spinnerifyPromise(formularyPromise);
    }
}
