import { Input, OnInit, Component, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { Tagging } from '@components/tags/tagging';

import { ConfirmDialog } from '@dialogs/confirm/confirm-dialog';
import { ResetPrinterDialog } from '@dialogs/reset-printer/reset-printer-dialog';

import { ActionService } from '@services/utils/action.service';
import { arrayUnique } from '@utils/objects';
import { BarcodeResource } from '@resources/barcode-resource.service';
import { BarcodeService } from '@services/core/barcode.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { KitResource } from '@resources/kit-resource.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { NdcScanUtilsService } from '@services/utils/ndc-scan-utils.service';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';
import { PrintService } from '@services/hardware/print.service';
import { TagAssociationResource } from '@resources/tag-association-resource.service';
import { TranslationService } from '@services/utils/translation.service';

import { LastPipe } from '@pipes/last.pipe';

@Component({
    selector: 'tagging-kit',
    templateUrl: './tagging-kit.html',
    styleUrls: ['./tagging-kit.scss'],
})
export class TaggingKit {
    @Input() kitMasters;
    @Input() type;
    @Input() printers;
    @Input() tag;

    defaultErrorMessage: string;
    kits = [];
    kitShared: any = {};
    existingKits: any = [];
    kitsToDedupe: any = [];
    printComplete: boolean = false;
    tagDuplicate: string;
    tagError: string;
    tryingToStartPrint: boolean;
    validators: {
        [key: string]: Function;
    };
    snackBarRef: MatSnackBar;
    flag: boolean;
    boundClearSharedEpc: Function;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name'];
    barcode: string;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('kitStep') kitStep: MatStep;
    @ViewChild('kitLabelStep') kitLabelStep: MatStep;
    @ViewChild('printStep') printStep: MatStep;
    @ViewChild('doneStep') doneStep: MatStep;
    @ViewChild('kitForm') kitForm: NgForm;
    @ViewChild('kitLabelForm') kitLabelForm: NgForm;
    @ViewChild('printForm') printForm: NgForm;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private actionService: ActionService,
        private barcodeResource: BarcodeResource,
        private barcodeScanService: BarcodeScanService,
        private barcodeService: BarcodeService,
        private hardwareService: HardwareService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private kitMasterService: KitMasterService,
        private kitResource: KitResource,
        private ndcScanUtilsService: NdcScanUtilsService,
        private parseFormErrorsService: ParseFormErrorsService,
        private printService: PrintService,
        private translationService: TranslationService,
        private tagAssociationResource: TagAssociationResource
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe((queryParamMap) => (this.barcode = queryParamMap.get('barcode')));
        this.initValidators();

        this.defaultErrorMessage = this.translationService.instant('tagging.system_error');

        this.barcodeScanService.blockListeners();

        this.boundClearSharedEpc = this.clearSharedEpc.bind(this);

        //main
        this.startNewPrint();

        // get the kit list so we can check physical labels against the list to avoid dupes.
        this.kitResource.kitList().then((results) => {
            this.existingKits = results.kits;
        });

        this.hardwareService.getDefaultPrinter().then((printer) => {
            this.kitShared.printer = printer;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.kitMasters) {
            this.refreshKitMasters();
        }
    }

    ngAfterViewInit() {
        this.refreshKitMasters();
    }

    refreshKitMasters() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.kitMasters);
        this.dataSource.sort = this.sort;
        this.clearFilter();
    }

    isNDC(barcode: string): boolean {
        return this.ndcScanUtilsService.extractNDCFromScan(barcode) !== barcode;
    }

    setFilter(): void {
        this.dataSource.filter = this.kitShared.filter;
    }

    clearFilter(): void {
        this.kitShared.filter = '';
        this.setFilter();
    }

    startNewPrint(): void {
        this.kitShared = {
            id: '',
            scannedEpc: '',
            epc: null,
            segment: null,
            printer: null,
            tagQuantity: 1,
            timeoutError: false,
        };

        this.kits = [];
        this.printComplete = false;

        if (!!this.type.batch) {
            this.kitShared.epc = this.tag.epc;
            this.kits.push({
                epc: this.kitShared.epc,
                physical_label: '',
            });
        } else {
            if (this.barcode && this.type.name === 'kit') {
                if (!this.isNDC(this.barcode)) {
                    this.kits.push({
                        epc: this.barcode,
                        physical_label: '',
                    });
                }
            }
        }
        this.hardwareService.getDefaultPrinter().then((printer) => {
            this.kitShared.printer = printer;
        });
    }

    // I didn't make these click actions on the buttons
    // because you can transition states by clicking on the next state header
    // as well, so all validations and transitions need to work in both cases
    //

    // as such we implement the additional validations as custom input fields
    // directly inside the forms
    //

    resetStepper(): void {
        this.printComplete = false;
        this.stepper.reset();
        // This seems weird, but if you don't aggressively reset the models the forms don't reset well
        this.kitForm?.resetForm();
        this.kitLabelForm?.resetForm();
        this.printForm?.resetForm();
        this.ngOnInit();
    }

    initValidators(): void {
        this.validators = {
            kit: () => {
                return !!this.kitShared.master;
            },
            kitLabel: () => {
                return this.kits.length > 0;
            },
            print: () => {
                return this.printComplete;
            },
        };
    }

    readyToPrint(): boolean {
        return !this.isPrinting() && this.kits.length > 0 && !!this.kitShared.printer;
    }

    isPrinting(): boolean {
        return this.printService.printing();
    }

    // uses the state names for clarity vs indices
    //

    selectionChange(event: StepperSelectionEvent): void {
        if (event.selectedIndex < event.previouslySelectedIndex) {
            this.stepper.steps.forEach((step, index) => {
                if (index > event.selectedIndex) {
                    step.interacted = false;
                }
            });
        }

        if (this.fromTo(event, this.kitStep, this.kitLabelStep)) {
            this.transitionToKitLabelStep();
        }

        if (this.fromTo(event, this.kitLabelStep, this.doneStep)) {
            this.associateLabels();
        }
    }

    setInputFocus(): void {
        let focusField;
        if (this.isSelected(this.kitStep)) {
            focusField = document.getElementById('kit_master_search');
            if (focusField) {
                focusField.focus();
            }
            return;
        }

        if (this.isSelected(this.kitLabelStep)) {
            focusField = document.getElementById('tagQuantity');
            if (focusField) {
                focusField.focus();
            }
            this.kitLabelUnique(false);
            return;
        }

        if (this.isSelected(this.printStep)) {
            focusField = document.getElementById('kit-print-select');
            if (focusField) {
                focusField.focus();
            }
            return;
        }
    }

    isSelected(step: MatStep): boolean {
        return !!this.stepper && this.stepper.selected === step;
    }

    fromTo(transition: StepperSelectionEvent, from: MatStep, to: MatStep): boolean {
        return transition.previouslySelectedStep === from && transition.selectedStep === to;
    }

    removeKit(kit): void {
        if (this.tagDuplicate === kit.epc) {
            this.tagDuplicate = undefined;
        }

        _.remove(this.kits, (k: any) => {
            return k.epc === kit.epc;
        });
    }

    resetPrinter(): void {
        const resetPrinterDialog = this.dialog.open(ResetPrinterDialog, {
            width: '600px',
            height: 'max-content',
        });
    }

    addKit(event): void {
        this.tagDuplicate = undefined;
        this.kitShared.scannedEpc = event;
        const scanValues =
            this.kitShared.scannedEpc && this.barcodeScanService.processBarcode(this.kitShared.scannedEpc);

        if (scanValues) {
            this.tagError = '';
            const epcList = this.kits.map((kit) => kit.epc);
            if (!_.includes(epcList, scanValues.epc)) {
                this.barcodeService
                    .barcodeObject(scanValues.epc)
                    .then((data) => {
                        if (data.object['class'] === 'Tag' && data.object['decommissioned'] === false) {
                            this.kits.push({
                                epc: scanValues.epc,
                                physical_label: '',
                            });
                        } else {
                            let errorKey;
                            if (data.object['decommissioned'] === true) {
                                errorKey = 'tagging.tag_decommissioned';
                            } else if (data.object['class'] === 'Package') {
                                errorKey = 'tagging.tag_already_associated_with_package';
                            } else if (data.object['class'] === 'Kit') {
                                errorKey = 'tagging.tag_already_associated_with_kit';
                            } else if (data.object['class'] === 'Bin') {
                                errorKey = 'tagging.tag_already_associated_with_bin';
                            } else {
                                errorKey = 'tagging.tag_already_associated';
                            }

                            this.translationService.get(errorKey, { epc: scanValues.epc }).then((translation) => {
                                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, translation);
                            });
                        }
                    })
                    .catch((error) => {
                        this.tagError = error.message;
                    });
            } else {
                this.tagDuplicate = scanValues.epc;
            }

            this.kitShared.scannedEpc = '';
        }
    }

    transitionToKitLabelStep(): void {
        if (this.type.print) {
            const q = this.kitShared.tagQuantity;
            this.changeKitList(q);
        }
    }

    changeKitList(desired: number): void {
        // delay by 1 sec
        setTimeout(() => {
            // no more than 25
            if (desired > 25) {
                desired = 25;
            }
            let cur = this.kits.length;
            if (cur === 0) {
                this.kits = _.map(_.range(desired), (i) => ({ id: desired > 1 ? i + 1 : undefined }));
            } else if (cur < desired) {
                if (!this.kits[0].id) {
                    this.kits[0].id = 1;
                }
                while (cur < desired) {
                    this.kits.push({ id: cur + 1 });
                    cur++;
                }
            } else {
                this.kits = this.kits.slice(0, desired);
            }
        }, 1000);
    }

    printKey(): string {
        return this.kitShared.timeoutError ? 'print_item.reprint' : 'common.print';
    }

    associateKey(): string {
        return this.kits.length > 1 ? 'print_kit.add_kits' : 'print_kit.add_kit';
    }

    selectKitMaster(kitMaster): void {
        if (kitMaster.allow_new_tags) {
            this.kitShared.master = kitMaster;
            this.kitsToDedupe = this.existingKits.filter((k) => {
                return k['kit_master']['id'] === kitMaster.id;
            });
        }
    }

    associateLabels(): void {
        this.kcMatSnackBarService.clearAll();

        const physicalLabels = this.kits.length;

        const finishAssociation = () => {
            const translationKey = this.kits.length > 1 ? 'tagging.tags_created' : 'tagging.tag_created';
            const tagCreated = this.translationService.instant(translationKey);
            const message = `${this.kitShared.master.name} - ${physicalLabels} ${tagCreated}`;
            this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, message);
        };

        const associationError = (data) => {
            this.snackBarRef = this.kcMatSnackBarService.open(
                SnackBarTypes.ERROR,
                data?.message || this.defaultErrorMessage
            );
        };

        this.tagAssociationResource
            .associateKitTag(null, this.kitShared.master.id, null, this.kits)
            .then(finishAssociation)
            .catch(associationError);
    }

    printLabels(): void {
        this.kitShared.timeoutError = false;
        this.tryingToStartPrint = true;

        const physicalLabels = `${this.kits[0].id}`;

        const printSuccess = () => {
            this.printComplete = true;
            this.printForm.controls['printValidator'].setValue(true);
            this.stepper.next();

            const translationKey =
                this.kits.length > 1 ? 'print_kit.print_started_plural' : 'print_kit.print_started_singular';

            this.translationService.get(translationKey, values).then((translation) => {
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.SUCCESS, translation);
            });
        };

        const printError = (data) => {
            const reason = data.reason;
            const unknownError = this.translationService.instant('errors.unknown_print_error');

            if (reason === 'timeout') {
                setTimeout(() => {
                    this.kitShared.timeoutError = true;
                });
            } else if (reason === 'encoding') {
                this.router.navigate(['/print-error']);
            } else if (reason === 'zpl') {
                this.snackBarRef = this.kcMatSnackBarService.open(
                    SnackBarTypes.ERROR,
                    data?.message || this.defaultErrorMessage
                );
            } else if (reason === 'unknown error') {
                this.snackBarRef = this.kcMatSnackBarService.open(SnackBarTypes.ERROR, unknownError);
            } else {
                // noop -- treat this as a success because it's not one of our known errors
            }
        };

        const kitData = Object.assign(_.cloneDeep(this.kitShared), { kits: this.kits }) as any;
        const kitType = kitData.master.name;

        const values = {
            kitType,
            physicalLabels,
        };

        this.hardwareService.setDefaultPrinter(kitData.printer.id.toString());
        this.printService
            .printKitTag(kitData)
            .then(printSuccess)
            .catch(printError)
            .finally(() => {
                this.tryingToStartPrint = false;
            });
    }

    kitLabelInfo(): string {
        let labelInfo: string = '';
        if (this.kits.length > 0) {
            if (this.type.print) {
                labelInfo = this.kits.map((kit) => kit.id).join(', ');
            } else {
                labelInfo = this.kits.map((kit) => `${kit.epc}: ${kit.physical_label}`).join(', ');
            }
        }

        return labelInfo;
    }

    kitLabelUnique(showDialog: boolean = true): void {
        let matchingKits = [];

        let enteredPhysicalLabels = [];
        if (this.type.print) {
            enteredPhysicalLabels = this.kits.map((kit) => kit.id.toString().toLowerCase());
        } else {
            enteredPhysicalLabels = this.kits.map((kit) => kit.physical_label.toString().toLowerCase());
        }

        this.kits.forEach((kit, i) => {
            let kitLabel;
            if (this.type.print) {
                if (!!kit.id) {
                    kitLabel = kit.id.toString().toLowerCase();
                }
            } else {
                if (!!kit.physical_label) {
                    kitLabel = kit.physical_label.toString().toLowerCase();
                }
            }

            if (kitLabel) {
                //  check for dupes against previously created kits
                let matchingKit = this.kitsToDedupe.find((k) => {
                    if (!!k) {
                        const label = k['physical_label'] as String;
                        return !!label && label.toLowerCase() === kitLabel;
                    }
                });
                if (!!matchingKit) {
                    if (this.type.print) {
                        matchingKits.push({ label: kitLabel, labelCtrl: i });
                    } else {
                        matchingKits.push({ label: kitLabel, labelCtrl: i, epc: kit.epc });
                    }
                } else if (enteredPhysicalLabels.length > 1) {
                    // check for dupes against other entered labels on the form
                    let alreadyEnteredLabel = enteredPhysicalLabels.filter((label) => {
                        return label.toLowerCase() === kitLabel;
                    });
                    if (alreadyEnteredLabel.length > 1) {
                        if (this.type.print) {
                            matchingKits.push({ label: kitLabel, labelCtrl: i });
                        } else {
                            matchingKits.push({ label: kitLabel, labelCtrl: i, epc: kit.epc });
                        }
                    } else {
                        this.clearUniqueErrors([{ label: kitLabel, labelCtrl: i, epc: kit['epc'] }]);
                    }
                }
            }
        });

        if (matchingKits.length > 0) {
            if (showDialog) {
                this.confirm(matchingKits);
                return;
            } else {
                this.setUniqueErrors(null, matchingKits);
            }
        }
        return;
    }

    confirm(kits) {
        const kitText = arrayUnique(kits.map((kit) => kit.label)).join(', ');

        let introText;
        if (kits.length > 1) {
            introText = this.translationService.instant('print_kit.dupe_intro_multi');
        } else {
            introText = this.translationService.instant('print_kit.dupe_intro_single');
        }

        const confirmDialog = this.dialog.open(ConfirmDialog, {
            width: '800px',
            height: 'max-content',
            data: {
                title: this.translationService.instant('modals.kit.replace_kit_tag'),
                intro: `${introText}<br /><br />`,
                description: `<b>${kitText}</b><br /><br />`,
                detail: this.translationService.instant('print_kit.dupe_detail'),
                okButton: this.translationService.instant('print_kit.dupe_ok'),
                cancelButton: this.translationService.instant('print_kit.dupe_cancel'),
            },
        });

        confirmDialog.afterClosed().subscribe((result) => {
            this.setUniqueErrors(result, kits);
        });
    }

    setUniqueErrors(result, kits) {
        let labelCtrl;
        const uniqueType = this.translationService.instant('print_kit.new_kit_labels');

        if (this.type.print) {
            if (!result) {
                kits.forEach((kit) => {
                    labelCtrl = this.kitLabelForm?.form.controls[`kitId${kit.labelCtrl}`];
                    labelCtrl.setErrors({ unique: uniqueType });
                });
            } else {
                kits.forEach((kit) => {
                    labelCtrl = this.kitLabelForm?.form.controls[`kitId${kit.labelCtrl}`];
                    labelCtrl.setErrors(null);
                });
                this.initValidators();
            }
        } else {
            if (!result) {
                labelCtrl = this.kitLabelForm?.form.controls[kits[0].epc];
                labelCtrl.setErrors({ unique: uniqueType });
            } else {
                labelCtrl = this.kitLabelForm?.form.controls[kits[0].epc];
                labelCtrl.setErrors(null);
            }
            this.initValidators();
        }
    }

    clearUniqueErrors(kits) {
        let labelCtrl;
        if (this.type.print) {
            kits.forEach((kit) => {
                labelCtrl = this.kitLabelForm?.form.controls[`kitId${kit.labelCtrl}`];
                labelCtrl.setErrors(null);
            });
        } else {
            labelCtrl = this.kitLabelForm?.form.controls[kits[0].epc];
            labelCtrl.setErrors(null);
        }
    }

    clearSharedEpc(): void {
        this.kitShared.scannedEpc = '';
    }

    parseErrors(errors): string {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    comparePrinters(o1: any, o2: any): boolean {
        return o1.id === o2?.id;
    }
}
