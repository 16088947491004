import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ActionService } from '@services/utils/action.service';

import { AssignableForm } from '../assignable-form/assignable-form';
import { CommonCRUDAPIService } from '@services/core/common-crud-api.service';
import { TranslationService } from '@services/utils/translation.service';
import { MatDialog } from '@angular/material/dialog';

import { BarcodeScanService } from '@services/core/barcode-scan.service';

import { AdminLocation } from '@models/admin/admin-location';

@Component({
    selector: 'admin-locations-create',
    templateUrl: './admin-locations-create.html',
    styleUrls: ['./admin-locations-create.scss'],
})
export class AdminLocationsCreate extends AssignableForm {
    location: AdminLocation;

    canSubmitAsset: boolean;
    editMode: boolean;
    eventListeners: { (): void }[];

    constructor(
        protected route: ActivatedRoute,
        protected actionService: ActionService,
        protected barcodeScanService: BarcodeScanService,
        protected commonCRUDAPIService: CommonCRUDAPIService,
        protected dialog: MatDialog,
        protected translationService: TranslationService,
        protected router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super(actionService, barcodeScanService, commonCRUDAPIService, dialog, translationService, router);
        this.assignType = 'location';
        this.editMode = false;
        this.canSubmitAsset = true;
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.location = data.location;
            this.assignObject = this.location;
        });

        super.ngOnInit();
        this.route.paramMap.subscribe((paramMap) => (this.editMode = !!paramMap.get('locationId')));
    }

    remove(): void {
        this.confirmRemove(this.location);
    }

    assetWasSelected(name: string, id: string): void {
        this.canSubmitAsset = true;
    }
}
