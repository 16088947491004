import { Component, ViewChildren, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { ReportSubscriptionDialog } from '@components/dialogs/report-subscription/report-subscription-dialog';
import { ReportSubscriptionResource } from '@services/resources/report-subscription-resource.service';

import { ReportResource } from '@services/resources/report-resource.service';
import { Report, ReportCategory } from '@models/core/report';
import { TranslationService } from '@services/utils/translation.service';
import { OrderByPipe } from '@pipes/order-by.pipe';

import { KittedItem } from '@models/core/kittedItem';
import { ActivatedRoute } from '@angular/router';

interface GroupKittedItem {
    clinicalEquivalenceId?: string;
    clinicalEquivalenceName?: string;
    sortName?: string; // allows us to sort no ceq items at the bottom
    items: Array<KittedItem>;
    totalKitted?: number;
    totalKitsByCEQ?: number;
    expanded: boolean;
    dataSource?: any;
}

@Component({
    selector: 'kitted-item-inventory',
    templateUrl: './report-kitted-item-inventory.html',
    styleUrls: ['./report-kitted-item-inventory.scss'],
})
export class ReportKittedItemInventory {
    reports: {
        report_categories: Array<ReportCategory>;
    };

    report;
    kittedItemsView: string = 'ndc';
    kittedItemsByGeneric: GroupKittedItem[];
    kittedItemsSummary: GroupKittedItem[];
    groupedItems: Array<any> = [{ items: null, expanded: false }];
    reportDate: Date;
    hasRun: boolean = false;
    hasData: boolean = false;
    isLoading: boolean = true;
    reportCategory: string;
    noCeqTranslation: string;
    isDownloadable: boolean = true;
    downloadFilters = {};

    displayedColumns: string[] = [
        'ndc',
        'manufacturer',
        'name',
        'formatted_package_size',
        'formatted_strength',
        'total_kitted',
        'total_kits',
    ];
    @ViewChildren('multiTableSort') multiTableSort: QueryList<MatSort>;
    @ViewChildren('multiTables') multiTables: QueryList<MatTable<any>>;

    constructor(
        private productModuleService: ProductModuleService,
        private reportResource: ReportResource,
        private translationService: TranslationService,
        private reportSubscriptionResource: ReportSubscriptionResource,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
            this.report = data.singleReport.report;
            this.reportCategory = data.singleReport.report.reportCategory;
        });

        this.productModuleService.setModule(ModuleTypes.REPORTING);
        this.translationService.get(['reports.no_ceq']).then((translations) => {
            this.noCeqTranslation = translations['reports.no_ceq'];
        });

        this.refreshReport();
    }

    filterReport(event): void {
        this.isLoading = true;

        // rendering mutliple sortable material tables is slow, and
        // there is no event to trap for it.
        // isLoading was being set to false too quickly.
        // wrapping in a timeout lets the material tables load properly
        setTimeout(() => {
            if (event.genericNdcItemType === 'ndc_pkg_str') {
                this.kittedItemsView = 'ndc';
                this.groupedItems = this.kittedItemsSummary;
            } else {
                this.kittedItemsView = 'clinical_equivalence';
                this.groupedItems = this.kittedItemsByGeneric;
            }
            this.setSort().then(() => {
                this.isLoading = false;
            });
        });
    }

    setSort() {
        // wrapping in a promise so that we can set isLoading after it is done
        return new Promise<void>((resolve) => {
            //wrapping in a time out makes sure that the table elements exist before the sort is added
            setTimeout(() => {
                this.multiTables.forEach(
                    (table, index) =>
                        ((table.dataSource as MatTableDataSourceWithNaturalSort<any>).sort =
                            this.multiTableSort.toArray()[index])
                );
                resolve();
            });
        });
    }

    // this is only run once, on load, for this report. The results are cached and the filterReport just switches between the two views.
    refreshReport(): void {
        this.reportResource.kittedItems_byNDCReport({}).then((data) => {
            data.reports.forEach((report) => {
                if (report.item_strength) {
                    report.item_strength = parseFloat(report.item_strength);
                    report.formatted_strength = `${report.item_strength} ${report.item_strength_uom}`;
                }
                if (report.package_size) {
                    report.package_size = parseFloat(report.package_size);
                    report.formatted_package_size = `${report.package_size} ${report.package_size_uom}`;
                }
            });
            // datasources for main table
            this.kittedItemsSummary = [{ items: data.reports, expanded: true }];
            this.kittedItemsSummary[0].dataSource = new MatTableDataSourceWithNaturalSort(data.reports);
            this.kittedItemsByGeneric = this.getKittedItemsByGeneric(data.reports);

            // set datasources for grouped items
            this.kittedItemsByGeneric.forEach((table, index) => {
                table.dataSource = new MatTableDataSourceWithNaturalSort(table.items);
            });

            this.reportDate = new Date();
            this.hasRun = true;
            this.hasData = !!data.reports.length;
            this.filterReport({ genericNdcItemType: 'ndc_pkg_str' });
        });
    }

    getKittedItemsByGeneric(data: Array<KittedItem>) {
        // groupBy is far more elegant than using the javascript reduce here.
        const itemsGroupedByCEQ: any = _.groupBy(data, 'clinical_equivalence_id');

        const itemsByGeneric = Object.keys(itemsGroupedByCEQ).map((key: any) => {
            if (!!itemsGroupedByCEQ[key]) {
                return {
                    clinicalEquivalenceId: key,
                    clinicalEquivalenceName:
                        itemsGroupedByCEQ[key][0].clinical_equivalence_full_name || this.noCeqTranslation,
                    sortName: itemsGroupedByCEQ[key][0].clinical_equivalence_full_name || 'zzzzzzzzzzz',
                    items: itemsGroupedByCEQ[key],
                    totalKitted: _.sum(itemsGroupedByCEQ[key], 'total_kitted'),
                    totalKitsByCEQ: itemsGroupedByCEQ[key][0].total_kits_by_ceq,
                    expanded: true,
                };
            }
            return;
        });

        return new OrderByPipe().transform(itemsByGeneric, 'sortName', true);
    }

    subscribeModal(): void {
        const subscribeFilters = {};
        const subscriptionFrequenciesPromise = this.reportSubscriptionResource.frequenciesList();
        const subscription = undefined;

        Promise.all([subscriptionFrequenciesPromise]).then((response) => {
            const frequencies = response[0].frequencies;

            this.dialog.open(ReportSubscriptionDialog, {
                width: '820px',
                height: 'max-content',
                autoFocus: false,
                data: {
                    reportName: this.report.name,
                    filterData: subscribeFilters,
                    frequencies: frequencies,
                    subscription: subscription,
                },
            });
        });
    }
}
