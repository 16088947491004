import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ActionService } from '@services/utils/action.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'shortage-report-page',
    templateUrl: './shortage-report-page.html',
    styleUrls: ['./shortage-report-page.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ShortageReportPage implements OnInit, AfterViewInit {
    // Bindings from UI-Router Resolvers
    kitMasters: any;
    shortageSegments: any;

    // Member Vars
    prevStateName: string;
    colWidths: any = {};
    links: Array<[string, string]> = [
        ['shortage_report.shortage_summary', '/inventory/shortage-report'],
        ['shortage_report.shortage_management', '/inventory/shortage-management'],
    ];
    activeLink: string[] = this.links[0];

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name', 'kit_master_name', 'acceptable_range', 'normal_par'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private activatedRoute: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService
    ) {
        //method executor so all your functions should be here
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.kitMasters = data.kitMasters;
            this.shortageSegments = data.shortageSegments;
        });
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.shortageSegments);
        this.dataSource.sort = this.sort;
    }

    shortageManagementActionAllow() {
        return this.actionService.isAllowAction(
            'kits_inventory',
            'update_kit_segment',
            'Show Add Formulary Item to Segment button'
        );
    }

    acceptableRange(index, showAlternates) {
        if (this.shortageSegments.length > 0) {
            let primary = this.shortageSegments[index].segment_templates.filter(
                (fill) => fill.primary_segment_template === true
            )[0];
            if (this.shortageSegments[index].has_alternate_fill && showAlternates) {
                return '';
            } else if (primary && primary.shortage_quantity_min != null) {
                return `${primary.shortage_quantity_min} - ${primary.shortage_quantity_max}`;
            } else {
                return '-';
            }
        }
    }

    expected(index, showAlternates) {
        if (this.shortageSegments.length > 0) {
            let primary = this.shortageSegments[index].segment_templates.filter(
                (fill) => fill.primary_segment_template === true
            )[0];
            if (this.shortageSegments[index].has_alternate_fill && showAlternates) {
                return ' ';
            } else if (primary && primary.quantity != null) {
                return primary.quantity;
            } else {
                return '-';
            }
        }
    }

    range(i) {
        if (this.shortageSegments.length > 0) {
            if (this.shortageSegments[i].shortage_quantity_min === this.shortageSegments[i].shortage_quantity_max) {
                return this.shortageSegments[i].shortage_quantity_min;
            }
            return `${this.shortageSegments[i].shortage_quantity_min} - ${this.shortageSegments[i].shortage_quantity_max}`;
        }
    }

    acceptableRangeFillOption(l, i) {
        if (this.shortageSegments.length > 0) {
            let segmentTemplate = this.shortageSegments[l].segment_templates[i];
            if (segmentTemplate.shortage_quantity_min || segmentTemplate.shortage_quantity_min === 0) {
                return `${segmentTemplate.shortage_quantity_min} - ${segmentTemplate.shortage_quantity_max}`;
            } else {
                return '-';
            }
        }
    }

    formularyItemsAndGenericsNames(l, i) {
        if (this.shortageSegments.length > 0) {
            let formularyItems = _.uniq(
                this.shortageSegments[l].segment_templates[i].formulary_items.map((item) => {
                    return `${item.item_name} ${item.item_strength_formatted} ${item.item_strength_uom}, ${item.package_size_formatted} ${item.package_size_uom} ${item.package_description_name}`;
                })
            );
            let generics = _.uniq(
                this.shortageSegments[l].segment_templates[i].clinical_equivalences.map((item) => {
                    return `${item.item_name} ${item.item_strength_formatted} ${item.item_strength_uom}, ${item.package_size_formatted} ${item.package_size_uom} Generic`;
                })
            );
            return formularyItems.concat(generics);
        }
    }

    toggle(segment): void {
        segment.showAlternates = !segment.showAlternates;
        this.colWidths = {
            names:
                document.querySelector('.mat-column-name').getBoundingClientRect().width +
                document.querySelector('.mat-column-kit_master_name').getBoundingClientRect().width,
            acceptable_range: document.querySelector('.mat-column-acceptable_range').getBoundingClientRect().width,
            normal_par: document.querySelector('.mat-column-normal_par').getBoundingClientRect().width,
        };
    }
}
