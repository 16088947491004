import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class KCBreadcrumbsService {
    showBreadcrumbs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private router: Router) {
        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.changeBreadcrumbsVisibility(true);
        });
    }

    changeBreadcrumbsVisibility(display: boolean) {
        this.showBreadcrumbs.next(display);
    }
}
