import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { SegmentResource } from '@resources/segment-resource.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';

import {
    SegmentResourceType,
    SegmentTemplateEditDialog,
} from '@dialogs/segment-template-edit/segment-template-edit-dialog';

@Injectable()

// TODO: most of this looks like wrapped resource calls
// which we should probably remove and just call the resource directly.
export class SegmentsService {
    segments: any = {};

    constructor(
        private dialog: MatDialog,
        private formularyItemsService: FormularyItemsService,
        private loadingSpinnerService: LoadingSpinnerService,
        private segmentResource: SegmentResource
    ) {}

    setAlternateFillFormatted(segment: any): void {
        //keeping 'result' as the variable name for the key because the charge sheets in external assets are expecting it
        segment.result = _.uniq(
            segment.items.map(function (item) {
                return `${!!item.name ? item.name : ''}${
                    !!item.item_strength_formatted ? ' ' + item.item_strength_formatted : ''
                }${!!item.item_strength_uom ? ' ' + item.item_strength_uom : ''}, ${
                    !!item.package_volume_formatted ? item.package_volume_formatted : ''
                }${!!item.package_volume_uom ? ' ' + item.package_volume_uom : ''}${
                    !!item.package_description_name ? ' ' + item.package_description_name : ''
                }`;
            })
        );
    }

    getSegments(kitMasterId, reload?: boolean) {
        if (this.segments[kitMasterId] && !reload) {
            return Promise.resolve(this.segments[kitMasterId]);
        } else {
            return this.segmentResource.segmentList(kitMasterId).then((data) => {
                this.segments[kitMasterId] = data.segments;
                return data.segments;
            });
        }
    }

    segmentTemplateEdit(segment, kitMasterId, targetTemplate) {
        const formularyItemsPromise = this.formularyItemsService.getFormularyItems();
        const segmentsPromise = this.getSegments(kitMasterId);

        return this.loadingSpinnerService
            .spinnerifyPromise(Promise.all([formularyItemsPromise, segmentsPromise]), LoadingSpinnerTypes.BACKDROP)
            .then(([formulary, segments]) => {
                const segmentTemplateEditDialog = this.dialog.open(SegmentTemplateEditDialog, {
                    width: '900px',
                    height: 'max-content',
                    data: {
                        formularyItems: formulary,
                        segment: segment,
                        segments: segments,
                        segmentTemplate: targetTemplate,
                        resource: SegmentResourceType.kitMaster,
                        kitMasterId,
                    },
                });

                return new Promise<void>((resolve, reject) => {
                    segmentTemplateEditDialog.afterClosed().subscribe((confirmed) => {
                        if (confirmed) {
                            return resolve();
                        } else {
                            return reject();
                        }
                    });
                });
            });
    }

    clear(kitMasterId?) {
        if (kitMasterId) {
            this.segments[kitMasterId] = undefined;
        } else {
            this.segments = {};
        }
    }

    getSingleSegment(segmentId, scanId?) {
        return this.segmentResource.getSingleSegment(segmentId, scanId).then(({ segment }) => segment);
    }

    getAllSegments(shortage?) {
        if (shortage) {
            return this.segmentResource.getAllSegments(shortage).then(({ segments }) => segments);
        }
        return this.segmentResource.getAllSegments().then(({ segments }) => segments);
    }

    addSegment(kitMasterId, segment) {
        return this.segmentResource.addSegment(kitMasterId, segment).then(({ segment }) => segment);
    }

    updateSegment(segmentData) {
        return this.segmentResource.updateSegment(segmentData.id, segmentData);
    }

    removeSegment(segmentId) {
        return this.segmentResource.removeSegment(segmentId);
    }

    bulkUpdateSegments(segments): Promise<any> {
        return this.segmentResource.bulkUpdateSegments({
            update_segments: segments,
        });
    }
}
