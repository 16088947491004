import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RetryService {
    queryParams: Record<string, string> = {};
    constructor(private route: ActivatedRoute) {
        route.queryParams.subscribe((queryParams) => (this.queryParams = queryParams));
    }

    queryString(defaults = {}): string {
        const searchParams = _.defaults(defaults, this.queryParams);
        let queryParams = '';
        _.each(searchParams, (value, param) => {
            if (!queryParams.length) {
                queryParams += '?';
            } else {
                queryParams += '&';
            }

            queryParams += `${param}=${value}`;
        });
        return queryParams;
    }

    callWithFallback(httpConfig, alternateUrl?, originalError?): Promise<any> {
        // Use fetch here because we need the error code
        httpConfig.body = JSON.stringify(httpConfig.data); // fetch expects body, $http expects data
        httpConfig.headers['Content-Type'] = 'application/json';

        let queryString = httpConfig.method === 'GET' ? this.queryString(httpConfig.params) : '';

        // i get the feeling we dont need to use fetch anymore
        return new Promise((resolve, reject) => {
            fetch(httpConfig.url + queryString, httpConfig)
                .then((result) => {
                    if (result.ok) {
                        //fake $http response for compatability
                        result
                            .json()
                            .then((data) => {
                                resolve({
                                    data,
                                    config: httpConfig,
                                    ...result,
                                });
                            })
                            .catch(() => {
                                //its not json - oh well who cares
                                resolve({
                                    data: null,
                                    config: httpConfig,
                                    ...result,
                                });
                            });
                    } else {
                        throw result;
                    }
                })
                .catch((message) => {
                    if (alternateUrl) {
                        httpConfig.url = alternateUrl;
                        return this.callWithFallback(httpConfig, null, message)
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        reject(originalError || message);
                    }
                });
        });
    }
}
