// Angular Imports
import { Routes } from '@angular/router';

// Our Angular 2 Components
import { PAGES } from 'components';

// Our Angular 2 Resolvers;
import { ActivityAnalyticsResolver } from '@resolvers/activity-analytics.resolver';
import { AdminCartsResolver } from '@resolvers/admin-carts.resolver';
import { AdvancedSegmentOptimizationResolver } from '@resolvers/advanced-segment-optimization.resolver';
import { ArchivedFormularyItemsResolver } from '@resolvers/archived-formulary-items.resolver';
import { BinScanDetailResolver } from '@resolvers/bin-scan-detail.resolver';
import { BinScanResolver } from '@resolvers/bin-scan.resolver';
import { BinsResolver } from '@resolvers/bins.resolver';
import { BlockedKitResolver } from '@resolvers/blocked-kit.resolver';
import { CartHistoryResolver } from '@resolvers/cart-history.resolver';
import { CartResolver } from '@resolvers/cart.resolver';
import { CartsResolver } from '@resolvers/carts.resolver';
import { CatalogsResolver } from '@resolvers/catalogs.resolver';
import { CurrentBinResolver } from '@resolvers/current-bin.resolver';
import { DetailScanResolver } from '@resolvers/detail-scan.resolver';
import { DictionaryEntryResolver } from '@resolvers/dictionary-entry.resolver';
import { ExpirationAnalyticsResolver } from '@resolvers/expiration-analytics.resolver';
import { FormularyItemArchiveKitMasterListResolver } from '@resolvers/formulary-item-archive-kit-master-list.resolver';
import { FormularyItemKitBinCountResolver } from '@resolvers/formulary-item-kit-bin-count.resolver';
import { FormularyItemResolver } from '@resolvers/formulary-item.resolver';
import { FormularyItemsResolver } from '@resolvers/formulary-items.resolver';
import { GroupIdnMapActionViewUsersResolver } from '@resolvers/group-idn-map-action-view-users.resolver';
import { GroupRolesResolver } from '@resolvers/group-roles.resolver';
import { HardwareResolver } from '@resolvers/hardware.resolver';
import { HospitalDataResolver } from '@resolvers/hospital-data.resolver';
import { InferredItemsConsumedRemovedResolver } from '@resolvers/inferred-items-consumed-removed.resolver';
import { KitMasterTypesResolver } from '@resolvers/kit-master-types.resolver';
import { KitMastersResolver } from '@resolvers/kit-masters.resolver';
import { KitMastersWithFromKitsResolver } from '@resolvers/kit-masters-with-from-kits.resolver';
import { KitScansResolver } from '@resolvers/kit-scans.resolver';
import { KitSummaryResolver } from '@resolvers/kit-summary.resolver';
import { KitsResolver } from '@resolvers/kits.resolver';
import { KittedInventoryWithCostResolver } from '@resolvers/kitted-inventory-with-cost.resolver';
import { LocationResolver } from '@resolvers/location.resolver';
import { LocationsIncludeRtlsResolver } from '@resolvers/locations-include-rtls.resolver';
import { LocationsResolver } from '@resolvers/locations.resolver';
import { LoginResolver } from '@resolvers/login.resolver';
import { ManufacturersResolver } from '@resolvers/manufacturers.resolver';
import { PackageDescriptionsResolver } from '@resolvers/package-descriptions.resolver';
import { PanelSpinnerResolver } from '@resolvers/panel-spinner.resolver';
import { PreTaggedMedicationsResolver } from '@resolvers/pre-tagged-medications.resolver';
import { PretaggedProblemsResolver } from '@resolvers/pretagged-problems.resolver';
import { PretaggedReportsResolver } from '@resolvers/pretagged-reports.resolver';
import { PrinterResolver } from '@resolvers/printer.resolver';
import { RecallResolver } from '@resolvers/recall.resolver';
import { RecallsNetworkResolver } from '@resolvers/recalls-network.resolver';
import { RecallsResolver } from '@resolvers/recalls.resolver';
import { ReportSubscriptionsResolver } from '@resolvers/report-subscriptions.resolver';
import { ReportsResolver } from '@resolvers/reports.resolver';
import { ScanInventoryResolver } from '@resolvers/scan-inventory.resolver';
import { SegmentsResolver } from '@resolvers/segments.resolver';
import { ShelvedInventorySettingResolver } from '@resolvers/shelved-inventory-setting.resolver';
import { ShortageSegmentsResolver } from '@resolvers/shortage-segments.resolver';
import { SingleDossierReportResolver } from '@resolvers/single-dossier-report.resolver';
import { SingleHardwareResolver } from '@resolvers/single-hardware.resolver';
import { SingleKitMasterResolver } from '@resolvers/single-kit-master.resolver';
import { SingleSegmentResolver } from '@resolvers/single-segment.resolver';
import { ScannerResolver } from '@resolvers/scanner.resolver';
import { TagTypesResolver } from '@resolvers/tag-types.resolver';
import { UserListResolver } from '@resolvers/user-list.resolver';
import { UserResolver } from '@resolvers/user.resolver';
import { MidmarkDeviceBatteryResolver } from '@resolvers/midmark-device-battery-resolver';
import { ContainersResolver } from '@resolvers/containers.resolver';

export function genericResolve(resolver: any): any {
    return resolver.resolve();
}

export const routes: Routes = [
    {
        path: 'add-credits',
        component: PAGES.AddCredits,
        resolve: {
            usageSummary: PreTaggedMedicationsResolver,
        },
    },
    {
        path: 'admin',
        component: PAGES.Admin,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'admin.title',
        },
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: PAGES.AdminHome,
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'batch-lots',
                component: PAGES.AdminBatchLots,
                resolve: {
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'carts',
                component: PAGES.AdminCartsList,
                resolve: {
                    carts: AdminCartsResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'carts/create',
                component: PAGES.AdminCartsCreate,
                resolve: {
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'carts/:cartId/update',
                component: PAGES.AdminCartsCreate,
                resolve: {
                    cart: CartResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'hardware',
                component: PAGES.AdminHardwareList,
                resolve: {
                    hardware: HardwareResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'hardware/:hardwareId/update',
                component: PAGES.AdminHardwareUpdate,
                resolve: {
                    hardware: SingleHardwareResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'hospital',
                component: PAGES.AdminHospital,
                resolve: {
                    hospital: HospitalDataResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'item-expirations',
                component: PAGES.AdminItemExpirations,
                resolve: {
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'recalls', // ?ids
                component: PAGES.AdminRecallsList,
                runGuardsAndResolvers: 'always',
                resolve: {
                    recalls: RecallsResolver,
                    recallsNetwork: RecallsNetworkResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'locations',
                component: PAGES.AdminLocationsList,
                resolve: {
                    locations: LocationsResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'locations-create',
                component: PAGES.AdminLocationsCreate,
                resolve: {
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'locations/:locationId/update',
                component: PAGES.AdminLocationsCreate,
                resolve: {
                    location: LocationResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'locks',
                component: PAGES.AdminLocks,
                data: { titleKey: 'admin.title' },
            },
            {
                path: 'pretagged-quarantine',
                component: PAGES.AdminPretaggedQuarantine,
                runGuardsAndResolvers: 'always',
                resolve: {
                    login: LoginResolver,
                    quarantinedItems: PretaggedReportsResolver,
                    problems: PretaggedProblemsResolver,
                    packageDescriptions: PackageDescriptionsResolver,
                    manufacturers: ManufacturersResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'recalls/create',
                component: PAGES.AdminRecallsCreate,
                resolve: {
                    formularyItems: FormularyItemsResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'recalls/:recallId/update',
                component: PAGES.AdminRecallsCreate,
                resolve: {
                    formularyItems: FormularyItemsResolver,
                    recall: RecallResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'users',
                component: PAGES.AdminUsersList,
                resolve: {
                    idnGroup: GroupIdnMapActionViewUsersResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'users/create/:selectedGroup',
                component: PAGES.AdminUsersCreate,
                resolve: {
                    idnGroup: GroupIdnMapActionViewUsersResolver,
                    groupRoles: GroupRolesResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'users/:userId',
                component: PAGES.AdminUsersProfile,
                resolve: {
                    user: UserResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
            {
                path: 'users/:userId/update/:selectedGroup?',
                component: PAGES.AdminUsersCreate,
                resolve: {
                    idnGroup: GroupIdnMapActionViewUsersResolver,
                    groupRoles: GroupRolesResolver,
                    user: UserResolver,
                    panelSpinner: PanelSpinnerResolver,
                },
                data: {
                    titleKey: 'admin.title',
                },
            },
        ],
    },
    {
        path: 'bin/:binId',
        component: PAGES.BinEdit,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            binScan: CurrentBinResolver,
        },
    },
    {
        path: 'change-tag-type/:tagTypeId',
        component: PAGES.ChangeTagType,
        resolve: {
            login: LoginResolver,
            printers: PrinterResolver,
            tagTypes: TagTypesResolver,
        },
        data: {
            titleKey: 'tagging.titles.change_tag_type',
        },
    },
    {
        path: 'credit-history',
        component: PAGES.CreditHistory,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'usage_history.title',
        },
    },
    {
        path: 'forgot-password',
        component: PAGES.NgxForgotPasswordComponent,
    },
    {
        path: 'inventory/archive-formulary-item/:formularyItemId',
        component: PAGES.FormularyArchiveItem,
        resolve: {
            login: LoginResolver,
            formularyItem: FormularyItemResolver,
            kitBinCount: FormularyItemKitBinCountResolver,
        },
        data: {
            titleKey: 'manage_formulary.archive_title',
        },
    },
    {
        path: 'inventory/manage-formulary',
        component: PAGES.FormularyIndex,
        resolve: {
            login: LoginResolver,
            formularyItems: FormularyItemsResolver,
            archivedFormularyItems: ArchivedFormularyItemsResolver,
        },
    },
    {
        path: 'inventory',
        data: {
            titleKey: 'inventory.title',
        },
        component: PAGES.KitsIndex,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            scanInventory: ScanInventoryResolver,
        },
    },
    {
        path: 'inventory/containers',
        data: {
            titleKey: 'containers.title',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: PAGES.ContainersIndex,
                data: {
                    breadcrumbs: true,
                    forceHideHeader: true,
                },
                resolve: {
                    login: LoginResolver,
                    scanners: ScannerResolver,
                },
            },
            {
                path: ':id',
                runGuardsAndResolvers: 'always',
                resolve: {
                    containerData: ContainersResolver,
                },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: PAGES.ContainersDetail,
                        resolve: {
                            login: LoginResolver,
                        },
                        data: {
                            titleKey: 'containers.container_details.page_title',
                            breadcrumbs: true,
                            forceHideHeader: true,
                        },
                    },
                    {
                        path: 'scans',
                        pathMatch: 'full',
                        component: PAGES.ContainersScans,
                        resolve: {
                            login: LoginResolver,
                        },
                        data: {
                            titleKey: 'containers.container_details.scan_history',
                            breadcrumbs: true,
                            forceHideHeader: true,
                        },
                    },
                    {
                        path: ':scanId',
                        pathMatch: 'full',
                        component: PAGES.ContainersDetail,
                        resolve: {
                            login: LoginResolver,
                        },
                        data: {
                            titleKey: 'containers.container_details.page_title',
                            breadcrumbs: true,
                            forceHideHeader: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'inventory/manage-items',
        pathMatch: 'full',
        component: PAGES.ManageItems,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            pretaggedProblems: PretaggedProblemsResolver,
        },
    },
    {
        path: 'inventory/manage-items/:epc',
        component: PAGES.ManageItems,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            pretaggedProblems: PretaggedProblemsResolver,
        },
    },
    {
        path: 'inventory/manage-item-print-error',
        component: PAGES.ManageItemsPrintError,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'manage_items.printer_error',
        },
    },
    {
        path: 'inventory/manage-item-print-unconfirmed',
        component: PAGES.ManageItemsPrintUnconfirmed,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'manage_items.printer_error',
        },
    },
    {
        path: 'kit/:kitId',
        component: PAGES.KitDetail,
        pathMatch: 'full',
        resolve: {
            carts: CartsResolver,
            kitSummary: KitSummaryResolver,
            locations: LocationsResolver,
            login: LoginResolver,
            usageSummary: PreTaggedMedicationsResolver,
        },
    },
    {
        path: 'kit/:kitId/:scanId',
        component: PAGES.KitDetail,
        runGuardsAndResolvers: 'always',
        resolve: {
            carts: CartsResolver,
            kitSummary: KitSummaryResolver,
            locations: LocationsResolver,
            login: LoginResolver,
            usageSummary: PreTaggedMedicationsResolver,
        },
    },
    {
        path: 'kit/block/:kitId/:scanId',
        component: PAGES.KitBlock,
        resolve: {
            blockedKit: BlockedKitResolver,
            login: LoginResolver,
        },
        data: {
            titleKey: 'kit_block.title',
        },
    },
    {
        path: 'kit-scans/:kitId',
        component: PAGES.KitScans,
        resolve: {
            kitScans: KitScansResolver,
            login: LoginResolver,
        },
        data: {
            titleKey: 'scan_history.title',
        },
    },
    {
        path: 'inventory/bins',
        component: PAGES.BinsIndex,
        resolve: {
            login: LoginResolver,
            scanInventory: ScanInventoryResolver,
            shelvedInventorySetting: ShelvedInventorySettingResolver,
            bins: BinsResolver,
            printers: PrinterResolver,
        },
        data: {
            titleKey: 'bins.title',
        },
    },
    {
        path: 'bin/:binId/:scanId',
        component: PAGES.BinScan,
        runGuardsAndResolvers: 'always',
        resolve: {
            binScan: BinScanResolver,
            binScanDetail: BinScanDetailResolver,
            scanInventory: ScanInventoryResolver,
        },
    },
    {
        path: '',
        component: PAGES.Home,
        resolve: {
            scanInventory: ScanInventoryResolver,
            printers: PrinterResolver,
            login: LoginResolver,
        },
        data: {
            forceHideHeader: true,
            titleKey: 'common.home',
        },
    },
    {
        path: 'inventory/carts',
        component: PAGES.CartsIndex,
        resolve: {
            login: LoginResolver,
            carts: CartsResolver,
            locations: LocationsIncludeRtlsResolver,
            kitMasters: KitMastersResolver,
        },
        data: {
            titleKey: 'carts.title',
        },
    },
    {
        path: 'inventory/archive-formulary-item/:formularyItemId/kit-masters',
        component: PAGES.KitMastersList,
        resolve: {
            login: LoginResolver,
            kitMasters: FormularyItemArchiveKitMasterListResolver,
        },
        data: {
            titleKey: 'manage_kit_masters.title',
        },
    },
    {
        path: 'inventory/create-formulary-item',
        component: PAGES.CreateEditFormularyItem,
        resolve: {
            login: LoginResolver,
            packageDescriptions: PackageDescriptionsResolver,
            hospitalCatalogs: CatalogsResolver,
        },
        data: {
            titleKey: 'manage_formulary.create_title',
            tagItemInfo: null,
        },
    },
    {
        path: 'inventory/create-formulary-item/:ndc',
        component: PAGES.CreateEditFormularyItem,
        resolve: {
            login: LoginResolver,
            dictionaryEntry: DictionaryEntryResolver,
            hospitalCatalogs: CatalogsResolver,
        },
        data: {
            titleKey: 'manage_formulary.create_title',
        },
    },
    {
        path: 'inventory/edit-formulary-item/:formularyItemId',
        component: PAGES.CreateEditFormularyItem,
        resolve: {
            login: LoginResolver,
            formularyItem: FormularyItemResolver,
            packageDescriptions: PackageDescriptionsResolver,
            hospitalCatalogs: CatalogsResolver,
        },
        data: {
            titleKey: 'manage_formulary.edit_title',
        },
    },
    {
        path: 'inventory/kit-master-create',
        component: PAGES.KitMasterEdit,
        resolve: {
            login: LoginResolver,
            kitMasterTypes: KitMasterTypesResolver,
        },
        data: {
            titleKey: 'create_kit_master.title',
        },
    },
    {
        path: 'inventory/manage-kit-masters/:kitMasterId/edit',
        component: PAGES.KitMasterEdit,
        resolve: {
            login: LoginResolver,
            kitMaster: SingleKitMasterResolver,
            kitMasterTypes: KitMasterTypesResolver,
        },
        data: {
            titleKey: 'edit_kit_master.title',
        },
    },
    {
        path: 'inventory/manage-kit-masters',
        component: PAGES.KitMastersList,
        resolve: {
            login: LoginResolver,
            kitMasters: KitMastersResolver,
        },
        data: {
            titleKey: 'manage_kit_masters.title',
        },
    },
    {
        path: 'inventory/manage-kit-masters/:kitMasterId',
        runGuardsAndResolvers: 'always',
        component: PAGES.SingleKitMaster,
        resolve: {
            login: LoginResolver,
            kitMasters: KitMastersResolver,
            segments: SegmentsResolver,
        },
        data: {
            titleKey: 'single_kit_master.title',
        },
    },
    {
        path: 'inventory/manage-kit-masters/:kitMasterId/segments/:segmentId',
        component: PAGES.SegmentEdit,
        resolve: {
            login: LoginResolver,
            kitMaster: SingleKitMasterResolver,
            segment: SingleSegmentResolver,
        },
        data: {
            titleKey: 'manage_segment.title',
        },
    },
    {
        path: 'login',
        component: PAGES.NgxLoginComponent,
    },
    {
        path: 'inventory/cart/:cartId',
        component: PAGES.CartManage,
        resolve: {
            login: LoginResolver,
            cart: CartResolver,
            locations: LocationsResolver,
            history: CartHistoryResolver,
        },
        data: {
            titleKey: 'carts.manage.title',
        },
    },
    {
        path: 'manage-subscriptions',
        component: PAGES.ManageSubscriptions,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            subscriptions: ReportSubscriptionsResolver,
        },
    },
    {
        path: 'mobile',
        component: PAGES.MobileCartHomeComponent,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'KitCheck Mobile Cart',
        },
        children: [
            {
                path: 'home',
                component: PAGES.MobileCartLandingPageComponent,
                resolve: {
                    login: LoginResolver,
                },
                data: {
                    titleKey: 'KitCheck Mobile Cart',
                },
            },
            {
                path: 'scan-summary/:kitId/:scanId',
                component: PAGES.MobileCartScanSummaryComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                    login: LoginResolver,
                    kitSummary: KitSummaryResolver,
                },
                data: {
                    titleKey: 'KitCheck Mobile Cart',
                },
            },
        ],
    },
    {
        path: 'mobile-kit/block/:kitId/:scanId',
        component: PAGES.MobileCartKitBlockComponent,
        resolve: {
            blockedKit: BlockedKitResolver,
        },
        data: {
            titleKey: 'KitCheck Mobile Cart',
        },
    },
    {
        path: 'password-reset',
        component: PAGES.NgxPasswordResetComponent,
    },
    {
        path: 'password-reset-expired',
        component: PAGES.NgxPasswordResetExpiredComponent,
    },
    {
        path: 'pre-tagged-medications',
        component: PAGES.PretaggedMedications,
        runGuardsAndResolvers: 'always',
        resolve: {
            usageSummary: PreTaggedMedicationsResolver,
        },
        data: {
            titleKey: 'pre_tagged_medications.title',
        },
    },
    {
        path: 'pre-tagged-credit-history',
        component: PAGES.PreTaggedUsageHistory,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'credit_history.title',
        },
    },
    {
        path: 'pre-tagged-usage-history',
        component: PAGES.PreTaggedUsageHistory,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'usage_history.title',
        },
    },
    {
        path: 'print-error',
        component: PAGES.PrintError,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'errors.print_error.title',
        },
    },
    {
        path: 'report/activity-analytics',
        component: PAGES.ReportActivityAnalytics,
        resolve: {
            login: LoginResolver,
            activityAnalytics: ActivityAnalyticsResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/advanced-segment-optimization',
        component: PAGES.ReportAdvancedSegmentOptimization,
        resolve: {
            login: LoginResolver,
            advancedSegmentOptimization: AdvancedSegmentOptimizationResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/aggregate-billing',
        component: PAGES.ReportBilling,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/expiration-analytics',
        component: PAGES.ReportExpirationAnalytics,
        resolve: {
            login: LoginResolver,
            expirationAnalytics: ExpirationAnalyticsResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/hospital',
        component: PAGES.ReportHospitalActivity,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/print-batch',
        component: PAGES.ReportItemBatchSummary,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
            report: SingleDossierReportResolver,
        },
    },
    {
        // TODO CF - I can't find this one - I think it's print batch detail
        path: 'report/item-batch-by-hospital',
        component: PAGES.ReportItemBatchByHospital,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
            report: SingleDossierReportResolver,
        },
    },
    {
        path: 'report/custom',
        component: PAGES.ReportItemExpirations,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/customLot',
        component: PAGES.ReportItemsByNDC,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/items-consumed-or-removed',
        component: PAGES.ReportInferredItemsConsumedRemoved,
        resolve: {
            login: LoginResolver,
            inferredItemsConsumedRemoved: InferredItemsConsumedRemovedResolver,
            reports: ReportsResolver,
            kitMasters: KitMastersWithFromKitsResolver,
        },
    },
    {
        path: 'report/itemsTagged',
        component: PAGES.ReportItemsTagged,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/kit_activity',
        component: PAGES.ReportKitActivity,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/rework',
        component: PAGES.ReportKitsNeedingRework,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/kitted-inventory-with-cost',
        component: PAGES.ReportKittedInventoryWithCost,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
            kittedInventoryWithCost: KittedInventoryWithCostResolver,
        },
    },
    {
        path: 'report/byNDC',
        component: PAGES.ReportKittedItemInventory,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
            singleReport: SingleDossierReportResolver,
        },
    },
    {
        path: 'report/item-recalls',
        component: PAGES.ReportRecalls,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report-print-batch-detail', // ?batchId&lotNum&createdAt&hospitalOwnership
        component: PAGES.ReportTaggedItemBatchSummary,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'report/recent',
        component: PAGES.ReportUserActivity,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
            users: UserListResolver,
        },
    },
    {
        path: 'report/virginia-board-of-pharmacy',
        component: PAGES.ReportVirginiaBoardOfPharmacy,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'reports',
        runGuardsAndResolvers: 'always',
        component: PAGES.ReportsIndex,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'reports',
        runGuardsAndResolvers: 'always',
        component: PAGES.ReportsList,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
    },
    {
        path: 'system-reports',
        component: PAGES.ReportsIndex,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
        data: {
            isSystem: true,
        },
    },
    {
        path: 'system-reports/system_inferred_items_consumed_removed',
        component: PAGES.ReportInferredItemsConsumedRemoved,
        resolve: {
            login: LoginResolver,
            inferredItemsConsumedRemoved: InferredItemsConsumedRemovedResolver,
            reports: ReportsResolver,
            kitMasters: KitMastersWithFromKitsResolver,
        },
        data: {
            isSystem: true,
        },
    },
    {
        path: 'system-reports/system_item_expiration_and_attributes_custom_item',
        component: PAGES.ReportItemExpirations,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
        data: {
            isSystem: true,
        },
    },
    {
        path: 'system-reports/system_item_recalls',
        component: PAGES.ReportRecalls,
        resolve: {
            login: LoginResolver,
            reports: ReportsResolver,
        },
        data: {
            isSystem: true,
        },
    },
    {
        path: 'scan/batch-verify/:scanId',
        component: PAGES.ScanBatchVerify,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'batch_verify.title',
        },
    },
    {
        path: 'scan/details/:scanId',
        component: PAGES.ScanDetails,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            detailScan: DetailScanResolver,
            pretaggedProblems: PretaggedProblemsResolver,
        },
    },
    {
        path: 'settings',
        component: PAGES.UserSettings,
        resolve: {
            login: LoginResolver,
        },
        data: {
            titleKey: 'settings.title',
        },
    },
    {
        path: 'inventory/shortage-management',
        component: PAGES.ShortageManagementPage,
        resolve: {
            kitMasters: KitMastersResolver,
            segments: SegmentsResolver,
        },
        data: {
            titleKey: 'shortage_report.title',
        },
    },
    {
        path: 'inventory/shortage-report',
        component: PAGES.ShortageReportPage,
        resolve: {
            kitMasters: KitMastersResolver,
            shortageSegments: ShortageSegmentsResolver,
        },
        data: {
            titleKey: 'shortage_report.title',
        },
    },
    {
        path: 'switch-hospital',
        component: PAGES.NgxSwitchHospitalComponent,
    },
    {
        path: 'tagging',
        component: PAGES.Tagging,
        runGuardsAndResolvers: 'always',
        resolve: {
            login: LoginResolver,
            kitMasters: KitMastersResolver,
            printers: PrinterResolver,
            hospitalCatalogs: CatalogsResolver,
        },
        data: {
            barcodeObject: null,
            barcode: null,
            type: null,
            newFormularyItem: null,
        },
    },
    {
        path: 'charge-sheet-test',
        component: PAGES.ChargeSheetTest,
        resolve: {
            kitMasters: KitMastersWithFromKitsResolver,
            kits: KitsResolver,
            login: LoginResolver,
        },
        data: {
            titleKey: 'charge sheet test',
        },
    },
    {
        path: 'midmark-device-batteries/low',
        component: PAGES.MidmarkLowBatteryReport,
        resolve: {
            login: LoginResolver,
            midmarkDeviceBatteries: MidmarkDeviceBatteryResolver,
        },
    },
];
