import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap, filter, map, tap, toArray } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ActionService } from '@services/utils/action.service';
import { GroupLoginService } from '@services/login/group-login.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService } from '@services/utils/kc-mat-snack-bar.service';
import { TranslationService } from '@services/utils/translation.service';

import { ProductModule } from '@models/core/product-module';
import { ChargeModelTypeName } from '@models/core/hospital-settings';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';

export enum ModuleTypes {
    INVENTORY = 'inventory',
    TAGGING = 'tagging',
    REPORTING = 'reporting',
    HELP = 'help',
}

@Injectable()
export class ProductModuleService {
    private modulesObserver: BehaviorSubject<ProductModule[]> = new BehaviorSubject(null);
    private allModules: ProductModule[] = [];
    private standardModules: string[] = [ModuleTypes.INVENTORY, ModuleTypes.REPORTING];
    private printerless: boolean = true;
    private isContainersEnabled: boolean;

    constructor(
        private actionService: ActionService,
        private groupLoginService: GroupLoginService,
        private hardwareService: HardwareService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private featureFlagService: FeatureFlagService,
        private router: Router,
        private translationService: TranslationService
    ) {
        this.buildAllModules();
    }

    observeModules(): Observable<any> {
        return this.modulesObserver;
    }

    setupFlags() {
        const featureFlag$ = this.featureFlagService.getFeatureValue('use-containers');

        featureFlag$.subscribe((value) => {
            this.isContainersEnabled = value;
        });

        return featureFlag$;
    }

    processModules() {
        const processModulesObservable$ = from(this.hardwareService.getPrinters()).pipe(
            concatMap((printers) =>
                this.setupFlags().pipe(
                    // build modules array
                    tap(() => this.buildAllModules()),
                    concatMap(() =>
                        from(this.allModules).pipe(
                            // Perform a side effect based on printers count
                            tap(() => {
                                this.printerless = printers.length === 0;
                            }),
                            // Filter not allowed modules based on action permissions
                            filter((menuItem) => this.isModuleAllowed(menuItem.name)),
                            // translate standard modules
                            map((menuItem) => {
                                const moduleKey = this.standardModules.find((m) => m === menuItem.name);

                                if (!moduleKey) {
                                    return menuItem;
                                }

                                const menuTranslations = this.translateModuleSync(moduleKey);

                                return {
                                    ...menuItem,
                                    ...menuTranslations,
                                };
                            }),
                            // Check if module is allowed to show
                            map((menuItem) => {
                                const dropdownAllowed: boolean = menuItem.dropdown.items.some((item) => item.allowed());

                                return {
                                    ...menuItem,
                                    dropdown: {
                                        ...menuItem.dropdown,
                                        allowed: dropdownAllowed,
                                        items: [
                                            ...menuItem.dropdown.items.map((item) => ({
                                                ...item,
                                                isAllowed: item.allowed(),
                                            })),
                                        ],
                                    },
                                };
                            }),
                            // Configure tagging module
                            map((menuItem) => {
                                if (menuItem.name !== ModuleTypes.TAGGING) {
                                    return menuItem;
                                }

                                return {
                                    ...menuItem,
                                    ...this.configureTaggingModule(menuItem),
                                };
                            }),
                            // Set dropdown item translations
                            map((menuItem) => {
                                const dropdownItems = this.setDropdownItemTranslations(menuItem);

                                return {
                                    ...menuItem,
                                    dropdown: {
                                        ...menuItem.dropdown,
                                        items: dropdownItems,
                                    },
                                };
                            }),
                            toArray()
                        )
                    )
                )
            )
        );

        processModulesObservable$.subscribe((values: ProductModule[]) => {
            this.allModules = values;
            this.modulesObserver.next(values);
        });

        return processModulesObservable$;
    }

    private translateModuleSync(moduleKey: string, startPageTitle = false) {
        return {
            title: !startPageTitle && `home.modules.${moduleKey}.title`,
            description: `home.modules.${moduleKey}.description`,
            startPageTitle: startPageTitle && `home.modules.${moduleKey}.start_page_title`,
        };
    }

    private configureTaggingModule(module): ProductModule {
        const binsEnabled = this.hospitalInfoService.allowShelvedInventory();
        let customTaggingAttributes = {} as Partial<ProductModule>;
        let moduleKey;

        if (binsEnabled && this.printerless) {
            moduleKey = 'tagging_bins_printerless';
        } else if (binsEnabled) {
            moduleKey = 'tagging';
        } else if (!binsEnabled && this.printerless) {
            moduleKey = 'tagging_binless_printerless';
        } else if (!binsEnabled) {
            moduleKey = 'tagging_binless_printered';
        }

        customTaggingAttributes = this.translateModuleSync(moduleKey, true);
        return {
            ...module,
            ...customTaggingAttributes,
            hideMenu: false,
            title: 'home.modules.tagging.title',
            startPageTitle: `home.modules.${moduleKey}.start_page_title`,
        };
    }

    private setDropdownItemTranslations(module: ProductModule) {
        const taggingPrintKey = this.printerless ? 'printerless.' : 'printered.';

        const moduleName = module.name;
        return module.dropdown.items.map((dropdownItem) => {
            const dropdownKey = dropdownItem.titleKey;
            const taggingAdd =
                moduleName === 'tagging' && dropdownKey !== 'pre_tagged_medications' ? taggingPrintKey : '';

            return {
                ...dropdownItem,
                title: `header.dropdown.${moduleName}.${taggingAdd}${dropdownKey}`,
            };
        });
    }

    setModule(moduleName: string = '') {
        if (!this.groupLoginService.isLoggedIn() && !this.groupLoginService.requirePassword()) {
            this.router.navigate(['/login']);
        }

        if (moduleName !== '') {
            const activeModuleSubscription$ = this.processModules().subscribe((modules: ProductModule[]) => {
                if (!this.isModuleAllowed(moduleName)) {
                    this.router.navigate(['/']);
                } else {
                    const newModules = modules.map((m) => (m.name === moduleName ? { ...m, isActive: true } : m));
                    this.allModules = newModules;
                    this.modulesObserver.next(newModules);
                }

                activeModuleSubscription$.unsubscribe();
            });
        }

        this.kcMatSnackBarService.clearAll();
    }

    getModule(moduleName: string): ProductModule {
        return this.getAvailableModules().find((m) => m.name === moduleName);
    }

    getAvailableModules(): ProductModule[] {
        return this.allModules.filter((m) => this.isModuleAllowed(m.name));
    }

    private isModuleAllowed(module) {
        switch (module) {
            case ModuleTypes.INVENTORY:
                return this.actionService.isAllowAction(
                    'kits_inventory',
                    'view_kits_inventory',
                    'Enable inventory section'
                );
            case ModuleTypes.TAGGING:
                return this.actionService.isAllowAction('kits_tagging', 'view_kits_tagging', 'Enable tagging section');
            case ModuleTypes.REPORTING:
                return this.actionService.isAllowAction(
                    'kits_reporting',
                    'view_kits_reports',
                    'Enable reports section'
                );
            case ModuleTypes.HELP:
                return true;
            default:
                return false;
        }
    }

    private buildAllModules() {
        const inventory: ProductModule = {
            name: 'inventory',
            stateName: '/inventory',
            startPageTitleKey: 'settings.start_page.show_inventory',
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: '/inventory/containers',
                        titleKey: 'containers',
                        allowed: () => true,
                    },
                    {
                        stateName: '/inventory',
                        titleKey: 'kits',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kits_inventory',
                                'Kits dropdown'
                            );
                        },
                    },
                    {
                        stateName: '/inventory/bins',
                        titleKey: 'bins',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.allowShelvedInventory() &&
                                this.actionService.isAllowAction('kits_inventory', 'view_bin', 'Bins dropdown')
                            );
                        },
                    },
                    {
                        stateName: '/inventory/carts',
                        titleKey: 'carts',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().kit_to_cart_enabled &&
                                this.actionService.isAllowAction('hospital_settings', 'view_cart', 'Carts dropdown')
                            );
                        },
                    },
                    {
                        stateName: '/inventory/manage-kit-masters',
                        titleKey: 'manage_kit_masters',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kit_master',
                                'Kit masters dropdown'
                            );
                        },
                    },
                    {
                        stateName: '/inventory/manage-formulary',
                        titleKey: 'manage_formulary',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'hospital_settings',
                                'create_formulary_item',
                                'Manage Formulary dropdown'
                            );
                        },
                    },
                    {
                        stateName: '/inventory/manage-items',
                        stateParams: { epc: null },
                        titleKey: 'manage_items',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_tagging',
                                'view_item_tag',
                                'Manage Items dropdown'
                            );
                        },
                    },
                    {
                        stateName: '/inventory/shortage-report',
                        titleKey: 'shortage_report',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'view_kits_inventory',
                                'Kits dropdown'
                            );
                        },
                    },
                ],
            },
        };

        const tagging: ProductModule = {
            name: 'tagging',
            stateName: '/tagging',
            type: 'item',
            hideMenu: false,
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: 'tagging',
                        type: 'item',
                        titleKey: 'items',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_tagging',
                                'create_item_tag',
                                'Print Item Tags dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'tagging',
                        type: 'kit',
                        titleKey: 'kits',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_inventory',
                                'create_kit',
                                'Print Kit Tags dropdown'
                            );
                        },
                    },
                    {
                        stateName: 'tagging',
                        type: 'bin',
                        titleKey: 'bins',
                        allowed: () => {
                            return (
                                this.actionService.isAllowAction(
                                    'kits_inventory',
                                    'create_bin',
                                    'Print Bin Tags dropdown'
                                ) && this.hospitalInfoService.allowShelvedInventory()
                            );
                        },
                    },
                    {
                        stateName: 'pre-tagged-medications',
                        titleKey: 'pre_tagged_medications',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().charge_model_type?.name ===
                                ChargeModelTypeName.pay_as_you_go
                            );
                        },
                    },
                ],
            },
        };

        const reporting: ProductModule = {
            name: 'reporting',
            stateName: '/reports',
            startPageTitleKey: 'settings.start_page.reports',
            headerOnly: false,
            dropdown: {
                items: [
                    {
                        stateName: '/reports',
                        titleKey: 'reports',
                        allowed: () => {
                            return this.actionService.isAllowAction(
                                'kits_reporting',
                                'view_kits_reports',
                                'Reports dropdown'
                            );
                        },
                    },
                    {
                        stateName: '/system-reports',
                        titleKey: 'system_reports',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.hasIdnRoot() &&
                                this.actionService.isAllowAction(
                                    'kits_reporting',
                                    'view_system_reports',
                                    'Reports dropdown'
                                )
                            );
                        },
                    },
                    {
                        stateName: '/manage-subscriptions',
                        titleKey: 'manage_subscriptions',
                        allowed: () => {
                            return (
                                this.hospitalInfoService.getHospitalSettings().scheduled_reports_enabled &&
                                this.actionService.isAllowAction(
                                    'kits_reporting',
                                    'view_kits_reports',
                                    'Reports dropdown'
                                )
                            );
                        },
                    },
                ],
            },
        };

        this.allModules = [inventory, tagging, reporting];
    }
}
