import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { HardwareResource } from '@resources/hardware-resource.service';

@Injectable({ providedIn: 'root' })
export class SingleHardwareResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private hardwareResource: HardwareResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const hardwareId = parseInt(activatedRoute.params['hardwareId']);
        const hardwarePromise = this.hardwareResource.hardwareData(hardwareId).then(({ hardware }) => {
            return hardware;
        });
        return this.loadingSpinnerService.spinnerifyPromise(hardwarePromise);
    }
}
