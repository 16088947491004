import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationService } from '@services/system/application.service';
import { KitSummary } from '@models/core/kit-summary';
import { ScanResult } from '@models/core/scan';
import { getScanUsername } from '@utils/kit-summary-util';

@Injectable()
export class MobileCartService {
    allowHomeNavigate: boolean = false;
    kitSummary: KitSummary;
    kitScannedByUser: string;
    showScanButton: boolean = false;
    showStandardSubheader: boolean = true;
    subheaderText: string = '';
    urls: string[];

    constructor(
        private applicationService: ApplicationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        route.url.subscribe((urls) => (this.urls = urls.map((urlSegment) => urlSegment.path)));
    }

    setupMobileCart() {
        // route to landing page since home component would be blank otherwise
        if (this.urls.length === 1 && this.urls[0] === 'mobile') {
            this.router.navigate(['/mobile/home']);
        }

        // allow desktop-to-mobile navigation
        this.applicationService.isMobile = true;
    }

    showScanSummaryHeader(kitSummary: KitSummary) {
        this.showScanButton = true;
        this.showStandardSubheader = false;
        this.subheaderText = '';
        this.kitSummary = kitSummary;
        this.kitScannedByUser = getScanUsername(kitSummary.scan);
    }

    goToScanSummary(scanResult: ScanResult) {
        this.router.navigate([`/mobile/scan-summary/${scanResult.kit_ids[0]}/${scanResult.id}`]);
    }

    goToDesktop() {
        this.applicationService.isMobile = false;
        this.allowHomeNavigate = true;
        this.router.navigate(['/']);
    }
}
