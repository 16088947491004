import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from '@services/utils/action.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

@Component({
    selector: 'inventory-kit-masters-list',
    templateUrl: './kit-masters-list.html',
    styleUrls: ['./kit-masters-list.scss'],
})
export class KitMastersList {
    protected kitMasters;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private actionService: ActionService,
        private kitMasterService: KitMasterService,
        private productModuleService: ProductModuleService
    ) {
        productModuleService.setModule(ModuleTypes.INVENTORY);
        kitMasterService.selectedKitMaster = null;
    }

    ngAfterViewInit() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.kitMasters);
        this.dataSource.sort = this.sort;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.kitMasters = data.kitMasters;
        });
    }

    newKitMaster() {
        this.router.navigate(['/inventory/kit-master-create']);
    }
}
