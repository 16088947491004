import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { ActionService } from '@services/utils/action.service';
import { ConfigurationProvider } from '@services/config/configuration';
import { GroupLoginService } from '@services/login/group-login.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { KitMasterResource } from '@resources/kit-master-resource.service';
import { KitMasterService } from '@services/core/kit-master.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ProductModuleService, ModuleTypes } from '@services/core/product-module.service';
import { SegmentsService } from '@services/core/segments.service';
import { TranslationService } from '@services/utils/translation.service';

import { CloneKitMasterDialog } from './clone-kit-master/clone-kit-master-dialog';
import { ArchiveKitMasterDialog } from './archive-kit-master/archive-kit-master-dialog';
import { UploadCsvDialog } from '@components/dialogs/upload-csv/upload-csv-dialog';

import { KitMaster } from '@models/core/kit-master';
import { Segment } from '@models/core/segment';
import { SegmentTemplate } from '@models/core/segment-template';

@Component({
    selector: 'inventory-single-kit-master',
    templateUrl: './single-kit-master.html',
    styleUrls: ['./single-kit-master.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SingleKitMaster {
    kitMasters: KitMaster[];
    segments: Segment[];

    multiFillTooltip: string;
    kitMaster: KitMaster;
    kitMasterId: number;
    colWidths = {};

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['name', 'quantity', 'billing_code'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private actionService: ActionService,
        private configuration: ConfigurationProvider,
        private groupLoginService: GroupLoginService,
        private hospitalInfoService: HospitalInfoService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private kitMasterResource: KitMasterResource,
        private kitMasterService: KitMasterService,
        private loadingSpinnerService: LoadingSpinnerService,
        private productModuleService: ProductModuleService,
        private segmentsService: SegmentsService,
        private translationService: TranslationService
    ) {
        this.productModuleService.setModule(ModuleTypes.INVENTORY);
    }

    ngOnInit() {
        // we assign both kitMaster and ui.kitMaster intentionally
        // ui.kitMaster represents the kitMaster selection of the dropdown, kitMaster
        // represents the target kitMaster for the page
        this.activatedRoute.data.subscribe((data) => {
            this.kitMasters = data.kitMasters;
            this.segments = data.segments;
            this.setupSelectedKitMaster();
            this.setupSegments();
        });

        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.kitMasterId = parseInt(paramMap.get('kitMasterId'));
            this.setupSelectedKitMaster();
        });

        this.multiFillTooltip = this.translationService.instant('single_kit_master.multi_fill_tooltip');
    }

    setupSelectedKitMaster() {
        this.kitMaster = this.kitMasters.find((kit_master) => kit_master.id === this.kitMasterId);
        this.kitMasterService.selectedKitMaster = this.kitMasters.find(
            (kit_master) => kit_master.id === this.kitMasterId
        );
    }

    ngAfterViewInit(): void {
        this.setupSegmentsTable();
    }

    setupSegmentsTable() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.segments);
        this.dataSource.sort = this.sort;
    }

    setupSegments(): void {
        this.segments.forEach((segment) => {
            if (segment.segment_templates.length > 1 || !segment.segment_templates.length) {
                segment.billing_code = '-';
                segment.quantity = '-';
            } else {
                const firstTemplate: SegmentTemplate = segment.segment_templates[0];
                segment.billing_code = firstTemplate.billing_code;
                segment.quantity = firstTemplate.quantity;
            }

            if (segment.segment_templates.length > 1) {
                segment.multiFill = true;
            }
        });

        if (this.dataSource) {
            this.dataSource.data = this.segments;
        }

        this.setupSegmentsTable();
    }

    onChangeKitMaster(): void {
        this.router
            .navigate([`/inventory/manage-kit-masters/${this.kitMasterService.selectedKitMaster.id}`])
            .then(() => {
                this.setupSelectedKitMaster();
            });
    }

    segmentTemplateEditDialog(): void {
        this.segmentsService
            .segmentTemplateEdit(null, this.kitMasterId, null)
            .then(() => {
                this.segmentsService.clear(this.kitMasterId);
                const segmentsPromise = this.segmentsService.getSegments(this.kitMasterId).then((segments) => {
                    this.segments = segments;
                    this.setupSegments();
                    this.kcMatSnackBarService.open(
                        SnackBarTypes.SUCCESS,
                        this.translationService.instant('manage_segment.success.created')
                    );
                });
                this.loadingSpinnerService.spinnerifyPromise(segmentsPromise);
            })
            .catch(() => {});
    }

    cloneKitMasterModal(): void {
        const cloneKitMasterDialog = this.dialog.open(CloneKitMasterDialog, {
            width: '700px',
            height: 'max-content',
            data: {
                kitMaster: this.kitMasterService.selectedKitMaster,
            },
        });
    }

    async archiveKitMaster() {
        const objType = await this.translationService.get('single_kit_master.kit_master');
        const objName = await this.translationService.get('single_kit_master.this_kit_master');
        const title = await this.translationService.get('modals.confirm_archive.header', { objType: objType });
        const description = await this.translationService.get('modals.confirm_archive.confirm', { objName: objName });

        const confirmDialog = this.dialog.open(ArchiveKitMasterDialog, {
            width: '550px',
            height: 'max-content',
            data: {
                kitMaster: this.kitMaster,
            },
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const archivePromise = this.kitMasterResource.archiveKitMaster(this.kitMaster).then(() => {
                    setTimeout(() => {
                        this.kcMatSnackBarService.open(
                            SnackBarTypes.SUCCESS,
                            this.translationService.instant('archive_kit_master.success', {
                                kit_master_name: this.kitMaster.name,
                            })
                        );
                    }, 500);
                    this.router.navigate(['/inventory/manage-kit-masters']);
                });
                return this.loadingSpinnerService.spinnerifyPromise(archivePromise);
            }
        });
    }

    done(): void {
        this.router.navigate(['/inventory/manage-kit-masters']);
    }

    showUploadModal(): void {
        const noun = this.translationService.instant('single_kit_master.upload_noun');
        const uploadDialog = this.dialog.open(UploadCsvDialog, {
            width: '600px',
            height: 'max-content',
            data: {
                noun: noun,
                url: `${this.configuration.kcEndpointV2()}segments/import`,
                kitMasterId: this.kitMasterId,
                authToken: this.authToken(),
            },
        });

        uploadDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const segmentsPromise = this.segmentsService
                    .getSegments(this.kitMasterId, true)
                    .then((data) => {
                        this.segments = data;
                        this.setupSegments();
                    })
                    .catch((err) => {
                        if (_.get(err, 'message')) {
                            this.kcMatSnackBarService.open(SnackBarTypes.ERROR, err.message);
                        }
                    });

                return this.loadingSpinnerService.spinnerifyPromise(segmentsPromise);
            }
        });
    }

    authToken(): string {
        return this.groupLoginService.authToken();
    }

    async downloadSegmentCSV() {
        let csvData = await this.kitMasterResource.downloadSegments(this.kitMasterId);
        const hospName = _.snakeCase(this.hospitalInfoService.getHospitalName());
        let downloadDate = moment().format('DD-MMM-YYYY');
        let filename = `${hospName}_${this.kitMaster.name}_${downloadDate}.csv`;

        const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
        if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.setAttribute('download', filename);
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        return;
    }

    toggle(lineItem): void {
        lineItem.showAlternates = !lineItem.showAlternates;
        this.colWidths = {
            name: document.querySelector('.mat-column-name').getBoundingClientRect().width,
            quantity: document.querySelector('.mat-column-quantity').getBoundingClientRect().width,
            billing_code: document.querySelector('.mat-column-billing_code').getBoundingClientRect().width,
        };
    }
}
