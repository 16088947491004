import { ScannedItem } from './scan';

interface URRITag {
    ROSpecID: Array<any>;
    SpecIndex: Array<any>;
    InventoryParameterSpecID: Array<any>;
    AntennaID: Array<number>;
    PeakRSSI?: number;
    ChannelIndex: Array<number>;
    FirstSeenTimestampUTC?: number;
    LastSeenTimestampUTC: string;
    TagSeenCount: number;
    EPC: string;
}

export class UniversalRfidReaderTag {
    private AntennaID: number;
    private PeakRSSI?: number;
    private TagSeenCount: number;
    private EPC: string;

    constructor(tag: URRITag) {
        this.EPC = tag.EPC;
        this.AntennaID = tag.AntennaID.pop();
        this.TagSeenCount = tag.TagSeenCount;
        this.PeakRSSI = tag.PeakRSSI;
    }

    standardize(): ScannedItem {
        return {
            epc: this.EPC,
            antenna: this.AntennaID,
            repeat: this.TagSeenCount,
            rssi: this.PeakRSSI,
        };
    }
}
