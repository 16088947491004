import { Component, Input, inject } from '@angular/core';
import { ActionService } from '@services/utils/action.service';

@Component({
    selector: 'epc-link',
    template: `
        <span *ngIf="this.includeLabel" class="{{ this.labelClass }}">{{ this.label | translate }}:</span>
        <a *ngIf="this.showLink" routerLink="/inventory/manage-items/{{ this.fullEpc }}" class="btn btn-link">
            {{ this.epcLinkText }}
        </a>
        <span *ngIf="!this.showLink">
            {{ this.epcLinkText }}
        </span>
    `,
})
export class EpcLink {
    showLink: boolean = false;
    includeLabel: boolean;
    @Input() epcLinkText!: string;
    @Input() fullEpc!: string;
    @Input() label: string = '';
    @Input() labelClass: string = 'label';
    constructor(private actionService: ActionService) {}
    ngOnInit() {
        this.showLink = this.actionService.isAllowAction('kits_tagging', 'view_item_tag', 'Show link to manage items');
        this.includeLabel = this.label.length > 0;
    }
}
