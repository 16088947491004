import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ActionService } from '@services/utils/action.service';
import { ApplicationService } from '@services/system/application.service';
import { HardwareService } from '@services/hardware/hardware.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ProductModuleService } from '@services/core/product-module.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss'],
})
export class Home {
    firstName: string;
    showWelcomePage: boolean;
    showAccessMessage: boolean;
    transferredMessage: string;
    newVerification: string;
    isBrandNav: boolean;

    constructor(
        protected actionService: ActionService,
        private applicationService: ApplicationService,
        protected hardwareService: HardwareService,
        protected localStorageService: LocalStorageService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private productModuleService: ProductModuleService,
        protected translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        const isBrandNav = this.router.getCurrentNavigation()?.extras?.state?.isBrandNav;
        if (!isBrandNav) {
            this.redirectToUserSetHomepage();
        }
    }

    ngOnInit() {
        this.setupPermissions();

        this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
            this.transferredMessage = queryParamMap.get('transferredMessage');
            this.newVerification = queryParamMap.get('newVerification');
        });
        this.firstName = this.localStorageService.get('firstName');

        if (this.applicationService.isMobile) {
            // mobile user unintentionally navigated to desktop
            this.router.navigate(['/mobile/home']);
        }
        this.productModuleService.setModule();

        if (this.newVerification === 'scan-details') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'add_to_bin.items_were_added_to_bin',
                params: { bin: this.transferredMessage },
            });
        } else if (this.newVerification === 'scan-details-remove') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'add_to_bin.items_were_removed_from_bin',
                params: { bin: this.transferredMessage },
            });
        } else if (this.newVerification === 'batch-verify') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'verify_and_add_to_bin.items_were_verified_and_added_to_bin',
                params: { bin: this.transferredMessage },
            });
        } else if (this.newVerification === 'batch-verify-remove') {
            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                key: 'verify_and_add_to_bin.items_were_verified_and_removed_from_bin',
                params: { bin: this.transferredMessage },
            });
        } else if (this.newVerification === 'verify') {
            if (this.transferredMessage) {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'batch_verify.your_items_have_been_verified_and',
                });
            } else {
                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                    key: 'batch_verify.your_items_have_been_verified',
                });
            }
        }

        this.productModuleService.processModules();
    }

    setupPermissions() {
        this.showWelcomePage = this.actionService.isAllowAction('kits', 'view_main_kits_menu', 'Show Welcome page');
        this.showAccessMessage = this.actionService.isAllowAction(
            'kits',
            'view_main_kits_menu',
            'NOT - Show No Access message'
        );
    }

    redirectToUserSetHomepage() {
        const defaultPage = this.localStorageService.get('defaultPage');
        if (defaultPage) {
            const module = this.productModuleService.getModule(defaultPage);
            if (module) {
                this.router.navigate([module.stateName]);
            }
        }
    }
}
