import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Report, ReportCategory } from '@models/core/report';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ReportResource } from '@services/resources/report-resource.service';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';

import { Batch } from '@models/core/batch';
import { BatchItems } from '@models/core/batch-items';

import { titleize } from '@utils/strings';

interface PrintBatch {
    trusted: boolean;
    verified: boolean;
}

interface Filters {
    tag_association_batch_id?: number;
    created_at?: string;
    formulary_item_id?: number;
    lot_num?: string;
    hospital_ownership?: string;
}

@Component({
    selector: 'report-tagged-item-batch-summary',
    templateUrl: './report-tagged-item-batch-summary.html',
    styleUrls: ['./report-tagged-item-batch-summary.scss'],
})
export class ReportTaggedItemBatchSummary {
    reports: {
        report_categories: ReportCategory[];
    };

    report: Report;
    isDownloadable: boolean = true;
    reportDate: Date;
    filters: Filters;
    hasData: boolean;
    hasRun: boolean = false;
    batchItems: BatchItems[];
    batch: Batch;
    showEpcsInReport: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'epc',
        'trusted',
        'trusted_reason',
        'verified',
        'verification_method',
        'verified_by',
        'verified_at',
    ];

    @ViewChild(MatSort) sort: MatSort;
    batchId: number;
    hospitalOwnership: string;
    createdAt: string;
    lotNum: string;
    formularyItemId: number;

    constructor(
        protected reportResource: ReportResource,
        private hospitalInfoService: HospitalInfoService,
        private loadingSpinnerService: LoadingSpinnerService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.reports = data.reports;
        });

        this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
            this.batchId = parseInt(queryParamMap.get('batchId'));
            this.hospitalOwnership = queryParamMap.get('hospitalOwnership');
            this.createdAt = queryParamMap.get('createdAt');
            this.lotNum = queryParamMap.get('lotNum');
            this.formularyItemId = parseInt(queryParamMap.get('formularyItemId'));
        });
        this.reportDate = new Date();
        this.showEpcsInReport = this.hospitalInfoService.getHospitalSettings().show_epcs_in_batch_summary_report;

        const reportCategory = this.reports.report_categories.find(
            (report_category) => report_category.name === 'activity'
        );
        this.report = reportCategory.reports.find((report) => report.name === 'print_batch_detail');

        if (this.batchId) {
            this.filters = {
                tag_association_batch_id: this.batchId,
                hospital_ownership: this.hospitalOwnership,
            };
        } else {
            // this is likely not used anymore... I couldn't find a place that called this report with these params, but leaving for now.
            this.filters = {
                created_at: this.createdAt,
                formulary_item_id: this.formularyItemId,
                lot_num: this.lotNum,
                hospital_ownership: this.hospitalOwnership,
            };
        }

        // not a typical report with filters, so we need to run it on load.
        this.refreshReport();
    }

    showSpinner(): boolean {
        return this.loadingSpinnerService.showSpinner;
    }

    refreshReport(): void {
        const promise = this.reportResource.printBatchDetailReport(this.filters).then((data) => {
            data.print_batch_details.forEach((item) => {
                if (item.trusted_reason) {
                    item.trusted_reason = titleize(item.trusted_reason.split('_').join(' '));
                }
                if (item.verification_method) {
                    item.verification_method = titleize(item.verification_method.split('_').join(' '));
                }
            });
            this.batchItems = data.print_batch_details;
            data.print_batch.quantity_restricted = this.batchItems.filter((item: PrintBatch) => {
                return !item.trusted;
            }).length;
            data.print_batch.quantity_trusted = this.batchItems.filter((item: PrintBatch) => {
                return !!item.trusted;
            }).length;
            data.print_batch.quantity_verified = this.batchItems.filter((item: PrintBatch) => {
                return !!item.verified;
            }).length;
            this.batch = data.print_batch;
            this.reportDate = new Date();
            this.dataSource = new MatTableDataSourceWithNaturalSort(this.batchItems);
            this.hasRun = true;
            this.hasData = !!this.dataSource.data.length;
            this.dataSource.sort = this.sort;
        });
        this.loadingSpinnerService.spinnerifyPromise(promise);
    }
}
