import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CartResource } from '@resources/cart-resource.service';

@Injectable({ providedIn: 'root' })
export class CartResolver {
    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private cartResource: CartResource
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Promise<any> {
        const cartId = parseInt(activatedRoute.params.cartId);

        return this.loadingSpinnerService.spinnerifyPromise(this.cartResource.cartData(cartId)).then(({ cart }) => {
            return cart;
        });
    }
}
