import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from '@services/utils/action.service';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { BlockedKit } from '@models/core/kit';
import { HospitalInfoService } from '@services/core/hospital-info.service';

@Component({
    selector: 'kit-block',
    templateUrl: './kit-block.html',
})
export class KitBlock implements OnInit, AfterViewInit {
    blockedKit: BlockedKit;

    kitId: number;
    scanId: number;
    loading: boolean = false;
    canadianHospital: boolean = false;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['item_name', 'epc', 'lot_num'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.kitId = parseInt(paramMap.get('kitId'));
            this.scanId = parseInt(paramMap.get('scanId'));
        });
        this.canadianHospital = this.hospitalInfoService.canadianHospital();

        this.activatedRoute.data.subscribe((data) => {
            this.blockedKit = data.blockedKit;

            if (!this.blockedKit || !this.blockedKit.kit || !this.blockedKit.untrusted_items) {
                this.router.navigate([`/kit/${this.kitId}/${this.scanId}`]);
            }
        });
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.blockedKit.untrusted_items);
        this.dataSource.sort = this.sort;
    }

    goManageItems(): void {
        this.loading = true;
        this.router.navigate(['/inventory/manage-items']);
    }
}
