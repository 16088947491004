import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';

import { NgxLoginService } from '@services/ngx-login/ngx-login/ngx-login.service';
import { NgxLoginEventService } from '@services/ngx-login/ngx-login-event/ngx-login-event.service';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.dirty && form.invalid;
    }
}

@Component({
    selector: 'lib-ngx-password-reset',
    templateUrl: './ngx-password-reset.component.html',
    styleUrls: ['./ngx-password-reset.component.scss'],
})
export class NgxPasswordResetComponent implements OnInit {
    hasPasswordRules: boolean;
    minPasswordLength: number;
    maxPasswordLength: number;
    passwordSettings: any;
    password_complexity_rules: any;
    preventPasswordReuse: boolean;
    resetPressed: boolean = false;
    token: string;

    resetPwForm: FormGroup;
    errorMatcher = new CrossFieldErrorMatcher();
    @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loginService: NgxLoginService,
        private ngxLoginEventService: NgxLoginEventService,
        private fb: FormBuilder
    ) {
        this.resetPwForm = this.fb.group(
            {
                password: '',
                confirmPassword: '',
            },
            {
                validator: this.passwordValidator,
            }
        );
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((queryParamMap) => (this.token = queryParamMap.get('reset_token')));
        setTimeout(() => {
            this.getPasswordRules();
            this.passwordInput.nativeElement.focus();
        }, 1000);
    }

    passwordValidator(form: FormGroup) {
        const condition = form.get('password').value !== form.get('confirmPassword').value;
        return condition ? { passwordsDoNotMatch: true } : null;
    }

    // get rules from service with the token
    // error if the token has expired
    getPasswordRules() {
        this.loginService.getHospitalPasswordRules(this.token).subscribe(
            (rules) => {
                this.passwordSettings = rules.password_settings;
                this.minPasswordLength = this.passwordSettings.min_length;
                this.maxPasswordLength = this.passwordSettings.max_length;
                this.preventPasswordReuse = this.passwordSettings.prevent_password_reuse;
                this.password_complexity_rules = this.passwordSettings.password_complexity_rules;
                this.hasPasswordRules = this.hasAnyRules(this.passwordSettings);
            },
            (err) => this.tokenExpired(err)
        );
    }

    hasAnyRules(rules): boolean {
        const complexityRules: any[] = rules.password_complexity_rules;
        return !!rules.minPasswordLength || !!rules.maxPasswordLength || (complexityRules && !!complexityRules.length);
    }

    hasOnlyMinRequirement(): boolean {
        return !!this.minPasswordLength && !this.maxPasswordLength;
    }

    hasOnlyMaxRequirement(): boolean {
        return !this.minPasswordLength && !!this.maxPasswordLength;
    }

    hasBothLengthRequirements(): boolean {
        return !!this.minPasswordLength && !!this.maxPasswordLength;
    }

    resetPassword(password: string): void {
        this.resetPressed = true;

        const user = {
            password: password,
            token: this.token,
        };

        this.loginService
            .resetPassword(user)
            .subscribe(
                ({ user }) => {
                    this.loginService.setLocalStorageItem('passwordResetRequired', false);
                    user.password_reset_required = false;

                    this.ngxLoginEventService.triggerLogin();
                    this.loginService.showToast(`${user.first_name} ${user.last_name} logged in successfully.`, 3000);

                    if (!user.current_hospital) {
                        this.loginService.storeDataForSwitch(user);
                        this.router.navigate(['/switch-hospital']);
                    } else {
                        this.loginService.initiateLogin(user);
                        this.ngxLoginEventService.triggerHospitalSelect();
                    }
                },
                (err) => {
                    this.handleError(err);
                }
            )
            .add(() => {
                this.resetPressed = false;
            });
    }

    handleError(err): void {
        this.loginService.showToast(err.message);
    }

    tokenExpired(err): void {
        this.router.navigate(['/password-reset-expired']);
    }

    cancelSwitch(): void {
        this.router.navigate(['/login']);
    }
}
